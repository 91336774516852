import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Ranges from "ewoq/prototypes/Ranges";

import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";

import CoffeeCup from "ewoq/prototypes/icons/coffeecuppurple.png";

const questions: QuestionType[] = [
  {
    id: "LQ_DRI_1.1",
    mandatory: true,
    plainTitle: "Regular caffeine consumption",
    title:
      "Do you regularly consume coffee, tea, or other caffeinated beverages?",
    explanation: "This means at least 2-3 times per week.",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "Sometimes" }]}
      />
    )
  },
  {
    id: "LQ_DRI_1",
    mandatory: true,
    plainTitle: "Caffeine consumption time",
    title: (
      <span>
        Indicate in which time ranges you consume coffee, tea, or other
        caffeinated drinks:
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_1.1");

      return a[idx].data !== undefined && a[idx].data["answer"] !== 1;
    },
    component: v => (
      <Ranges.Question
        onValid={v}
        labels={[
          "12-2 AM",
          "2-4 AM",
          "4-6 AM",
          "6-8 AM",
          "8-10 AM",
          "10 AM to 12 PM",
          "12-2 PM",
          "2-4 PM",
          "4-6 PM",
          "6-8 PM",
          "8-10 PM",
          "10AM to 12 PM"
        ]}
      />
    )
  },
  {
    id: "LQ_DRI_2",
    mandatory: true,
    plainTitle: "Choice of caffeine drink",
    title: (
      <span>
        Please indicate which caffeinated drinks you consume more than once per
        week:
      </span>
    ),
    explanation: (
      <span>Consider teas, caffeinated soft drinks, coffee, etc.</span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 1;
    },
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_DRI_2.1",
            title: "Drink type:",
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
                placeholder="e.g. cappucino"
              />
            )
          },
          {
            id: "LQ_DRI_2.2",
            title: "Size",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.picture}
                labels={["Espresso small", "Normal", "American"]}
                picture={CoffeeCup}
                includeDone={false}
              />
            )
          },
          {
            id: "LQ_DRI_2.3",
            title: "Time of day:",
            component: v => (
              <Ranges.Question
                onValid={v}
                labels={["Morning", "Afternoon", "Evening"]}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_DRI_3",
    mandatory: true,
    plainTitle: "Decaf?",
    title: <span>Do you drink decaffeinated coffee?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Sometimes", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DRI_4",
    mandatory: false,
    plainTitle: "Effect of caffeine",
    title: <span>How much does coffee affect you?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Optimal alertness", "Jittery"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DRI_5",
    mandatory: true,
    plainTitle: "Coffee impact in evening",
    title: (
      <span>Does coffee impact your sleep if you drink it in the evening?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Sometimes", "Always"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DRI_6.1",
    mandatory: false,
    plainTitle: "Do you like coffee?",
    title: <span>How much do you enjoy drinking coffee?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Somewhat", "I love it"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DRI_7.1",
    mandatory: true,
    plainTitle: "Do you consume alcohol?",
    title: "Do you consume alcohol (beer, wine, liquor, etc)?",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DRI_6.2",
    mandatory: false,
    plainTitle: "Do you enjoy alcohol?",
    title: <span>How much do you enjoy drinking alcohol?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Somewhat", "I love it"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DRI_7.2",
    mandatory: true,
    plainTitle: "How often do you consume alcohol?",
    title: <span>In the past week, how often have you consumed alcohol?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Input.Question onValid={v} inputType={"number"} placeholder={"Days"} />
    )
  },
  {
    id: "LQ_DRI_7.3",
    mandatory: false,
    plainTitle: "Drink Frequency",
    title: (
      <span>How many drinks did you have this past week, on average?</span>
    ),
    shouldShow: (a: Question[]) => {
      const idxEverDrink = a.findIndex(a => a.id === "LQ_DRI_7.1");
      const idxRecentDrink = a.findIndex(a => a.id === "LQ_DRI_7.2");
      return (
        a[idxEverDrink].data !== undefined &&
        a[idxEverDrink].data["answer"] > 0.1 &&
        a[idxRecentDrink].data !== undefined &&
        parseInt(a[idxRecentDrink].data["text"]) > 0
      );
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Wine", extraInput: "number", placeHolder: "glasses" },
          { text: "Beer", extraInput: "number", placeHolder: "bottles/cans" },
          { text: "Cocktail", extraInput: "number", placeHolder: "glasses" },
          { text: "Hard liquor", extraInput: "number", placeHolder: "shots" }
        ]}
      />
    )
  },
  {
    id: "LQ_DRI_8.1",
    mandatory: true,
    plainTitle: "Past month alcohol consumption",
    title: <span>In the past month, how often have you consumed alcohol?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="days" />
    )
  },
  {
    id: "LQ_DRI_8.2",
    mandatory: false,
    plainTitle: "Getting drunk frequency",
    title: <span>In the past month, how often have you gotten drunk?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="days" />
    )
  },
  {
    id: "LQ_DRI_9",
    mandatory: false,
    plainTitle: "Preferred drink",
    title: <span>What are your preferred drinks?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Wine" },
          { text: "Beer" },
          { text: "Cocktails" },
          { text: "Hard liquor" },
          { text: "Other", extraInput: "string" }
        ]}
        allAbove={true}
      />
    )
  },
  {
    id: "LQ_DRI_10",
    mandatory: false,
    plainTitle: "Drinking start age",
    title: (
      <span>
        At what age did you start drinking enough to get drunk more than once
        per month?
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_DRI_7.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        extraRadio={["I never drank that much"]}
      />
    )
  },
  {
    id: "LQ_DRI_11",
    mandatory: true,
    plainTitle: "Alcohol sensitivity",
    title: <span>Are you very sensitive to alcohol?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely", "Not at all"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  }
];
export default questions;
