import { getUserId } from "./utils";

const BASE_URL = "/persist-questionnaire";

const VERSION = 1;

export const obtainUserId = () => {
  const options = {
    credentials: "include"
  };
  return fetch(`${BASE_URL}/whoami`, options as any);
};

export interface ApiRequest {
  project: string;
  questionnaire: string;
  priv?: object;
  pub?: object;
  answerId: string;
  isOnline: boolean;
  userId: string;
}

export const answerQuestionnaire = (
  project: string,
  questionnaire: string,
  priv?: object,
  pub?: object
) => {
  const userId = getUserId();
  if (!userId) {
    obtainUserId();
  }

  saveAndSend({
    project,
    questionnaire,
    priv,
    pub,
    // answerId: `${new Date().toISOString()}_${questionnaire}_${getUserId() ||
    //   "none"}`,
    answerId: `${new Date().toISOString()}_${questionnaire}_${getUserId()}`,
    isOnline: false,
    userId: getUserId() || "none"
  });
};

const saveAndSend = (req: ApiRequest) => {
  putQueue(req);
  setTimeout(sendQueue, 500);
};

const putQueue = (q: ApiRequest) => {
  const i = `queue-${q.answerId}`;
  localStorage.setItem(i, JSON.stringify(q));
  console.log("saved to queue as: ", i);
  return i;
};

export const sendQueue = () => {
  console.log("Start syncronization to cloud");
  listQueue().forEach(i => {
    const q = JSON.parse(localStorage[i]) as ApiRequest;
    if (!q.isOnline) {
      console.log("Found a questionnaire that was not online, uploading: ", i);
      sendResponseApi(q, i).then(a => checkQuestionnaire());
    }
  });
};

export const sendResponseApi = (q: ApiRequest, queueId: string) => {
  const options = {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    // mode: "no-cors",
    body: JSON.stringify({
      private: { ...q.priv, ewoq_version: VERSION },
      public: q.pub || {},
      answerId: q.answerId
    })
  };

  const f = fetch(
    `${BASE_URL}/respond/${q.project}/${q.questionnaire}`,
    options as any
  );

  f.then(function(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    console.log("Uploaded to server.");

    return response;
  }).catch(e => console.log("Could not contact the server"));
  return f;
};

const checkQuestionnaire = () => {
  console.log("Checking that all questionnaires exists online");

  if (listQueue().every(r => JSON.parse(localStorage[r]).isOnline)) {
    return;
  }

  if (listQueue().length === 0) {
    return;
  }

  const q = JSON.parse(localStorage[listQueue()[0]]);

  const options = {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" }
  };

  const f = fetch(`${BASE_URL}/public/${q.project}`, options as any);
  f.then(r =>
    r.json().then(j => {
      listQueue().forEach(queueId => {
        if (
          j.find((qs: any) =>
            decodeURIComponent(qs["path"]).endsWith(
              `${queueId.slice(6)}/public.json`
            )
          )
          // j is the list of public.json files.
          // checks if the path of one of them contains the queueId
          // 6 is the lenght of "queue-"
        ) {
          console.log("It is online");
          q.isOnline = true;
          localStorage[queueId] = JSON.stringify(q);
        }
      });
    })
  );

  return f;
};

export const listQueue = () =>
  Object.keys(localStorage).filter(e => e.startsWith("queue-"));

export const clearQueue = () => {
  listQueue().forEach(q => {
    if (JSON.parse(localStorage[q])["userId"] !== getUserId()) {
      console.log(
        "Found object in the queue from a user that is not the current one, deleting",
        q
      );
      delete localStorage[q];
    }
  });
};
