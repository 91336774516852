import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { Language } from "./../Questionnaires/ScreeningQuestionnaire/misc";
import { getUserId, setUserId } from "ewoq/utils";
import { En, De, l, LanguageContext } from "ewoq/components/Language";
import SleepLogDay from "./SleepLogDay";
import SleepLogNight from "./SleepLogNight";
import ThankYouPage from "./ThankYouPage";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";
import { Link } from "react-router-dom";
import { SPChoice, StudyPopulation, StudyPopulationContext, defaultStudyPopulation } from "StudyPopulationChoice";

interface Props {
  onLanguageChoice: (language: Language) => void;
  onStudyPopulationChoice: (population: StudyPopulation) => void;
  studyPopulation: StudyPopulation;
}

const Container = styled.div`
 text-align: center;
`;

interface State {
  cookie: string;
  words: string[];
  isQuestionnaireClick: boolean;
  phase: Phase;
  isConfirmed: any;
}

// interface Props {
//   onDone: (s: boolean) => void;
//   onChangeLanguage: (l: Language) => void;
//   language: Language;
// }

enum Phase {
  cweek,
  sleeplogday,
  sleeplognight,
  thankyoupage,
}


const ButtonArea = styled.div`
margin: auto;
margin-bottom: 2em;
margin-top: 2em;
text-align: justify;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 800px) {
  width: 70%;
`;

const SegmentBox = styled.div`
  margin: 1em;
`;


class ControlWeek extends React.Component<Props, State> {
  static contextType = LanguageContext;
  constructor(props: Props) {
    super(props);
    const cookie = getUserId() || ""; //I shouldn't have to do this
    this.state = {
      isQuestionnaireClick: false,
      cookie,
      words: Array(3).fill(undefined),
      phase: Phase.cweek,
      isConfirmed: undefined,
    };
  }  

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase });
  };  

  updateCookie = () => {
    const words = this.state.words;
    if (words.every((w) => w.length > 0)) {
      const cookie = words[0] + "-" + words[1] + "-" + words[2];
      setUserId(cookie);
      this.setState({ cookie });
      this.setState({isConfirmed: true})
    }
  };

  onChange = (idx: number) => (a: any, b: any) => {
    let words = this.state.words.slice();
    words[idx] = b.value.trim();
    this.setState({ words });
  };

  onConfirm = (boolConfirm: boolean) => {
    this.setState({isConfirmed: boolConfirm})
  };

  render() {
    switch (this.state.phase) {
      case Phase.cweek: {
        return (
          <div> 

            <HeaderContainer>
              <En>Control week</En>
              <De>Kontrollwoche</De>
            </HeaderContainer> 

            <Ui.Container>
            {/* <SegmentBox> */}
              {/* <ParticipantID>{getUserId() || ""}</ParticipantID> */}
              <Ui.Segment>
                {/* {this.state.questionnaire === undefined ? ( */}
                <Ui.ButtonGroup floated="right" size="mini">
                  <Ui.Button
                    onClick={() => this.props.onLanguageChoice(Language.en)}
                    color={this.context === Language.en ? "teal" : undefined}
                  >
                    <Ui.Flag name="gb" />
                  </Ui.Button>
                  <Ui.ButtonOr />
                  <Ui.Button
                    onClick={() => this.props.onLanguageChoice(Language.de)}
                    color={this.context === Language.de ? "teal" : undefined}
                  >
                    <Ui.Flag name="de" />
                  </Ui.Button>
                </Ui.ButtonGroup>

                &nbsp;&nbsp;&nbsp;

                {/* Study population choice */}
                <Ui.ButtonGroup vertical size="mini">

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.EpiKids)}
                            color={studyPopulation === StudyPopulation.EpiKids ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Parent + child", de: "Eltern + Kind" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "4 - 12 years", de: "4 - 12 Jahre" })}>
                            </Ui.ButtonContent>                              
                          </Ui.Button>                        
                        </div>
                      )} />                        

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.HealthyTeens)}
                            color={studyPopulation === StudyPopulation.HealthyTeens ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Teenager", de: "Jugendliche" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "13 - 17 years", de: "13 - 17 Jahre" })}>
                            </Ui.ButtonContent>                                
                          </Ui.Button>
                        </div>
                      )} />                                               


                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            content={l({ en: "Adult", de: "Erwachsene" })}
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.HealthyAdults)}
                            color={studyPopulation === StudyPopulation.HealthyAdults ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Adult", de: "Erwachsene" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "18 - 30 years", de: "18 - 30 Jahre" })}>
                            </Ui.ButtonContent>                                   
                          </Ui.Button>
                        </div>
                      )} /> 

                </Ui.ButtonGroup>
                <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   </SPChoice>

                <SPChoice studyPopulation={StudyPopulation.NotChosen}>   
                  <SectionBody>
                    <Ui.Label color="violet" justify="center" pointing>
                      <En> Who are you? </En>
                      <De> Wer bist Du? </De>                                      
                    </Ui.Label>
                  </SectionBody>  
                </SPChoice>                  


            <p> &nbsp; </p>
            {/* <HeaderContainer>All Questionnaires</HeaderContainer> */}
            {/* <Ui.Container> */}
              {/* <Ui.Segment> */}
                <Ui.Divider horizontal>
                  <En>Please enter the 3-word-code</En>
                  <De>Bitte 3-Wort-Code eingeben</De>
                </Ui.Divider>

                <ParticipantID>{this.state.cookie}</ParticipantID>                    

                  {/* {!this.state.isConfirmed && ( */}
                      <Container>
                        <Ui.Label color="violet" justify="center" pointing>
                          <En> Is this your 3-word-code? </En>
                          <De> Ist das Dein 3-Word-code? </De>                                      
                        </Ui.Label>
                      </Container>  
                  {/* )}          */}

                  <SectionBody>
                    <Container>
                      <Ui.ButtonGroup>

                        <Ui.Button
                          onClick={() => this.onConfirm(false)}
                          content={l({ en: "No", de: "nein" })} 
                          color={this.state.isConfirmed === false ? "teal" : undefined}
                        >
                        </Ui.Button>  
                      <Ui.ButtonOr />
                        <Ui.Button
                        onClick={() => this.onConfirm(true)}
                        content={l({ en: "Yes", de: "Ja" })} 
                        color={this.state.isConfirmed === true ? "teal" : undefined}
                        >
                        </Ui.Button>  

                        </Ui.ButtonGroup>
                    </Container> 
                  </SectionBody>                  


                {this.state.isConfirmed === false && (

                  <SectionBody>
                    <Ui.Form>
                      <Ui.Form.Group widths="equal">
                        <Ui.Form.Input
                          onChange={this.onChange(0)}
                          value={this.state.words[0]}
                          placeholder="Wort 1"
                          color="red"
                        />
                        <Ui.Form.Input
                          onChange={this.onChange(1)}
                          value={this.state.words[1]}
                          placeholder="Wort 2"
                        />
                        <Ui.Form.Input
                          onChange={this.onChange(2)}
                          value={this.state.words[2]}
                          placeholder="Wort 3"
                        />
                      </Ui.Form.Group>
                      <Ui.Form.Field>

                      <SectionBody>
                      <Container>
                        <Ui.Button
                          onClick={this.updateCookie}
                          disabled={!this.state.words.every((w) => w && w.length > 0)}
                        >
                          <En> Change </En>
                          <De> Ändern </De>
                        </Ui.Button>

                      {/* <Ui.Button
                        // onClick={this.onConfirm}
                        // disabled={!this.state.words.every((w) => w && w.length > 0)}
                      >
                        <En> Confirm </En>
                        <De> Bestätigen </De>
                      </Ui.Button>    */}
                    </Container> 
                    </SectionBody>
                    </Ui.Form.Field>
                  </Ui.Form>
                </SectionBody>
              )}
              </Ui.Segment>


              <Ui.Segment>
              <Ui.Divider horizontal>
                  <En>Sleep diary</En>
                  <De>Schlaftagebuch</De>
                </Ui.Divider>

                <Squeeze>

                <Ui.Message warning style={{textAlign: "center"}}>
                  <De>Bitte füllen Sie das Schlaftagebuch an allen 7 Tagen der Kontrollwoche jeden Abend und jeden Morgen aus. <strong>Stellen Sie sich dafür am besten einen Erinnerungswecker auf 21h abends und 7h morgens .</strong></De>
                  <En>Please fill out this sleep diary on all of the 7 days of the control week every evening and morning, respectively. <strong>We recommend to set a reminder alarm at 9 p.m. and 7 a.m. </strong></En>
                </Ui.Message>            

                  <SectionBody>
                    <Ui.Button 
                      color="teal" 
                      fluid 
                      disabled={this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation || !this.state.isConfirmed === true}   
                      onClick={this.advancePhase(Phase.sleeplogday)}               
                    >
                      <En> Before you go to bed: how was your day? </En>
                      <De> Bevor Du zu Bett gehst: wie war dein Tag? </De>                   
                    </Ui.Button>
                  </SectionBody>    

                  <SectionBody>
                      <Ui.Button 
                        color="teal" 
                        fluid 
                        disabled={this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation || !this.state.isConfirmed === true}      
                        onClick={this.advancePhase(Phase.sleeplognight)}               
                      >
                        <En> After you got up: how was your night? </En>
                        <De> Nachdem Du aufstehst: wie war deine Nacht? </De>

                      </Ui.Button>
                    </SectionBody>   
                    {/* <SPChoice studyPopulation={StudyPopulation.NotChosen}>   
                  <SectionBody>
                    <Ui.Label color="violet" justify="center" pointing>
                    <Ui.Message error style={{textAlign: "center"}}>                    
                      <En> <strong>Please select who you are first (top left corner) </strong></En>
                      <De> <strong>Bitte wähle zuerst aus, wer du bist (oben links)</strong> </De>      
                    </Ui.Message>            
                    </Ui.Label>
                  </SectionBody>  
                </SPChoice>                                     */}
                </Squeeze>                             
              </Ui.Segment>


              <Ui.Segment>
              <Ui.Divider horizontal>
                  <En>Further documents</En>
                  <De>Weitere Dokumente</De>
                </Ui.Divider>

                  <SectionBody>
                    <Container>            
                      <a  href="/documents/study_plan.pdf"
                          target="_blank"
                          rel="noopener noreferrer">

                          <Ui.Button color="teal" size="medium" icon="right arrow" content={l({ en: "study plan", de: "Studienplan" })} labelPosition="right" justify="center"></Ui.Button>
                      </a>                
            
                      <a  href="/documents/restrictions.pdf"
                          target="_blank"
                          rel="noopener noreferrer">
                        
                          <Ui.Button color="teal" size="medium" icon="right arrow" content={l({ en: "Your duties", de: "Deine Pflichten" })} labelPosition="right" justify="center"></Ui.Button>
                      </a>                                             

                      <a  href="/documents/aw_manual.pdf"
                          target="_blank"
                          rel="noopener noreferrer">
                        
                          <Ui.Button color="teal" size="medium" icon="right arrow" content={l({ en: "Activity monitor (instructions)", de: "Aktivitätsmonitor (Anleitung)" })} labelPosition="right" justify="center"></Ui.Button>
                      </a>                                                                               
                    </Container>                    
                  
                  </SectionBody> 
                </Ui.Segment>


                <Ui.Segment>
                  <Ui.Divider horizontal>
                      <En>Where we will meet</En>
                      <De>Wo wir uns treffen</De>
                  </Ui.Divider>

                    <SectionBody>
                      <Container>
                        <En>We will meet at <strong>Spiegelhofstrasse 25</strong> at the time communicated to you by the study coordinator.</En>
                        <De>Wir treffen uns an der <strong>Spiegelhofstrasse 25</strong> zu der von dem Studienkoordniator kommunizierten Zeit.</De>
                      </Container>
                      <Squeeze>
                      <iframe
                        width="100%"
                        height="300"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.0590063459827!2d8.55601941583436!3d47.37176681216353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0b113c00001%3A0x7e9ba4ef3d418c61!2sKinderspital%20Z%C3%BCrich!5e0!3m2!1sen!2sch!4v1576863433175!5m2!1sen!2sch"
                        scrolling="no"
                      />
                    </Squeeze>  
                  </SectionBody> 
                </Ui.Segment>



                <Ui.Segment>
            <Ui.Divider horizontal>
                <En>Rules during the control week</En>
                <De>Regeln in der Kontrollwoche</De>                            
            </Ui.Divider>
            <SectionBody>
                <En>
                  For this experiment, we want to ensure a relatively uneventful
                  sleep-wake rhythm <strong>1 week</strong> prior to the experiment day. Please make
                  sure that none of the following occur in the week before the main 
                  experiment, and if they do, please reschedule:
                </En>
                <De>
                  Für dieses Experiment möchten wir <strong>1 Woche</strong> vor dem Versuchstag 
                  einen relativ ereignislosen Schlaf-Wach-Rhythmus sicherstellen. 
                  Bitte stellen Sie sicher, dass im Woche vor dem Hauptexperiment 
                  keine der folgenden Situationen eintritt. Falls Sie auftreten, 
                  verschieben Sie bitte Ihren Termin:        
                </De>               

              <Squeeze>
                <Ui.Segment color="red">
                  <ul>
                    <li>
                      <En>Terrible sleep for one night</En>
                      <De>Furchtbarer Schlaf für eine Nacht</De>  
                    </li>   
                    <li>
                      <En>Sleep or wake-up time shifted by more than 1h from your future sleep schedule you will be given</En>
                      <De>Schlaf- oder Weckzeiten, die um mehr als 1h von Ihrem zukünftigen Schlafplan, den Sie von uns bekommen, verschoben sind</De>   
                    </li>   
                    <li>
                      <En>Night activites (e.g. parties, or watching TV and Netflix at the expense of sleep, etc.)</En>
                      <De>Nachtaktivitäten (z. B. Partys oder Fernsehen und Netflix auf Kosten des Schlafes, usw.)</De>   
                    </li>                                                         
                    <li>
                      <En>Any event, that might affect your sleep or well-being during the experiment (e.g. music festivals, exams, or other exhausting events)</En>
                      <De>Jedes Ereignis, das Ihren Schlaf oder Ihr Wohlbefinden während des Experiments beeinträchtigen könnte (z. B. Musikfestivals, exzessive Partys, Prüfungen oder andere anstrengende Ereignisse).</De>  
                    </li>                    
                    <li>
                      <En>Sickness (e.g. flu) or seriously injured (e.g. broken arm)</En>
                      <De>Krankheit (z. B. Grippe) oder schwere Verletzung (z. B. gebrochener Arm)</De>   
                    </li>     
                    <li>
                      <En>Longer trips</En>
                      <De>Längere Reisen</De>  
                    </li>            
                    <li>
                      <En>Consuming any drug apart from more than 1 standard portion of caffeine or alcohol per day</En>
                      <De>Das Konsumieren jeglicher Drogen außer 1 Standardportion Koffein oder Alkohol pro Tag</De>   
                    </li>                                                                          
                  </ul>
                </Ui.Segment>
              </Squeeze>
              <Ui.Divider hidden />
              <En>
                The days before the experiment have a particularly high impact 
                on your sleep on the experimental night. Please make sure to reschedule 
                if any of the following happen <strong>3 days</strong> before the experiment:
              </En>
              <De>
                Die Tage vor dem Experiment wirken sich besonders stark auf Ihren Schlaf 
                in der experimentellen Nacht aus.Bitte stellen Sie sicher, dass Sie einen 
                neuen Termin festlegen, wenn in den <strong>3 Tagen</strong> vor dem Experiment Folgendes passiert:                                       
              </De>                
              <Squeeze>
                <Ui.Segment color="yellow">
                  <ul>
                    <li>
                      <En>Consuming any drug, including caffein and alcohol</En>
                      <De>Das Konsumieren jeglicher Drogen einschliesslich Alkohol und Koffein</De>                           
                    </li>
                    <li>
                      <En>Only at the day of the experiment: excessive sport or sauna visit</En>
                      <De>Nur am Tag des Experiments: übermäßiger Sport oder Saunabesuch</De>                         
                    </li>
                  </ul>
                </Ui.Segment>
              </Squeeze>
              
              <Ui.Divider hidden />
                  <En>
                    Additionally, participation requires the following activities 
                    <strong>1 week</strong> prior to the experiment:
                  </En>
                  <De>
                    Darüber hinaus, führen Sie bei Teilnahme am Experiment ab 
                    <strong>1 Woche</strong> vor dem Experiment folgenden Aktivitäten aus:
                  </De>                                                          
              <Squeeze>
                <Ui.Segment color="green">
                  <ul>
                    <li>
                      <En>Wearing an actimetry meter constantly, except for during showers and sports (actimetry meter will be provided by us)</En>
                      <De>Kontinuierliches Tragen eines Aktimeters, außer beim Duschen und Sport (Aktimeter wird von uns bereitgestellt)</De>                       
                    </li>
                    <li>
                      <En>Keeping a sleep diary</En>
                      <De>Führen eines Schlaftagebuchs</De>                         
                    </li>
                    <li>
                      <En>Keeping a regular sleep-wake rhythm</En>
                      <De>Beibehalten eines regulären Schlaf-Wach Rhythmus</De>                         
                    </li>                    
                  </ul>
                </Ui.Segment>
              </Squeeze>              
            </SectionBody>
          </Ui.Segment>                

            </Ui.Container>
          </div>
          )
        }
        case Phase.sleeplogday: {
          return (
            <SleepLogDay
              onDone={this.advancePhase(Phase.thankyoupage)}
            />
          );
        }   
        case Phase.sleeplognight: {
          return (
            <SleepLogNight
              onDone={this.advancePhase(Phase.thankyoupage)}
            />
          );
        }   
        case Phase.thankyoupage: {
          return (
            <ThankYouPage
              onDone={this.advancePhase(Phase.thankyoupage)}
            />
        );
      }
    }
  }
}

export default ControlWeek;
