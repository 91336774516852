import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import { threeHoursDay } from "Questionnaires/StandardQuestionComponents/TimeofDay";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

import * as YesNoQ from "ewoq/prototypes/YesNo";

import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_FOO_1",
    mandatory: true,
    plainTitle: "Caffeine today?",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child consume any caffeine today?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you consume any caffeine today?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind heute Koffein zu sich genommen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du heute Koffein zu Dir genommen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie heute Koffein zu Sich genommen?</SPChoice>
    </De>
    </span>
    ),
    explanation: (
      <span>
    <En>
      Including tea, cola, energy drinks, etc.
    </En>

    <De>
      Dies beinhaltet Tee, Cola, Energy Drinks, etc.
    </De>
    </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_FOO_2",
    mandatory: false,
    plainTitle: "Caffeinated drinks list:",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Please list how many caffeinated drinks your child had:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Please list how many caffeinated drinks you had:</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Bitte zählen Sie auf wieviele koffeinhaltige Getränke Ihr Kind zu sich genommen hat:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bitte zähle auf wieviele koffeinhaltige Getränke Du zu Dir genommen hast:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Bitte zählen Sie auf wieviele koffeinhaltige Getränke Sie zu Sich genommen haben:</SPChoice>
    </De>
    </span>
    ),
    
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_FOO_1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "type",
            title: l({ en: "Type:",  de: "Sorte:" }),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
                placeholder={l({ en: "e.g. cappuccino", de: "z.B. Cappuccino" })}
              />
            )
          },
          {
            id: "number",
            title: l({ en: "How many?",  de: "Wie viele?" }),
            component: v => (
              <Input.Question onValid={v} inputType="number" placeholder="1" />
            )
          },
          {
            id: "when",
            title: l({ en: "When:",  de: "Wann?" }),
            component: v => threeHoursDay(v, false)
          }
        ]}
      />
    )
  }
];
export default questions;
