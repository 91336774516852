// makes a little popup over a question mark icon to further explain a concept

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

const Text = styled.div`
  text-align: justify;
`;

class Why extends React.Component {
  render() {
    return (
      <Ui.Popup
        trigger={
          <sup>
            <Ui.Icon color="blue" name="question circle outline" />
          </sup>
        }
        hoverable
        on={["click", "hover"]}
        wide
      >
        <Text>{this.props.children}</Text>
      </Ui.Popup>
    );
  }
}

export default Why;
