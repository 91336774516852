import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import { Answer } from "ewoq/prototypes/GroupQuestions";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

const questions = (studyPopulation: StudyPopulation): QuestionType[] => [
  {
    id: "SR_RES_1",
    mandatory: true,
    plainTitle: "Late coffee?",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Over the previous day, did your child drink coffee (or other caffeinated drinks) after 4PM?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Over the previous day, did you drink coffee (or other caffeinated drinks) after 4PM?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind am gestrigen Tag Kaffee (oder andere koffeinhaltige Getränke) nach 16:00 Uhr getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du am gestrigen Tag Kaffee (oder andere koffeinhaltige Getränke) nach 16:00 Uhr getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie am gestrigen Tag Kaffee (oder andere koffeinhaltige Getränke) nach 16:00 Uhr getrunken?</SPChoice>
    </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_2",
    mandatory: true,
    plainTitle: "Nap?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child take a nap?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you take a nap?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind ein Nickerchen gemacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du ein Nickerchen gemacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie ein Nickerchen gemacht?</SPChoice>
    </De>
      </span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_3",
    mandatory: true,
    plainTitle: "Party?",
    title: <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child go to a late-night party?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you go to a late-night party?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      War Ihr Kind an einer Party bis spät in die Nacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Warst Du an einer Party bis spät in die Nacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Waren Sie an einer Party bis spät in die Nacht?</SPChoice>
    </De>
    </span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_4",
    mandatory: true,
    plainTitle: "Alcohol?",
    title: <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      XXX Did your child drink more than 1 serving of alcohol?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you drink more than 1 serving of alcohol?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      XXX Hast Du mehr als 1 Portion Alkohol getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du mehr als 1 Portion Alkohol getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie mehr als 1 Portion Alkohol getrunken?</SPChoice>
    </De>
      </span>,
      
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyKids:
          return false;
        case StudyPopulation.EpiKids:
          return false;
        default:
          return true;
        }
      },

    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_5",
    mandatory: true,
    plainTitle: "Cigarettes?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      XXX Did your child smoke cigarettes (or consume other nicotine)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you smoke cigarettes (or consume other nicotine)?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      XXX Hast Du Zigaretten geraucht (oder sonst irgendwie Nikotin zu Dir genommen)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du Zigaretten geraucht (oder sonst irgendwie Nikotin zu Dir genommen)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie Zigaretten geraucht (oder sonst irgendwie Nikotin zu Sich genommen)?</SPChoice>
    </De>
      </span>,
           
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyKids:
          return false;
        case StudyPopulation.EpiKids:
          return false;
        default:
          return true;
        }
      },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_6",
    mandatory: true,
    plainTitle: "Drugs or medication?",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child take any prescription/medical drugs?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you take any prescription/medical or recreational drugs?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind rezeptpflichtige Medikamente zu sich genommen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du rezeptpflichtige Medikamente oder Drogen zu Dir genommen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie rezeptpflichtige Medikamente oder Drogen zu Sich genommen?</SPChoice>
    </De>
        </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_7",
    mandatory: true,
    plainTitle: "Other affects health?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did anything else affect your childs health?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did anything else affect your health?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat sonst etwas die Gesunheit Ihres Kindes beeinflusst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hat sonst etwas Deine Gesunheit beeinflusst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Hat sonst etwas Ihre Gesunheit beeinflusst?</SPChoice>
    </De>
    </span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_8",
    mandatory: true,
    plainTitle: "Other affects sleep?",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did anything else happen that probably affects your childs sleep?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did anything else happen that probably affects your sleep?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Ist sonst etwas passiert, das möglicherweise den Schlaf Ihres Kindes beeinflusst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Ist sonst etwas passiert, das möglicherweise Deinen Schlaf beeinflusst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Ist sonst etwas passiert, das möglicherweise Ihren Schlaf beeinflusst?</SPChoice>
    </De>
    </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_8",
    mandatory: false,
    plainTitle: "Explaination of thing that affects sleep:",
    title: <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      If your child feels comfortable telling us, please explain what happened:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      If you feel comfortable telling us, please explain what happened:</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Falls sich Ihr Kind dabei wohl fühlt, erzählen Sie bitte was passiert ist:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Falls Du Dich dabei wohl fühlst, erzähl bitte was passiert ist:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Falls Sie Sich dabei wohl fühlen, erzählen Sie bitte was passiert ist:</SPChoice>
    </De>
    </span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_RES_8");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => <Input.Question large inputType="string" onValid={v} />
  }
];
export default questions;
