import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l, LanguageContext } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as Input from "ewoq/prototypes/TypeInput";



const questions: QuestionType[] = [

  {
    id: "SLB-NIGHT-01",
    plainTitle: "Lights off",
    mandatory: true,
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> When did your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]} >When did you </SPChoice>
          turn off the lights last night? 
        </En>

      <De> 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Wann hat Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> Wann hast Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Wann haben Sie </SPChoice>
        gestern Abend das Licht gelöscht? 
      </De> 
      </span>
    ),    
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "SLB-NIGHT-02",
    plainTitle: "Wake up",
    mandatory: true,
    title: (
      <span>
      <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> When did your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>  When did you </SPChoice>
        get up this morning? 
      </En>

      <De> 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Wann ist Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> Wann bist Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Wann sind Sie </SPChoice>
        heute Morgen aufgestanden?
      </De> 
      </span>
    ),    
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "SLB-NIGHT-03",
    plainTitle: "Comments",
    mandatory: false,
    title: (
      <span>
        <En> Comments about the previous night: </En> 
        <De> Bemerkungen zur vergangenen Nacht: </De>        
      </span>
    ),    
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
      />
    )
  },  
];
export default questions;
