import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/TirednessQuestions";
import { answerQuestionnaire } from "ewoq/api";

interface Props {
  onDone?: () => void;
  viewAll?: boolean;
  name?: string;
}

class Tiredness extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", this.props.name + "-PreviousDayReport-Tiredness", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default Tiredness;
