import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

import * as Range from "ewoq/prototypes/Ranges";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_SLE_1.1",
    mandatory: true,
    plainTitle: "Sleep quality:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How was your childs sleep?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How was your sleep?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie war der Schlaf Ihres Kindes?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie war Dein Schlaf?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie war Ihr Schlaf?</SPChoice>
    </De>
    </span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: [ l({ en: "Terrible",     de: "Grauenhaft" }),
                      l({ en: "Standard",     de: "Standard" }),
                      l({ en: "Perfect",      de: "Perfekt" }), ] },
          { labels: [ l({ en: "Superficial",  de: "Oberflächlich" }),
                      l({ en: "Deep",         de: "Tief" }), ] },
          { labels: [ l({ en: "Fragmented",   de: "Fragmentiert/Zerlegt" }),
                      l({ en: "Continuous",   de: "Kontinuierlich" }), ] },
          { labels: [ l({ en: "Restless",     de: "Ruhelos" }),
                      l({ en: "Calm",         de: "Ruhig" }), ] },
        ]}
      />
    )
  },
  {
    id: "SR_SLE_1.2",
    mandatory: true,
    plainTitle: "Current state: ",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How does your child feel right now?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How do you feel right now?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie fühlt sich Ihr Kind jetzt gerade?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie fühlst Du Dich jetzt gerade?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie fühlen Sie Sich jetzt gerade?</SPChoice>
    </De></span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: [ l({ en: "Tired",            de: "Müde" }),
                      l({ en: "Refreshed",        de: "Erholt" }), ] },
          { labels: [ l({ en: "In a bad mood",    de: "Schlecht gelaunt" }),
                      l({ en: "In a good mood",   de: "Gut gelaunt" }), ] },
          { labels: [ l({ en: "Apathetic",        de: "Teilnahmslos" }),
                      l({ en: "Full of energy",   de: "Energievoll" }), ] },
          { labels: [ l({ en: "Restless / tense", de: "Ruhelos / Angespannt" }),
                      l({ en: "Calm",             de: "Ruhig" }), ] },
          { labels: [ l({ en: "Unfocused",        de: "Unfokusiert" }),
                      l({ en: "Concentrated",     de: "Fokusiert" }), ] },
        ]}
      />
    )
  },
  {
    id: "SR_SLE_2",
    mandatory: true,
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How was your childs sleep environment?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How was your sleep environment?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie war die Schlafumgebung Ihres Kindes?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie war Deine Schlafumgebung?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie war Ihre Schlafumgebung?</SPChoice>
    </De>
      </span>,
    plainTitle: "Sleep environment:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: [ l({ en: "Comfortable",    de: "Gemütlich" }),
                      l({ en: "Uncomfortable",  de: "Ungemütlich" }), ] },
          { labels: [ l({ en: "Too quiet",      de: "Zu leise" }),
                      l({ en: "Perfect",        de: "Perfekt" }), 
                      l({ en: "Too noisy",      de: "Zu laut" }), ] },
          { labels: [ l({ en: "Too cold",       de: "Zu kalt" }),
                      l({ en: "Perfect",        de: "Perfekt" }), 
                      l({ en: "Too warm",       de: "Zu warm" }), ] },
          { labels: [ l({ en: "Too dark",       de: "Zu dunkel" }),
                      l({ en: "Perfect",        de: "Perfekt" }),  
                      l({ en: "Too bright",     de: "Zu hell" }), ] },
        ]}
      />
    )
  },
  {
    id: "SR_SLE_3.1",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did anything disturb your childs sleep?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did anything disturb your sleep?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat irgendetwas den Schlaf Ihres Kindes gestört?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hat irgendetwas Deinen Schlaf gestört?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Hat irgendetwas Ihren Schlaf gestört?</SPChoice>
    </De>
    </span>,
    plainTitle: "Sleep disturbances:",
    explanation: (
      <span>
        <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child notice something unusual (smells, sounds, etc)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you notice something unusual (smells, sounds, etc)?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Ist Ihrem Kind etwas ungewöhliches aufgefallen (Gerüche, Klänge, etc.)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Ist Dir etwas ungewöhliches aufgefallen (Gerüche, Klänge, etc.)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Ist Ihnen etwas ungewöhliches aufgefallen (Gerüche, Klänge, etc.)?</SPChoice>
    </De>
    </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder={l({ en: "e.g. people talking",           de: "z.B. sprechende Personen" })}
        extraRadio={[l({ en: "No",                            de: "Nein" }),
                     l({ en: "Maybe, but I don't know what",  de: "Vielleicht, aber ich weiss nicht was" })]}
      />
    )
  },

  {
    id: "SR_SLE_3.2",
    title: <span>
    <En>
  <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
    When during the night was your child disturbed?</SPChoice>
  <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
    When during the night were you disturbed?</SPChoice>
  </En>

  <De>
  <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
    Wann während der Nacht wurde Ihr Kind gestört?</SPChoice>
  <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
    Wann während der Nacht wurdest Du gestört?</SPChoice>
  <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
    Wann während der Nacht wurden Sie gestört?</SPChoice>
  </De>
  </span>,
    plainTitle: "Disturbance timing:",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_2");
      return a[idx].data !== undefined && a[idx].data["radioAnswer"] !== 0;
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={[ l({ en: "Beginning",  de: "Anfang" }),
                  l({ en: "Middle",     de: "Mitte" }),
                  l({ en: "End",        de: "Ende" }), 
                  l({ en: "Unsure",     de: "Unsicher" })]}
      />
    )
  },
  {
    id: "SR_SLE_4",
    mandatory: true,
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How long did it take your child to fall asleep, approximately?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How long did it take you to fall asleep, approximately?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Wie lange hat Ihr Kind ungefähr gebraucht um einzuschlafen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Wie lange hast Du ungefähr gebraucht um einzuschlafen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Wie lange haben Sie ungefähr gebraucht um einzuschlafen?</SPChoice>
      </De>
      </span>,
    plainTitle: "Time to fall asleep:",
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder={l({ en: "Minutes", de: "Minuten" })} />
    )
  },
  {
    id: "SR_SLE_5.1",
    mandatory: true,
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How easy was it for your child to fall asleep?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How easy was it for you to fall asleep?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Wie leicht fiel es Ihrem Kind einzuschlafen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Wie leicht fiel es Dir einzuschlafen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Wie leicht fiel es Ihnen einzuschlafen?</SPChoice>
      </De>
      </span>,
    plainTitle: "Ease of falling asleep:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[ l({ en: "Extremely hard",  de: "Extre schwer" }),
                  l({ en: "Extremely easy",  de: "Exrem leicht" }),
           ]}
      />
    )
  },
  {
    id: "SR_SLE_5.2",
    mandatory: false,
    plainTitle: "Causes for difficulty falling asleep:",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did any of the following make it difficult to fall asleep for your child once you turned the lights off?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did any of the following make it difficult to fall asleep once you turned the lights off?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Führte eines (oder mehrere) der Folgenden Dinge dazu, dass Ihr Kind Mühe beim einschlafen hatte, als das Licht aus war?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Führte eines (oder mehrere) der Folgenden Dinge dazu, dass Du Mühe beim einschlafen hattest, als das Licht aus war?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Führte eines (oder mehrere) der Folgenden Dinge dazu, dass Sie Mühe beim einschlafen hatten, als das Licht aus war?</SPChoice>
      </De>
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] < 0.5;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: l({ en: "Ruminating thoughts (couldn’t stop thinking about something over and over)",            
                      de: "Grübelnde Gedanken (konnte nicht aufhören über etwas nachzudenken)" })},
          { text: l({ en: "Anxiety" ,                           de: "Ängstlichkeit" })},
          { text: l({ en: "Excitement" ,                        de: "Aufregung" })},
          { text: l({ en: "Not optimal sleep environment" ,     de: "Nicht optimale Schlafumgebung" })},
          { text: l({ en: "Not tired enough" ,                  de: "Nicht müde genug" })},
          { text: l({ en: "Something, but I don't know what" ,  de: "Irgendwas, aber ich weiss nicht was" })},
          { text: l({ en: "Other" ,                             de: "Andere" }), extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.1",
    mandatory: false,
    plainTitle: "Waking in the night:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child wake up in the night?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you wake up in the night?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Ist Ihr Kind in der Nacht aufgewacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Bist Du in der Nacht aufgewacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Sind Sie in der Nacht aufgewacht?</SPChoice>
      </De>
      </span>,
    explanation:
    <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      This refers to waking up any time before your child was supposed to, and had enough time to go back to sleep.</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      This refers to waking up any time before you were supposed to, and had enough time to go back to sleep.</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Dies bezieht sich auf das Erwachen, bevor Ihr Kind hätte aufwachen sollen und genug Zeit hatte um noch einmal einzuschlafen</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Dies bezieht sich auf das Erwachen, bevor Du hättest aufwachen sollen und genug Zeit hattest um noch einmal einzuschlafen</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Dies bezieht sich auf das Erwachen, bevor Sie hätten aufwachen sollen und genug Zeit hatten um noch einmal einzuschlafen</SPChoice>
      </De>
      </span>,
      
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never",           de: "Nie" })},
          { text: l({ en: "Once or twice" ,  de: "ein- oder zweimal" })},
          { text: l({ en: "Several times" ,  de: "Mehrfach" })},
          { text: l({ en: "I barely slept" , de: "Ich habe kaum geschlafen" })},
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.2",
    mandatory: false,
    plainTitle: "Confidence in waking at night:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How sure is your child about waking up in the night?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How sure are you about waking up in the night?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Wie sicher ist Ihr Kind, dass es in der Nacht erwacht ist?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Wie sicher bist Du Dir, dass Du in der Nacht erwacht bist?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Wie sicher sind Sie Sich, dass Sie in der Nacht erwacht sind?</SPChoice>
      </De>
      </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "0%",          de: "0%" }),
          l({ en: "100% sure" ,  de: "100% sicher" })]}
      />
    )
  },
  {
    id: "SR_SLE_7.1",
    mandatory: false,
    plainTitle: "Wake up thirsty?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child wake up thirsty?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you wake up thirsty?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      War Ihr Kind beim erwachen durstig?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Warst Du beim erwachen durstig?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Waren Sie beim erwachen durstig?</SPChoice>
      </De>
      </span>,
    explanation:
    <span>
      <En>
      This refers to both waking up in the middle of the night, and with the morning alarm.
      </En>
      <De>
      Dies bezieht sich sowohl auf das Erwachen mitten in der Nacht, als auch am Morgen früh vor dem Aufstehen. 
      </De></span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_7.2",
    mandatory: false,
    plainTitle: "Wake up hungry?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child wake up hungry?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you wake up hungry?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      War Ihr Kind beim erwachen hungrig?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Warst Du beim erwachen hungrig?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Waren Sie beim erwachen hungrig?</SPChoice>
      </De>
      </span>,

    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_7.3",
    mandatory: false,
    plainTitle: "Wake up pain?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child wake up with any aches or pain?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you wake up with any aches or pain?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Ist Ihr Kind mit irgendwelchen Schmerzen aufgewacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bist Du mit irgendwelchen Schmerzen aufgewacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Sind Sie mit irgendwelchen Schmerzen aufgewacht?</SPChoice>
      </De>
      </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "I feel perfectly fine",  de: "Ich fühle mich völlig in Ordnung" }),
          l({ en: "A few aches",            de: "wenige Schmerzen" }),
          l({ en: "Some aches",             de: "einige Schmerzen" }),
          l({ en: "My whole body aches" ,   de: "Mein ganzer Körper schmerzt" })
        ]}
      />
    )
  },
  {
    id: "SR_SLE_8",
    mandatory: false,
    plainTitle: "Time to wake up:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How long did it take your child to fully wake up?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How long did it take you to fully wake up?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie lange hat Ihr Kind gebraucht um komplett aufzuwachen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie lange hast Du gebraucht um komplett aufzuwachen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie lange haben Sie gebraucht um komplett aufzuwachen?</SPChoice>
      </De>
      </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          l({ en: "Instantly",  de: "Sofort" }),
          l({ en: "An hour",    de: "eine Stunde" }),
          l({ en: "2 hours",    de: "2 Stunden" }),
          l({ en: ">3 hours",   de: ">3 Stunden" })
        ]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={[l({ en: "I'm still not fully awake",  de: "Ich bin immernoch nicht ganz wach" })]}
      />
    )
  },

  {
    id: "SR_SLE_9",
    mandatory: true,
    plainTitle: "Wake with alarm?",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child wake up when the experimenter knocked in the morning?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you wake up when the experimenter knocked in the morning?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Ist Ihr Kind aufgewacht, als der Experimentator am Morgen geklopft hat?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bist Du aufgewacht, als der Experimentator am Morgen geklopft hat?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Sind Sie aufgewacht, als der Experimentator am Morgen geklopft hat?</SPChoice>
      </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Yes",
                      de: "Ja" })},
          { text: l({ en: "I was already awake before the knocking" ,  
                      de: "Ich war bereits vor dem Klopfen wach" })},
          { text: l({ en: "Yes, but I had already woken up before and gone back to sleep" ,  
                      de: "Ja, aber ich war bereits zuvor wach und schlief nochmals ein" })},
          // { text: l({ en: "Yes, but hitting snooze / with more than one alarm" , 
          //             de: "Ich habe kaum geschlafen" })},
          // { text: l({ en: "Yes, but then I went back to sleep and woke up spontaneously" ,  
          //             de: "Mehrfach" })},
          { text: l({ en: "No, I woke up when the door was opened"  , 
                      de: "Nein, ich bin aufgewacht, als die Tür geöffnet wurde" })},
        ]}
      />
    )
  },

  {
    id: "SR_SLE_10",
    mandatory: true,
    plainTitle: "Ease of waking up:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How easy was it for your child to wake up?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How easy was it for you to wake up?</SPChoice>
      </En>

      <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie leicht/schwer fiel es Ihrem Kind aufzuwachen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie leicht/schwer fiel es Dir aufzuwachen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie leicht/schwer fiel es Ihnen aufzuwachen?</SPChoice>
      </De>
      </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[ l({ en: "Extremely easy", de: "Extrem leicht" }),
                  l({ en: "Extremely hard", de: "Exrem schwer" })      
        ]}
      />
    )
  }
];
export default questions;
