import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { answerQuestionnaire } from "ewoq/api";
import { getUserId } from "ewoq/utils";
import { Squeeze } from "ewoq/components/miscStyles";
import { En, De, l} from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const HeaderContainer = styled.div`
  height: auto;
  min-height: 3.5em;

  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;

const SectionBody = styled.div`
  text-align: justify;
  padding: 1em;
`;

const CodeText = styled.span`
  font-size: 1.5em;
  text-transform: uppercase;
  font-style: bold;
`;

const TextQ = styled.p`
  font-style: bold;
  font-size: 1.1em;
  text-align: justify;
`;

const Email = styled.p`
  font-style: bold;
  font-size: 1.2em;
  text-align: center;
`;

interface Props {}

interface State {
  answer: number | undefined;
  modalOpen: boolean;
}

class Eligible extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { answer: undefined, modalOpen: true }; //TODO: make this state get saved to disk
  }

  onSubmit = () => () => {
    this.setState({ modalOpen: false });
    if (this.state.answer === 0) {
      window.location.replace("./");
    }
    answerQuestionnaire("EPI", "BasicInfo-Metadata", {
      isHonest: this.state.answer,
      language: (window as any).currentLanguage,
    }); //TODO, add language of state here?
  };

  render() {
    return (
      <div>
        <Ui.Modal closeOnDimmerClick={false} open={this.state.modalOpen}>
          <Ui.Modal.Content>
            <TextQ>
              <En>
                We are a scientific research lab operating on a budget and
                dedicated to producing the highest quality research possible.
                Intentionally incorrect or careless answers will lead to
                unintentionally false results. Science depends on you being
                truthful and thorough.
              </En>
              <De>
                Wir sind ein wissenschaftliches Forschungslabor mit kleinem Budget und
                widmen uns der Forschung von höchster Qualität.
                Absichtlich falsche oder nachlässige Antworten führen zu
                unbeabsichtigt falschen Resultaten. Wissenschaft hängt von 
                
                <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ihrer   </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Deiner  </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ihrer   </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ihrer   </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Deiner  </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ihrer   </SPChoice>  

                Ehrlichkeit und Gründlichkeit ab.
              </De>              

            </TextQ>
            <Squeeze>
              <Ui.Segment color="violet">
                <Ui.Form>
                  <Ui.Header as="h3">
                    <En> Were your answers accurate? </En>
                    <De> Waren 
                      
                    <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ihre   </SPChoice>
                    <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Deine  </SPChoice>
                    <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ihre   </SPChoice>
                    <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ihre   </SPChoice>
                    <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Deine  </SPChoice>
                    <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ihre   </SPChoice>   

                      Antworten korrekt? </De>
                  </Ui.Header>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "Please discard my questionnaire (for whatever reason)", 
                                 de: "Bitte verwerfen Sie meinen Fragebogen (aus welchem ​​Grund auch immer)"})}                                                                   
                      checked={this.state.answer === 0}
                      onClick={() => this.setState({ answer: 0 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "I’m not really sure about how accurate my answers were", 
                                 de: "Ich bin mir nicht sicher, wie genau meine Antworten waren."})}                       
                      checked={this.state.answer === 1}
                      onClick={() => this.setState({ answer: 1 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "I may have made mistakes, but they were unintentional and would be happy to correct them", 
                                 de: "Ich habe vielleicht Fehler gemacht, aber sie waren unbeabsichtigt und würde sie gerne korrigieren."})}                          
                      checked={this.state.answer === 2}
                      onClick={() => this.setState({ answer: 2 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "To the best of my knowledge, all the answers provided are correct.", 
                                 de: "Nach meinem besten Wissen sind alle Antworten korrekt."})}                       
                      checked={this.state.answer === 3}
                      onClick={() => this.setState({ answer: 3 })}
                    />
                  </Ui.Form.Field>

                  <Ui.Form.Field>
                    <Ui.Button
                      disabled={this.state.answer === undefined}
                      color="green"
                      onClick={this.onSubmit()}
                    >
                      Confirm
                    </Ui.Button>
                  </Ui.Form.Field>
                </Ui.Form>
              </Ui.Segment>
            </Squeeze>
          </Ui.Modal.Content>
        </Ui.Modal>
       
        <HeaderContainer>
          <En>Thank you very much!</En>
          <De>Vielen herzlichen Dank!</De>                
        </HeaderContainer>

        <Ui.Container>
          <Ui.Segment>
          <Ui.Divider horizontal>
                <En>Congratulations!</En>
                <De>Herzlichen Glückwunsch!</De>                            
            </Ui.Divider>            
            <SectionBody>
                   

              <Ui.Message info>
                <En>
                  The questionnaire was successfully answered. 
                 </En>
                <De>
                  Der Fragebogen wurde erfolgreich ausgefüllt.                             
                </De>                
              </Ui.Message>
            </SectionBody>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Eligible;
