import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

import * as GroupQuestion from "ewoq/prototypes/GroupQuestions";
import { SubQuestions } from "ewoq/prototypes/GroupQuestions";

interface subQuestionsObjType {
  [s: string]: SubQuestions;
}

const SubQs: subQuestionsObjType = {
  a: {
    id: "a",
    title: "How much:",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["A little", "50% of the time", "The whole time"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  b: {
    id: "b",
    title: "What about?",
    explanation: "in just 1 sentence.",
    component: v => <Input.Question onValid={v} inputType="string" />
  },
  c: {
    id: "c",
    title: "How easy was it?",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  c1: {
    id: "c1",
    title: "If it was not easy, what made it hard?",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "External distractions" },
          { text: "Too tired, hard staying awake" },
          { text: "Uncomfortable" },
          { text: "Intruding thoughts" },
          { text: "Boring" },
          { text: "Too jittery" },
          { text: "Other", extraInput: "string" }
        ]}
        includeDone={false}
      />
    )
  },
  d: {
    id: "d",
    title: "When?",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[{ text: "Start" }, { text: "Middle" }, { text: "End" }]}
        includeDone={false}
      />
    )
  },
  e: {
    id: "e",
    title: "Characterize the content:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Vivid", "Vague"] },
          { labels: ["Social", "Non-social"] },
          { labels: ["Realistic", "Fantasy"] },
          { labels: ["Complicated", "Simple"] },
          { labels: ["Lots of movement", "Stationary"] },
          { labels: ["Egocentric", "Completely external"] }
        ]}
      />
    )
  },
  f: {
    id: "f",
    title: "Senses involved:",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Visual" },
          { text: "Auditory" },
          { text: "Olfactory" },
          { text: "Taste" },
          { text: "Other" }
        ]}
        includeDone={false}
      />
    )
  },
  g: {
    id: "g",
    title: "Speed of your thoughts:",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Slow", "Fast"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
};

const questions: QuestionType[] = [
  {
    id: "RT_2.1",
    title: (
      <span>
        Please indicate which of the following you engaged in during the rest
        period:
      </span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Daydreaming" },
          { text: "Worrying" },
          { text: "Planning" },
          { text: "Jumbled thoughts/uncontrolled stream of consciousness" },
          { text: "Blank mind" },
          { text: "Focused on something" },
          { text: "Remembering" },
          { text: "I don't remember" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "Daydreaming",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][0];
    },
    title: "Regarding the time you spent daydreaming:",
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[
          SubQs.a,
          SubQs.b,
          SubQs.c,
          SubQs.d,
          SubQs.e,
          SubQs.f,
          SubQs.g,
          SubQs.c1
        ]}
      />
    )
  },

  {
    id: "Worrying",
    title: "Regarding the time you spent worrying:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][1];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.a, SubQs.b, SubQs.d, SubQs.g]}
      />
    )
  },
  {
    id: "Planning",
    title: "Regarding the time you spent planning:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][2];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.a, SubQs.b, SubQs.c, SubQs.d, SubQs.g]}
      />
    )
  },
  {
    id: "Jumbled",
    title:
      "Regarding the time you spent in an uncontrolled stream of consciousness:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][3];
    },
    component: v => (
      <GroupQuestion.Questions onValid={v} subQuestions={[SubQs.d, SubQs.g]} />
    )
  },
  {
    id: "Blank",
    title: "Regarding the time you spent not thinking of anything:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][4];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.a, SubQs.c, SubQs.d]}
      />
    )
  },
  {
    id: "Focus",
    title: "Regarding the time you spent focused:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][5];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[
          SubQs.a,
          SubQs.b,
          SubQs.c,
          SubQs.d,
          SubQs.f,
          {
            id: "location",
            title: "Where was the focus of your attention?",
            component: v => (
              <MultipleChoice.Question
                onValid={v}
                options={[{ text: "External" }, { text: "Internal" }]}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "Remembering",
    title: "Regarding the time you spent remembering:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][6];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[
          SubQs.a,
          SubQs.b,
          SubQs.c,
          SubQs.d,
          SubQs.e,
          SubQs.f,
          SubQs.g
        ]}
      />
    )
  },
  {
    id: "Other",
    title: "Regarding the time you spent doing something else:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_2.1");
      return a[idx].data !== undefined && a[idx].data["answers"][8];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.a, SubQs.b, SubQs.d, SubQs.g]}
      />
    )
  }
];

export default questions;

//TODO: add plain titles and change numbering, and update questions
