import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNo from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";

import { En, De, l, L } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [

  {
    id: "FMC_01",
    mandatory: false,
    plainTitle: "Cycle:",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
              Does your child have a menstrual cycle (the period)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
              Do you have a menstrual cycle (the period)?</SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Hat Ihr Kind einen Menstruationszyklus (die Periode)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Hast Du einen Menstruationszyklus (die Periode)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Haben Sie einen Menstruationszyklus (die Periode)?</SPChoice>
        </De>
      </span>
    ),

    component: v => <YesNo.Question onValid={v} />
  },

    {
        id: "FMC_02",
        mandatory: false,
        plainTitle: "Regular Cycle:",
        title: (
          <span>
            <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child have a regular menstrual cycle, that is of a similar length each month?</SPChoice>
            <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you have a regular menstrual cycle, that is of a similar length each month?
               </SPChoice>
            </En>
            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Hat Ihr Kind einen regelmässigen Menstruationszyklus, der jeden Monat ähnlich lange dauert?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Hast Du einen regelmässigen Menstruationszyklus, der jeden Monat ähnlich lange dauert?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie einen regelmässigen Menstruationszyklus, der jeden Monat ähnlich lange dauert?
               </SPChoice>
            </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const isCycle = a.findIndex(a => a.id === "FMC_01");
    
          return (
            a[isCycle].data !== undefined &&
            a[isCycle].data["answer"] === YesNo.AnswerType.yes
          );  // asks if person has a menstrual cycle
        },        

        component: v => <YesNo.Question onValid={v} />
      },

      {
        id: "FMC_03",
        mandatory: false,
        plainTitle: "Cycle duration:",
        title: (
          <span>
            <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How long does your childs monthly menstrual cycle usually take?</SPChoice>
            <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How long does your monthly menstrual cycle usually take?
               </SPChoice>
            </En>
            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie lange dauert der Monatszyklus Ihres Kindes normalerweise?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie lange dauert Dein Monatszyklus normalerweise?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie lange dauert Ihr Monatszyklus normalerweise?
               </SPChoice>
            </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const isCycle = a.findIndex(a => a.id === "FMC_01");
    
          return (
            a[isCycle].data !== undefined &&
            a[isCycle].data["answer"] === YesNo.AnswerType.yes
          );  // asks if person has a menstrual cycle
        },   

        component: v => (
            <Input.Question
              onValid={v}
              inputType="number"
              placeholder={l({ en: "days", de: "Tage" })}
            />
          )
      },

      {
        id: "FMC_04",
        mandatory: false,
        plainTitle: "Menstruation duration:",
        title: (
          <span>
            <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How long does your childs period (menstruation) usually take?</SPChoice>
            <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How long does your period (menstruation) usually take?
               </SPChoice>
            </En>
            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie lange dauert die Periode (Menstruation) Ihres Kindes normalerweise?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie lange dauert Deine Periode (Menstruation) normalerweise?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie lange dauert Ihre Periode (Menstruation) normalerweise?
               </SPChoice>
            </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const isCycle = a.findIndex(a => a.id === "FMC_01");
    
          return (
            a[isCycle].data !== undefined &&
            a[isCycle].data["answer"] === YesNo.AnswerType.yes
          );  // asks if person has a menstrual cycle
        },   

        component: v => (
            <Input.Question
              onValid={v}
              inputType="number"
              placeholder={l({ en: "days", de: "Tage" })}
            />
          )
      },

      {
        id: "FMC_05",
        mandatory: false,
        plainTitle: "Day of Cycle:",
        title: (
          <span>
            <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                If day 1 is the start of your childs period (menstruation), on which day of the monthly menstrual cycle is your child right now?</SPChoice>
            <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                If day 1 is the start of your period (menstruation), on which day of the monthly menstrual cycle are you right now?
               </SPChoice>
            </En>
            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wenn Tag 1 der Start der Periode (Menstruation) Ihres Kindes ist, an welchem Tag des Monatzyklus befindest sich Ihr Kind jetzt gerade?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wenn Tag 1 der Start Deiner Periode (Menstruation) ist, an welchem Tag Deines Monatzyklus befindest Du Dich jetzt gerade?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wenn Tag 1 der Start Ihrer Periode (Menstruation) ist, an welchem Tag Ihres Monatzyklus befinden Sie Sich jetzt gerade?
               </SPChoice>
            </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const isCycle = a.findIndex(a => a.id === "FMC_01");
    
          return (
            a[isCycle].data !== undefined &&
            a[isCycle].data["answer"] === YesNo.AnswerType.yes
          );  // asks if person has a menstrual cycle
        },   

        component: v => (
            <Input.Question
              onValid={v}
              inputType="number"
            />
          )
      },

      {
        id: "FMC_06",
        mandatory: false,
        plainTitle: "Contraceptive:",
        title: (
          <span>
            <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child use one of the following contraceptive?</SPChoice>
            <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you use one of the following contraceptive?
               </SPChoice>
            </En>
            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Verwendet Ihr Kind eines der folgenden Verhütungsmitteln?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Verwendest Du eines der folgenden Verhütungsmitteln?
                </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Verwenden Sie eines der folgenden Verhütungsmitteln?
               </SPChoice>
            </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const isCycle = a.findIndex(a => a.id === "FMC_01");
    
          return (
            a[isCycle].data !== undefined &&
            a[isCycle].data["answer"] === YesNo.AnswerType.yes
          );  // asks if person has a menstrual cycle
        },           

        component: v => (
            <MultipleChoice.Question
              onValid={v}
              options={[
                { text: l({ en: "Oral contraceptive pill", de: "Antibabypille" }) },
                { text: l({ en: "Contraceptive patch", de: "Hormonpflaster"}) },
                { text: l({ en: "Intrauterine Device (IUD)", de: "In­t­ra­ute­rin­pes­sar (Spirale)"}) },
                { text: l({ en: "Other",  de: "Anderes" }), extraInput: "string" }
              ]}
            />
          )
      },

];

export default questions;