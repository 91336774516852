import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as Range from "ewoq/prototypes/Ranges";

const questions: QuestionType[] = [
  {
    id: "LQ_LI2_1.1",
    mandatory: false,
    plainTitle: "Regular exercise",
    title: <span>Do you exercise regularly?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_1.2",
    mandatory: false,
    plainTitle: "Sport:",
    title: <span>What sport/activity?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. football"
      />
    )
  },
  {
    id: "LQ_LI2_1.3",
    mandatory: false,
    plainTitle: "Sport location",
    title: <span>Where do you do sports?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[{ text: "Indoor" }, { text: "Outdoor" }]}
      />
    )
  },
  {
    id: "LQ_LI2_1.4",
    mandatory: false,
    plainTitle: "Sport time",
    title: <span>At what time of day do you do sports?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={[
          "12-2 AM",
          "2-4 AM",
          "4-6 AM",
          "6-8 AM",
          "8-10 AM",
          "10 AM to 12 PM",
          "12-2 PM",
          "2-4 PM",
          "4-6 PM",
          "6-8 PM",
          "8-10 PM",
          "10AM to 12 PM"
        ]}
      />
    )
  },
  {
    id: "LQ_LI2_2",
    mandatory: false,
    plainTitle: "Time seated",
    title: (
      <span>
        Over the past two months, how much of your waking day did you spend
        seated?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_3",
    mandatory: false,
    plainTitle: "Time in daylight",
    title: (
      <span>
        Over the past two months, how much time of your day did you spend
        outside during daylight hours on average?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },

  {
    id: "LQ_LI2_4.1",
    mandatory: false,
    plainTitle: "Videogame frequency",
    title: <span>Do you play videogames?</span>,
    explanation:
      "Only count cell phone games if you usually play them for longer than 1 hour.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_4.2",
    mandatory: false,
    plainTitle: "Videogame time played",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_4.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    title: <span>When you play, for how long on average?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_4.3",
    mandatory: false,
    plainTitle: "Gaming impacts sleep?",
    title: <span>Do you feel gaming impacts your sleep?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_4.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Ruins my sleep", "No effect", "Improves my sleep"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_5.1",
    mandatory: false,
    plainTitle: "Time on phone",
    title: (
      <span>
        How much time do you spend on your phone or other handheld devices per
        day?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_5.2",
    mandatory: false,
    plainTitle: "Time on computer",
    title: (
      <span>
        How much time do you spend in front of a computer and/or TV per day?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_5.4",
    mandatory: false,
    plainTitle: "Screen impacts sleep?",
    title: (
      <span>Do you think time in front of a screen impacts your sleep?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Ruins my sleep", "Does nothing", "Improves my sleep"]}
        extraRadio={["I don't know", "Maybe?"]}
      />
    )
  },
  {
    id: "LQ_LI2_6.1",
    mandatory: false,
    plainTitle: "Reading frequency",
    title: (
      <span>
        Do you often read? Consider anything that takes longer than 15 minutes.
      </span>
    ),
    explanation:
      "Reading material includes reading books, articles, etc. Does not onclude comics, generic web content, text messages, spread sheets, etc.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_6.2",
    mandatory: false,
    plainTitle: "Reading duration",
    title: <span>When you read, for how long on average per day?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_6.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", ">6 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_6.3",
    mandatory: false,
    plainTitle: "Reading impacts sleep?",
    title: <span>Do you feel reading impacts your sleep?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_6.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Ruins my sleep", "Does nothing", "Improves my sleep"]}
        extraRadio={["I don't know", "Maybe?"]}
      />
    )
  },
  {
    id: "LQ_LI2_7",
    mandatory: false,
    plainTitle: "Pet?",
    title: <span>Do you live with a pet?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes", extraInput: "string", placeHolder: "e.g. dog" },
          { text: "Ruins my sleep" }
        ]}
      />
    )
  },
  {
    id: "LQ_LI2_8.1",
    mandatory: false,
    plainTitle: "Social introversion:",
    title: <span>Do you consider yourself: </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Introverted", "Extroverted"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_8.2",
    mandatory: false,
    plainTitle: "Parties per month",
    title: (
      <span>
        How many late-night parties, including “drinks with friends”, have you
        been to in the past month?
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder="Parties per month"
      />
    )
  },
  {
    id: "LQ_LI2_9.1",
    mandatory: false,
    title: <span>Do you play a musical instrument?</span>,
    plainTitle: "Musician?",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Hobbyist", "Professional"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_9.2",
    mandatory: false,
    plainTitle: "Time listening to music",
    title: (
      <span>
        How much time do you spend listening to music per day, on average?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "3 hours", "6 hours", "9 hours", ">12 hours"]}
      />
    )
  },
  {
    id: "LQ_LI2_9.3",
    mandatory: false,
    plainTitle: "Reason for music",
    title: <span>When you do listen to music, is it: </span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI2_9.2");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "While working/studying" },
          { text: "While relaxing" },
          { text: "While commuting" },
          { text: "Without doing anything else" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_LI2_9.5",
    mandatory: false,
    plainTitle: "Like soundtracks?",
    title: (
      <span>
        Do you like movie soundtracks (e.g. from Lord of the Rings, or Harry
        Potter)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Indifferent", "I love them"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI2_9.6",
    mandatory: false,
    plainTitle: "Enjoy music?",
    title: <span>Do you enjoy listening to music?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Neutral", "I like it", "I love it"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];
export default questions;
