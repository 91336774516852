import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { Language } from "../ScreeningQuestionnaire/misc";
import Handedness from "./Handedness";
import MCTQ from "./MCTQ";
import SleepQuality from "./SleepQuality";
import EpworthSleepinessScale from "./EpworthSleepinessScale";
import Attention from "./Attention";
import DaytimeSleepiness from "./DaytimeSleepiness";
import StrengthDifficulties from "./StrengthDifficulties";
import SocioEconomicStatus from "./SocioEconomicStatus";
import Finish from "./Finish";
import MenstrualCycle from "./MenstrualCycle";
import HandDexterity from "./HandDexterity";
import MRSafety from "./MRSafety";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";


import { getUserId } from "ewoq/utils";
import { En, De } from "ewoq/components/Language";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";
import Questions from "ewoq/prototypes/GroupQuestions";
import {
  defaultStudyPopulation,
  StudyPopulationContext,
} from "StudyPopulationChoice";

// const ButtonArea = styled.div`
//   margin: auto;
//   text-align: center;
// `;

// const SubText = styled.div`
//   font-style: italic;
//   margin-bottom: 1em;
//   text-align: center;
// `;

enum Phase {
  Start,
  Handedness,
  SleepQuality,
  EpworthSleepinessScale,
  MCTQ,
  Attention,
  DaytimeSleepiness,
  StrengthDifficulties,
  SocioEconomicStatus,
  MRSafety,
  MenstrualCycle,
  HandDexterity,
  Finish,
}

// interface Props {}

interface State {
  cookie: string;
  language: Language;
  phase: Phase;
  // studyPopulation: StudyPopulation;
}

interface Props {
  // onDone: (s: boolean) => void;
  // onChangeLanguage: (l: Language) => void;
  // language: Language;
}

class BasicInfo extends React.Component<Props, State> {
  static contextType = StudyPopulationContext;
  constructor(props: Props) {
    super(props);
    const cookie = getUserId() || ""; //I shouldn't have to do this
    this.state = {
      cookie,
      phase: Phase.Start,
      language: Language.de,
      // studyPopulation: StudyPopulation.NotChosen,
    };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase }, () => console.log(this.state));
  };

  // onLanguageChoice = (language: Language) => this.setState({ language });

  render() {
    // if (this.context == defaultStudyPopulation) {
    //   // return <p>
    //   //   <En> You have to select a study population (e.g. kids with epilepsy (8-13y)</En>
    //   //   <De> Sie müssen eine Untersuchungsgruppe auswählen um fortzufahren (z. B. Kinder mit Epilepsie (8-13J)</De>
    //   // </p>;
    //   return (
    //     <div>
    //       <ParticipantID>{getUserId()}</ParticipantID>
    //       <HeaderContainer>
    //         <p>
    //           <En>Huups</En>
    //           <De>Huups</De>
    //         </p>
    //       </HeaderContainer>
    //       <Ui.Container>
    //         <Ui.Segment>
    //           <Ui.Divider horizontal>
    //             <p>
    //               <En>What went wrong?</En>
    //               <De>Was ist passiert?</De>
    //             </p>
    //           </Ui.Divider>
    //           <SectionBody>

    //           <Ui.Message error>
    //               <En> Please choose your <strong>health status and age</strong>. </En>
    //               <De> Bitte wählen Sie Ihren <strong>Gesundheitsstatus und Ihr Alter</strong> aus. </De>
    //           </Ui.Message>                


    //             <LinkQ href="/questionnaires">
    //               <Ui.Button >
    //                 <span>
    //                   <En> Back </En>
    //                   <De> Zurück </De>
    //                 </span>                  
    //               </Ui.Button>
    //           </LinkQ>
    //           </SectionBody>
    //         </Ui.Segment>
    //       </Ui.Container>
    //     </div>
    //     )}
    // if (this.state.cookie.length <= 5){
    //   return(
    //     <div>
    //       <HeaderContainer>
    //           <En>Huups</En>
    //           <De>Huups</De>
    //       </HeaderContainer>  

    //       <Ui.Container>
    //         <Ui.Segment>

    //           <Ui.Divider horizontal>
    //               <En>What went wrong?</En>
    //               <De>Was ist passiert?</De>
    //           </Ui.Divider>

    //           <SectionBody>
    //             {this.state.cookie.length}

    //             <Ui.Message error>
    //                 <En> 
    //                   Please enter the <strong>3-word-code</strong> given to you by the study coordinator. 
    //                   In case you have not received any 3-word-code, please contact the study coordinator (sven.leach@kispi.uzh.ch)
    //                 </En>
    //                 <De> 
    //                   Bitte geben Sie den <strong>3-Wort-Code</strong> ein, den Sie von dem Studienkoordinator erhalten haben. 
    //                   Falls Sie keinen 3-Word-Code erhalten haben, kontaktieren Sie bitte den Studienkoordinator (sven.leach@kispi.uzh.ch)
    //                 </De>
    //             </Ui.Message>   

    //             <LinkQ href="/questionnaires">
    //               <Ui.Button >
    //                 <span>
    //                   <En> Back </En>
    //                   <De> Zurück </De>
    //                 </span>                  
    //               </Ui.Button>
    //             </LinkQ>

    //           </SectionBody>

    //         </Ui.Segment>
    //       </Ui.Container>          
    //     </div>
    //   )
    // }



    // switch (this.state.phase) {
    //   case Phase.Start: {
    //     return (
    //       <div>
    //         <ParticipantID>{getUserId()}</ParticipantID>
    //         <HeaderContainer>
    //           <p>
    //             <En>Basic participant information</En>
    //             <De>Grundlegende Probandeninformationen</De>
    //           </p>
    //         </HeaderContainer>
    //         <Ui.Container>
    //           <Ui.Segment>
    //             {/* <SectionBody>
    //               <Ui.Segment>
    //                 <Ui.Divider horizontal>Choose a language</Ui.Divider>
    //                   <SubText>
    //                     If you later decide to change language, you will have to
    //                     start all over again.
    //                   </SubText>
    //                   <ButtonArea>
    //                     <Ui.ButtonGroup>
    //                       <Ui.Button
    //                         onClick={() => this.props.onChangeLanguage(Language.en)}
    //                         color={
    //                           this.props.language === Language.en
    //                             ? "teal"
    //                             : undefined
    //                         }
    //                       >
    //                         <Ui.Flag name="gb" />
    //                       </Ui.Button>

    //                       <Ui.ButtonOr />
    //                       <Ui.Button
    //                         onClick={() => this.props.onChangeLanguage(Language.de)}
    //                         color={
    //                           this.props.language === Language.de
    //                             ? "teal"
    //                             : undefined
    //                         }
    //                       >
    //                         <Ui.Flag name="de" />
    //                       </Ui.Button>
    //                     </Ui.ButtonGroup>
    //                   </ButtonArea>
    //               </Ui.Segment>
    //             </SectionBody> */}

    //             <Ui.Divider horizontal>
    //               <p>
    //                 <En>Instructions</En>
    //                 <De>Anleitung</De>
    //               </p>
    //             </Ui.Divider>
    //             <SectionBody>
    //               <p>
    //                 <En>
    //                   This questionnaire will ask you about yourself, lifestyle,
    //                   health, and sleep habits/quality. Questions that are not
    //                   mandatory will let you skip ahead if you don't feel
    //                   comfortable answering, or just don't want to, although we
    //                   appreciate every answer you give us. We recognize that
    //                   some questions are intrusive, and rather than risk a false
    //                   answer, we prefer if you skip them. You can always ask the
    //                   experimenter why we bother asking any question, and we
    //                   will be happy to explain. If you don’t understand the
    //                   question, please ask for clarification.
    //                 </En>
    //                 <De>
    //                   Dieser Fragebogen befragt den Schlaf, die Müdigkeit, generelle
    //                   Fähigkeiten, sowie den sozio-ökonomischen Status Ihres Kindes.
    //                   Fragen die nicht zwingend beantwortet werden
    //                   müssen können übersprungen werden, falls Sie oder Ihr Kind sich nicht wohl
    //                   fühlen diese zu beantworten. Sie können den Studienkoordinator
    //                   jederzeit Fragen, warum eine Frage gestellt wird - wir stehen
    //                   Ihnen gerne Rede und Antwort. Falls Sie eine Frage nicht 
    //                   verstehen sollten, fragen Sie uns bitte um Hilfe.                  
    //                 </De>
    //               </p>

    //               <Ui.Button
    //                 fluid
    //                 onClick={this.advancePhase(Phase.DaytimeSleepiness)}
    //               >
    //               <p>
    //                 <En>Continue</En>
    //                 <De>Fortfahren</De>
    //               </p>
    //               </Ui.Button>
    //             </SectionBody>
    //           </Ui.Segment>
    //         </Ui.Container>
    //       </div>
    //     );
    // }



    switch (this.state.phase) {
      case Phase.Start: {

        switch(this.context){
          // case StudyPopulation.EpiKids: {
          //   return <DaytimeSleepiness onDone={this.advancePhase(Phase.Handedness)} />;             
          // }
          // case StudyPopulation.HealthyKids: {
          //   return <DaytimeSleepiness onDone={this.advancePhase(Phase.Handedness)} />;             
          // }
          case StudyPopulation.HealthyAdults: {
            return <EpworthSleepinessScale onDone={this.advancePhase(Phase.Handedness)} />;  

          }          
          default: 
            return <DaytimeSleepiness onDone={this.advancePhase(Phase.Handedness)} />; 

        }
      }       

      case Phase.Handedness: {
        return <Handedness onDone={this.advancePhase(Phase.MCTQ)} />;
      }
      // case Phase.SleepQuality: {
      //   return <SleepQuality onDone={this.advancePhase(Phase.MCTQ)} />;
      // }
      case Phase.MCTQ: {
        return <MCTQ onDone={this.advancePhase(Phase.StrengthDifficulties)} />;
      }
      // case Phase.Attention: {
      //   return <Attention onDone={this.advancePhase(Phase.DaytimeSleepiness)} />;
      // } 
      case Phase.StrengthDifficulties: {
        return <StrengthDifficulties onDone={this.advancePhase(Phase.SocioEconomicStatus)} />;
      }
      case Phase.SocioEconomicStatus: {
        return <SocioEconomicStatus onDone={this.advancePhase(Phase.MenstrualCycle)} />;
      }
      case Phase.MenstrualCycle: {
        return <MenstrualCycle onDone={this.advancePhase(Phase.HandDexterity)} />;
      }      
      case Phase.HandDexterity: {
        return <HandDexterity onDone={this.advancePhase(Phase.Finish)} />;
      }           
      case Phase.Finish: {
        return <Finish />;
      }      
      // case Phase.MRSafety: {
      //   return <MRSafety onDone={this.advancePhase(Phase.Start)} />;
      // }
    }
  }
}

export default BasicInfo;
