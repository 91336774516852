import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNo from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as GroupForm from "ewoq/prototypes/TypeInputGroup";
import WhyPopup from "ewoq/components/WhyPopup";
import { En, De, l, L } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_MCTQ_1",
    plainTitle: "Working days:",
    mandatory: false,
    title: (
      <span>
        <En>How many&nbsp; 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            school days does your child have per week?
          </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            working/school days do you have per week?
          </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            working days do you have per week?
          </SPChoice>
        </En>
        <De>Wie viele&nbsp;
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Schultage hat Ihr Kind       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Arbeits-/Schultage hast Du   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Arbeits-/Schultage haben Sie </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Schultage hat Ihr Kind       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Arbeits-/Schultage hast Du   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Arbeits-/Schultage haben Sie </SPChoice>
          pro Woche?

        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          These are days in which your child has a regular schedule that dictates more
          or less when it has to wake up and/or go to sleep in the evening.
          </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          These are days in which you have a regular schedule that dictates more
          or less when you have to wake up and/or go to sleep in the evening.
          </SPChoice>
        </En>

        <De>
          Das sind Tage, an denen&nbsp;
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Ihr Kind einen regelmässigen Zeitplan hat, der
            Ihrem Kind mehr oder weniger vorgibt, wann es am Morgen aufstehen und/oder
            am Abend schlafen gehen muss.
            Falls die Antwort 0 oder 7 Tage ist, dann überlegen Sie bitte, ob sich die Schlafzeiten Ihres Kindes
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und füllen Sie den Fragebogen entsprechend aus.   </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Du einen regelmässigen Zeitplan hast, der
            Dir mehr oder weniger vorgibt, wann Du am Morgen aufstehen und/oder
            am Abend schlafen gehen musst.
            Falls die Antwort 0 oder 7 Tage ist, dann überlege bitte, ob sich Deine Schlafzeiten
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und fülle den Fragebogen entsprechend aus.                      </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Sie einen regelmässigen Zeitplan haben, der
            Ihnen mehr oder weniger vorgibt, wann Sie am Morgen aufstehen und/oder
            am Abend schlafen gehen müssen.
            Falls die Antwort 0 oder 7 Tage ist, dann überlegen Sie bitte, ob sich Ihre Schlafzeiten
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und füllen Sie den Fragebogen entsprechend aus.           </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Ihr Kind einen regelmässigen Zeitplan hat, der
            Ihrem Kind mehr oder weniger vorgibt, wann es am Morgen aufstehen und/oder
            am Abend schlafen gehen muss.
            Falls die Antwort 0 oder 7 Tage ist, dann überlegen Sie bitte, ob sich die Schlafzeiten Ihres Kindes
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und füllen Sie den Fragebogen entsprechend aus.                   </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Du einen regelmässigen Zeitplan hast, der
            Dir mehr oder weniger vorgibt, wann Du am Morgen aufstehen und/oder
            am Abend schlafen gehen musst.
            Falls die Antwort 0 oder 7 Tage ist, dann überlege bitte, ob sich Deine Schlafzeiten
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und fülle den Fragebogen entsprechend aus.   </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Sie einen regelmässigen Zeitplan haben, der
            Ihnen mehr oder weniger vorgibt, wann Sie am Morgen aufstehen und/oder
            am Abend schlafen gehen müssen.
            Falls die Antwort 0 oder 7 Tage ist, dann überlegen Sie bitte, ob sich Ihre Schlafzeiten
          <strong> nicht dennoch</strong> an allgemeinen "Werktagen" und "Wochenenden" unterscheiden
          und füllen Sie den Fragebogen entsprechend aus.            </SPChoice>



        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      const WD = parseInt(s.text);
      return WD >= 0 && WD <= 7;
    },
    warning: l({
      en: "Should be between 0 and 7",
      de: "Zahl muss zwischen 0 und 7 liegen"
    }),
    component: v => (
      <Input.Question
        placeholder={l({ en: "days", de: "Tage" })}
        inputType={"number"}
        onValid={v}
      />
    )
  },

  {
    id: "BQ_MCTQ_2_1",
    mandatory: false,
    plainTitle: "bedtime (work)",
    title: (
      <span>
        <En>
            <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On school days:<br />
            At which time does your child go to bed?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On school/work days:<br />
            At which time do you go to bed?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On work days:<br />
            At which time do you go to bed?
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An Schultagen:<br />
            Um welche Zeit geht Ihr Kind zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit gehst Du zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit gehen Sie zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An Schultagen:<br />
            Um welche Zeit geht Ihr Kind zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit gehst Du zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit gehen Sie zu Bett?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "BQ_MCTQ_2_2",
    mandatory: false,
    plainTitle: "Lights-off (work)",
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On school days:<br />
            At what time does your child turn the light off to go to sleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On school/work days:<br />
            At what time do you turn the light off to go to sleep (including putting phone or tablet down)?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On work days:<br />
            At what time do you turn the light off to go to sleep (including putting phone or tablet down)?
            </SPChoice>
          
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An Schultagen:<br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), damit ihr Kind schlafen geht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit schaltest Du das Licht aus (und legst Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An Schultagen:<br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), damit ihr Kind schlafen geht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit schaltest Du das Licht aus (und legst Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>
        </De>
      </span>
    ),

    explanation: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Indicate the time at which your child starts trying to actually sleep.
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Indicate the time at which you start trying to actually sleep.
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Indicate the time at which you start trying to actually sleep.
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Geben Sie die Zeit an, um die Ihr Kind tatsächlich anfängt zu probieren einzuschlafen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Gib die Zeit an, um die Du tatsächlich anfängst zu probieren einzuschlafen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Geben Sie die Zeit an, um die Sie tatsächlich anfangen zu probieren einzuschlafen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Geben Sie die Zeit an, um die Ihr Kind tatsächlich anfängt zu probieren einzuschlafen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Gib die Zeit an, um die Du tatsächlich anfängst zu probieren einzuschlafen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Geben Sie die Zeit an, um die Sie tatsächlich anfangen zu probieren einzuschlafen.</SPChoice>
        </De>
      </span>

    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )

  },

  {
    id: "BQ_MCTQ_2_3",
    mandatory: false,
    plainTitle: "Fall asleep time (work)",
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On school days:<br />
            How many minutes does your child need to fall asleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On school/work days:<br />
            How many minutes do you need to fall asleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On work days:<br />
            How many minutes do you need to fall asleep?
            </SPChoice>
            
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An Schultagen:<br />
            Wie viele Minuten braucht Ihr Kind um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An Arbeits-/Schultagen:<br />
            Wie viele Minuten brauchst Du um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An Arbeits-/Schultagen:<br />
            Wie viele Minuten brauchen Sie um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An Schultagen:<br />
            Wie viele Minuten braucht Ihr Kind um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An Arbeits-/Schultagen:<br />
            Wie viele Minuten brauchst Du um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An Arbeits-/Schultagen:<br />
            Wie viele Minuten brauchen Sie um einzuschlafen?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder={l({ en: "minutes", de: "Minuten" })}
      />
    )
  },

  {
    id: "BQ_MCTQ_2_4",
    mandatory: false,
    plainTitle: "wake up time (work)",
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On school days:<br />
            At what time does your child wake up?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On school/work days:<br />
            At what time do you wake up?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On work days:<br />
            At what time do you wake up?
          </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An Schultagen:<br />
            Um welche Zeit wacht Ihr Kind auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit wachst Du auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit wachen Sie auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An Schultagen:<br />
            Um welche Zeit wacht Ihr Kind auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit wachst Du auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An Arbeits-/Schultagen:<br />
            Um welche Zeit wachen Sie auf?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "BQ_MCTQ_2_5",
    mandatory: false,
    plainTitle: "get up time (work)",
    title: (
      <span>
          <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On school days:<br />
            How long does your child have to get up in the morning?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On school/work days:<br />
            How long do you have to get up in the morning?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On work days:<br />
            How long do you have to get up in the morning?
          </SPChoice>
          </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An Schultagen:<br />
            Wie lange liegt Ihr Kind im Bett bevor es aufsteht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An Arbeits-/Schultagen:<br />
            Wie lange liegst Du im Bett bevor Du aufstehst?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An Arbeits-/Schultagen:<br />
            Wie lange liegen Sie im Bett bevor Sie aufstehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An Schultagen:<br />
            Wie lange liegt Ihr Kind im Bett bevor es aufsteht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An Arbeits-/Schultagen:<br />
            Wie lange liegst Du im Bett bevor Du aufstehst?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An Arbeits-/Schultagen:<br />
            Wie lange liegen Sie im Bett bevor Sie aufstehen?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder={l({ en: "minutes", de: "Minuten" })}
      />
    )
  },


  {
    id: "BQ_MCTQ_3",
    mandatory: false,
    plainTitle: "workweek alarm:",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Does your child regularly use an alarm on <b>school days</b>?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Do you regularly use an alarm on <b>school/work days</b>?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Do you regularly use an alarm on <b>work days</b>?
          </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Stellt sich Ihr Kind bzw. stellen Sie Ihrem Kind regelmässig einen Wecker an <b>Schultagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Stellst Du Dir regelmässig einen Wecker an <b>Schul-/Arbeitstagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Stellen Sie sich regelmässig einen Wecker an <b>Schul-/Arbeitstagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Stellt sich Ihr Kind bzw. stellen Sie Ihrem Kind regelmässig einen Wecker an <b>Schultagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Stellst Du Dir regelmässig einen Wecker an <b>Schul-/Arbeitstagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Stellen Sie sich regelmässig einen Wecker an <b>Schul-/Arbeitstagen</b>?</SPChoice>

        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This is in relation to your answer on usual wakeup time on &nbsp;
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          school days.
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          school/work days.
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          work days.
          </SPChoice>
        </En>
        <De>

          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit Ihres Kindes an
          Schultagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Dies steht im Zusammenhang mit Deiner Antwort zur üblichen Weckzeit an
          Schul-/Arbeitstagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit an
          Schul-/Arbeitstagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit Ihres Kindes an
          Schultagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Dies steht im Zusammenhang mit Deiner Antwort zur üblichen Weckzeit an
          Schul-/Arbeitstagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit an
          Schul-/Arbeitstagen.</SPChoice>

        </De>
      </span>
    ),
    component: v => <YesNo.Question onValid={v} />
  },

  {
    id: "BQ_MCTQ_4",
    mandatory: false,
    plainTitle: "Pre-alarm wake:",
    // shouldShow: (a: Question[]) => {
    //   const idxEpi = a.findIndex(a => a.id === "BQ_MCTQ_3");

    //   return (
    //     a[idxEpi].data !== undefined &&
    //     a[idxEpi].data["answer"] === YesNo.AnswerType.yes
    //   ); 
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Does your child regularly wake up <b>before</b> your alarm goes off?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Do you regularly wake up <b>before</b> your alarm goes off?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Do you regularly wake up <b>before</b> your alarm goes off?
          </SPChoice>
          
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Wacht Ihr Kind </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Wachst Du</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Wachen Sie</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Wacht Ihr Kind </SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Wachst Du</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Wachen Sie</SPChoice>

            &nbsp;regelmässig auf <b>bevor</b> der Wecker läutet?
        </De>
      </span>
    ),
    component: v => <YesNo.Question onValid={v} />
  },

  
  {
    id: "BQ_MCTQ_5_1",
    mandatory: false,
    plainTitle: "bedtime (free)",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At which time does your child go to bed?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At which time do you go to bed?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup> <br />
            At which time do you go to bed?
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit geht Ihr Kind zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit gehst Du zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit gehen Sie zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit geht Ihr Kind zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit gehst Du zu Bett?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit gehen Sie zu Bett?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "BQ_MCTQ_5_2",
    mandatory: false,
    plainTitle: "Lights-off (free)",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time does your child turn the light off to go to sleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time do you turn the light off to go to sleep (including putting phone or tablet down)?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time do you turn the light off to go to sleep (including putting phone or tablet down)?
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schalten Sie das Licht aus, damit ihr Kind schlafen geht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schaltest Du das Licht aus (und legst Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schalten Sie das Licht aus, damit ihr Kind schlafen geht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schaltest Du das Licht aus (und legst Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?</SPChoice>
        </De>
      </span>
    ),

    explanation: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Indicate the time at which your child starts trying to actually sleep.
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Indicate the time at which you start trying to actually sleep.
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Indicate the time at which you start trying to actually sleep.
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Geben Sie die Zeit an, um die Ihr Kind tatsächlich anfängt zu probieren einzuschlafen.</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Gib die Zeit an, um die Du tatsächlich anfängst zu probieren einzuschlafen.</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Geben Sie die Zeit an, um die Sie tatsächlich anfangen zu probieren einzuschlafen.</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Geben Sie die Zeit an, um die Ihr Kind tatsächlich anfängt zu probieren einzuschlafen.</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Gib die Zeit an, um die Du tatsächlich anfängst zu probieren einzuschlafen.</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Geben Sie die Zeit an, um die Sie tatsächlich anfangen zu probieren einzuschlafen.</SPChoice>
        </De>
      </span>

    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )

  },

  {
    id: "BQ_MCTQ_5_3",
    mandatory: false, 
    plainTitle: "Fall asleep time (free)",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How many minutes does your child need to fall asleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How many minutes do you need to fall asleep?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How many minutes do you need to fall asleep?
            </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten braucht Ihr Kind um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten brauchst Du um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten brauchen Sie um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten braucht Ihr Kind um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten brauchst Du um einzuschlafen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie viele Minuten brauchen Sie um einzuschlafen?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder={l({ en: "minutes", de: "Minuten" })}
      />
    )
  },

  {
    id: "BQ_MCTQ_5_4",
    mandatory: false, 
    plainTitle: "wake up time (free)",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time does your child wake up?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time do you wake up?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            At what time do you wake up?
          </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wacht Ihr Kind auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wachst Du auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wachen Sie auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wacht Ihr Kind auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wachst Du auf?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Um welche Zeit wachen Sie auf?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
      />
    )
  },

  {
    id: "BQ_MCTQ_5_5",
    mandatory: false, 
    plainTitle: "get up time (free)",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How long does your child have to get up in the morning?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How long do you have to get up in the morning?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            On days off:<WhyPopup>Weekends, holidays, etc.</WhyPopup><br />
            How long do you have to get up in the morning?
          </SPChoice>
          </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegt Ihr Kind im Bett bevor es aufsteht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegst Du im Bett bevor Du aufstehst?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegen Sie im Bett bevor Sie aufstehen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegt Ihr Kind im Bett bevor es aufsteht?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegst Du im Bett bevor Du aufstehst?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup><br />
            Wie lange liegen Sie im Bett bevor Sie aufstehen?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder={l({ en: "minutes", de: "Minuten" })}
      />
    )
  },

  {
    id: "BQ_MCTQ_6",
    mandatory: false,
    plainTitle: "Weekend alarm:",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 7;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
            Does your child regularly use an alarm on <b>days off</b>?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
            Do you regularly use an alarm on <b>days off</b>?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
            Do you regularly use an alarm on <b>days off</b>?
          </SPChoice>
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Stellt sich Ihr Kind bzw. stellen Sie Ihrem Kind regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Stellst Du Dir regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Stellen Sie sich regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Stellt sich Ihr Kind bzw. stellen Sie Ihrem Kind regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Stellst Du Dir regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Stellen Sie sich regelmässig einen Wecker an <b>freien Tagen</b>?</SPChoice>

        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This is in relation to your answer on usual wakeup time on days off.
        </En>
        <De>

          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit Ihres Kindes an
          freien Tagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Dies steht im Zusammenhang mit Deiner Antwort zur üblichen Weckzeit an
          freien Tagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit an
          freien Tagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit Ihres Kindes an
          freien Tagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Dies steht im Zusammenhang mit Deiner Antwort zur üblichen Weckzeit an
          freien Tagen.</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit an
          freien Tagen.</SPChoice>

        </De>
      </span>
    ),
    component: v => <YesNo.Question onValid={v} />
  },

  {
    id: "BQ_MCTQ_7",
    mandatory: false,
    plainTitle: "Weekend alarm reason:",
    // shouldShow: (a: Question[]) => {
    //   const idx = a.findIndex(a => a.id === "BQ_MCTQ_1");
    //   return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    // },
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Can your child for some reason <b>not</b> decide the time of going to bed/getting up itself, even on weekends?
            </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Can you for some reason <b>not</b> decide the time of going to bed/getting up yourself, even on weekends?
           </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Can you for some reason <b>not</b> decide the time of going to bed/getting up yourself, even on weekends?
          </SPChoice>
          
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>
            Kann ihr Kind aus bestimmten Gründen auch an freien Tagen seine Schlafenszeiten nicht selbst wählen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
            Kannst Du aus bestimmten Gründen auch an freien Tagen Deine Schlafenszeiten nicht selbst wählen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
            Können Sie aus bestimmten Gründen auch an freien Tagen Ihre Schlafenszeiten nicht selbst wählen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiKids}>
            Kann ihr Kind aus bestimmten Gründen auch an freien Tagen seine Schlafenszeiten nicht selbst wählen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
            Kannst Du aus bestimmten Gründen auch an freien Tagen Deine Schlafenszeiten nicht selbst wählen?</SPChoice>

          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
            Können Sie aus bestimmten Gründen auch an freien Tagen Ihre Schlafenszeiten nicht selbst wählen?</SPChoice>

        </De>
      </span>
    ),

    component: v => <YesNo.Question onValid={v} />
  },

  {
    id: "BQ_MCTQ_8",
    mandatory: false, 
    plainTitle: "Weekend alarm reason follow-up:",
    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "BQ_MCTQ_7");

      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNo.AnswerType.yes
      );
    },
    title: (
      <span>
        <En>
          What is/are the reason(s)?
        </En>
        <De>
          Was ist der Grund/sind die Gründe?
        </De>
      </span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        allAbove={false}
        includeDone={true}
        options={[
          { text: l({ en: "Family/Pets", de: "Familienmitglieder/Haustiere" }) },
          { text: l({ en: "Hobbies", de: "Hobbys" }) },
          { text: l({ en: "Other", de: "Andere" }), extraInput: "string" }
        ]}
      />
    )
  }
];

export default questions;
