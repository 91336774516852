import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/MCTQuestions";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import MCTQscore from "./MCTQscorer";
import { En, De } from "ewoq/components/Language";
import { answerQuestionnaire } from "ewoq/api";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { SectionBody } from "ewoq/components/miscStyles";

const MessageText = styled.div`
  text-align: justify;
`;


interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class MCTQ extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    const score = MCTQscore(answers);
    let isEligible = Object.values(answers).every((a) => a.isOk);
    if (score !== undefined) {
      console.log("mctq score is: ", score);
      isEligible = isEligible && score > 2 && score < 6.5;
    }

    answerQuestionnaire("EPI", "BasicInfo-MCTQ", {
      answers: answers,
      scores: score,
      isEligible: isEligible,
    });

    this.props.onDone(isEligible);
  };

  render() {
    return (
      <div>
          <Ui.Segment>
          <Ui.Divider horizontal>  
          <En>Instructions</En>
          <De>Anleitung</De> 
          </Ui.Divider>      
          <SectionBody>              
            <En>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
              In this questionnaire, you report on your childs typical sleep behaviour over the past <strong>4 weeks</strong>.
              We ask about school days and school-free days separately. Please respond to the questions according
              to your perception of a standard week that includes your childs usual school days and school-free days.  </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
              In this questionnaire, you report on your typical sleep behaviour over the past <strong>4 weeks</strong>.
              We ask about school days and school-free days separately. Please respond to the questions according
              to your perception of a standard week that includes your usual school days and school-free days.  </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
              In this questionnaire, you report on your typical sleep behaviour over the past <strong>4 weeks</strong>.
              We ask about work days and work-free days separately. Please respond to the questions according
              to your perception of a standard week that includes your usual work days and work-free days.  </SPChoice>
            </En>
            <De>           
              <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   
              In diesem Fragebogen schildern Sie das typische Schlafverhalten Ihres Kindes in den vergangenen <strong>vier
              Wochen</strong>. Wir fragen getrennt nach Schultagen und freien Tagen. Denken Sie an eine normale
              Woche, die den üblichen Schultagen und freien Tagen Ihres Kindes entspricht. 
              </SPChoice>
              <SPChoice studyPopulation={StudyPopulation.HealthyTeens}> In diesem Fragebogen schilderst Du Dein typisches 
              Schlafverhalten in den vergangenen <strong>vier Wochen</strong>. Wir fragen getrennt nach Schultagen und 
              freien Tagen. Denke an eine normale Woche, die Deinen üblichen Schultagen und freien Tagen entspricht. 
              </SPChoice>
              <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> In diesem Fragebogen schildern Sie Ihr typisches 
              Schlafverhalten in den vergangenen <strong>vier Wochen</strong>. Wir fragen getrennt nach Schul-/Arbeitstagen und 
              freien Tagen. Denken Sie an eine normale Woche, die Ihren üblichen Schul-/Arbeitstagen und freien Tagen entspricht.
              </SPChoice>
              <SPChoice 
              studyPopulation={StudyPopulation.EpiKids}> In diesem Fragebogen schildern Sie das typische Schlafverhalten Ihres Kindes in den vergangenen <strong>vier
              Wochen</strong>. Wir fragen getrennt nach Schultagen und freien Tagen. Denken Sie an eine normale
              Woche, die den üblichen Schultagen und freien Tagen Ihres Kindes entspricht. 
              </SPChoice>
              <SPChoice studyPopulation={StudyPopulation.EpiTeens}> In diesem Fragebogen schilderst Du Dein typisches 
              Schlafverhalten in den vergangenen <strong>vier Wochen</strong>. Wir fragen getrennt nach Schultagen und 
              freien Tagen. Denke an eine normale Woche, die Deinen üblichen Schultagen und freien Tagen entspricht.   
              </SPChoice>
              <SPChoice studyPopulation={StudyPopulation.EpiAdults}> In diesem Fragebogen schildern Sie Ihr typisches 
              Schlafverhalten in den vergangenen <strong>vier Wochen</strong>. Wir fragen getrennt nach Schul-/Arbeitstagen und 
              freien Tagen. Denken Sie an eine normale Woche, die Ihren üblichen Schul-/Arbeitstagen und freien Tagen entspricht. 
              </SPChoice>              
            </De>
        </SectionBody> 
        </Ui.Segment> 
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default MCTQ;
