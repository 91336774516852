import React from "react";
import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import styled from "styled-components";
import questions from "./Questions/BasicExclusionQuestions";
import { answerQuestionnaire } from "ewoq/api";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

import { En, De } from "ewoq/components/Language";
import { StudyPopulationContext } from "StudyPopulationChoice";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class Screening_01 extends React.Component<Props> {
  static contextType = StudyPopulationContext;
  onSubmit = (answers: QuestionsObj) => {
    let isEligible = Object.values(answers).every(a => a.isOk);
    this.props.onDone(isEligible);

    // So that the answers are actually saved (?) 
    answerQuestionnaire("EPI", "Screening-01-BasicExclusion-NoIsOk", {
      answers: answers,
      isEligible: isEligible,
    });    
  };

  render() {
    return (
      <div>
        <Ui.Message warning>
          <Ui.MessageContent>
            <MessageText>
              <En>
                <p>
                  Please answer honestly. This is important to exclude
                  responders who may react badly to the conditions of the
                  experiment, or are otherwise considered “at risk”. 
                  {/* If you are
                  unsure about the meaning of the medical terms, just click on
                  them, and you will be redirected to the relevant Wikipedia
                  page. */}
                </p>{" "}
                <p>
                  {/* <strong>Your answers will not be saved.</strong> */}
                  <strong>
                    Your answers will be saved anonymously.
                  </strong>
                </p>
              </En>
              <De>
                <p>
                  Es ist unabdingbar, dass 
                  <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults, StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> Sie ehrlich antworten. </SPChoice>
                  <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}> Du ehrlich antwortest. </SPChoice>                 
                  Diese Fragen sind wichtig, um
                  Personen vorzeitig aus der Studie auszuschliessen, die möglicherweise schlecht
                  auf die Experimentalbedingungen reagieren könnten
                  oder anderweitig als "gefährdet" betrachtet werden. 
                  {/* Falls Sie
                  über die Bedeutung eines medizinischen Ausdruckes unsicher
                  sind, klicken Sie auf ihn und Sie werden zu der entsprechenden
                  Wikipediaseite geleitet. */}
                </p>{" "}
                <p>
                  <strong>
                    <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults, StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>Ihre </SPChoice>
                    <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>Deine </SPChoice>                      
                    Antworten werden anonym gespeichert, ein Zurückführen auf
                    <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults, StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> Ihre </SPChoice>
                    <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}> Deine </SPChoice>    
                    Person ist nicht möglich.
                  </strong>
                  {/* <strong>Ihre Antworten werden nicht gespeichert.</strong> */}
                </p>
              </De>
            </MessageText>
          </Ui.MessageContent>
        </Ui.Message>
        <Interrogator
          questions={questions(this.context)}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default Screening_01;