import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import WhyPopup from "ewoq/components/WhyPopup";
import { Language } from "./misc";
import { En, De, l } from "ewoq/components/Language";
import { SPChoice, StudyPopulation, StudyPopulationContext, defaultStudyPopulation } from "StudyPopulationChoice";

const HeaderContainer = styled.div`
  // height: 3.5em;
  height: auto;
  min-height: 3.5em;
  // font-size: 5vh;
  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;

const SectionBodySegment = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

const SectionBody = styled.div`
  text-align: justify;
  padding: 1em;
`;

const WarningBox = styled.div`
  margin-top: 1em;
`;

const SubText = styled.div`
  font-style: italic;
  margin-bottom: 1em;
  text-align: center;
`;

const LinksArea = styled.div`
  // padding-bottom: 3em;
  margin-top: 1em;
`;

const ButtonArea = styled.div`
  margin: auto;
  text-align: center;
`;

interface State {
  consent: boolean | undefined;
}

interface Props {
  onDone: (s: boolean) => void;
  onChangeStudyPopulation: (population: StudyPopulation) => void;
  onChangeLanguage: (l: Language) => void;
  language: Language;
  studyPopulation: StudyPopulation;
}

class Consent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { consent: undefined };
  }

  onConsentClick = (consent: boolean) => () => this.setState({ consent });

  render() {
    return (
      <div>
        <LinksArea>
          <a href="/">
            <Ui.Button
              content={"Experiment Information"}
              icon="arrow left"
              compact
              size="tiny"
            />
          </a>
        </LinksArea>
        <HeaderContainer>Screening Questionnaire</HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <SectionBodySegment>
              <SectionBody>
                <Ui.Segment>
                  <Ui.Divider horizontal>
                    <En>
                      Choose a language
                    </En>
                    <De>
                      Wählen Sie eine Sprache
                    </De>
                  </Ui.Divider>
                  <SubText>
                    <En>
                      If you later decide to change language, you will have to start all over again.
                    </En>
                    <De>
                      Wenn Sie während den Fragebögen die Sprache ändern möchten, müssen Sie von vorne beginnen.
                    </De>
                  </SubText>
                  {/* <Ui.Grid centered columns={4}>
                    <Ui.Grid.Column> */}
                  <ButtonArea>
                    <Ui.ButtonGroup>
                      <Ui.Button
                        onClick={() => this.props.onChangeLanguage(Language.en)}
                        color={
                          this.props.language === Language.en
                            ? "teal"
                            : undefined
                        }
                      >
                        <Ui.Flag name="gb" />
                        {/* TODO: make flags bigger, nicer */}
                      </Ui.Button>
                      <Ui.ButtonOr />
                      <Ui.Button
                        onClick={() => this.props.onChangeLanguage(Language.de)}
                        color={
                          this.props.language === Language.de
                            ? "teal"
                            : undefined
                        }
                      >
                        <Ui.Flag name="de" />
                      </Ui.Button>
                    </Ui.ButtonGroup>
                    {/* </Ui.Grid.Column>
                  </Ui.Grid> */}
                  </ButtonArea>
                </Ui.Segment>
              </SectionBody>
              <Ui.Divider horizontal>
                <En>
                  Terms and conditions
                </En>
                <De>
                  Allgemeine Bedingungen
                </De>
              </Ui.Divider>
              <SectionBody>
              <En>
                <p>
                  This questionnaire has 2 sections. Both include simple
                  yes/no questions regarding your health and other sensitive
                  data such as drug use.{" "}
                  {/* <b>
                    Your answers to these first questions will not be saved;
                    this is only an interactive tool to determine if you can
                    participate.
                  </b> */}
                  {/* <WhyPopup>
                    Since we ask about illegal activity and health related
                    issues, normally we first need written consent from you.
                    Since this questionnaire is all done before meeting you, we
                    can't keep such data, even anonymously.
                  </WhyPopup> */}
                  The remaining parts of the questionnaire will ask you
                  questions about your sleep, your drinking and smoking habits.
                  <WhyPopup>
                    <p>
                      Some questions are uncomfortable but necessary to exclude
                      participants who do not match the correct profile for this
                      experiment.
                    </p>
                    <p>
                      Some questions will be very similar. This is because this
                      questionnaire is a mix of traditional sleep-evaluation
                      measures, and custom questions we're testing for the first
                      time.
                    </p>
                  </WhyPopup>
                  These answers will be saved, and only included in later
                  analyses if you are accepted for the study and sign formal
                  consent.
                </p>
                <p>
                  The whole questionnaire takes 10-15 minutes. If you realize
                  halfway through that you may not be eligible, or you do not
                  feel comfortable sharing all this information, you may leave
                  the questionnaire at any time, no harm done. Data of
                  incomplete questionnaires will be discarded without further
                  analyses.
                </p>
                <p>
                  Nowhere in this questionnaire do we ask for personal
                  identifiable information,
                  <WhyPopup>
                    name, date of birth, contact info; anything that could be
                    used to trace the data to you
                  </WhyPopup>
                  but instead provide you a 3-word-code at the end, so that the data remains completely anonymous. 
                  Once you have established a scheduled phone call, the experiment will
                  be explained again in person, and we will ask for formal
                  written consent. Then, you need only provide us with your
                  code, and we can de-anonymize your data.
                </p>
                <p>
                  <strong>
                    It is very important that you reach the end of the
                    questionnaire and receive your code.
                  </strong>{" "}
                  Otherwise we have no way of knowing which questionnaire is
                  yours, and you'll have to do it again.
                </p>
              </En>
              <De>
              <p>
                  Dieser Fragebogen besteht aus zwei Teilen. Beide beinhalten überwiegend einfache Ja/Nein Fragen 
                  zu Ihrer Gesundheit und anderen sensiblen Informationen wie z.B. Medikamentenkonsum.{" "}
                  {/* <b>
                    Your answers to these first questions will not be saved;
                    this is only an interactive tool to determine if you can
                    participate.
                  </b> */}
                  {/* <WhyPopup>
                    Since we ask about illegal activity and health related
                    issues, normally we first need written consent from you.
                    Since this questionnaire is all done before meeting you, we
                    can't keep such data, even anonymously.
                  </WhyPopup> */}
                  Andere Fragen befassen sich zusätzlich mit Ihrem Schlaf-, Trink und Rauchverhalten.
                  <WhyPopup>
                    <p>
                      Manche Fragen sind unangenehm aber notwendig, um Teilnehmer auszuschliessen, die nicht den Anforderungen des Experiments entsprechen.
                    </p>
                    <p>
                      Manche Fragen werden sehr ähnlich sein. Dies liegt daran, dass dieser Fragebogen zusammengestezt
                      wurde aus traditionellen Schlaf-Evaluations-Fragen, sowie neuen, massgeschneiderten Fragen, die wir zum ersten Mal verwenden.
                    </p>
                  </WhyPopup>
                  Erst wenn Sie endgültig zur Studie zugelassen sind und eine formelle Einverständniserklärung unterschrieben haben, 
                  werden Ihre gespeicherten Antworten in späteren Analysen miteingeschlossen.
                </p>
                <p>
                  Der gesamte Fragebogen dauert etwa 10-15 Minuten. Falls Sie während des Beantwortens merken,
                  dass Sie vermutlich nicht für die Studie geeignet sind oder es Ihnen nicht angenehm ist solche Informationen preiszugeben, 
                  können Sie den Fragebogen selbstverständlich jederzeit verlassen. Unvollständige Fragebögen werden nicht für 
                  Analysen verwendet und somit gelöscht.
                </p>
                <p>
                  An keiner Stelle in diesem Fragebogen wird nach persönlichen, zur Identifikation verwendbaren Informationen gefragt.
                  <WhyPopup>
                    Name, Geburtsdatum, Kontaktinformationen; jegliche Daten, die zur Zurückverfolgung zu Ihnen verwendet werden könnten
                  </WhyPopup>
                  Stattdessen erhalten Sie am Ende einen persönlichen 3-Worte Code.
                  Sobald Sie via Mail mit uns Kontakt aufnehmen, wird Ihnen das Experiment noch einmal im Detail erklärt. 
                  Erst nach dem Unterschreiben der Einverständniserklärung werden wir Sie nach dem 3-Worte Code fragen, 
                  sodass die Daten für den weiteren Verlauf des Experimentes ent-anonymisiert werden können.
                </p>
                <p>
                  <strong>
                    Es ist absolut notwendig, dass Sie das Ende des Fragebogens ereichen und den 3-Worte Code erhalten.
                  </strong>{" "}
                  Ohne diesen haben wir keine Möglichkeit den ausgefüllten Fragebogen mit Ihnen in Verbindung zu setzen,
                  weshalb Sie den Fragebogen erneut ausfüllen müssten.
                </p>
              </De>
              </SectionBody>
              <SectionBody>
                <Ui.Segment>
                  <Ui.Divider horizontal>
                    <En>
                      Choose a study population
                    </En>
                    <De>
                      Wählen Sie eine Studienpopulation
                    </De>
                  </Ui.Divider>
                  <SubText>
                    <En>
                      Please choose your study population according to the participant.
                    </En>
                    <De>
                      Bitte wählen Sie die Studienpopulation gemäss der Person, die teilnehmen möchte.
                    </De>
                  </SubText>
                  {/* <Ui.Grid centered columns={4}>
                    <Ui.Grid.Column> */}
                  <ButtonArea>
                    <Ui.ButtonGroup vertical size="medium">

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            // icon="child"
                            // labelPosition="right"
                            // content={l({ en: "Parent + child", de: "Eltern + Kind" })}
                            onClick={() => this.props.onChangeStudyPopulation(StudyPopulation.EpiKids)}
                            color={studyPopulation === StudyPopulation.EpiKids ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Parent + child", de: "Eltern + Kind" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "4 - 12 years", de: "4 - 12 Jahre" })}>
                            </Ui.ButtonContent>                              
                          </Ui.Button>                        
                        </div>
                      )} />                        

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            // icon="reddit"
                            // labelPosition="right"
                            // content={l({ en: "Teenager", de: "Jugendliche" })}
                            onClick={() => this.props.onChangeStudyPopulation(StudyPopulation.HealthyTeens)}
                            color={studyPopulation === StudyPopulation.HealthyTeens ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Teenager", de: "Jugendliche" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "13 - 17 years", de: "13 - 17 Jahre" })}>
                            </Ui.ButtonContent>                                
                          </Ui.Button>
                        </div>
                      )} />                                               

                      {/* <Ui.ButtonOr /> */}

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            // icon="user" 
                            // labelPosition="right"
                            content={l({ en: "Adult", de: "Erwachsene" })}
                            onClick={() => this.props.onChangeStudyPopulation(StudyPopulation.HealthyAdults)}
                            color={studyPopulation === StudyPopulation.HealthyAdults ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Adult", de: "Erwachsene" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "18 - 30 years", de: "18 - 30 Jahre" })}>
                            </Ui.ButtonContent>                                   
                          </Ui.Button>
                        </div>
                      )} />

                    </Ui.ButtonGroup>
                    {/* <WhyPopup>
                      <En>
                        Children
                      </En>                      
                            <p>
                              4 - 12 Jahre.
                            </p>
                    </WhyPopup>                     */}
                  </ButtonArea>
                </Ui.Segment>
              </SectionBody>
              <SectionBody>
              </SectionBody>
              <SectionBody>
                <Ui.Message warning>
                  <Ui.MessageHeader>
                    <En>
                      Do you agree to allow your answers to be saved and used
                      for scientific research?
                    </En>
                    <De>
                      Sind Sie damit einverstanden, dass Ihre Antworten
                      gespeichert und für wissenschaftliche Forschung verwendet
                      werden?
                    </De>
                  </Ui.MessageHeader>
                  <Ui.MessageContent>
                    {
                      <WarningBox>
                        <p>
                          <En>
                            Your identity will in no way be associated with the
                            answers. The data will only become associated with
                            your identity if you choose to come to the lab, sign
                            formal consent, and participate. Otherwise, it will
                            be discarded.
                          </En>
                          <De>
                            Ihre Identität wird in keiner Weise mit den
                            Antworten in Verbindung gebracht. Die Daten werden
                            nur dann mit Ihrer Identität verknüpft, wenn Sie
                            sich dafür entscheiden, zum Labor zu kommen, eine
                            formelle Einwilligung zu unterschreiben und
                            teilzunehmen. Andernfalls werden sie verworfen.
                          </De>
                        </p>
                        <Ui.Form>
                          <Ui.Form.Field>
                            <Ui.Checkbox
                              radio
                              label={l({ en: "Accept", de: "Zustimmen" })}
                              checked={this.state.consent}
                              onClick={this.onConsentClick(true)}
                            />
                          </Ui.Form.Field>
                          <Ui.Form.Field>
                            <Ui.Checkbox
                              radio
                              label={l({
                                en: "Disagree",
                                de: "Nicht zustimmen"
                              })}
                              checked={
                                !this.state.consent &&
                                this.state.consent !== undefined
                              }
                              onClick={this.onConsentClick(false)}
                            />
                          </Ui.Form.Field>
                        </Ui.Form>
                      </WarningBox>
                    }
                  </Ui.MessageContent>
                </Ui.Message>
              </SectionBody>
              <SectionBody>
                <Ui.Button
                  fluid
                  disabled={this.state.consent !== true || this.props.studyPopulation === defaultStudyPopulation}
                  onClick={() => this.props.onDone(this.state.consent || false)}
                >
                  {l({ en: "Start", de: "Start" })}
                </Ui.Button>
              </SectionBody>
            </SectionBodySegment>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Consent;
