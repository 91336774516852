import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";

const questions: QuestionType[] = [
  {
    id: "RT_4.1",
    mandatory: false,
    title: <span>Please indicate your sleepiness during the rest test: </span>,
    component: v => kss(v)
  },
  {
    id: "RT_4.2",
    mandatory: false,
    title: <span>How do you feel: </span>,
    component: v => fourEnergiesPresent(v)
  },
  {
    id: "RT_4.3.1",
    mandatory: false,
    title: <span>Have you fallen asleep while: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Watching TV" },
          { text: "Doing the rest task" },
          { text: "During the break" },
          { text: "Not at all" }
        ]}
      />
    )
  },
  {
    id: "RT_4.3.2",
    mandatory: false,
    title: <span>How sure are you of your answer: </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["0%", "100% sure"]}
      />
    )
  },
  {
    id: "RT_4.4",
    mandatory: false,
    title: (
      <span>
        How alert are you? How quickly do you feel you would respond to
        something (e.g. a ball thrown at your head)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Slow, sluggish", "Normal", "Hyperalert"]}
      />
    )
  },
  {
    id: "RT_4.5",
    mandatory: false,
    title: <span>How much need do you feel to fall asleep right now?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I can't sleep right now" },
          { text: "I would have a hard time falling asleep" },
          { text: "I might sleep" },
          { text: "I would like to sleep" },
          { text: "I really want to sleep" },
          { text: "This is the most I've ever wanted to sleep" },
          { text: "I feel tired, but I don't think I could sleep" }
        ]}
      />
    )
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
