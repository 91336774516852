import React from "react";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as GroupYesNo from "ewoq/prototypes/YesNoGroup";
import { QuestionType } from "ewoq/containers/Interrogator";
import { En, De, l } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "SQ_SEN_1",
    plainTitle: "Sensitive skin",
    title: (
      <span>
        <En>Do you have sensitive skin?</En>
        <De>Haben Sie empfindliche Haut?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          This means that cleaning without gloves results in skin irritations,
          you need a special sun block, you have{" "}
          <a
            href="https://en.wikipedia.org/wiki/Dermatitis"
            target="_blank"
            rel="noopener noreferrer"
          >
            skin eczemas
          </a>
          , allergies,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Psoriasis"
            target="_blank"
            rel="noopener noreferrer"
          >
            psoriasis
          </a>{" "}
          (long lasting red, dry, itchy skin) or problems with acne, etc.
        </En>
        <De>
          Das würde bedeuten, dass Körperreinigung ohne Handschuhe zu
          Hautreizungen führt, Sie einen speziellen Sonnenschutz benötigen oder
          Sie{" "}
          <a
            href="https://de.wikipedia.org/wiki/Dermatitis"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hautekzeme
          </a>
          , Allergien,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Psoriasis"
            target="_blank"
            rel="noopener noreferrer"
          >
            Psoriasis
          </a>{" "}
          (lang anhaltende rote, trockene, juckende Haut) oder Probleme mit Akne
          usw. haben.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_2",
    plainTitle: "Surgery",
    title: (
      <span>
        <En>Have you had major surgery in the past 3 months?</En>
        <De>Hatten Sie in den letzten 3 Monaten eine grössere Operation?</De>
      </span>
    ),

    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>Requiring full-body anaesthesia.</En>
        <De>Unter Vollnarkose.</De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_3",
    plainTitle: "Brain surgery",
    title: (
      <span>
        <En>Have you ever had brain surgery?</En>
        <De>Hatten Sie jemals eine Operation am Gehirn?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_4",
    plainTitle: "Psychiatric disorder",
    title: (
      <span>
        <En>
          Have you ever been diagnosed with (or strongly suspected of having) a
          neurological/psychiatric disorder, or suffered brain damage?
        </En>
        <De>
          Wurde bei Ihnen jemals eine neurologische / psychiatrische Störung
          diagnostiziert (oder stark vermutet) oder leideten Sie jemals an einer
          Gehirnverletzung?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples include, but are not limited to:{" "}
          <a
            href="https://en.wikipedia.org/wiki/anorexia"
            target="_blank"
            rel="noopener noreferrer"
          >
            anorexia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Attention_deficit_hyperactivity_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            ADHD
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/anxiety_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            anxiety
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/autism"
            target="_blank"
            rel="noopener noreferrer"
          >
            autism
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/bipolar_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            bipolar disorder
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/aneurysm"
            target="_blank"
            rel="noopener noreferrer"
          >
            brain haemorrhage/aneurysm
          </a>
          , brain malformations,{" "}
          <a
            href="https://en.wikipedia.org/wiki/bulimia_nervosa"
            target="_blank"
            rel="noopener noreferrer"
          >
            bulimia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/cerebral_palsy"
            target="_blank"
            rel="noopener noreferrer"
          >
            cerebral palsy
          </a>
          , major concussion,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Cluster_headache"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            cluster headaches
          </a>
          , frequent and severe{" "}
          <a
            href="https://en.wikipedia.org/wiki/Migraine"
            target="_blank"
            rel="noopener noreferrer"
          >
            migraines
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Major_depressive_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            depression
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/dystonia"
            target="_blank"
            rel="noopener noreferrer"
          >
            dystonia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/epilepsy"
            target="_blank"
            rel="noopener noreferrer"
          >
            epilepsy
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/intracranial_pressure"
            target="_blank"
            rel="noopener noreferrer"
          >
            intracranial pressure
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/multiple_sclerosis"
            target="_blank"
            rel="noopener noreferrer"
          >
            multiple sclerosis
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/phobia"
            target="_blank"
            rel="noopener noreferrer"
          >
            phobias
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/psychosis"
            target="_blank"
            rel="noopener noreferrer"
          >
            psychosis
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/PTSD"
            target="_blank"
            rel="noopener noreferrer"
          >
            PTSD
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/schizophrenia"
            target="_blank"
            rel="noopener noreferrer"
          >
            schizophrenia
          </a>
          , stroke, tumours, etc.
        </En>
        <De>
          Z.B.:{" "}
          <a
            href="https://de.wikipedia.org/wiki/anorexia"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anorexie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Attention_deficit_hyperactivity_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            ADHS
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Angstst%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Angststörungen
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Autismus"
            target="_blank"
            rel="noopener noreferrer"
          >
            Autismus
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Bipolare_St%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            bipolare Störung
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Aneurysma#Zerebrales_Aneurysma"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gehirnblutung/-aneurysma
          </a>
          , Gehirnfehlbildungen,{" "}
          <a
            href="https://de.wikipedia.org/wiki/bulimia_nervosa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bulimie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Infantile_Zerebralparese"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zerebralparese
          </a>
          , schwere Gehirnerschütterung,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Cluster-Kopfschmerz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cluster-Kopfschmerzen
          </a>
          , häufige und schwere{" "}
          <a
            href="https://de.wikipedia.org/wiki/Migr%C3%A4ne"
            target="_blank"
            rel="noopener noreferrer"
          >
            Migräne
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Depression"
            target="_blank"
            rel="noopener noreferrer"
          >
            Depression
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Dystonie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dystonie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Epilepsie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Epilepsie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Hirndruck"
            target="_blank"
            rel="noopener noreferrer"
          >
            erhöhter Hirndruck
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Multiple_Sklerose"
            target="_blank"
            rel="noopener noreferrer"
          >
            Multiple Sklerose
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Phobie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Phobien
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Psychose"
            target="_blank"
            rel="noopener noreferrer"
          >
            Psychosen
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/PTSD"
            target="_blank"
            rel="noopener noreferrer"
          >
            PTBS
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Schizophrenie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schizophrenie
          </a>
          , Schlaganfall, Tumoren usw.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_5",
    plainTitle: "Larning disabilities",
    title: (
      <span>
        <En>
          Have you ever been diagnosed or strongly suspected of having a
          learning disability?
        </En>
        <De>
          Wurde bei Ihnen jemals eine Lernschwäche diagnostiziert oder stark
          vermutet?
        </De>
      </span>
    ),

    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples include:{" "}
          <a
            href="https://en.wikipedia.org/wiki/dyslexia"
            target="_blank"
            rel="noopener noreferrer"
          >
            dyslexia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/dyscalculia"
            target="_blank"
            rel="noopener noreferrer"
          >
            dyscalculia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/dysgraphia"
            target="_blank"
            rel="noopener noreferrer"
          >
            dysgraphia
          </a>
          , etc.
        </En>
        <De>
          Z.B.:{" "}
          <a
            href="https://de.wikipedia.org/wiki/Lese-_und_Rechtschreibst%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legasthenie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Dyskalkulie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dyskalkulie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Agrafie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dysgrafie{" "}
          </a>
          usw.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_6",
    plainTitle: "Hearing impairements",
    title: (
      <span>
        <En>Do you have any hearing impairments in either ear?</En>
        <De>Haben Sie Hörstörungen auf einem Ohr?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          {" "}
          Examples include: minor deafness,{" "}
          <a
            href="https://en.wikipedia.org/wiki/tinnitus"
            target="_blank"
            rel="noopener noreferrer"
          >
            tinnitus
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/cochlear_implant"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            cochlear implants
          </a>{" "}
          (or anything requiring hearing assistance device), etc.
        </En>
        <De>
          Z.B.: leichte Taubheit,{" "}
          <a
            href="https://de.wikipedia.org/wiki/tinnitus"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tinnitus
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Cochlea-Implantat"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cochlea-Implantate
          </a>{" "}
          (oder andere Hörgeräte) usw.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_7",
    plainTitle: "Vision impairments",
    title: (
      <span>
        <En>Can you see clearly from both eyes?</En>
        <De>Können Sie mit beiden Augen gut sehen?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.yes,
    explanation: (
      <span>
        <En>
          You can answer “yes” if you can see clearly while wearing contact
          lenses or glasses. Please answer “no” for color blindness.
        </En>
        <De>
          Antworten Sie „ja“, wenn Sie mit Kontaktlinsen oder Brillen klar sehen
          können. Bitte antworten Sie mit "Nein" bei Farbenblindheit.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_8",
    plainTitle: "Infectious diseases",
    title: (
      <span>
        <En>
          Have you suffered from a serious infectious disease in the past 3
          years, or are still affected by one?
        </En>
        <De>
          Haben Sie in den letzten 3 Jahren an einer schweren
          Infektionskrankheit gelitten oder sind noch immer davon betroffen?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          <p>
            These are diseases that required you to take medication for a
            prolonged period ({">"}2 weeks) and/or that prevent you from
            working/studying 100% of the time.
          </p>
          <p>
            Examples: Lyme disease, AIDS/HIV, syphilis, FSME (tick
            encephalitis), parasites, dengue, malaria, TB, Hepatitis C, measles,
            diphtheria, legionnaires."
          </p>
        </En>
        <De>
          <p>
            Gemeint sind Krankheiten, bei denen Sie über einen längeren Zeitraum
            ({">"} 2 Wochen) Medikamente einnehmen müssen und / oder die Sie daran
            hindern, 100% zu arbeiten / zu studieren.
          </p>
          <p>
            Z.B.: Lyme-Borreliose, AIDS/HIV, Syphilis, FSME
            (Zeckenenzephalitis), Parasiten, Dengue-Fieber, Malaria, TB,
            Hepatitis C, Masern, Diphtherie, Legionellen.
          </p>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_9",
    plainTitle: "Fatigue",
    title: (
      <span>
        <En>
          {" "}
          Do you suffer regularly from{" "}
          <a
            href="https://en.wikipedia.org/wiki/chronic_fatigue_syndrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            fatigue
          </a>
          , either physical or mental?
        </En>
        <De>
          Leiden Sie regelmäßig unter physischer oder psychischer{" "}
          <a
            href="https://de.wikipedia.org/wiki/chronic_fatigue_syndrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            Müdigkeit
          </a>
          ?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          This means you tire easily throughout the day, so much so that it
          seriously impacts your work and social life.
        </En>
        <De>
          Das bedeutet, dass Sie am Tag sehr schnell müde werden, was Ihre
          Arbeit und Ihr soziales Leben ernsthaft beeinträchtigt.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_10",
    plainTitle: "Habitual fainting",
    title: (
      <span>
        <En>Do you regularly faint or suffer from dizziness?</En>
        <De>Fühlen Sie sich regelmäßig schwindelig oder fallen in Ohnmacht?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_11",
    plainTitle: "Recent fainting",
    title: (
      <span>
        <En>
          Have you fainted or lost consciousness at least once in the past year
          for more than 5 minutes?
        </En>
        <De>
          Haben Sie im letzten Jahr mindestens einmal mehr als 5 Minuten lang
          das Bewusstsein verloren?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },

  {
    id: "SQ_SEN_12",
    plainTitle: "Hand impairments",
    title: (
      <span>
        <En>
          Do you have any impairment to your hands/fingers on either hand that
          make typing challenging?
        </En>
        <De>
          Haben Sie eine Beeinträchtigung Ihrer Hände/Finger, die das Tippen
          schwierig macht?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>This can be caused by anything, both temporary or permanent.</En>
        <De>Das kann vorübergehend oder dauerhaft sein.</De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_13",
    plainTitle: "Other",
    title: (
      <span>
        <En>
          Do you have any other physical impairment, medical condition or
          disability?
        </En>
        <De>
          Haben Sie eine andere körperliche Beeinträchtigung, Krankheit oder
          Behinderung?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples include but are not limited to:{" "}
          <a
            href="https://en.wikipedia.org/wiki/autoimmune_disease"
            target="_blank"
            rel="noopener noreferrer"
          >
            autoimmune disorders
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/metabolic_disorder"
            target="_blank"
            rel="noopener noreferrer"
          >
            metabolic disorders
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/paraplegia"
            target="_blank"
            rel="noopener noreferrer"
          >
            paraplegia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/endocrine_disease"
            target="_blank"
            rel="noopener noreferrer"
          >
            hormonal disorders
          </a>
          , tumours (also benign), cardiovascular problems (e.g. past heart
          attack,{" "}
          <a
            href="https://en.wikipedia.org/wiki/angina"
            target="_blank"
            rel="noopener noreferrer"
          >
            angina
          </a>
          , weak heart,{" "}
          <a
            href="https://en.wikipedia.org/wiki/congenital_heart_defect"
            target="_blank"
            rel="noopener noreferrer"
          >
            congenital heart defects
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/diabetes"
            target="_blank"
            rel="noopener noreferrer"
          >
            diabetes
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/obesity"
            target="_blank"
            rel="noopener noreferrer"
          >
            obesity
          </a>
          , kidney problems, severe{" "}
          <a
            href="https://en.wikipedia.org/wiki/scoliosis"
            target="_blank"
            rel="noopener noreferrer"
          >
            scoliosis
          </a>{" "}
          (bent spine), organ transplant, severe
          <a
            href="https://en.wikipedia.org/wiki/asthma"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            asthma
          </a>
          , lung deficiencies, third degree burns, chronic pain,{" "}
          <a
            href="https://en.wikipedia.org/wiki/anemia"
            target="_blank"
            rel="noopener noreferrer"
          >
            anaemia
          </a>{" "}
          (iron deficiency), thyroid problems,{" "}
          <a
            href="https://en.wikipedia.org/wiki/strabismus"
            target="_blank"
            rel="noopener noreferrer"
          >
            strabismus
          </a>
        </En>
        <De>
          Z.B.:{" "}
          <a
            href="https://de.wikipedia.org/wiki/Autoimmunerkrankung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Autoimmunerkrankungen
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Stoffwechselst%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stoffwechselerkrankungen
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Querschnittl%C3%A4hmung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Querschnittslähmung
          </a>{" "}
          (Beeinträchtigung der Beine),{" "}
          <a
            href="https://de.wikipedia.org/wiki/Hormonelle_St%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            hormonelle Störungen
          </a>
          , Tumoren (auch gutartige), Herz-Kreislauf-Probleme (z. B. Herzinfarkt
          in der Vergangenheit,{" "}
          <a
            href="https://de.wikipedia.org/wiki/angina"
            target="_blank"
            rel="noopener noreferrer"
          >
            Angina
          </a>
          , schwaches Herz,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Herzfehler"
            target="_blank"
            rel="noopener noreferrer"
          >
            angeborene Herzfehler
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/diabetes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Diabetes
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Fettleibigkeit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fettleibigkeit
          </a>
          , Nierenprobleme, schwere{" "}
          <a
            href="https://de.wikipedia.org/wiki/Skoliose"
            target="_blank"
            rel="noopener noreferrer"
          >
            Skoliose
          </a>{" "}
          (gebogener Rücken), Organtransplantation, schweres{" "}
          <a
            href="https://de.wikipedia.org/wiki/asthma"
            target="_blank"
            rel="noopener noreferrer"
          >
            Asthma
          </a>
          , schwere Lungenerkrankungen, Verbrennungen dritten Grades, chronische
          Schmerzen,{" "}
          <a
            href="https://de.wikipedia.org/wiki/An%C3%A4mie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anämie
          </a>{" "}
          (Eisenmangel), Schilddrüsenprobleme,{" "}
          <a
            href="https://de.wikipedia.org/wiki/strabismus"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schielen.
          </a>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_14",
    plainTitle: "Broken limbs",
    title: (
      <span>
        <En>
          Are you currently or have recently been required to wear a cast or
          long-term bandage?
        </En>
        <De>
          Müssen Sie derzeit einen Gipsverband tragen oder hatten kürzlich einen
          Langzeitverband?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          <p>For example from a broken arm or pulled tendon. </p>{" "}
          <p>
            If the same amount of time you were wearing the cast/bandage has
            already passed without it, and you can freely move as before, you
            can answer “no”.
          </p>
        </En>
        <De>
          <p>
            Zum Beispiel von einem gebrochenen Arm oder einer überdehnten Sehne.
          </p>
          <p>
            Wenn die gleiche Zeit, die Sie mit dem Verband verbracht haben,
            bereits ohne wieder vergangen ist und Sie sich wie zuvor frei
            bewegen können, können Sie mit „Nein“ antworten.
          </p>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_15",
    plainTitle: "Current medication",
    title: (
      <span>
        <En>Do you currently take any prescription medication?</En>
        <De>Nehmen Sie derzeit verschreibungspflichtige Medikamente ein?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          {" "}
          <p>
            This is any pill, tablet, syrup, or other that required a doctor’s
            prescription to obtain from the pharmacy.{" "}
          </p>
          <p>
            The only <u>exceptions</u> are:{" "}
            <a
              href="https://en.wikipedia.org/wiki/hormonal_contraception"
              target="_blank"
              rel="noopener noreferrer"
            >
              contraception
            </a>
            , creams for limited external use.
          </p>
        </En>
        <De>
          <p>
            Dies beinhaltet Pillen, Tabletten, Sirups oder andere Stoffe, für
            die ein ärztliches Rezept in der Apotheke erforderlich ist.
          </p>
          <p>
            Die einzigen <u>Ausnahmen</u> sind:{" "}
            <a
              href="https://de.wikipedia.org/wiki/Empf%C3%A4ngnisverh%C3%BCtung"
              target="_blank"
              rel="noopener noreferrer"
            >
              Empfängnisverhütung
            </a>
            , Cremes zur begrenzten äußerlichen Anwendung.
          </p>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_16",
    plainTitle: "Recent medication",
    title: (
      <span>
        <En>Have you taken prescription medication in the past month? </En>
        <De>
          Haben Sie im letzten Monat verschreibungspflichtige Medikamente
          eingenommen?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_17",
    plainTitle: "Drug use",
    title: (
      <span>
        <En>
          Have you ever used recreational drugs regularly, i.e. more than once
          per week?
        </En>
        <De>
          Haben Sie jemals regelmäßig Drogen konsumiert, d. h. mehr als einmal
          pro Woche?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples include but are not limited to: marijuana, heroin, opium,
          cocaine, meth, amphetamines, MDMA, GHB, G, speed, ecstasy, LSD,
          mescaline, psilocybin (i.e. “magic mushrooms”), steroids.
        </En>
        <De>
          Z.B.: Marihuana, Heroin, Opium, Kokain, Meth, Amphetamine, MDMA, GHB,
          G, speed, Ecstasy, LSD, Mescalin, Psilocybin (z.B. "magic mushrooms"),
          Steroide.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_18",
    plainTitle: "Addiction",
    title: (
      <span>
        <En>
          Have you ever suffered an addiction requiring rehabilitation to drugs,
          gambling, or other?
        </En>
        <De>
          Hatten Sie jemals eine Suchterkrankung (z.B. Drogen, Glücksspiel,
          etc), die eine Rehabilitation erforderte?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_19",
    plainTitle: "Withdrawl",
    title: (
      <span>
        <En>Have you ever experienced withdrawal from a drug?</En>
        <De>Hatten Sie jemals Entzugssymptome von Drogen?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Common examples include nicotine/cigarettes and opioids/painkillers.
        </En>
        <De>
          Häufige Beispiele sind Nikotin/Zigaretten und Opioide/Schmerzmittel.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_20",
    plainTitle: "Alcohol abuse",
    title: (
      <span>
        <En>Have you ever suffered from alcohol abuse?</En>
        <De>Haben Sie jemals unter Alkoholmissbrauch gelitten?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          This means you were drinking regularly until severely drunk several
          times per week or more.
        </En>
        <De>
          Das bedeutet, dass Sie mehrmals pro Woche stark betrunken waren.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_21",
    plainTitle: "Sleep disorders",
    title: (
      <span>
        <En>Have you ever suffered from a sleep disorder?</En>
        <De>Haben Sie jemals an einer Schlafstörung gelitten?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples include:{" "}
          <a
            href="https://en.wikipedia.org/wiki/sleep_apnea"
            target="_blank"
            rel="noopener noreferrer"
          >
            sleep apnea
          </a>
          , severe snoring,{" "}
          <a
            href="https://en.wikipedia.org/wiki/narcolepsy"
            target="_blank"
            rel="noopener noreferrer"
          >
            narcolepsy
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/parasomnia"
            target="_blank"
            rel="noopener noreferrer"
          >
            parasomnia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/sleep_paralysis"
            target="_blank"
            rel="noopener noreferrer"
          >
            sleep paralysis
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/cataplexy"
            target="_blank"
            rel="noopener noreferrer"
          >
            cataplexy
          </a>{" "}
          (sudden muscle weakness), fainting bouts,{" "}
          <a
            href="https://en.wikipedia.org/wiki/chronic_fatigue_syndrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            chronic fatigue
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/hypersomnia"
            target="_blank"
            rel="noopener noreferrer"
          >
            hypersomnia
          </a>{" "}
          (excessive time spent sleeping),{" "}
          <a
            href="https://en.wikipedia.org/wiki/insomnia"
            target="_blank"
            rel="noopener noreferrer"
          >
            insomnia
          </a>
          ,{" "}
          <a
            href="https://en.wikipedia.org/wiki/restless_leg_syndrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            restless leg syndrome
          </a>
          .
        </En>
        <De>
          Z.B.:{" "}
          <a
            href="https://de.wikipedia.org/wiki/Schlafapnoe-Syndrom"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schlafapnoe
          </a>
          , starkes Schnarchen,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Narkolepsie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Narkolepsie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Parasomnie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Parasomnie
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Schlafparalyse"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schlaflähmung
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Kataplexie"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kataplexie
          </a>{" "}
          (plötzliche Muskelschwäche), Ohnmacht,{" "}
          <a
            href="https://de.wikipedia.org/wiki/chronic_fatigue_syndrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            chronische Müdigkeit
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Schlafsucht"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hypersomnie
          </a>{" "}
          (Schlafsucht),{" "}
          <a
            href="https://de.wikipedia.org/wiki/Schlafst%C3%B6rung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schlaflosigkeit
          </a>
          ,{" "}
          <a
            href="https://de.wikipedia.org/wiki/Restless-Legs-Syndrom"
            target="_blank"
            rel="noopener noreferrer"
          >
            Restless Leg Syndrom
          </a>
          .
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_22",
    plainTitle: "Medication for sleep",
    title: (
      <span>
        <En>
          Do you regularly (at least once per week) take medication to help you
          sleep or that impact your sleep?
        </En>
        <De>
          Nehmen Sie regelmäßig (mindestens einmal pro Woche) Medikamente ein,
          die Ihnen beim Schlafen helfen oder sich auf Ihren Schlaf auswirken?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples: Zolpidem (Ambien®, Stilnox®), benzodiazepines (Temesta®,
          Seresta®, Xanax®, Dormicum®), antidepressives (Remeron®, Trittico®),
          antihistamines (allergy drugs such as Sanalepsi N®, Benocten®)
        </En>
        <De>
          Z.B.: Zolpidem (Ambien®, Stilnox®), Benzodiazepine (Temesta®,
          Seresta®, Xanax®, Dormicum®), Antidepressiva (Remeron®, Trittico®),
          Antihistaminika (Medikamente gegen Allergien, wie Sanalepsi N®,
          Benocten®)
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },

  {
    id: "SQ_SEN_23",
    plainTitle: "Medication as stimulant",
    title: (
      <span>
        <En>Do you take any medication as a stimulant?</En>
        <De>Nehmen Sie Medikamente als Stimulantien ein?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          Examples: Adderall®, methylphenidate (Ritalin®), modafinil (Provigil®)
        </En>
        <De>
          Z.B.: Adderall®, Methylphenidat (Ritalin®), Modafinil (Provigil®)
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_24",
    plainTitle: "Difficult sleep periods",
    title: (
      <span>
        <En>
          Have you ever suffered from difficult sleep for periods longer than a
          month in the past year?
        </En>
        <De>
          Haben Sie im vergangenen Jahr länger als einen Monat an
          Schlafstörungen gelitten?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          This means you had trouble falling asleep, trouble staying asleep,
          waking up frequently, woke up not feeling rested, etc.
        </En>
        <De>
          Gemeint sind Probleme beim Einschlafen, Durchschlafen, häufiges
          Aufwachen, Aufwachen ohne genügende Erholung usw.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_25",
    plainTitle: "Daytime sleepiness",
    title: (
      <span>
        <En>Do you currently suffer from daytime sleepiness?</En>
        <De>Leiden Sie derzeit unter Tagesmüdigkeit?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    explanation: (
      <span>
        <En>
          This means you regularly feel sleepy most of the day, for most days.
        </En>
        <De>
          Das bedeutet, dass Sie sich an den meisten Tagen den ganzen Tag über
          regelmäßig schläfrig fühlen.
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_26",
    plainTitle: "Seasonal changes",
    title: (
      <span>
        <En>
          Do you suffer from seasonal changes in mood or sleepiness, enough to
          disrupt your ability to work or study normally?
        </En>
        <De>
          Leiden Sie unter saisonalen Stimmungsschwankungen oder erhöhter
          Schläfrigkeit, die Ihre Fähigkeit normal zu arbeiten oder zu lernen
          beeinträchtigen?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_27",
    plainTitle: "Pregnant",
    title: (
      <span>
        <En>Are you pregnant?</En>
        <De>Sind Sie schwanger?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_28",
    plainTitle: "Small kids",
    title: (
      <span>
        <En>Do you have children younger than 5?</En>
        <De>Haben Sie Kinder im Alter von unter 5 Jahren?</De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_SEN_29",
    plainTitle: "Additional disruptors",
    title: (
      <span>
        <En>
          Have any of the following affected you in the past month, enough to
          impact your sleep, restfulness, or mood/psychological wellbeing?
        </En>
        <De>
          Haben Sie im letzten Monat eine der folgenden Situationen erlebt, die
          Ihren Schlaf, Ihre Ruhe oder Ihre Stimmung bzw. Ihr psychisches
          Wohlbefinden beeinträchtigten?
        </De>
      </span>
    ),
    isOk: (s: GroupYesNo.State) => {
      return s.answers.every(a => a === YesNoQ.AnswerType.no);
    },
    component: v => (
      <GroupYesNo.Question
        onValid={v}
        subQuestions={[
          l({
            en: "Death of a close friend or relative",
            de: "Tod eines engen Freundes oder Verwandten"
          }),
          l({
            en: "Severe medical illness, treated or untreated",
            de: "Schwere medizinische Erkrankung, behandelt oder unbehandelt"
          }),
          l({
            en: "A serious accident (e.g. a car crash)",
            de: "Ein schwerer Unfall (z. B. ein Autounfall)"
          }),
          l({
            en: "Taking care of a seriously ill friend or relative",
            de: "Betreuung eines schwerkranken Freundes oder Verwandten"
          }),
          l({
            en: "Yes, but I’d rather not say",
            de: "Ja, aber ich möchte es nicht sagen "
          }),
          l({ en: "Other", de: "Andere" })
        ]}
      />
    )
  }
];

export default questions;
