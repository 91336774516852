import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Range from "ewoq/prototypes/Ranges";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import { activities } from "Questionnaires/StandardQuestionComponents/Miscelanous";
import { En, De, l } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "LQ_SLE_1",
    mandatory: false,
    plainTitle: "Ease of stopping activities to sleep",
    title: (
      <span>
        In the evening, how hard is it for you to stop what you’re doing and get
        ready for sleep?
      </span>
    ),
    tip:
      "Click on the bar to indicate your answer on the scale. Click on the labels if you want exactly that answer. If viewed on a mobile device, do not rotate the screen between answers.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely difficult"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_2",
    mandatory: false,
    plainTitle: "What determines bed time",
    title: <span>What determines when you go to sleep?</span>,

    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "As soon as I feel sleepy, I go to sleep." },
          {
            text:
              "I have a regular schedule and go to sleep at the same time because of it."
          },
          {
            text:
              "I go to sleep at different times, depending on what I’m doing in the evening (working, gaming, internet, etc)."
          },
          { text: "Other people determine when I can go to sleep." },
          {
            text:
              "I go to bed depending on when I have to/want to wake up the next morning."
          },
          { text: "Whenever it starts getting too late." },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_3",
    mandatory: false,
    plainTitle: "How often sleep later than desired",
    title: (
      <span>
        How often do you get so caught up in what you’re doing in the evening
        that you go to bed more than 2 hours past your target bedtime?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_4",
    mandatory: true,
    plainTitle: "Bed time variance",
    title: (
      <span>
        How much has your bedtime varied over the past 2 months on average?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "By less than 30 minutes" },
          { text: "30 minutes - 1 hour" },
          { text: "1-2 hours" },
          { text: "More than 2 hours" },
          { text: "Not sure" }
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_5",
    mandatory: true,
    plainTitle: "Pre bed activities",
    title: (
      <span>
        Do you regularly do any of the following just before ({"<"}2h) sleep?
      </span>
    ),
    component: v => activities(v)
  },
  {
    id: "LQ_SLE_6",
    mandatory: false,
    plainTitle: "Reading device",
    title: <span>When you read before bed, which medium do you use?</span>, //TODO: condition to only appear if reading was clicked above
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_SLE_5");
      return a[idx].data !== undefined && a[idx].data["answers"][0];
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Paper" },
          { text: "LED screen (e.g. iPad)" },
          { text: "E-Ink reader (e.g. kindle)" },
          { text: "Back-lit e-ink reader (e.g. kindle paperwhite)" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_7",
    mandatory: true,
    plainTitle: "Sleep hygiene",
    title: (
      <span>
        Do you regularly do any of the following to improve sleep quality:{" "}
      </span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Maintain a certain room temperature" },
          {
            text: "Maintain constant darkness (wear a face mask/lower shades)"
          },
          { text: "Use earplugs" },
          { text: "Use an air humidifier" },
          { text: "Eat/drink/take something: ", extraInput: "string" },
          { text: "Other", extraInput: "string" }
        ]}
        allAbove={true}
      />
    )
  },
  {
    id: "LQ_SLE_8",
    mandatory: false,
    plainTitle: "Dream recall",
    title: <span>How often do you usually remember dreaming?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_9",
    mandatory: false,
    plainTitle: "Ever lucid dream?",
    title: <span>Have you ever experienced lucid dreaming?</span>,
    explanation: (
      <span>
        Lucid dreaming is when you are aware that you are dreaming, and can
        sometimes take some control over what you do or what happens during the
        dream.
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["Not sure", "At least once"]}
      />
    )
  },
  {
    id: "LQ_SLE_10",
    mandatory: true,
    plainTitle: "Regular alarm",
    title: <span>Do you regularly use an alarm to wake up?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_11",
    mandatory: false,
    plainTitle: "Wake up at target time without alarm",
    title: (
      <span>
        If you don’t have an alarm, can you successfully tell yourself to wake
        up at a certain time?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Sometimes", "Always"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_12",
    mandatory: false,
    plainTitle: "Wake up before alarm",
    title: (
      <span>
        When you have to get up at a regular time every day, do you start to
        wake up before the alarm clock?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Sometimes", "Always"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_13",
    mandatory: false,
    plainTitle: "Early rising experience",
    title: <span>When is the earliest time you had to regularly wake up?</span>,
    explanation:
      "This is asking for the earliest alarm you've ever had to wake up to on most days a week for at least a month.",
    component: v => <Input.Question onValid={v} inputType={"time"} />
  },
  {
    id: "LQ_SLE_14",
    mandatory: true,
    plainTitle: "If you stay awake late, when do you wake up",
    title: (
      <span>
        If you stay up much later than usual and don’t set an alarm, when do you
        wake up the following morning?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Same time as always" },
          { text: "A little later than usual" },
          {
            text:
              "I sleep the same amount as always, and so I wake up much later"
          }
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_15",
    mandatory: true,
    plainTitle: "Morning wakefulness if up earlier than usual",
    title: (
      <span>
        If you are woken up earlier than usual, can you “wake up” properly and
        do whatever you have to do?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easily", "Not at all"]} //TODO: figure out what this used to say
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_16",
    mandatory: false,
    plainTitle: "Sleep inertia duration",
    title: <span>How long after you wake up do you feel fully awake: </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Instantly", "An hour", "2 hours", ">3 hours"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_17",
    mandatory: true,
    plainTitle: "Wake up requirements",
    title: (
      <span>Do you “require” any of the following before you feel awake:</span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "A shower" },
          { text: "Coffee" },
          { text: "Exercise" },
          { text: "Breakfast" },
          { text: "Other", extraInput: "string" }
        ]}
        allAbove={true}
      />
    )
  },
  {
    id: "LQ_SLE_18.1",
    mandatory: true,
    plainTitle: "Ever done shift work",
    title: <span>Have you ever done shift work?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_SLE_18.2",
    mandatory: true,
    plainTitle: "Shift work:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_SLE_18.1");
      return (
        a[idx].data !== undefined &&
        a[2].data["answer"] === YesNoQ.AnswerType.yes
      );
    }, //TODO: not working!
    title: <span>Please describe what shift work you've done: </span>,
    component: v => <Input.Question onValid={v} large inputType={"string"} />
  },
  {
    id: "LQ_SLE_19",
    plainTitle: "Safe driving at night?",
    mandatory: false,
    title: (
      <span>
        Assuming you had a license, would you feel safe driving past midnight
        (because of tiredness)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all safe", "Very safe"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_20",
    mandatory: false,
    plainTitle: "Adjust to time zone shifts?",
    title: (
      <span>
        When you travel across time zones, how easy is it for you to adjust to
        the new time (e.g. going west)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["I adjust immediately", "It takes me a long time"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={[" I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_21",
    mandatory: true,
    plainTitle: "Post lunch dip?",
    title: (
      <span>
        After lunch, do you feel sleepiness, or a dip in productivity?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Never" },
          { text: "Sometimes" },
          { text: "Yes, but not enough to impact my life" },
          { text: "Yes, I have a hard time working" },
          { text: "Yes, I can’t work at all/would prefer to nap" }
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_22",
    mandatory: false,
    plainTitle: "Nap ease",
    title: <span>How easy do you find it to take a nap after lunch?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easy", "Very hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_23",
    mandatory: false,
    plainTitle: "Preferred nap time:",
    title: <span>If/when you nap, what time of day do you prefer?</span>,
    tip: "Select all hours that apply",
    component: v => (
      <Range.Question
        onValid={v}
        labels={[
          "8 AM",
          "9 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "1 PM",
          "2 PM",
          "3 PM",
          "4 PM",
          "5 PM",
          "6 PM",
          "7 PM",
          "8 PM",

          "no preference"
        ]}
      />
    )
  }, //TODO convert to ranges
  {
    id: "LQ_SLE_24",
    mandatory: true,
    plainTitle: "Overcome sleep inertia in the morning",
    title: (
      <span>
        If you have something important to do in the <b>morning</b>, but feel
        very drowsy, can you overcome your drowsiness?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easily", "With great difficulty"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_25",
    mandatory: true,
    plainTitle: "Overcome sleep inertia in the night",
    title: (
      <span>
        If you have something important to do in the <b>night</b>, but feel very
        drowsy, can you overcome your drowsiness?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easily", "With great difficulty"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_26",
    mandatory: true,
    plainTitle: "Ease staying awake late",
    title: <span>How easily can you stay awake late into the night?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Very easily", "With great difficulty"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_SLE_27",
    mandatory: false,
    plainTitle: "Preferred working hours",
    title: <span>Select which hours you prefer to work:</span>,
    component: v => (
      <Range.Question
        onValid={v}
        labels={[
          "1 AM",
          "2 AM",
          "3 AM",
          "4 AM",
          "5 AM",
          "6 AM",
          "7 AM",
          "8 AM",
          "9 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "1 PM",
          "2 PM",
          "3 PM",
          "4 PM",
          "5 PM",
          "6 PM",
          "7 PM",
          "8 PM",
          "9 PM",
          "10 PM",
          "11 PM",
          "12 AM",
          "no preference"
        ]}
      />
    )
  },
  {
    id: "LQ_SLE_28",
    mandatory: true,
    plainTitle: "Past month total sleep deprivation",
    title: (
      <span>
        In the past month, how many times have you skipped a night of sleep?
      </span>
    ),
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="days" />
    )
  },
  {
    id: "LQ_SLE_29",
    mandatory: true,
    plainTitle: "Past month sleep restriction",
    title: (
      <span>
        In the past month, how many nights have you slept less than 6 hours?
      </span>
    ),
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="nights" />
    )
  },
  {
    id: "LQ_SLE_30",
    mandatory: false,
    plainTitle: "Energy levels",
    title: (
      <span>
        Lately, in the second half of the day, indicate on the slider how you
        usually feel.
      </span>
    ),
    component: v => fourEnergiesPresent(v)
  },
  {
    id: "LQ_SLE_31",
    mandatory: false,
    plainTitle: "Quick to get bored",
    title: (
      <span>How quickly do you get tired of doing a boring activity?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Immediately"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_32",
    mandatory: false,
    plainTitle: "Quick to get demotivated",
    title: (
      <span>
        How easily do you lose motivation, when things aren’t going as well as
        expected?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Immediately"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_33",
    mandatory: false,
    plainTitle: "Procrastination lately",
    title: (
      <span>
        This past month, how often do you find yourself procrastinating while
        working/studying?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Always"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_SLE_34",
    mandatory: false,
    plainTitle: "sleep in class",
    title: (
      <span>
        <En>How often do you sleep or doze off during class?</En>
        <De>Wie oft schläfst oder nickst Du während des Unterrichts ein?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_35",
    mandatory: false,
    plainTitle: "sleep while working",
    title: (
      <span>
        <En>
          How often do you sleep or doze off while during homework (or similar
          work)?
        </En>
        <De>Wie oft schläfst oder nickst Du während der Hausaufgaben ein?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_36",
    mandatory: false,
    plainTitle: "usual daytime alertness",
    title: (
      <span>
        <En>Are you awake and alert throughout the day?</En>
        <De>Bist Du während der grössten Zeit des Tages wach und munter?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_37",
    mandatory: false,
    plainTitle: "grumpyness",
    title: (
      <span>
        <En>How often are you tired and grumpy during the day?</En>
        <De>Wie oft bist Du müde und mürrisch während des Tages?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_38",
    mandatory: false,
    plainTitle: "rise difficulty",
    title: (
      <span>
        <En>
          How often do you find it difficult to get out of bed in the morning?
        </En>
        <De>Wie oft fällt es Dir schwer, morgens aus dem Bett zu kommen?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_39",
    mandatory: false,
    plainTitle: "go back to sleep frequency",
    title: (
      <span>
        <En>
          How often do you fall sleep again after you woke up in the morning?
        </En>
        <De>
          Wie oft schläfst Du nachdem du morgens geweckt wirst noch einmal ein?
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_40",
    mandatory: false,
    plainTitle: "need to be woken up",
    title: (
      <span>
        <En>How often do you have to be woken up in the morning?</En>
        <De>Wie oft musst Du morgens geweckt werden?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  },
  {
    id: " LQ_SLE_41",
    mandatory: false,
    plainTitle: "need more sleep?",
    title: (
      <span>
        <En>How often do you feel that you need more sleep?</En>
        <De>Wie oft hast Du das Gefühl, dass Du mehr Schlaf brauchst?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Very often", de: "Sehr oft" }) }
        ]}
      />
    )
  }
];

export default questions;
