import React from "react";
import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import styled from "styled-components";
import questions from "./Questions/StrengthDifficultiesQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De } from "ewoq/components/Language";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  viewAll?: boolean;
  onDone?: () => void;
  // language: Language;
}

class StrengthDifficulties extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "BasicInfo-StrengthDifficulties", { answers: answers });
    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        {/* <Ui.Message warning>
          <Ui.MessageContent>
            <MessageText> */}
            <Ui.Segment>
          <Ui.Divider horizontal>  
          <En>Instructions</En>
          <De>Anleitung</De> 
          </Ui.Divider>      
          <SectionBody>  
            <En>
               <p>
                <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of your child's behaviour over the last<strong> six months or this school year.</strong>
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of your behaviour over the last<strong> six months or this school year.</strong>
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyAdults}>
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of how things have been for you over the last<strong> six months.</strong>
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiKids}>
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of your child's behaviour over the last<strong> six months or this school year.</strong>
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of your behaviour over the last<strong> six months or this school year.</strong>
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiAdults}>
                Please decide for each item how true it is. It would help us if you answered all items as best as you can even if you are not absolutaly certain.
                Please give your answer on the basis of how things have been for you over the last<strong> six months.</strong></SPChoice>      
               </p>{" "}    
            </En>       
             <De>          
              <p>
                <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   
                Bitte entscheiden Sie für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworten Sie bitte alle Fragen so gut Sie können, selbst wenn Sie sich nicht ganz sicher sind oder Ihnen eine Frage merkwürdig vorkommt.
                Bitte berücksichtigen Sie bei der Antwort das verhalten Ihres Kindes in den letzten <strong>sechs Monaten beziehungsweise in diesem Schuljahr.</strong> </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>
                Bitte entscheide für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworte bitte alle Fragen so gut Du kannst, selbst wenn Du Dir nicht ganz sicher bist oder Dir eine Frage merkwürdig vorkommt.
                Überlege bitte bei der Antwort, wie es Dir in den letzten <strong>sechs Monaten</strong> ging.
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> 
                Bitte entscheiden Sie für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworten Sie bitte alle Fragen so gut Sie können, selbst wenn Sie sich nicht ganz sicher sind oder Ihnen eine Frage merkwürdig vorkommt.
                Überlegen Sie bitte bei der Antwort, wie es Ihnen in den letzten <strong>sechs Monaten</strong> ging.</SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiKids}>   
                Bitte entscheiden Sie für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworten Sie bitte alle Fragen so gut Sie können, selbst wenn Sie sich nicht ganz sicher sind oder Ihnen eine Frage merkwürdig vorkommt.
                Bitte berücksichtigen Sie bei der Antwort das verhalten Ihres Kindes in den letzten <strong>sechs Monaten beziehungsweise in diesem Schuljahr.</strong> </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiTeens}>
                Bitte entscheide für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworte bitte alle Fragen so gut Du kannst, selbst wenn Du Dir nicht ganz sicher bist oder Dir eine Frage merkwürdig vorkommt.
                Überlege bitte bei der Antwort, wie es Dir in den letzten <strong>sechs Monaten</strong> ging.
                </SPChoice>
                <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     
                Bitte entscheiden Sie für jeden Punkt, inwiefern dieser zutrifft oder nicht. 
                Beantworten Sie bitte alle Fragen so gut Sie können, selbst wenn Sie sich nicht ganz sicher sind oder Ihnen eine Frage merkwürdig vorkommt.
                Überlegen Sie bitte bei der Antwort, wie es Ihnen in den letzten <strong>sechs Monaten</strong> ging.</SPChoice>             
              </p>{" "}
        </De>
            {/* </MessageText>
          </Ui.MessageContent>
        </Ui.Message> */}
</SectionBody> 
</Ui.Segment>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default StrengthDifficulties;
