// this is a whole-screen popup that starts as a link

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

const Trigger = styled.span`
  color: #517cc0;
  text-decoration: underline;
`;

const Text = styled.span`
  text-align: justify;
`;

interface Props {
  trigger: string;
}

class InfoModal extends React.Component<Props> {
  render() {
    return (
      <Ui.Modal
        dimmer="inverted"
        trigger={
          <span>
            {" "}
            <Trigger>{this.props.trigger}</Trigger>
          </span>
        }
      >
        <Ui.Modal.Content scrolling>
          <Text>{this.props.children}</Text>
        </Ui.Modal.Content>
      </Ui.Modal>
    );
  }
}

export default InfoModal;
