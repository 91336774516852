import React from "react";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { En, De, l } from "ewoq/components/Language";

export const fourEnergiesPast = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      { labels:[
        l({ en: "Physically exhausted",                 de: "Physisch erschöpft" }),
        l({ en: "Jittery with energy to move",          de: "Zappelig mit Energie für's Bewegen" }),
      ]},
      { labels: [
        l({ en: "Emotionally drained, asocial",         de: "Emotional ausgelaugt, ungeselig" }),
        l({ en: "Extremely social",                     de: "Extrem sozial" })
      ]},
      { labels: [
        l({ en: "Mentally exhausted",                   de: "Mental erschöpft" }),
        l({ en: "Interested in anything",               de: "Interessiert an allem" })
      ]},
      {
        labels: [
        l({ en: "I hate what I did",                    de: "Ich hasse was ich getan habe" }),
        l({ en: "I felt satisfied with what I'd done",  de: "Ich war zufrieden mit dem, was ich getan habe" })
      ]}
    ]}
  />
);

export const fourEnergiesPresent = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      { labels:[
        l({ en: "Physically exhausted",                 de: "Physisch erschöpft" }),
        l({ en: "Jittery with energy to move",          de: "Zappelig mit Energie für's Bewegen" }),
      ]},
      { labels: [
        l({ en: "Emotionally drained, asocial",         de: "Emotional ausgelaugt, ungeselig" }),
        l({ en: "Extremely social",                     de: "Extrem sozial" })
      ]},
      { labels: [
        l({ en: "Mentally exhausted",                   de: "Mental erschöpft" }),
        l({ en: "Interested in anything",               de: "Interessiert an allem" })
      ]},
      { labels: [
        l({ en: "I hate what I'm doing",                de: "Ich hasse was ich tue" }),
        l({ en: "I feel satisfied with what I'm doing", de: "Ich bin zufrieden mit dem, was ich tue" })
      ]}
    ]}
  />
);
