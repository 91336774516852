import React from "react";
import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import styled from "styled-components";
import questions from "./Questions/WakeQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { En, De } from "ewoq/components/Language";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class SensitiveChecklist extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    let BMI =
      answers["SQ_WAK_3"].data &&
      answers["SQ_WAK_2"].data &&
      Number(answers["SQ_WAK_3"].data.text) /
        Math.pow(Number(answers["SQ_WAK_2"].data.text) / 100, 2);

    let isEligible =
      Object.values(answers).every((a) => a.isOk) &&
      BMI &&
      BMI > 18 &&
      BMI < 30;

    answerQuestionnaire("EPI", "Screening-Wake", {
      answers: answers,
      score: BMI,
      isEligible: isEligible,
    });

    this.props.onDone(isEligible || false);
  };
  render() {
    return (
      <div>
        <Ui.Message>
          <MessageText>
            <Ui.MessageContent>
              <span>
                <En>
                  Please answer honestly. We're testing new questions, which is
                  why some seem repeated. To you logicians out there, yes, some
                  answers are not strictly mutually exclusive. Please choose the
                  answer that is "truest".
                </En>
                <De>
                  Antworten Sie bitte ehrlich. Wir testen neue Fragen, weshalb
                  einige repetitiv erscheinen können. An alle Logiker: Ja,
                  einige Antworten schliessen sich nicht zwangsweise gegenseitig
                  aus. Bitte wählen Sie die Antwort aus, die am "wahrsten" ist.
                </De>
              </span>
            </Ui.MessageContent>
          </MessageText>
        </Ui.Message>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default SensitiveChecklist;
