import { QuestionsObj } from "ewoq/containers/Interrogator";
import { str2Time } from "ewoq/utils";

// calculates Pitsberg Sleep Quality Index, returns an object with the different
// components, and the global score. in getAnswers, you can detail how to map the
// question answers to the expected questions, adjusting as needed the different
// types of input.

const getAnswers = (s: QuestionsObj) => ({
  Q1: str2Time(s["SQ_PSQI_1"].data["text"]), //bedtime
  Q2: parseInt(s["SQ_PSQI_2"].data["text"]), // minutes to sleep
  Q3: str2Time(s["SQ_PSQI_3"].data["text"]), // wakeup time
  Q4: parseInt(s["SQ_PSQI_4"].data["text"]), // usual sleep hours
  Q5: s["SQ_PSQI_5"].data["answers"], // sleep disturbances
  Q6: s["SQ_PSQI_6"].data["answer"],
  Q7: s["SQ_PSQI_7"].data["answer"],
  Q8: s["SQ_PSQI_8"].data["answer"],
  Q9: s["SQ_PSQI_9"].data["answer"]
});

// composite score based on estimated time to fall asleep, and frequency of having trouble falling asleep
const sleepLatency = (Q2: number, Q5a: number) => {
  let score = undefined;
  if (Q2 <= 15) {
    score = 0;
  } else if (Q2 <= 30) {
    score = 1;
  } else if (Q2 <= 60) {
    score = 2;
  } else {
    score = 3;
  }
  score = score + Q5a;

  let compositeScore = undefined;
  if (score === 0) {
    compositeScore = 0;
  } else if (score < 2) {
    compositeScore = 1;
  } else if (score < 4) {
    compositeScore = 2;
  } else if (score < 6) {
    compositeScore = 3;
  }
  return compositeScore;
};

// merges scores of two answers on how much daily activity is affected by tiredness
const dayDysfunction = (Qa: number, Qb: number) => {
  const score = Qa + Qb;
  let compositeScore = 0;
  if (score === 0) {
    compositeScore = 0;
  } else if (score <= 2) {
    compositeScore = 1;
  } else if (score <= 4) {
    compositeScore = 2;
  } else {
    compositeScore = 3;
  }
  return compositeScore;
};

// converts sleep duration in a score from 0 to 3
const sleepDuration = (Q4: number) => {
  let score = 0;
  if (Q4 > 7) {
    score = 0;
  } else if (Q4 >= 6) {
    score = 1;
  } else if (Q4 >= 5) {
    score = 2;
  } else {
    score = 3;
  }
  return score;
};

// calculates sleep efficiency as the ratio between time asleep and time in bed
const sleepEfficiency = (Q1: number, Q3: number, Q4: number) => {
  const totsleep = Q4;
  const totbed = (Q3 + 24 - Q1) % 24;
  const efficiency = totsleep / totbed;

  let score = 0;
  if (efficiency > 0.85) {
    score = 0;
  } else if (efficiency >= 0.75) {
    score = 1;
  } else if (efficiency >= 0.65) {
    score = 2;
  } else {
    score = 3;
  }
  return score;
};

// sums up all the possible causes for interrupted sleep into a single score
const sleepDisturbance = (Q5: number[]) => {
  const scores = Q5.slice(1, Q5.length).reduce((a, b) => a + b, 0);
  let score = 0;
  if (scores === 0) {
    score = 0;
  } else if (scores < 9) {
    score = 1;
  } else if (scores < 18) {
    score = 2;
  } else {
    score = 3;
  }
  return score;
};

// sums all the values in array, in this case all scores
const sumValues = (scores: object) =>
  Object.values(scores).reduce((a, b) => a + b, 0);

// composes object of various scores
const PSQIscore = (s: QuestionsObj) => {
  const Answers = getAnswers(s);
  let PSQIscore = {
    "subjective sleep quality": Answers["Q6"],
    "sleep latency": sleepLatency(Answers["Q2"], Answers["Q5"][0]),
    "sleep duration": sleepDuration(Answers["Q4"]),
    "habitual sleep efficiency": sleepEfficiency(
      Answers["Q1"],
      Answers["Q3"],
      Answers["Q4"]
    ),
    "sleep disturbances": sleepDisturbance(Answers["Q5"]),
    "use of sleeping medication": Answers["Q7"],
    "daytime dysfunction": dayDysfunction(Answers["Q8"], Answers["Q9"]),
    globalScore: 0
  };
  const Global = sumValues(PSQIscore);
  PSQIscore["globalScore"] = Global;
  return PSQIscore;
};

export default PSQIscore;
