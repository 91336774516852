import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l, LanguageContext } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import * as Slider from "ewoq/prototypes/Slider";

import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as Input from "ewoq/prototypes/TypeInput";



const questions: QuestionType[] = [

  {
    id: "MOT-1",
    plainTitle: "Motivation",
    mandatory: true,
    title: (
      <span>
        <En>
          How motivated
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> is your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} >are you </SPChoice>
          to do the next test? 
        </En>

      <De> 
        Wie motiviert
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> ist Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> bist Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> sind Sie </SPChoice>
        für den nächsten Test? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "not at all", de: "überhaupt nicht" }), 
              l({ en: "average", de: "mässig" }), 
              l({ en: "very motivated", de: "sehr motiviert" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  },
];
export default questions;
