import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Slider from "ewoq/prototypes/Slider";
import { fourEnergiesPast } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "SR_TIR_1",
    mandatory: true,
    plainTitle: "Time spent working yesterday:",
    title: (
      <span>
        <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How much of the previous day did your child spend working / studying?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How much of the previous day did you spend working / studying?</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wieviel des gestrigen Tages hat Ihr Kind mit arbeiten / lernen verbracht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wieviel des gestrigen Tages hast Du mit arbeiten / lernen verbracht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wieviel des gestrigen Tages haben Sie mit arbeiten / lernen verbracht?</SPChoice>
    </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Consider anything your child doesn't consider fun or restful.</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Consider anything you don't consider fun or restful.</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Denken Sie an alles, was Ihr Kind nicht als spassig oder erholsam sieht.</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Denke an alles, was Du nicht als spassig oder erholsam siehst.</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Denken Sie an alles, was Sie nicht als spassig oder erholsam sehen.</SPChoice>
    </De>
      </span>
    ),
    // tip: "If you want, click on the labels to select exactly that answer",   better remove that information or present at the beginning of very first questionnaire
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Didn't work",          de: "Nicht gearbeitet" }),
          l({ en: "Worked all the time",  de: "Die ganze Zeit gearbeitet" }), ]}
      />
    )
  },
  {
    id: "SR_TIR_2.1",
    mandatory: true,
    plainTitle: "More or less tired than usual?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Was your child overall more or less tired than usual?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Were you overall more or less tired than usual?</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      War Ihr Kind ingesamt mehr oder weniger Müde als sonst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Warst Du ingesamt mehr oder weniger Müde als sonst?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Waren Sie ingesamt mehr oder weniger Müde als sonst?</SPChoice>
    </De>
    </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Less",   de: "weniger" }),
          l({ en: "Usual",  de: "wie sonst" }), 
          l({ en: "More",   de: "mehr" })]}
      />
    )
  },
  {
    id: "SR_TIR_2.2",
    mandatory: false,
    plainTitle: "Did you want to nap?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Would your child have liked to take a nap?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Would you have liked to take a nap?</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hätte Ihr Kind gerne ein Nickerchen gemacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hättest Du gerne ein Nickerchen gemacht?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Hätten Du gerne ein Nickerchen gemacht?</SPChoice>
    </De>
    </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Not at all",  de: "Überhaupt nicht" }),
          l({ en: "Absolutely",  de: "Absolut" })]}
      />
    )
  },
  {
    id: "SR_TIR_2.3",
    mandatory: false,
    plainTitle: "Wanted more coffee?",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Does your child wish it could have drunk more coffee/caffeine?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Do you wish you could have drunk more coffee/caffeine?</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hätte Ihr Kind gerne mehr Kaffee/Koffein getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hättest Du gerne mehr Kaffee/Koffein getrunken?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Hätten Du gerne mehr Kaffee/Koffein getrunken?</SPChoice>
    </De>
    </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Not at all",  de: "Überhaupt nicht" }),
          l({ en: "Absolutely",  de: "Absolut" })]}
      />
    )
  },
  {
    id: "SR_TIR_3.1",
    mandatory: false,
    plainTitle: "Morning tiredness:",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Please indicate how tired your child felt on average in the <b>morning</b>:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Please indicate how tired you felt on average in the <b>morning</b>:</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Bitte beschreiben Sie, wie müde Ihr Kind durchschnittlich am <b>Morgen</b> war:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bitte beschreibe, wie müde Du durchschnittlich am <b>Morgen</b> warst:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Bitte beschreiben Sie, wie müde Sie durchschnittlich am <b>Morgen</b> warst:</SPChoice>
    </De>
      </span>
    ),
    component: v => kss(v)
  }, //TODO get this information from KSS file somehow
  {
    id: "SR_TIR_3.2",
    mandatory: false,
    plainTitle: "Evening tiredness:",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Please indicate how tired your child felt on average in the <b>evening</b>:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Please indicate how tired you felt on average in the <b>evening</b>:</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Bitte beschreiben Sie, wie müde Ihr Kind durchschnittlich am <b>Abend</b> war:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bitte beschreibe, wie müde Du durchschnittlich am <b>Abend</b> warst:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Bitte beschreiben Sie, wie müde Sie durchschnittlich am <b>Abend</b> warst:</SPChoice>
    </De>
      </span>
    ),
    component: v => kss(v)
  },
  {
    id: "SR_TIR_4",
    mandatory: false,
    plainTitle: "Energy end of day:",
    title: <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      By the end of the day, how did your child feel?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      By the end of the day, how did you feel?</SPChoice>
      </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Am Ende des Tages, wie hat sich Ihr Kind gefühlt?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Am Ende des Tages, wie hast Du Dich gefühlt?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Am Ende des Tages, wie haben Sie Sich gefühlt?</SPChoice>
    </De>
    </span>,
    component: v => fourEnergiesPast(v)
  }
];
export default questions;
