import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./CurrentStateQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

interface State {
  name?: string;
}

export class CurrentState extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { name: this.props.name || ""};
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", this.state.name + "-CurrentState", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };

  onClick = (selection: string) => {
    this.setState({ name: this.props.name + "-" + selection });
  };

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">How do you feel?</Ui.Container>
        </HeaderContainer>
        {/* { this.state.name + "-CurrentState" } */}
        <Ui.Container>
          <ChooseSession
            sessions={[
              ["Night", "Morning"],
            ]}
            selection={this.state.name}
            onClick={this.onClick}
          />
          {!(this.state.name && this.state.name.length > 6) ? null : ( 
            <Interrogator
              questions={questions}
              onDone={this.onSubmit}
              viewAll={this.props.viewAll}
            />
          )}
        </Ui.Container>
      </div>
    );
  }
}

export default CurrentState;
