import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import Ref from "ewoq/components/References";
import Figure from "ewoq/components/Figure";
import fig1 from "./pictures/SleepModel.jpg";
import fig2 from "./pictures/LocalSleep.jpg";

import { SectionBody, HeaderContainer } from "ewoq/components/miscStyles";

const SectionBodySegment = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }

  margin-bottom: 1em;
`;

const LinksArea = styled.div`
  padding-bottom: 3em;
  margin-top: 1em;
`;

const references = [
  " ",
  "1. 	Borbély AA: A two process model of sleep regulation. Hum Neurobiol 1982, 1:195–204.",
  "2. 	Achermann P, Borbély AA: Simulation of daytime vigilance by the additive interaction of a homeostatic and a circadian process. Biol Cybern 1994, 71:115–121.",
  "3. 	Riedner BA, Vyazovskiy V V, Huber R, Massimini M, Esser S, Murphy M, Tononi G: Sleep homeostasis and cortical synchronization: III. A high-density EEG study of sleep slow waves in humans. Sleep 2007, 30:1643–57.",
  "4. 	Tononi G, Cirelli C: Sleep and the Price of Plasticity: From Synaptic and Cellular Homeostasis to Memory Consolidation and Integration. Neuron 2014, 81:12–34.",
  "5. 	Dijk DJ, Brunner DP, Beersma DGM, Borbely AA: Electroencephalogram power density and slow wave sleep as a function of prior waking and circadian phase. Sleep 1990, 13:430–440.",
  "6. 	Tobler I, Borbély AA: Sleep EEG in the rat as a function of prior waking. Electroencephalogr Clin Neurophysiol 1986, 64:74–76.",
  "7. 	Finelli LA, Baumann H, Borbély AA, Achermann P: Dual electroencephalogram markers of human sleep homeostasis: Correlation between theta activity in waking and slow-wave activity in sleep. Neuroscience 2000, 101:523–529.",
  "8. 	Huber R, Ghilardi MF, Massimini M, Tononi G: Local sleep and learning. Nature 2004, 430:78–81.",
  "9. 	Huber R, Ghilardi MF, Massimini M, Ferrarelli F, Riedner BA, Peterson MJ, Tononi G: Arm immobilization causes cortical plastic changes and locally decreases sleep slow wave activity. Nat Neurosci 2006, 9:1169–1176.",
  "10. 	Hung C-S, Sarasso S, Ferrarelli F, Riedner B, Ghilardi MF, Cirelli C, Tononi G: Local Experience-Dependent Changes in the Wake EEG after Prolonged Wakefulness. Sleep 2012, 36:59–72.",
  "11. 	Bernardi G, Siclari F, Yu X, Zennig C, Bellesi M, Ricciardi E, Cirelli C, Ghilardi MF, Pietrini P, Tononi G: Neural and Behavioral Correlates of Extended Training during Sleep Deprivation in Humans: Evidence for Local, Task-Specific Effects. J Neurosci 2015, 35:4487–4500.",
  "12. 	Vyazovskiy V V., Olcese U, Hanlon EC, Nir Y, Cirelli C, Tononi G: Local sleep in awake rats. Nature 2011, 472:443–447.",
  "13. 	Siclari F, Tononi G: Local aspects of sleep and wakefulness. Curr Opin Neurobiol 2017, 44:222–227.",
  "14. 	Mukhametov LM, Supin AY, Polyakova IG: Interhemispheric asymmetry of the electroencephalographic sleep patterns in dolphins. Brain Res 1977, 134:581–584.",
  "15. 	Terzaghi M, Sartori I, Tassi L, Didato G, Rustioni V, LoRusso G, Manni R, Nobili L: Evidence of dissociated arousal states during nrem parasomnia from an intracerebral neurophysiological study. Sleep 2009, 32:409–412.",
  "16. 	Ferster ML, Lustenberger C, Karlen W: Configurable mobile system for autonomous high-quality sleep monitoring and closed-loop acoustic stimulation. IEEE Sensors Lett 2019, doi:10.1109/LSENS.2019.2914425.",
  "17. 	Laing EE, Möller-Levet CS, Poh N, Santhi N, Archer SN, Dijk D-J: Blood transcriptome based biomarkers for human circadian phase. Elife 2017, 6."
];

const Home: React.SFC = () => (
  <div>
    <LinksArea>
      <a href="/">
        <Ui.Button
          content={"Experiment Information"}
          icon="arrow left"
          compact
          size="tiny"
        />
      </a>
    </LinksArea>
    <HeaderContainer>Local Sleep in the Brain</HeaderContainer>
    <Ui.Container>
      <Ui.Segment>
        <SectionBodySegment>
          <Ui.Divider horizontal>Sleep Pressure Measured in Sleep</Ui.Divider>
          <SectionBody>
            <p>
              The understanding of how sleep and wake interact with each other
              is critical for understanding sleep disorders in particular and
              the brain in general. While significant progress has been made in
              the past century, sleep has proven to be more complex than
              initially anticipated, and many important avenues of research
              remain unexplored. One of the most useful models of sleep, the
              two-process model <Ref indexes={[1, 2]} refs={references} />, has
              provided a framework for understanding slow wave activity (SWA)
              produced during deep non-REM (NREM) sleep{" "}
              <Ref indexes={[3]} refs={references} />. SWA is characterized by
              large oscillations in the EEG signal and is believed to be
              necessary for allowing neurons the conditions to recuperate from
              the past day of activity <Ref indexes={[4]} refs={references} />.
            </p>
            <p>
              SWA can easily be measured in the delta frequency band (0.5-4Hz)
              extracted from the power spectrum of the EEG
              (electroencephalography) of a sleeping brain, and it can be
              clearly shown how this delta activity exponentially decreases over
              the course of the night (Figure 1A). The two-process model
              explains this decrease by predicting a “process s” that represents
              homeostasis: an increase in sleep pressure during the day,
              followed by a decrease during sleep, returning to original
              baseline levels (Figure 1B).
            </p>

            <Figure href={fig1} label="Figure 1">
              A) Histogram of slow wave density over the course of the night,
              from <Ref indexes={[3]} refs={references} />. B) The two-process
              model for the change in SWA after a normal day or after sleep
              deprivation, from <Ref indexes={[1]} refs={references} />. Process
              s indicates the homeostatic build up in sleep pressure. Process c
              indicates other circadian changes related to sleep. The dots in
              the top figure indicate the recorded slow wave activity during a
              night after normal wake, or after 36h sleep deprivation.
            </Figure>
          </SectionBody>
          <Ui.Divider horizontal>Sleep Pressure Measured in Wake</Ui.Divider>
          <SectionBody>
            <p>
              The relationship between time awake and subsequent SWA has been
              repeatedly shown <Ref indexes={[5, 6]} refs={references} />. Less
              well-established is what “sleep pressure” is during wake. While
              not as clear-cut as SWA, an indicator of a homeostatic increase in
              the wake EEG over the course of the day can be found in the
              increase in theta power (4-8 Hz), especially evident with sleep
              deprivation <Ref indexes={[7]} refs={references} />.
            </p>
            <p>
              Explaining the restorative properties of SWA in terms of
              homeostasis implies that there would be use-dependent effects,
              such that areas that are more used during the day require more
              restoration at night. This non-uniform distribution of sleep has
              in fact been shown by Huber et al. both by increasing neural
              activity with a motor learning task the day before{" "}
              <Ref indexes={[8]} refs={references} /> and decreasing neural
              activity with arm immobility{" "}
              <Ref indexes={[9]} refs={references} />. These manipulations
              resulted in localized changes to subsequent SWA that can be seen
              in Figures 2A and 2B. Later studies{" "}
              <Ref indexes={[10, 11]} refs={references} /> further showed that
              local changes in theta power in wake predict the distribution of
              SWA the following night (Figures 2C-D).
            </p>

            <Figure href={fig2} label="Figure 2">
              A) Percentage increase in SWA relative to baseline the night
              following a motor learning task, from{" "}
              <Ref indexes={[8]} refs={references} />. B) Decrease in SWA in the
              same area following a day of arm immobilization, from{" "}
              <Ref indexes={[9]} refs={references} />. C) Increase in theta
              power during a day of sleep deprivation while listening to
              audiobooks, relative to a day playing a driving simulator. D) The
              increase in SWA in the night following the audiobook listening.
              The significant channels correspond to language related areas. C
              and D from <Ref indexes={[10]} refs={references} />.
            </Figure>
          </SectionBody>
          <Ui.Divider horizontal>
            Changes in Theta as Evidence of Local Sleep
          </Ui.Divider>
          <SectionBody>
            <p>
              The correlation between local theta activity in wake and delta
              activity at night makes a compelling argument that they are two
              sides of the same coin, however correlation is not a sufficient
              explanation. Intracortical recordings allow a more detailed look
              into what occurs during wake. Vyazovskiy et al.{" "}
              <Ref indexes={[12]} refs={references} /> found that the EEG of
              rats shows the same increase in theta power over the course of
              time awake, and this increase is matched by an increase in “off
              periods”, short windows in time in which individual neuronal
              firing activity ceases; this is the same mechanism driving slow
              waves during sleep. This similarity to SWA has led researchers to
              hypothesize that underlying the increased theta power are actually
              brief local slow waves.
            </p>
            <p>
              While the relationship between sleep pressure and theta power in
              humans is clear, evidence of local slow waves is not. There are
              other contexts in which sleep stages and sleep and wake can be
              present at the same time, such as falling asleep and waking up
              periods <Ref indexes={[13]} refs={references} />. However, the
              clear coexistence between true wake and true sleep has only really
              been shown in extreme cases: some animal species that require
              constant movement like dolphins can sleep with one brain
              hemisphere while the other remains awake&nbsp;
              <Ref indexes={[14]} refs={references} />; or in humans during
              peculiar situations such as sleep walking{" "}
              <Ref indexes={[15]} refs={references} />. Further evidence of
              local sleep is needed than just an increase in power in a given
              frequency band. While the ideal data would come from intracortical
              recordings in humans, this is difficult to obtain and generally
              recorded from patients, thus not derived from experimental setups
              geared towards fundamental research.
            </p>
          </SectionBody>
          <Ui.Divider horizontal>What We Aim to Find</Ui.Divider>
          <SectionBody>
            <p>
              Instead, more can be extrapolated from human non-invasive EEG. We
              wish to conduct our own study investigating the diurnal increase
              in sleep pressure, with a particular focus on identifying an
              electrophysiological biomarker that could correspond to local
              sleep. Like with past experiments, we will have participants awake
              for an extended period to produce sufficient sleep pressure that
              can then be compared to changes in SWA between the previous and
              following night. Unlike past experiments, however, we will be
              recording continuously during a variety of tasks to ensure a
              specific relationship to sleepiness rather than task or fatigue,
              also allowing us to potentially identify infrequent events.
            </p>
            <p>
              Furthermore, should we identify such a biomarker, we would develop
              later interventional studies to determine a proper causal
              relationship between these events and subsequent homeostatic
              recovery. In particular, the SleepLoop consortium our lab is a
              part of has been developing a closed-loop device that uses
              auditory tones presented during slow waves to boost or suppress
              them, as needed <Ref indexes={[16]} refs={references} />. Should
              the local sleep biomarker found in wake resemble a slow wave, we
              would anticipate it to respond to auditory stimulation in the same
              way.
            </p>
            <p>
              From this proposed experiment, we hope to identify a specific
              biomarker that can reliably be used to determine sleep pressure at
              a region-specific level. In practice, we would be interested in
              determining its clinical utility for providing an objective marker
              of sleepiness, especially in children, whose developing brains can
              vary greatly across regions. Eventually other laboratories may be
              interested in using such a marker to evaluate sleepiness in
              late-night drivers, as is being proposed for blood biomarkers{" "}
              <Ref indexes={[17]} refs={references} />. At a later stage we also
              hope to determine if interacting with this biomarker with auditory
              tones can either boost or suppress its effects, as is done with
              slow waves.
            </p>
          </SectionBody>
        </SectionBodySegment>
      </Ui.Segment>
    </Ui.Container>
  </div>
);

export default Home;
