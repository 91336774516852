// these are the sections of the home page, as well as all the popups that get called.

import React from "react";
import styled from "styled-components";
import * as Ui from "semantic-ui-react";
import WhyPopup from "ewoq/components/WhyPopup";

import { Squeeze } from "ewoq/components/miscStyles";

import { SectionBody, HeaderContainer } from "ewoq/components/miscStyles";

const sections = [
  {
    title: "Intro",
    body: (
      <div>
        <p>
          This is a non-clinical study to understand the effects of very quiet
          tones on brain activity. We use{" "}
          <a
            href="https://simple.wikipedia.org/wiki/Electroencephalography"
            target="_blank"
            rel="noopener noreferrer"
          >
            EEG
          </a>{" "}
          to measure the brain's response to sounds during sleep. This involves{" "}
          <strong>
            coming to the lab for 3 nights, filling out questionnaires, and a
            few cognitive tests.{" "}
          </strong>{" "}
        </p>
        <Squeeze>
          <Ui.Segment raised color="violet">
            For participating, you will be paid <strong>350CHF</strong>. In the
            exceptional case that the experiment cannot be completed, you will
            be paid partial amounts.
          </Ui.Segment>
        </Squeeze>
      </div>
    )
  },
  {
    title: "Who are we",
    body: (
      <div>
        <p>
          We are part of the{" "}
          <a
            href="https://www.kispi.uzh.ch/fzk/de/abteilungen/uebersicht/schlafzentrum/Seiten/default.aspx#a=akk1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Child Development Center
          </a>{" "}
          at the University Children's Hospital of Zurich. We specialize in
          using high density EEG{" "}
          <WhyPopup>
            This just means we use 128 electrodes instead of the clinical
            standard of 20, or the research standard of 64. With this many
            electrodes, we can better see what happens in more restricted
            regions of the brain. It is also useful for sleep studies, because
            electrodes like to fall off when people roll around in bed at night.
          </WhyPopup>{" "}
          to study local changes in sleep and sleep pressure.
        </p>

        <Squeeze>
          <Ui.Segment color="violet" compact>
            <p>If you have any qustions, you may contact Elena directly:</p>
            <p>elena.krugliakova@kispi.uzh.ch</p>
          </Ui.Segment>
        </Squeeze>
      </div>
    )
  }
];

const SectionBodySegment = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
  margin-bottom: 1em;
`;

const Rebound: React.SFC = () => (
  <div>
    <HeaderContainer>Participate in a sleep experiment!</HeaderContainer>
    <Ui.Container>
      {sections.map((section, idx) => (
        <Ui.Segment key={idx}>
          <span id={`${idx}-home-section`}></span>{" "}
          <SectionBodySegment>
            <Ui.Divider horizontal>{section.title}</Ui.Divider>
            <SectionBody>{section.body}</SectionBody>
          </SectionBodySegment>
        </Ui.Segment>
      ))}
    </Ui.Container>
  </div>
);

export default Rebound;
