import React from "react";
import * as Radio from "ewoq/prototypes/Radio";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import { QuestionType } from "ewoq/containers/Interrogator";
import { str2Time } from "ewoq/utils";
import { En, De, l } from "ewoq/components/Language";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SQ_SLE_1",
    plainTitle: "Preferred wakeup time:",
    title: (
      <span>
        <En>
          On week days, at what time would you <i>prefer</i> to wake up?
        </En>
        <De>
          Wann <i>möchten</i> Sie an Wochentagen aufwachen?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This means if you are free to choose your own working schedule, what
          time would you impose yourself to wake up.
        </En>
        <De>
          Das bedeutet, wenn Sie Ihren Arbeitstag frei einteilen könnten, um
          welche Zeit würden Sie aufwachen wollen.
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      const t = str2Time(s.text);
      return t >= 6 && t <= 11; // originally, this was supposed to relate to the PSQI numbers, but this makes more sense as a question to screen for extreme hours
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },
  {
    id: "SQ_SLE_2",
    plainTitle: "Preferred sleep time",
    title: (
      <span>
        <En>On week days, when would you prefer to go to sleep?</En>
        <De>Wann möchten Sie an Wochentagen schlafen gehen?</De>
      </span>
    ),
    isOk: (s: Input.State) => {
      const t = str2Time(s.text);
      return t >= 21 || t <= 1;
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },
  {
    id: "SQ_SLE_3",
    plainTitle: "Ever have to wake up 2h earler?",
    title: (
      <span>
        <En>
          How often do you have to set an alarm 2 hours before your preferred
          wakeup time?
        </En>
        <De>
          Wie oft müssen Sie 2 Stunden vor Ihrer bevorzugten Weckzeit einen
          Wecker stellen?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer <= 3, // any answer but last one
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          {
            text: l({
              en: "Sometimes, once per month or less",
              de: "Manchmal, einmal im Monat oder weniger"
            })
          },
          { text: l({ en: "2-4 times per month", de: "2-4 Mal pro Monat" }) },
          {
            text: l({
              en: "More than once per week",
              de: "Mehr als einmal pro Woche"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_4",
    plainTitle: "Shift work",
    title: (
      <span>
        <En>
          Have you had a schedule in the past 5 years that required you to sleep
          more than 3 hours off from your preferred sleep window?
        </En>
        <De>
          Hatten Sie in den letzten 5 Jahren einen Zeitplan, nach dem Sie mehr
          als 3 Stunden von Ihrem bevorzugten Schlaffenster entfernt schlafen
          mussten?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This includes night shifts, jobs you had to wake up extremely early to
          get to, or go to sleep much later than preferred. A “job” means this
          lasted 1 month or more, including school.
        </En>
        <De>
          Dazu gehören Nachtschichten, Jobs, bei denen man extrem früh aufstehen
          musste, oder Jobs, bei denen man viel später als gewünscht schlafen
          ging. Ein "Job" bedeutet, dass dieser 1 Monat oder länger dauerte.
        </De>
      </span>
    ), //TODOe update
    isOk: (s: Radio.State) => s.answer < 2,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Once, more than 1 year ago",
              de: "Einmal vor mehr als einem Jahr"
            })
          },
          { text: l({ en: "Once, this year", de: "Einmal in diesem Jahr" }) },
          { text: l({ en: "More than once", de: "Mehr als einmal" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_5",
    plainTitle: "Free time sleep regularity",
    title: (
      <span>
        <En>
          Do you go to bed at a regular time and get up at a regular time even
          if you don’t have to?
        </En>
        <De>
          Gehen Sie zu regelmässigen Zeiten ins Bett und stehen Sie zu
          regelmässigen Zeiten auf, auch wenn Sie das nicht müssen?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This means you go to sleep and wake up around the same time when on
          vacation or not working early or late.
        </En>
        <De>
          Das bedeutet, dass Sie zur gleichen Zeit schlafen gehen und aufwachen,
          wenn Sie in den Ferien sind oder nicht bis spät oder früh am morgen
          schon arbeiten müssen.
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Not really", de: "Nicht wirklich" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Often", de: "Oft" }) },
          { text: l({ en: "Always", de: "Immer" }) },
          { text: l({ en: "I don't know", de: "Ich weiss nicht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_6",
    plainTitle: "Napper?",
    title: (
      <span>
        <En>Do you nap?</En>
        <De>Machen Sie Nickerchen?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 2,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "Less than once per month",
              de: "Weniger als einmal pro Monat"
            })
          },
          {
            text: l({
              en: "Once per week or less",
              de: "Einmal pro Woche oder weniger"
            })
          },
          {
            text: l({
              en: "More than once per week",
              de: "Mehr als einmal pro Woche"
            })
          },
          { text: l({ en: "Every day", de: "Jeden Tag" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_7",
    plainTitle: "Can you miss a night of sleep",
    title: (
      <span>
        <En>
          Are you the sort of person who can easily miss out on a night of
          sleep?
        </En>
        <De>
          Sind Sie die Art von Person, die leicht auf eine Nacht Schlaf
          verzichten kann?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Definitely not", de: "Definitiv nicht" }) },
          { text: l({ en: "Not really", de: "Nicht wirklich" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Fairly easy", de: "Ziemlich einfach" }) },
          { text: l({ en: "Definitely", de: "Definitiv" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_8",
    plainTitle: "Late night work",
    title: (
      <span>
        <En>How do you react to working late in the night?</En>
        <De>Wie reagieren Sie darauf, spät in die Nacht zu arbeiten?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 4,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "I can't do it", de: "Ich kann es nicht" }) },
          { text: l({ en: "I dislike doing it", de: "Ich mag es nicht" }) },
          { text: l({ en: "I sometimes do it", de: "Ich mache es manchmal" }) },
          {
            text: l({
              en: "I have no problem doing it",
              de: "Ich habe kein Problem damit"
            })
          },
          {
            text: l({
              en: "It’s my most productive time to work",
              de: "Es ist meine produktivste Zeit zu arbeiten "
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_9",
    plainTitle: "Prolongued wakefulness experience",
    title: (
      <span>
        <En>How often have you spent 20 or more hours awake?</En>
        <De>Wie oft haben Sie 20 oder mehr Stunden wach verbracht?</De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This would mean that you stayed awake until the early morning hours.
        </En>
        <De>
          Das würde bedeuten, dass Sie bis in die frühen Morgenstunden wach
          geblieben sind.
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Less than twice per year",
              de: "Weniger als 2 Mal pro Jahr"
            })
          },
          { text: l({ en: "2-5 times per year", de: "2-5 Mal pro Jahr" }) },
          {
            text: l({
              en: "Around once every month",
              de: "Etwa einmal pro Monat"
            })
          },
          {
            text: l({
              en: "More than once per month",
              de: "Mehr als einmal pro Monat"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_10",
    plainTitle: "Preferred sleep duration",
    title: (
      <span>
        <En>How many hours of sleep do you prefer to have?</En>
        <De>Wie viele Stunden schlafen Sie am liebsten?</De>
      </span>
    ),
    isOk: (s: Input.State) => Number(s.text) <= 11 && Number(s.text) >= 6,
    component: v => (
      <Input.Question onValid={v} inputType={"number"} placeholder={"hours"} />
    )
  },
  {
    id: "SQ_SLE_11",
    plainTitle: "Regular sleep resitriction (<5h):",
    title: (
      <span>
        <En>How often do you sleep less than 5 hours?</En>
        <De>Wie oft schlafen Sie weniger als 5 Stunden?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 4,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Less than twice per year",
              de: "Weniger als 2 Mal pro Jahr"
            })
          },
          { text: l({ en: "2-5 times per year", de: "2-5 Mal pro Jahr" }) },
          {
            text: l({
              en: "Around once every month",
              de: "Etwa einmal pro Monat"
            })
          },
          {
            text: l({
              en: "More than once per month",
              de: "Mehr als einmal pro Monat"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_12",
    plainTitle: "Effects of sleep deprivation",
    title: (
      <span>
        <En>When you do, does it affect your:</En>
        <De>Wenn Sie dies tun, wirkt sich dies auf Folgendes aus:</De>
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SQ_SLE_11");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0;
    },
    tip: "Check all that apply, then click DONE",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        allAbove={true}
        options={[
          { text: l({ en: "Mood", de: "Stimmung" }) },
          {
            text: l({
              en: "Physical performance",
              de: "Körperliche Leistungsfähigkeit"
            })
          },
          {
            text: l({
              en: "Mental performance",
              de: "Mentale Leistungsfähigkeit"
            })
          },
          { text: l({ en: "Safety", de: "Sicherheit" }) },
          { text: l({ en: "Clumsiness", de: "Ungeschicklichkeit" }) },
          { text: l({ en: "Sleepiness", de: "Schläfrigkeit" }) },
          { text: l({ en: "Energy levels", de: "Energieniveau" }) },
          { text: l({ en: "Other", de: "Alles oben Genannte" }) } // TODOe
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_13",
    plainTitle: "Pillow",
    title: (
      <span>
        <En>Are you particular about your pillow?</En>
        <De>Brauchen Sie ein bestimmtes Kissen?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer > 0,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "I can only sleep on my own pillow",
              de: "Ich kann nur auf meinem eigenen Kissen schlafen"
            })
          },
          {
            text: l({
              en: "I sleep poorly on most pillows",
              de: "Ich schlafe auf den meisten Kissen schlecht"
            })
          },
          {
            text: l({
              en: "I prefer some pillows over others",
              de: "Ich bevorzuge einige Kissen gegenüber anderen"
            })
          },
          {
            text: l({
              en: "I can sleep on most pillows",
              de: "Ich kann auf den meisten Kissen schlafen"
            })
          },
          {
            text: l({
              en: "I can sleep with no pillows",
              de: "Ich kann ohne Kissen schlafen"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_14",
    plainTitle: "External sleep disturbances",
    title: (
      <span>
        <En>How much do the following disturb your sleep?</En>
        <De>Wie sehr stören die folgenden Dinge Ihren Schlaf?</De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This means that it keeps you from falling asleep or from sleeping
          deeply.
        </En>
        <De>
          Das bedeutet, dass es Sie daran hindert einzuschlafen oder tief
          schlafen zu können.
        </De>
      </span>
    ),
    isOk: (s: RadioTable.State) => {
      const part1 = s.answers
        .filter((a, idx) => [0, 3, 4, 5].includes(idx))
        .every(a => a <= 4); // checks that these questions don't have an answer of 4

      const numberof3s = s.answers.filter(
        (a, idx) => [0, 3, 4, 5, 7].includes(idx) && a == 4
      ).length;
      const numbreof4s = s.answers.filter(
        (a, idx) => [1, 2, 6, 7].includes(idx) && a == 5
      ).length;
      const part2 = numberof3s + numbreof4s <= 4; //counts how many answer 3s and 4s are given from specfic questions

      return part1 && part2;
    },
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "Different bed", de: "Anderes Bett" }),
          l({
            en: "Traffic (or other outside noise)",
            de: "Verkehrslärm (oder sonstiger Aussenlärm)"
          }),
          l({ en: "Voices in other rooms", de: "Stimmen in Nebenräumen" }),
          l({
            en: "Noise from electronic devices",
            de: "Geräusche von elektronischen Geräten"
          }),
          l({ en: "Bedroom temperature", de: "Schlafzimmertemperatur" }),
          l({ en: "Seasons", de: "Jahreszeiten" }),
          l({ en: "Light from window", de: "Licht vom Fenster" }),
          l({ en: "LED lights in the room", de: "LED Licht im Raum" })
        ]}
        options={[
          l({ en: "I don't know", de: "Ich weiss nicht" }),
          l({ en: "Not at all", de: "Überhaupt nicht" }),
          l({ en: "Just a little", de: "Nur ein wenig" }),
          l({ en: "Somewhat", de: "Etwas" }),
          l({ en: "Often", de: "Oft" }),
          l({ en: "Always", de: "Immer" })
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_15",
    plainTitle: "Things needed to sleep:",
    title: (
      <span>
        <En>
          Is there anything you 'need' to do to make sure you have a good night
          sleep?
        </En>
        <De>
          Gibt es irgendetwas, das Sie tun müssen, um einen guten Schlaf zu
          haben?
        </De>
      </span>
    ),
    //used to be excluded if answer was yes, but may be misinterpreted, so for now not
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({ en: "Yes", de: "Ja" }),
            placeHolder: l({ en: "What?", de: "Was?" }),
            extraInput: "string"
          },
          { text: l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_16",
    plainTitle: "Sleep talking",
    title: (
      <span>
        <En>Do you ever talk in your sleep?</En>
        <De>Sprechen Sie jemals im Schlaf?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Very rarely, very little",
              de: "Sehr selten, sehr wenig"
            })
          },
          {
            text: l({
              en: "Once a week or so, a little",
              de: "Einmal in der Woche oder so, wenig"
            })
          },
          {
            text: l({
              en: "Once a week or so, a lot",
              de: "Einmal in der Woche oder so, viel"
            })
          },
          { text: l({ en: "Almost every night", de: "Fast jede Nacht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_17",
    plainTitle: "Sleep walking",
    title: (
      <span>
        <En>Did you ever sleepwalk as an adult?</En>
        <De>Haben Sie schon einmal als Erwachsener geschlafwandelt?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 1,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) },
          { text: l({ en: "Regularly", de: "Regelmässig" }) },
          { text: l({ en: "Almost every night", de: "Fast jede Nacht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_18",
    plainTitle: "Nightmares",
    title: (
      <span>
        <En>Have you suffered from nightmares in the past few months?</En>
        <De>
          Haben Sie in den letzten Monaten unter schlechten Träumen gelitten?
        </De>
      </span>
    ), //TODOe
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          {
            text: l({
              en: "Once per week or less",
              de: "Einmal pro Woche oder weniger"
            })
          },
          {
            text: l({
              en: "A couple of times per week",
              de: "Ein paar Mal pro Woche"
            })
          },
          { text: l({ en: "Every night", de: "Jede Nacht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_19",
    plainTitle: "Difficulty sleep phases",
    title: (
      <span>
        <En>
          Do you have phases, several nights in a row, in which you have
          difficulty falling asleep?
        </En>
        <De>
          Haben Sie Phasen von mehreren Nächten hintereinander, in denen Sie
          Schwierigkeiten haben einzuschlafen?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never/Rarely", de: "Nie/Selten" }) },
          {
            text: l({
              en: "Less than once every 3 months",
              de: "Weniger als einmal alle 3 Monate"
            })
          },
          {
            text: l({
              en: "Less than once per month",
              de: "Weniger als einmal pro Monat"
            })
          },
          {
            text: l({
              en: "More than once per month",
              de: "Mehr als einmal pro Monat"
            })
          },
          { text: l({ en: "Regularly", de: "Regelmässig" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_20",
    plainTitle: "Wake up at night",
    title: (
      <span>
        <En>How often do you wake up at night?</En>
        <De>Wie oft wachen Sie nachts auf?</De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This means you become fully awake, enough to either get out of bed, or
          look at your phone, or have trouble falling back asleep.
        </En>
        <De>
          Das bedeutet, dass Sie komplett aufwachen, genug, um aufzustehen, auf
          Ihr Handy zu schauen oder Schwierigkeiten haben wieder einzuschlafen.
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          {
            text: l({
              en: "Some nights, but not regularly",
              de: "Manche Nächte, aber nicht regelmäßig"
            })
          },
          { text: l({ en: "Often", de: "Oft" }) },
          {
            text: l({
              en: "Almost every night, just once",
              de: "Fast jede Nacht, nur einmal"
            })
          },
          {
            text: l({
              en: "A couple of times per night",
              de: "Ein paar Mal pro Nacht"
            })
          },
          {
            text: l({
              en: "Many times per night",
              de: "Mehrere Male pro Nacht"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_21",
    plainTitle: "Bathroom at night",
    title: (
      <span>
        <En>Do you wake up to go to the bathroom at night?</En>
        <De>Wachen Sie nachts auf, um auf die Toilette zu gehen?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          {
            text: l({
              en: "Some nights, but not regularly",
              de: "Manche Nächte, aber nicht regelmäßig"
            })
          },
          { text: l({ en: "Often", de: "Oft" }) },
          {
            text: l({
              en: "Almost every night, just once",
              de: "Fast jede Nacht, nur einmal"
            })
          },
          {
            text: l({
              en: "A couple of times per night or more",
              de: "Ein paar Mal pro Nacht oder mehr"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_22",
    plainTitle: "Drink water at night",
    title: (
      <span>
        <En>Do you wake up to drink water at night?</En>
        <De>Wachen sie nachts auf, um Wasser zu trinken?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer <= 4,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          { text: l({ en: "Rarely", de: "Selten" }) },
          {
            text: l({
              en: "Some nights, but not regularly",
              de: "Manche Nächte, aber nicht regelmäßig"
            })
          },
          { text: l({ en: "Often", de: "Oft" }) },
          {
            text: l({
              en: "Almost every night, just once",
              de: "Fast jede Nacht, nur einmal"
            })
          },
          {
            text: l({
              en: "A couple of times per night or more",
              de: "Ein paar Mal pro Nacht oder mehr"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_23",
    plainTitle: "Good sleeper?",
    title: (
      <span>
        <En>Do you consider yourself: </En>
        <De>Sehen Sie sich selbst als: </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer > 1,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "A poor sleeper", de: "Ein schlechter Schläfer" }) },
          { text: l({ en: "a light sleeper", de: "Ein leichter Schläfer" }) },
          {
            text: l({
              en: "An average sleeper",
              de: "Ein durchschnittlicher Schläfer"
            })
          },
          { text: l({ en: "a deep sleeper", de: "Ein tiefer Schläfer" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_24",
    plainTitle: "Supplements for sleep",
    title: (
      <span>
        <En>
          Do you ever take supplements for improving sleep, such as melatonin or
          valerian?
        </En>
        <De>
          Nehmen Sie ab und zu Nahrungsergänzungsmittel zur Verbesserung des
          Schlafes ein, wie Melatonin oder Baldrian?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Less than once per month",
              de: "Weniger als einmal pro Monat"
            })
          },
          {
            text: l({
              en: "A couple of times per month",
              de: "Ein paar Mal pro Monat"
            })
          },
          {
            text: l({
              en: "A couple of times per week",
              de: "Ein paar Mal pro Woche"
            })
          },
          { text: l({ en: "Every night", de: "Jede Nacht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_SLE_25",
    plainTitle: "Herbal sleep treatments",
    title: (
      <span>
        <En>
          Do you take herbal teas such as camomille or homeopathy to help you
          sleep?
        </En>
        <De>
          Trinken Sie Kräutertees (z.B. Kamillentee) oder nehmen homöopathische
          Mittel ein, um Ihnen beim Schlafen zu helfen?
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Less than once per month",
              de: "Weniger als einmal pro Monat"
            })
          },
          {
            text: l({
              en: "A couple of times per month",
              de: "Ein paar Mal pro Monat"
            })
          },
          {
            text: l({
              en: "A couple of times per week",
              de: "Ein paar Mal pro Woche"
            })
          },
          { text: l({ en: "Every night", de: "Jede Nacht" }) }
        ]}
      />
    )
  }
];

export default questions;
