// makes popup cards with an image, intended for showing the faces of the people involved

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

const Text = styled.div`
  text-align: justify;
  font-size: 0.9em;
`;

const Trigger = styled.span`
  color: #517cc0;
  text-decoration: underline;
`;

const CardSegment = styled.div`
  width: 15em;
`;

interface Props {
  trigger: string;
  picture: string;
  meta?: string;
}

class Person extends React.Component<Props> {
  render() {
    return (
      <Ui.Popup
        trigger={
          <span>
            <Trigger>{this.props.trigger}</Trigger>
          </span>
        }
        hoverable
      >
        <CardSegment>
          <Ui.Card>
            <Ui.Image src={this.props.picture} wrapped ui={false} />
            <Ui.Card.Content>
              <Ui.Card.Header>{this.props.trigger}</Ui.Card.Header>
              <Ui.Card.Meta>{this.props.meta}</Ui.Card.Meta>
              <Ui.Card.Description>
                <Text>{this.props.children}</Text>
              </Ui.Card.Description>
            </Ui.Card.Content>
          </Ui.Card>
        </CardSegment>
      </Ui.Popup>
    );
  }
}

export default Person;
