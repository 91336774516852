import React from "react";
import * as Ui from "semantic-ui-react";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID
} from "ewoq/components/miscStyles";

import { getUserId } from "ewoq/utils";
import Overview from "./Overview";
import Thoughts from "./Thoughts";
import Feelings from "./FeelingsRest";
import Tiredness from "./TirednessRest";

enum Phase {
  Start,
  Overview,
  Thoughts,
  Feelings,
  Tiredness
}

interface Props {}

interface State {
  phase: Phase;
  day?: string;
  session?: string;
}

class Lifestyle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { phase: Phase.Start, day: undefined, session: undefined };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase });
  };

  onChange = (a: any, b: any) => {
    this.setState({ day: b.value });
  };

  render() {
    const Sessions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const days = ["Adaptation", "Main", "Recovery"];
    switch (this.state.phase) {
      case Phase.Start: {
        return (
          <div>
            <ParticipantID>{getUserId()}</ParticipantID>
            <HeaderContainer>Rest Test Questionnaire</HeaderContainer>
            <Ui.Container>
              <Ui.Segment>
                <Ui.Divider horizontal>Choose session</Ui.Divider>

                <SectionBody>
                  <Ui.Form>
                    <Ui.Form.Field>
                      <Ui.Button.Group>
                        {days.map((d, idx) => (
                          <Ui.Button
                            onClick={() => this.setState({ day: d })}
                            key={idx}
                            color={this.state.day === d ? "violet" : undefined}
                          >
                            {d}
                          </Ui.Button>
                        ))}
                      </Ui.Button.Group>
                    </Ui.Form.Field>
                    <Ui.Form.Field>
                      <Ui.Button.Group>
                        {Sessions.map((s, idx) => (
                          <Ui.Button
                            key={idx}
                            onClick={() => this.setState({ session: s })}
                            color={
                              this.state.session === s ? "violet" : undefined
                            }
                          >
                            {s}
                          </Ui.Button>
                        ))}
                      </Ui.Button.Group>
                    </Ui.Form.Field>
                    <Ui.Form.Input
                      label="Timepoint"
                      placeholder="main-1"
                      width={5}
                      value={
                        this.state.session === undefined
                          ? undefined
                          : this.state.day + "-" + this.state.session
                      }
                      onChange={this.onChange}
                    />
                    <Ui.Form.Button
                      disabled={this.state.day === undefined}
                      onClick={this.advancePhase(Phase.Overview)}
                    >
                      Start
                    </Ui.Form.Button>
                  </Ui.Form>
                </SectionBody>
              </Ui.Segment>
            </Ui.Container>
          </div>
        );
      }
      case Phase.Overview: {
        return (
          <Overview
            onDone={this.advancePhase(Phase.Thoughts)}
            name={this.state.day + "-" + this.state.session}
          />
        );
      }
      case Phase.Thoughts: {
        return (
          <Thoughts
            onDone={this.advancePhase(Phase.Feelings)}
            name={this.state.day + "-" + this.state.session}
          />
        );
      }
      case Phase.Feelings: {
        return (
          <Feelings
            onDone={this.advancePhase(Phase.Tiredness)}
            name={this.state.day + "-" + this.state.session}
          />
        );
      }
      case Phase.Tiredness: {
        return (
          <div>
            {" "}
            <Tiredness
              onDone={this.advancePhase(Phase.Start)}
              name={this.state.day + "-" + this.state.session}
            />
            {this.setState({ day: undefined, session: undefined })}
          </div>
        );
      }
    }
  }
}

export default Lifestyle;
