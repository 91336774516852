import React from "react";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";

export const activities = (v: any) => (
  <MultipleChoice.Question
    onValid={v}
    options={[
      { text: "Read" }, //This must stay first; other questions refer to this answer
      { text: "Exercise" },
      { text: "Listen to music / radio / podcasts" },
      { text: "Yoga / meditation" },
      { text: "Snack / eat late dinner" },
      { text: "Watch TV / movies / videos" },
      { text: "Browse the internet" },
      { text: "Social media / texting" },
      { text: "Socialize / talk on phone" },
      { text: "Gaming" },
      { text: "Take a bath or shower" },
      { text: "Work (on a screen)" },
      { text: "Work (not on a screen)" },
      { text: "Other", extraInput: "string" }
    ]}
  />
);
