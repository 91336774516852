import React from "react";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import { QuestionType } from "ewoq/containers/Interrogator";
import WhyPopup from "ewoq/components/WhyPopup";
import * as Radio from "ewoq/prototypes/Radio";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import { l, En, De } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "SQ_WAK_1",
    plainTitle: "Age",
    title: (
      <span>
        <En>Age: </En>
        <De>Alter: </De>
      </span>
    ),
    isOk: (s: Input.State) => Number(s.text) > 17 && Number(s.text) < 26,
    component: v => (
      <Input.Question inputType="number" placeholder="years" onValid={v} />
    )
  },
  {
    id: "SQ_WAK_2",
    plainTitle: "Height",
    title: (
      <span>
        <En>
          Height:{" "}
          <WhyPopup>
            This is not a screening form for the Swiss Guard &#x1f643; we just
            need to calculate your BMI to make sure you're within a healthy
            range.
          </WhyPopup>
        </En>
        <De>
          Grösse:{" "}
          <WhyPopup>
            Dies ist keine Bewerbung für die Schweizergarde &#x1f643;. Wir
            müssen Ihren BMI berechnen, um sicherzustellen, dass Sie sich in
            einem gesunden Bereich befinden.
          </WhyPopup>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => Number(s.text) > 100 && Number(s.text) < 300, //just so that it only takes reasonable numbers
    component: v => (
      <Input.Question inputType="number" placeholder="cm" onValid={v} />
    )
  },
  {
    id: "SQ_WAK_3",
    plainTitle: "Weight",
    title: (
      <span>
        <En>Weight: </En>
        <De>Gewicht: </De>
      </span>
    ),
    isOk: (s: Input.State) => Number(s.text) > 40 && Number(s.text) < 150,
    component: v => (
      <Input.Question inputType="number" placeholder="kg" onValid={v} />
    )
  },
  {
    id: "SQ_WAK_4",
    plainTitle: "Current nicotine use",
    title: (
      <span>
        <En>How often do you smoke, vape, or use nicotine patches?</En>
        <De>Rauchen, vapen oder benutzen Sie Nikotinpflaster?</De>
      </span>
    ),
    explanation: (
      <span>
        <En>This includes any form of nicotine consumption.</En>
        <De>Dies schliesst jegliche Formen von Nikotinkonsum mit ein.</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3, // only takes the first 3 answers
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Once per month or less",
              de: "Einmal pro Monat oder weniger"
            })
          },
          {
            text: l({
              en: "Once per week or less",
              de: "Einmal pro Woche oder weniger"
            })
          },
          { text: l({ en: "Once per day", de: "Einmal am Tag" }) },
          { text: l({ en: "Several times per day", de: "Mehrmals täglich" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_5",
    plainTitle: "Ever a smoker?",
    title: (
      <span>
        <En>
          Have you ever smoked regularly (>1 cigarette per day) previously?
        </En>
        <De>
          Haben Sie schon einmal regelmäßig geraucht (>1 Zigarette pro Tag)?
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_WAK_6",
    plainTitle: "Alcohol consumption frequency",
    title: (
      <span>
        <En>
          In the following table, indicate for each row how often you drink a
          given amount of alcohol in a single occasion.
        </En>
        <De>
          Geben Sie in der folgenden Tabelle für jede Zeile an, wie oft Sie eine
          bestimmte Menge Alkohol an einem Anlass trinken.
        </De>
      </span>
    ),
    isOk: (s: RadioTable.State) =>
      !s.answers.includes(4) && s.answers[2] < 3 && s.answers[3] < 2, // excludes extreme quantities of alcohol; number in brackets is the subquestion index, the other number refers to the answer option index
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "One drink", de: "Ein Getränk" }),
          l({ en: "2-3 drinks", de: "2-3 Getränke" }),
          l({ en: "4-6 drinks", de: "4-6 Getränke" }),
          l({ en: ">6 drinks", de: ">6 Getränke" })
        ]}
        options={[
          l({ en: "Never", de: "Nie" }),
          l({
            en: "Once per month or less",
            de: "Einmal pro Monat oder weniger"
          }),
          l({
            en: "Once per week or less",
            de: "Einmal pro Woche oder weniger"
          }),
          l({ en: "A couple times per week", de: "Ein paar mal pro Woche" }),
          l({ en: "Every day", de: "Jeden Tag" })
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_7",
    plainTitle: "Caffeine consumption frequency",
    title: (
      <span>
        <En>
          Do you regularly drink coffee or other caffeinated beverages,
          especially during the week?
        </En>
        <De>
          Trinken Sie regelmäßig Kaffee oder andere koffeinhaltige Getränke,
          besonders unter der Woche?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>Indicate how many servings per day for each category you drink.</En>
        <De>
          Geben Sie an, wie viele Portionen pro Tag in jeder Kategorie Sie
          trinken.
        </De>
      </span>
    ),
    isOk: (s: RadioTable.State) => {
      const notExtremes =
        !s.answers.includes(3) && s.answers[0] < 2 && s.answers[1] < 2;
      const Total = s.answers.reduce((a, b) => a + b, 0); // this sums all the numbers in an array
      return notExtremes && Total < 4; // total caffeinated drinks and in each category, fewer than a certain amount
    },
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "Coffee", de: "Kaffee" }),
          l({
            en: "Energy drinks (e.g. Red Bull)",
            de: "Energy drinks (z.B. Red Bull)"
          }),
          l({ en: "Black/green tea", de: "Schwarzer/grüner Tee" }),
          l({
            en: "Caffeinated soft drinks (e.g. Coca Cola)",
            de: "Koffeinhaltige Erfrischungsgetränke"
          })
        ]}
        options={[
          l({ en: "Less than 1 per day", de: "Weniger als 1 pro Tag" }),
          l({ en: "1-2 servings per day", de: "1-2 Portionen pro Tag" }),
          l({ en: "3 servings per day", de: "3 Portionen pro Tag" }),
          l({
            en: "4 or more servings per day",
            de: "4 oder mehr Portionen pro Tag"
          })
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_8",
    plainTitle: "Need coffee?",
    title: (
      <span>
        <En>Do you “need” coffee in the morning before you feel awake?</En>
        <De>Brauchen Sie morgens Kaffee, um sich wach zu fühlen?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer > 0, //any answer but yes
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Yes", de: "Ja" }) },
          { text: l({ en: "No", de: "Nein" }) },
          { text: l({ en: "Sometimes", de: "Manchmal" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_9",
    plainTitle: "Regular stimulant use",
    title: (
      <span>
        <En>
          Do you ever regularly take some other food, drink, or medication to
          stay awake?
        </En>
        <De>
          Nehmen Sie regelmäßig etwas zu essen, zu trinken oder Medikamente ein,
          um wach zu bleiben?
        </De>
      </span>
    ),
    //this probably would screen too many other people for stupid reasons; keep just to quiz
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({ en: "Yes", de: "Ja" }),
            placeHolder: l({ en: "What?", de: "Was?" }),
            extraInput: "string"
          },
          { text: l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_10",
    plainTitle: "Stress",
    title: (
      <span>
        <En>
          Over the past month, how stressed did you feel on a regular basis?
        </En>
        <De>
          Wie gestresst haben Sie sich im letzten Monat regelmäßig gefühlt?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "Vacation-levle relaxed",
              de: "Urlaubsniveau entspannt"
            })
          },
          { text: l({ en: "Relaxed", de: "Entspannt" }) },
          { text: l({ en: "Some stress", de: "Etwas Stress" }) },
          { text: l({ en: "A lot of stress", de: "Sehr viel Stress" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_11",
    plainTitle: "Stress vulnerability",
    title: (
      <span>
        <En>In general, how vulnerable are you to stress?</En>
        <De>Wie anfällig sind Sie im Allgemeinen auf Stress?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "I’m almost never stressed",
              de: "Ich bin fast nie gestresst"
            })
          },
          {
            text: l({
              en: "Only important things stress me out",
              de: "Nur wichtige Dinge belasten mich"
            })
          },
          {
            text: l({
              en: "Some things stress me out",
              de: "Einige Dinge belasten mich"
            })
          },
          {
            text: l({
              en: "A lot of things stress me out",
              de: "Viele Dinge belasten mich"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_WAK_12",
    plainTitle: "Time zone travel",
    title: (
      <span>
        <En>How often do you travel across 2 or more time zones?</En>
        <De>Wie oft reisen Sie durch zwei oder mehr Zeitzonen?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 3,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Never", de: "Nie" }) },
          {
            text: l({
              en: "Rarely, a couple times per year",
              de: "Selten, ein paar Mal im Jahr"
            })
          },
          {
            text: l({
              en: "Occasionally, 3-4 times per year",
              de: "Gelegentlich, 3-4 Mal pro Jahr"
            })
          },
          {
            text: l({
              en: "Around once every 2 months",
              de: "Ungefähr alle 2 Monate"
            })
          },
          { text: l({ en: "Almost every month", de: "Fast jeden Monat" }) },
          {
            text: l({
              en: "More than once per month",
              de: "Mehr als einmal pro Monat"
            })
          }
        ]}
      />
    )
  }
];

export default questions;
