// these are the sections of the home page, as well as all the popups that get called.

import React from "react";
import InfoModal from "ewoq/components/InfoModal";
import styled from "styled-components";
import * as Ui from "semantic-ui-react";
import WhyPopup from "ewoq/components/WhyPopup";
import Person from "ewoq/components/Person";
import Huber from "./icons/Huber.jpeg";
import Sven from "./icons/Sven.jpg";
import Elena from "./icons/Elena.jpg";
import Lisa from "./icons/Lisa.jpg";
import { Squeeze } from "ewoq/components/miscStyles";

const Highlights = styled.span`
  color: black;
  font-weight: bold;
`;

const Email = styled.p`
  font-style: bold;
  font-size: 1.2em;
  text-align: center;
`;

const Obligations = (
  <div>
    <Ui.Divider horizontal>Obligations</Ui.Divider>
    <Ui.SegmentGroup>
      <Ui.Segment>Follow the instructions</Ui.Segment>
      <Ui.Segment>Answer the questionnaires honestly</Ui.Segment>
      <Ui.Segment>
        inform the experimenter of any changes to your health or schedule that
        could interfere with the experiment; it is not necessary to specify
        what.
      </Ui.Segment>
      <Ui.Segment>
        Reschedule the experiment if anything interferes with your sleep or
        health before the experiment
      </Ui.Segment>
      <Ui.Segment>During the control week: </Ui.Segment>
      <Ui.SegmentGroup>
        <Ui.Segment>
          Don’t consume caffeine in the afternoon after 4PM (coffee, caffeinated
          soft drinks, energy drinks, black or green tea, etc.).
        </Ui.Segment>
        <Ui.Segment>
          Do not drink more than 2 portions of caffeinated beverages per day
          (e.g. only 2 coffees, or 1 double espresso, or one coffee and one Red
          Bull).
        </Ui.Segment>
        <Ui.Segment>
          Do not drink any alcohol, or take any drugs, or smoke anything at all.
        </Ui.Segment>
        <Ui.Segment>
          Don’t have any early morning activities that require you to set an
          earlier alarm than planned.
        </Ui.Segment>
        <Ui.Segment>
          Avoid parties or other events that might delay your sleep schedule.
        </Ui.Segment>
        <Ui.Segment>
          Wear the actimetry watch the whole time, but especially while
          sleeping. If the data shows periods of more than 2h when it wasn’t
          worn, and you didn’t inform the experimenter, you may be disqualified
          from continuing the experiment.
        </Ui.Segment>
        <Ui.Segment>
          Every morning before breakfast, fill out the Sleep Report. It is
          important that you do so before doing anything else because we ask
          about dreams, whose memory fades very quickly. It is acceptable to be
          up to 2 hours late.
        </Ui.Segment>
        <Ui.Segment>
          Contact the experimenter if you have any doubts if something is ok to
          do, and carefully report things that happened that you suspect could
          be relevant.
        </Ui.Segment>
      </Ui.SegmentGroup>
      <Ui.Segment>During the main experiment, please:</Ui.Segment>
      <Ui.SegmentGroup>
        <Ui.Segment>
          Arrive on time to the appointment. You will have a lot to do, and
          serious delays would require rescheduling the whole experiment.
        </Ui.Segment>
        <Ui.Segment>
          Sleep when asked and stay awake when asked. We understand that this is
          intentionally difficult, and it does require some effort on your part.
        </Ui.Segment>
        <Ui.Segment>
          Go to the toilet and take care of other needs during the Free Time
          periods, not elsewhere in the experiment. These are meant as
          unrecorded times for your benefit; deviating from the scheduled
          activities could invalidate the whole experiment.
        </Ui.Segment>
        <Ui.Segment>
          Follow the instructions, engage in the tasks, and ask questions if
          anything is unclear.
        </Ui.Segment>
      </Ui.SegmentGroup>
    </Ui.SegmentGroup>
  </div>
);

const Reschedule = (
  <div>
    <p>
      To ensure that your sleep is as normal as possible for the main
      experiment, you will need to reschedule if any of the following happen:
    </p>
    <ul>
      <li>Take any drugs</li>
      <li>Get seriously sick (e.g. flu) or injured (e.g. broken arm)</li>
      <li>Travel across 2 or more time zones</li>
      <li>5 or more consecutive nights of poor sleep, for whatever reason</li>
      <li>Sleep deprivation (staying awake longer than 20 hours)</li>
      <li>
        Sleep restriction (sleeping less than 6 hours a night for at least 3
        consecutive nights)
      </li>
    </ul>

    <p>Less severe but still requiring rescheduling includes:</p>
    <ul>
      <li>A cold or similar small illness (can still go to work)</li>
      <li>Getting a vaccine</li>
      <li>Drinking alcohol so much you can't walk / passed out</li>
      <li>Menstruation</li>
      <li>Exams</li>
      <li>Smoke/vape/nicotine patch</li>
      <li>Festival or other exhausting event</li>
      <li>Long trip</li>
      <li>Terrible night of sleep</li>
    </ul>
  </div>
);

const Anonymity = (
  <div>
    <p>
      All answers provided in the screening questionnaire will be collected and
      stored completely anonymously; at no point do we ask for your name,
      address or other personal identifiable information.
    </p>
    <p>
      Instead, you will receive at the end of the questionnaire a 3 word code.
      Write it down, or memorize it. Only once you come to the main experiment
      and tell us your name and code will we know which questionnaire answers
      belong to you. The association between the code and your name will be
      saved in an encrypted file on secure Kinderspital computers.
    </p>
  </div>
);

const Confidentiality = (
  <div>
    <p>
      Personal data (e.g. name, address, date of birth) will be coded. “Coding”
      means that all data that could identify you are replaced by a randomly
      generated code. The decoding code will always remain encrypted within the
      institution.
    </p>
    <p>
      Only a few people will have access to your uncoded data and only to
      perform tasks necessary for the project. All persons who are in possession
      of the data in the study are bound by confidentiality. People who do not
      know the code will not be able to draw any conclusions about your
      identity.
    </p>
    <p>
      For publication, we will render publicly available your individual coded
      EEG data, actigraphy data, and non-sensitive questionnaire data (e.g.
      handedness, gender). The data cannot therefore be traced back to you. Your
      name will never appear on the Internet or in a publication. Data
      protection regulations are respected and you as a participant can have
      access to your data at any time.
    </p>
  </div>
);

const sections = [
  {
    title: "The aim of the study",
    body: (
      <div>
        <p>
          This study investigates the effect of quiet, non-arousing tones on the
          sleeping brain. We aim at improving sleep quality substantially with
          the long-term goal to develop an alternative, non-invasive therapy for
          epilepsy. For that, we use{" "}
          <a
            href="https://simple.wikipedia.org/wiki/Electroencephalography"
            target="_blank"
            rel="noopener noreferrer"
          >
            high-density EEG
          </a>{" "}
          to measure your brain activity during sleep.
        </p>
        <Squeeze>
          <Ui.Segment raised color="violet">
            Participants <strong>above and at the age of 18</strong> will be paid 200 CHF 
            (100 CHF per night). Children and partipants <strong>below the age of 18</strong> will 
            be thanked with two book, game or cinema vouchers (1 per night), as well as 
            a little present uppon completing the study. In the exceptional case that the 
            experiment cannot be completed, you will be compensated partially.
          </Ui.Segment>
        </Squeeze>
      </div>
    ),
  },
  {
    title: "Who can participate?",
    body: (
      <div>
        <p>
          Participants are screened so that they are very similar among each
          other, without introducing confounding elements that might bias the
          results.
        </p>
        <Squeeze>
          <Ui.Segment raised color="green">
            <p>You must be:</p>
            <ul>
              <li>
                Between the ages of 18 and 30{" "}
                <WhyPopup>
                  Sleep alters a lot with age. In our research we want to investigate 
                  children, teenagers, as well as young adults. Older participants are not
                  investigated in this study.
                </WhyPopup>
              </li>
              <li>
                Healthy (all above mentioned ages) or diagnosed with epilepsy (4-17 y){" "}
                <WhyPopup>
                  This means that you do not suffer from any psychiatric,
                  neurological, physical, sleep-related or nervous system
                  related disorders, except for epilepsy.
                </WhyPopup>
              </li>
              <li>
                Of an average or early chronotype{" "}
                <WhyPopup>
                  This means you don't prefer to wake up extremely late.
                </WhyPopup>
              </li>
              <li>If healthy: A good sleeper
              <WhyPopup>
                  This means that you have no problems falling asleep, 
                  sleeping through and feel refreshed the next morning.
                  Patients with epilepsy do not need to fullfil this criteria.
                </WhyPopup>                
              </li>
              <li>Right-handed</li>
            </ul>
          </Ui.Segment>
        </Squeeze>
        <Squeeze>
          <Ui.Segment raised color="red">
            <p>
              You <strong>cannot participate</strong> if any of the following
              applies to you:
            </p>
            <ul>
              <li>
                Sleeping problems
                <WhyPopup>
                  Simply put: you must be able to sleep in the lab so that we
                  can study your brain during sleep.
                </WhyPopup>
              </li>
              <li>
                An irregular sleep-wake rhythm
                <WhyPopup>
                  This means that you go to sleep and wake up at different hours
                  of the day in the course of a week (as it the case for, e.g.,
                  shift workers or students who enjoy nightlife). During the
                  study, you must be able to go to bed and wake up at the exact
                  same hour for two weeks.
                </WhyPopup>
              </li>
              <li>
                Daytime sleepiness or daytime naps
                <WhyPopup>
                  This means that you would very likely have difficulties falling
                  asleep in the lab.
                </WhyPopup>
              </li>
              <li>
                Having travelled through time-zones during the last 30 days.
                <WhyPopup>
                  Travelling through time zones shift your inner body clock.
                  Your body needs time to adjust to the local time.
                </WhyPopup>
              </li>
              <li>
                Obesity
                <WhyPopup>
                   Obesity (BMI over 30.0) increases the risk of suffering from sleep disorders.
                </WhyPopup>
              </li>
              <li>
                Excessive sweating
                  <WhyPopup>
                    Excessive sweating during the recording would make the
                    recording unusable unfortunately.
                  </WhyPopup>
              </li>
              <li>
                Hearing disorder
                <WhyPopup>
                  As we investigate the effect of quiet, soft, non-arousing
                  tones during the night, you must be able to actually perceive
                  them.
                </WhyPopup>
              </li>
              <li>
                Pregnancy
                <WhyPopup>For the safety of your unborn.</WhyPopup>
              </li>
              <li>
                Skin allergy or very sensitive skin
                <WhyPopup>
                  Some people with sensitive skin react negatively to the EEG
                  gel.
                </WhyPopup>
              </li>
              <li>
                Drug and medication use or abuse
                <WhyPopup>
                  We want to know how the "usual" brain functions. Drugs alter
                  your brain functioning, some more severe than others, which we
                  want to rule out. The only exception is contraception and
                  medication for external use.
                </WhyPopup>
              </li>
              <li>
                Daily nicotine use or high caffeine or alcohol consumption
                <WhyPopup>
                  Even though accepted by society, high consumption of nicotine,
                  caffeine or alcohol will certainly affect the outcome of the
                  experiment.
                </WhyPopup>
              </li>
              <li>Inability to follow the procedures of the study</li>
            </ul>
          </Ui.Segment>
        </Squeeze>
        <p>
          If you're not sure, or hope that something doesn't apply exactly to
          your situation, it will become clear with the Screening Questionnaire
          (provided <a href={`#4-home-section`}>below</a>) if you are eligibile
          or not.
        </p>
        <h4>Risks</h4>
        <p>
          There are no risky procedures involved in this study if you are a
          healthy young participant without skin problems. Even though your
          comfort during the study is our highest priority, you may have
          problems to fall asleep in a new environment. Furthermore, some people
          react negatively to the conductive gel used for the EEG, but these are
          individuals who tend to have multiple skin allergies, eczemas, etc.
        </p>
      </div>
    ),
  },
  {
    title: "The experiment",
    body: (
      <div>
        <Ui.SegmentGroup>
          <Ui.Segment>
            <h5>Preparation for the study:</h5>
          </Ui.Segment>
          <Ui.SegmentGroup>
            <Ui.Segment>
              <Highlights>Online screening procedure</Highlights>: this is to
              determine if you are eligibile to participate (see criteria{" "}
              <a href={`#1-home-section`}>above</a>
              ). It will ask you questions about your health, drinking, smoking
              and drug use, sleep habits and other aspects that would make you
              ineligibile to participate.
              <WhyPopup>
                This is important because we want to carefully control for
                external factors that affect your brain activity during sleep,
                so that we can conclude that a possible effect comes from the
                quiet, soft, non-arousing, tones presented during the night and
                not from anything else.
              </WhyPopup>
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>Over the phone meetings and questionnaires</Highlights>:
              Here you can ask questions and sign the formal consent
              form.
            </Ui.Segment>
          </Ui.SegmentGroup>
        </Ui.SegmentGroup>
        <div>
          <p>
            Participation is entirely voluntary. If you decide to withdraw, you
            do not need to give a reason. This study was approved by the Kantonale Ethikkommission Zürich
            (BASEC ID: 2019-02134). For more information, you can read the Information and Consent document in{" "}
            <a
              href="/documents/A01_03_01_DE_HEA_ADULTS_Studieninfo.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              German
            </a>{" "} or {" "}
            <a
              href="/documents/A01_03_01_ENG_HEA_ADULTS_Studieninfo.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              English
            </a>{" "},            
            which is what you will need to sign before participating.
            
            {/* You can also refer to the{" "}
            <a
              href="/documents/3_Information_and_Consent_de.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              German version
            </a>
            . */}
          </p>
        </div>
        <Ui.Divider hidden />
      </div>
    ),
  },
  {
    title: "When and where",
    body: (
      <div>
        <p>        
            All recording sessions will presumably be conducted between <strong>June 2023 and June 2024. &nbsp;
          </strong>
          The experiment will take place at the Kinderpsital Zurich.
        </p>
        <Squeeze>
          <iframe
            width="100%"
            height="300"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.0590063459827!2d8.55601941583436!3d47.37176681216353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0b113c00001%3A0x7e9ba4ef3d418c61!2sKinderspital%20Z%C3%BCrich!5e0!3m2!1sen!2sch!4v1576863433175!5m2!1sen!2sch"
            scrolling="no"
          />
        </Squeeze>
      </div>
    ),
  },
  {
    title: "How to participate",
    body: (
      <div>
        <p>Within the next few days you will find the screening questionnaire here. After filling out this questionnaire, 
          you will know whether you are eligibile or not.
        </p>
        <p>
          To make sure you fit the criteria for participating, you can start by
          filling in this{" "}
          <InfoModal trigger="fully anonymous">{Anonymity}</InfoModal> screening
          questionnaire. Answers provided, as well as all data collected for the
          experiment, will be treated with{" "}
          <InfoModal trigger="full confidentiality.">
            {Confidentiality}
          </InfoModal>
          <strong>{" "}We have noticed that Safari does not save your given answers correctly. Please use either Chrome,
            Firefox or Opera to fill out the questionnaire.
          </strong>
        </p>
        <a href="/screening">
          <Ui.Button color="violet" fluid size="massive">
            Screening Questionnaire
          </Ui.Button>
        </a>
      </div>
    ),
  },
  {
    title: "Who are we",
    body: (
      <div>
        <p>
          We are part of the{" "}
          <a
            href="https://www.kispi.uzh.ch/fzk/de/abteilungen/uebersicht/schlafzentrum/Seiten/default.aspx#a=akk1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Child Development Center
          </a>{" "}
          at the University Children's Hospital of Zurich. We specialize in
          using high density EEG{" "}
          <WhyPopup>
            This just means we use 128 electrodes instead of the clinical
            standard of 20, or the research standard of 64. With this many
            electrodes, we can better see what happens in more restricted
            regions of the brain. It is also useful for sleep studies, because
            electrodes like to fall off when people roll around in bed at night.
          </WhyPopup>{" "}
          to study local changes in sleep and sleep pressure. &nbsp;

          <Person trigger="Prof. Reto Huber" picture={Huber}>
            Reto is the boss, and if you have any serious issues, he will be
            available to help.
          </Person>{" "}
          is the principal investigator,{" "}
          <Person trigger="Sven Leach" picture={Sven}>
          Sven is a postdoc in the lab and provides us with his mentorship and guidance.{" "}
          </Person>{" "}
          is overseeing the project and supporting{" "}
          <Person trigger="Elena Schmidt" picture={Elena}>
          This whole thing is Elena’s research project. She's the one who's running all of this, and is very happy to answer any questions.
          </Person>{" "}
          who is conducting this study for her PhD thesis and{" "}
          <Person trigger="Lisa Cazzato" picture={Lisa}>
          Lisa supports Elena during the recordings and helps with data analysis.
          </Person>{" "}          
          writes her master thesis with us.
        </p>
        <div>
        <Squeeze>
          <Ui.Segment color="violet">
            <p>If you have any qustions, you may contact Elena directly:</p>
            <Email>elena.schmidt@kispi.uzh.ch</Email>
          </Ui.Segment>
        </Squeeze>
        </div>
      </div>
    ),
  },
];

export default sections;
