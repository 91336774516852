import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { answerQuestionnaire } from "ewoq/api";
import { getUserId } from "ewoq/utils";
import { Squeeze } from "ewoq/components/miscStyles";
import { En, De, l} from "ewoq/components/Language";
import { SPChoice, StudyPopulation, StudyPopulationContext, defaultStudyPopulation } from "StudyPopulationChoice";

const HeaderContainer = styled.div`
  height: auto;
  min-height: 3.5em;

  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;

const SectionBody = styled.div`
  text-align: justify;
  padding: 1em;
`;

const CodeText = styled.span`
  font-size: 1.5em;
  text-transform: uppercase;
  font-style: bold;
`;

const TextQ = styled.p`
  font-style: bold;
  font-size: 1.1em;
  text-align: justify;
`;

const Email = styled.p`
  font-style: bold;
  font-size: 1.2em;
  text-align: center;
`;

const GoogleForm = styled.p`
  font-style: bold;
  font-size: 2em;
  text-align: center;
`;

const Container = styled.div`
 text-align: center;
`;

interface Props {}

interface State {
  answer: number | undefined;
  modalOpen: boolean;
}

class Eligible extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { answer: undefined, modalOpen: true }; //TODO: make this state get saved to disk
  }

  onSubmit = () => () => {
    this.setState({ modalOpen: false });
    if (this.state.answer === 0) {
      window.location.replace("./");
    }
    answerQuestionnaire("EPI", "Screening-NoIsOk-Metadata", {
      isHonest: this.state.answer,
      language: (window as any).currentLanguage,
    }); //TODO, add language of state here?
  };

  render() {
    return (
      <div>
        <Ui.Modal closeOnDimmerClick={false} open={this.state.modalOpen}>
          <Ui.Modal.Content>
            <TextQ>
              <En>
                We are a scientific research lab operating on a budget and
                dedicated to producing the highest quality research possible.
                Intentionally incorrect or careless answers will lead to
                unintentionally false results. Science depends on you being
                truthful and thorough.
              </En>
              <De>
                Wir sind ein wissenschaftliches Forschungslabor mit kleinem Budget und
                widmen uns der Forschung von höchster Qualität.
                Absichtlich falsche oder nachlässige Antworten führen zu
                unbeabsichtigt falschen Resultaten. Wissenschaft hängt von Deiner
                Ehrlichkeit und Gründlichkeit ab.
              </De>              

            </TextQ>
            <Squeeze>
              <Ui.Segment color="violet">
                <Ui.Form>
                  <Ui.Header as="h3">
                    <En> Were your answers accurate? </En>
                    <De> Waren deine Antworten korrekt? </De>
                  </Ui.Header>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "Please discard my questionnaire (for whatever reason)", 
                                 de: "Bitte verwerfen Sie meinen Fragebogen (aus welchem ​​Grund auch immer)"})}                                                                   
                      checked={this.state.answer === 0}
                      onClick={() => this.setState({ answer: 0 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "I’m not really sure about how accurate my answers were", 
                                 de: "Ich bin mir nicht sicher, wie genau meine Antworten waren."})}                       
                      checked={this.state.answer === 1}
                      onClick={() => this.setState({ answer: 1 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "I may have made mistakes, but they were unintentional and would be happy to correct them", 
                                 de: "Ich habe vielleicht Fehler gemacht, aber sie waren unbeabsichtigt und würde sie gerne korrigieren."})}                          
                      checked={this.state.answer === 2}
                      onClick={() => this.setState({ answer: 2 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label= {l({en: "To the best of my knowledge, all the answers provided are correct.", 
                                 de: "Nach meinem besten Wissen sind alle Antworten korrekt."})}                       
                      checked={this.state.answer === 3}
                      onClick={() => this.setState({ answer: 3 })}
                    />
                  </Ui.Form.Field>

                  <Ui.Form.Field>
                    <Ui.Button
                      disabled={this.state.answer === undefined}
                      color="green"
                      onClick={this.onSubmit()}
                    >
                      Confirm
                    </Ui.Button>
                  </Ui.Form.Field>
                </Ui.Form>
              </Ui.Segment>
            </Squeeze>
          </Ui.Modal.Content>
        </Ui.Modal>
        <HeaderContainer>
          <En>You are applicable for our study!</En>
          <De>Sie sind für unsere Studie geeignet!</De>                
        </HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
          <Ui.Divider horizontal>
                <En>Study information and Consent Form</En>
                <De>Studieninformation und Einwilligungserklärung</De>                            
            </Ui.Divider>            
            <SectionBody>
              <p>
                <En>
                  It is now time to go through the{" "}
                  <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   <a href="/documents/v03_3_1_Studieninformation_ENG_Hea_Parents_4-17y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiKids}>       <a href="/documents/v03_3_1_Studieninformation_ENG_Epi_Parents_4-17y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  <a href="/documents/v03_3_1_Studieninformation_ENG_Hea_Adolescents_14-17y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      <a href="/documents/v03_3_1_Studieninformation_ENG_Epi_Adolescents_14-17y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> <a href="/documents/v03_3_1_Studieninformation_ENG_Hea_Adults_18-30y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     <a href="/documents/v03_3_1_Studieninformation_ENG_Epi_Adults_18-30y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.NotChosen}>     <a href="/documents/v03_3_1_Studieninformation_ENG_Hea_Adults_18-30y.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  

                  {/* <a
                    href="/documents/v03_3_1_Studieninformation_ENG_Hea_Adults_18-30y.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Study Information And Consent Form
                  </a> */}
                  {" "}                   
                  to get a detailed overview of what will happen during the study. Please read it carefully
                  and decide only after, if you want to participate or not. Please note that we will only do all procedures 
                  mentioned on page 7 <strong>except for the MRI and the IQ test</strong>. The pilot will be compensated with
                  <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids, StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}> one voucher for each experimental night. </SPChoice>  
                  <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> with 200 CHF (100 CHF per experimental night). </SPChoice>                      
                 </En>
                <De>
                  Der nächste Schritt wäre das Durchgehen der {" "}
                  <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   <a href="/documents/v03_3_1_Studieninformation_DE_Hea_Parents_4-17J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiKids}>       <a href="/documents/v03_3_1_Studieninformation_DE_Epi_Parents_4-17J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  <a href="/documents/v03_3_1_Studieninformation_DE_Hea_Adolescents_14-17J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      <a href="/documents/v03_3_1_Studieninformation_DE_Epi_Adolescents_14-17J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> <a href="/documents/v03_3_1_Studieninformation_DE_Hea_Adults_18-30J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     <a href="/documents/v03_3_1_Studieninformation_DE_Epi_Adults_18-30J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  
                  <SPChoice studyPopulation={StudyPopulation.NotChosen}>     <a href="/documents/v03_3_1_Studieninformation_DE_Hea_Adults_18-30J.pdf" target="_blank" rel="noopener noreferrer"> <strong>Studieninformation und Einwilligungserklärung</strong></a> </SPChoice>  

                  {/* <a
                    href="/documents/v03_3_1_Studieninformation_DE_Hea_Adults_18-30J.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Studieninformation und Einwilligungserklärung</strong> */}
                  {/* </a> */}
                  {" "}                    
                  um einen detaillierten Überblick darüber zu bekommen, was Sie in der Studie erwartet. Bitte lesen Sie 
                  sie aufmerksam durch und entscheiden Sie erst danach, ob Sie weiter teilnehmen möchten. Bitte beachten Sie,
                  dass wir <strong>kein MRI und keinen IQ Test</strong> durchführen (siehe Seite 7 der Studieninformation und Einwilligungserklärung).
                  Die Teilnahme an der Studie wird deshalb 
                  <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids, StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}> mit jeweils einem Gutschein pro Experimentalnacht vergütet. </SPChoice>  
                  <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> mit 200 CHF (100 CHF pro Experimentalnacht) vergütet. </SPChoice>                                  
                </De>                    
              </p>
              </SectionBody>
            </Ui.Segment>




            <Ui.Segment>
              <Ui.Divider horizontal>
                  <En>Your personal code</En>
                  <De>Deine persönliche Kennung</De>                            
              </Ui.Divider>     
              <SectionBody>    
              <Squeeze>
                <Ui.Segment textAlign="center" raised inverted color="violet">
                  <CodeText>{getUserId()}</CodeText>
                </Ui.Segment>
              </Squeeze>
              <Ui.Message warning>
                <En>
                  <strong>Please remember or write down this code!! </strong>
                  The study coordinator needs to know this code in order to associate your answers to your person. 
                  Please send this code to the study coordinator after the study coordinator explained the study 
                  to you in detail on the phone and you agreed to participate.
                 </En>
                <De>
                  <strong>Bitte notieren Sie sich unbedingt diesen code!! </strong>
                  Der Studienkoordinator muss diesen Code kennen, um Ihre Antworten Ihrer Person zuordnen zu können.
                  Bitte senden Sie diesen Code an dem Studienkoordinator, nachdem der Studienkoordinator Ihnen die 
                  Studie im Detail am Telefon erklärt hat und Sie am Telefon zugestimmt haben teilzunehmen.                    
                </De>                
              </Ui.Message>
            </SectionBody>
          </Ui.Segment>



            
            {/* <Ui.Segment>
              <Ui.Divider horizontal>
                  <En>Our time slots</En>
                  <De>Unsere time slots</De>                            
              </Ui.Divider>
            <SectionBody>    
              <p>
                <En>If you want to participate, please email us. We will explain the study to you again on the phone and, 
                  if you are still interested in participating, set an appointment for your experiment dates. </En>   
                <De>
                  Falls Sie an der Studie teilnehmen möchten, füllen Sie bitte das folgende Formular aus. Wir benutzen das
                  Formular zur Koordination der Probanden, es beinhaltet u. A. unsere aktuellen time slots. Bitte hinterlassen 
                  Sie ebenfalls Ihre Telefonnummer, damit wir Sie, falls Ihnen ein freier slot zugewiesen wird, für ein 30
                  minütiges Telefonat anrufen können. In dem Telefonat besprechen wir den Ablauf der Studie noch einmal im Detail.
                </De>   
              </p>
            </SectionBody>   
            <SectionBody >    
              <Container>            
                <a href="https://forms.gle/ZnJX4FFHRgNhXVS2A">
                    <Ui.Button color="violet" size="big" icon="right arrow" content="zum Formular" labelPosition="right" justify="center"></Ui.Button>
                </a>                
              </Container>   
            </SectionBody> 
            </Ui.Segment> */}


            <Ui.Segment>
              <Ui.Divider horizontal>
                  <En>Burning questions?</En>
                  <De>Brennende Fragen?</De>                            
              </Ui.Divider>              
            <SectionBody>
              <En>
                If you have questions that you would like to ask before we call you, feel free to send us an email to:
              </En>                
              <De>
                Falls Sie zu diesem Zeitpunkt bereits Fragen haben, die Sie stellen möchten bevor wir Sie anrufen, können Sie 
                jederzeit eine Email schreiben an:
              </De>   

              <Squeeze><Email>epi.recruitement@gmail.com</Email></Squeeze>
            </SectionBody> 
            </Ui.Segment>




          <Ui.Segment>
            <Ui.Divider horizontal>
                <En>Now what</En>
                <De>Wie weiter</De>                            
            </Ui.Divider>
            <SectionBody>
                <En>
                  For this experiment, we want to ensure a relatively uneventful
                  sleep-wake rhythm <strong>1 week</strong> prior to the experiment day. Please make
                  sure that none of the following occur in the week before the main 
                  experiment, and if they do, please reschedule:
                </En>
                <De>
                  Für dieses Experiment möchten wir <strong>1 Woche</strong> vor dem Versuchstag 
                  einen relativ ereignislosen Schlaf-Wach-Rhythmus sicherstellen. 
                  Bitte stellen Sie sicher, dass im Woche vor dem Hauptexperiment 
                  keine der folgenden Situationen eintritt. Falls Sie auftreten, 
                  verschieben Sie bitte Ihren Termin:        
                </De>               

              <Squeeze>
                <Ui.Segment color="red">
                  <ul>
                    <li>
                      <En>Terrible sleep for one night</En>
                      <De>Furchtbarer Schlaf für eine Nacht</De>  
                    </li>   
                    <li>
                      <En>Sleep or wake-up time shifted by more than 1h from your future sleep schedule you will be given</En>
                      <De>Schlaf- oder Weckzeiten, die um mehr als 1h von Ihrem zukünftigen Schlafplan, den Sie von uns bekommen, verschoben sind</De>   
                    </li>   
                    <li>
                      <En>Night activites (e.g. parties, or watching TV and Netflix at the expense of sleep, etc.)</En>
                      <De>Nachtaktivitäten (z. B. Partys oder Fernsehen und Netflix auf Kosten des Schlafes, usw.)</De>   
                    </li>                                                         
                    <li>
                      <En>Any event, that might affect your sleep or well-being during the experiment (e.g. music festivals, exams, or other exhausting events)</En>
                      <De>Jedes Ereignis, das Ihren Schlaf oder Ihr Wohlbefinden während des Experiments beeinträchtigen könnte (z. B. Musikfestivals, exzessive Partys, Prüfungen oder andere anstrengende Ereignisse).</De>  
                    </li>                    
                    <li>
                      <En>Sickness (e.g. flu) or seriously injured (e.g. broken arm)</En>
                      <De>Krankheit (z. B. Grippe) oder schwere Verletzung (z. B. gebrochener Arm)</De>   
                    </li>     
                    <li>
                      <En>Longer trips</En>
                      <De>Längere Reisen</De>  
                    </li>            
                    <li>
                      <En>Consuming any drug apart from more than 1 standard portion of caffeine or alcohol per day</En>
                      <De>Das Konsumieren jeglicher Drogen außer 1 Standardportion Koffein oder Alkohol pro Tag</De>   
                    </li>                                                                          
                  </ul>
                </Ui.Segment>
              </Squeeze>
              <Ui.Divider hidden />
              <En>
                The days before the experiment have a particularly high impact 
                on your sleep on the experimental night. Please make sure to reschedule 
                if any of the following happen <strong>3 days</strong> before the experiment:
              </En>
              <De>
                Die Tage vor dem Experiment wirken sich besonders stark auf Ihren Schlaf 
                in der experimentellen Nacht aus.Bitte stellen Sie sicher, dass Sie einen 
                neuen Termin festlegen, wenn in den <strong>3 Tagen</strong> vor dem Experiment Folgendes passiert:                                       
              </De>                
              <Squeeze>
                <Ui.Segment color="yellow">
                  <ul>
                    <li>
                      <En>Consuming any drug, including caffein and alcohol</En>
                      <De>Das Konsumieren jeglicher Drogen einschliesslich Alkohol und Koffein</De>                           
                    </li>
                    <li>
                      <En>Only at the day of the experiment: excessive sport or sauna visit</En>
                      <De>Nur am Tag des Experiments: übermäßiger Sport oder Saunabesuch</De>                         
                    </li>
                  </ul>
                </Ui.Segment>
              </Squeeze>
              
              <Ui.Divider hidden />
                  <En>
                    Additionally, participation requires the following activities 
                    <strong>1 week</strong> prior to the experiment:
                  </En>
                  <De>
                    Darüber hinaus, führen Sie bei Teilnahme am Experiment ab 
                    <strong> 1 Woche</strong> vor dem Experiment folgenden Aktivitäten aus:
                  </De>                                                          
              <Squeeze>
                <Ui.Segment color="green">
                  <ul>
                    <li>
                      <En>Wearing an actimetry meter constantly, except for during showers and sports (actimetry meter will be provided by us)</En>
                      <De>Kontinuierliches Tragen eines Aktimeters, außer beim Duschen und Sport (Aktimeter wird von uns bereitgestellt)</De>                       
                    </li>
                    <li>
                      <En>Keeping a sleep diary</En>
                      <De>Führen eines Schlaftagebuchs</De>                         
                    </li>
                    <li>
                      <En>Keeping a regular sleep-wake rhythm</En>
                      <De>Beibehalten eines regulären Schlaf-Wach Rhythmus</De>                         
                    </li>                    
                  </ul>
                </Ui.Segment>
              </Squeeze>              
            </SectionBody>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Eligible;
