import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Slider from "ewoq/prototypes/Slider";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "LQ_PAI_",
    plainTitle: "Pain sensitivity",
    mandatory: false,
    title: (
      <span>
        Imagine or consider possible the following scenarios. Please mark on the
        scale the value that is most true for you. Keep in mind that there are
        no ‘‘right” or ‘‘wrong” answers; only your personal assessment of the
        situation counts.
      </span>
    ),
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_PAI_1",
            title:
              "Imagine you bump your shin badly on a hard edge, for example, on the edge of a glass coffee table. How painful would that be for you?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_2",
            title: "Imagine you burn your tongue on a very hot drink.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_3",
            title:
              "Imagine your muscles are slightly sore as the result of physical activity.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_4",
            title: "Imagine you trap your finger in a drawer.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_5",
            title: "Imagine you take a shower with lukewarm water.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_6",
            title: "Imagine you have mild sunburn on your shoulders.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_7",
            title: "Imagine you grazed your knee falling off your bicycle.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_8",
            title:
              "Imagine you accidentally bite your tongue or cheek badly while eating.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_9",
            title: "Imagine walking across a cool tiled floor with bare feet.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },

          {
            id: "LQ_PAI_11",
            title:
              " Imagine you have a minor cut on your finger and inadvertently get lemon juice in the wound.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_12",
            title: "Imagine you prick your fingertip on the thorn of a rose.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_13",
            title:
              " Imagine you stick your bare hands in the snow for a couple of minutes or bring your hands in contact with snow for some time, for example, while making snowballs.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_14",
            title:
              "Imagine you shake hands with someone who has a normal grip.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_15",
            title:
              "Imagine you shake hands with someone who has a very strong grip.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_16",
            title:
              "Imagine you pick up a hot pot by inadvertently grabbing its equally hot handles.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_17",
            title:
              "Imagine you are wearing sandals and someone with heavy boots steps on your foot.",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          },
          {
            id: "LQ_PAI_18",
            title:
              "Imagine you bump your elbow on the edge of a table (‘‘funny bone”).",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all painful", "Most severe pain imaginable"]}
              />
            )
          }
        ]}
      />
    )
  }
];
export default questions;
