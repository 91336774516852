import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNo from "ewoq/prototypes/YesNo";
import * as GroupForm from "ewoq/prototypes/TypeInputGroup";
import WhyPopup from "ewoq/components/WhyPopup";
import { En, De, l, L } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "SQ_MCTQ_1",
    plainTitle: "Working days:",
    title: (
      <span>
        <En>How many working/school days do you have per week?</En>
        <De>Wie viele Arbeits-/Schultage haben Sie pro Woche?</De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          These are days in which you have a regular schedule that dictates more
          or less when you have to wake up and/or go to sleep in the evening.
        </En>
        <De>
          Das sind Tage, an denen Sie einen regelmässigen Zeitplan haben, der
          Ihnen mehr oder weniger vorgibt, wann Sie am Morgen aufstehen und/oder
          am Abend schlafen gehen müssen.
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      const WD = parseInt(s.text);
      return WD >= 0 && WD <= 7;
    },
    warning: l({
      en: "Should be between 0 and 7",
      de: "Zahl muss zwischen 0 und 7 sein"
    }),
    component: v => (
      <Input.Question
        placeholder={l({ en: "days", de: "Tage" })}
        inputType={"number"}
        onValid={v}
      />
    )
  },
  {
    id: "SQ_MCTQ_2",
    plainTitle: "Working days bed times:",
    title: (
      <span>
        <En>On work / school days:</En>
        <De>An Arbeits-/Schultagen:</De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "SQ_MCTQ_1");
      return a[idx].data && parseInt(a[idx].data["text"]) !== 0;
    },
    component: v => (
      <GroupForm.Question
        subQuestions={[
          {
            text: l({
              en:
                "At what time do you turn the light off to go to sleep (including putting phone or tablet down) on work days?",
              de:
                "Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?"
            }),
            inputType: "time",
            explanation: l({
              en:
                "Indicate the time at which you start trying to actually sleep.",
              de:
                "Geben Sie die Zeit an, an der Sie tatsächlich anfangen zu probieren einzuschlafen."
            })
          },
          {
            text: l({
              en: "How many minutes do you need to fall asleep on work days?",
              de: "Wie viele Minuten brauchen Sie um einzuschlafen?"
            }),
            inputType: "number",
            placeholder: l({ en: "minutes", de: "Minuten" })
          },
          {
            text: l({
              en: "At what time do you wake up on work days?",
              de: "Um welche Zeit wachen Sie auf?"
            }),
            inputType: "time"
          }
        ]}
        onValid={v}
      />
    )
  },
  {
    id: "SQ_MCTQ_3",
    plainTitle: "Free days bed times:",
    title: (
      <span>
        <En>
          On free days:<WhyPopup>weekends, holidays, etc</WhyPopup>
        </En>
        <De>
          An freien Tagen:<WhyPopup>Wochenenden, Ferien, usw.</WhyPopup>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "SQ_MCTQ_1");
      return a[idx].data && parseInt(a[idx].data["text"]) !== 7;
    },
    component: v => (
      <GroupForm.Question
        subQuestions={[
          {
            text: l({
              en:
                "At what time do you turn the light off to go to sleep (including putting phone or tablet down) on free days?",
              de:
                "Um welche Zeit schalten Sie das Licht aus (und legen Handy oder Tablet weg), um schlafen zu gehen?"
            }),
            inputType: "time"
          },
          {
            text: l({
              en: "How many minutes do you need to fall asleep on free days?",
              de: "Wie viele Minuten brauchen Sie um einzuschlafen?"
            }),
            inputType: "number",
            placeholder: l({ en: "minutes", de: "Minuten" })
          },
          {
            text: l({
              en: "At what time do you wake up on free days?",
              de: "Um welche Zeit wachen Sie auf?"
            }),
            inputType: "time"
          }
        ]}
        onValid={v}
      />
    )
  },
  {
    id: "SQ_MCTQ_4",
    plainTitle: "Weekend alarm:",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "SQ_MCTQ_1");
      return a[idx].data && parseInt(a[idx].data["text"]) !== 7;
    },
    title: (
      <span>
        <En>
          Do you regularly use an alarm on <b>free days</b>?
        </En>
        <De>
          Stellen Sie sich regelmässig einen Wecker an <b>freien Tagen</b>?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          This is in relation to your answer on usual wakeup time on free days.
        </En>
        <De>
          Dies steht im Zusammenhang mit Ihrer Antwort zur üblichen Weckzeit an
          freien Tagen.
        </De>
      </span>
    ),
    component: v => <YesNo.Question onValid={v} />
  }
];

export default questions;
