import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import WorldMap from "ewoq/prototypes/icons/worldmap.jpg";

import * as Map from "ewoq/prototypes/ImageCoordinates";

import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";

const questions: QuestionType[] = [
  {
    id: "LQ_DEM_1.1",
    mandatory: false,
    plainTitle: "Occupation",
    title: <span>Current occupation: </span>,
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. student, bartender"
      />
    )
  },
  {
    id: "LQ_DEM_1.2",
    mandatory: false,
    plainTitle: "Years of edicuation",
    title: <span>Indicate your current level of eduction:</span>,
    explanation: "Each label indicates that you have finished that school.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_2.1",
    mandatory: false,
    plainTitle: "father's job",
    title: "What is your father's occupation?",
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. banker, nurse"
        extraRadio={["I don't know", "Many different jobs", "Doesn't work"]}
      />
    )
  },
  {
    id: "LQ_DEM_2.2",
    mandatory: false,
    plainTitle: "father's education",
    title: <span>Indicate your father's level of education:</span>,
    explanation: "Each label indicates the last obtained degree.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_2.3",
    mandatory: false,
    plainTitle: "mother's job",
    title: "What is your mother's occupation?",
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. banker, nurse"
        extraRadio={["I don't know", "Many different jobs", "Doesn't work"]}
      />
    )
  },
  {
    id: "LQ_DEM_2.4",
    mandatory: false,
    plainTitle: "mother's education",
    title: <span>Indicate your mother's level of education:</span>,
    explanation: "Each label indicates the last obtained degree.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_3",
    mandatory: false,
    plainTitle: "Years worked",
    title: <span>Indicate how many years of full-time work you've done: </span>,
    explanation:
      "This does not count part-time work such as work done while studying. If you only did part-time work, click the extra button.",
    component: v => (
      <Input.Question
        onValid={v}
        inputType="number"
        placeholder="Years"
        extraRadio={["I did some part-time work"]}
      />
    )
  },
  {
    id: "LQ_DEM_4",
    mandatory: false,
    plainTitle: "Status",
    title: <span>Where do you fall in the "hierarchy" of your workplace?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Unpaid (e.g. intern, bachelor/master student)" },
          { text: "Paid internship" },
          { text: "Employee" },
          { text: "Low manager" },
          { text: "Middle manager" },
          { text: "Executive" },
          { text: "Director (e.g. CEO)" },
          { text: "I don't work within a hierarchical structure" },
          { text: "Self enployed" },
          { text: "Unsure" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_DEM_5.1",
    mandatory: false,
    plainTitle: "Income",
    title: <span>How much do you earn after taxes per month?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["0", "1000 CHF", "5000 CHF", "10000 CHF", ">15000 CHF"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_5.2",
    mandatory: false,
    plainTitle: "Household income",
    title: <span>If different: what is your household income?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["0", "5000 CHF", "10000 CHF", "20000 CHF", ">40000 CHF"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know", "Not different"]}
      />
    )
  },
  {
    id: "LQ_DEM_6",
    mandatory: false,
    plainTitle: "Job hazzard",
    title: <span>What is the hazard level of your job?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "No risk, e.g. data analyst",
          "A little risk, e.g. nurse",
          "Dangerous, e.g. firefighter"
        ]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_7",
    mandatory: false,
    plainTitle: "Stigmatization",
    title: (
      <span>
        How stigmatized / socially marginalized do you personally feel in your
        daily life?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "No stigma at all",
          "Constantly stigmatized / discriminated against"
        ]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_8",
    mandatory: false,
    plainTitle: "Swiss residence status",
    title: <span>Swiss residence status: </span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Born citizen" },
          { text: "Acquired citizen" },
          { text: "L permit" },
          { text: "B permit" },
          { text: "C permit" },
          { text: "Tourist" },
          { text: "Other" }
        ]}
      />
    )
  },
  {
    id: "LQ_DEM_9.1",
    mandatory: false,
    plainTitle: "Ethnic origin",
    title:
      "Please indicate on the map where you ethnically / genetically originate, to the best of your knowledge.",
    tip: "Click anywhere on the map",
    explanation:
      "This is to evaluate (approximately) if there are genetic differences in circadian rhythms or vulnerability to sleep deprivation.",
    component: v => <Map.Question onValid={v} src={WorldMap} />
  },
  {
    id: "LQ_DEM_9.2",
    mandatory: false,
    plainTitle: "Ethnic origin confidence",
    title: <span>How sure are you of your answer?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Random guess" },
          { text: "Good guess" },
          { text: "I know my family ancestry" },
          { text: "I took a genetic test" }
        ]}
      />
    )
  }, //TODO, make group question with previous
  {
    id: "LQ_DEM_10",
    mandatory: false,
    plainTitle: "Cities lived",
    title: (
      <span>
        Please indicate the city/cities where you have lived the majority of
        your life:
      </span>
    ),
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_DEM_10.1",
            title: "Location: ",
            component: v => (
              <Input.Question
                inputType="string"
                onValid={v}
                placeholder="Zurich, Switzerland"
              />
            )
          }, //potential TODO, make it limited to actually existing countries?
          {
            id: "LQ_DEM_10.3",
            title: "Number of years lived there: ",
            component: v => (
              <Input.Question
                inputType="string"
                onValid={v}
                placeholder="Years"
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_DEM_11.1",
    mandatory: false,
    plainTitle: "Current relationship status",
    title: <span>What is your current “relationship status”?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Single (stable)" },
          { text: "Single (searching for a relationship)" },
          { text: "Recently single" },
          { text: "Dating" },
          { text: "In a stable relationship / married" },
          { text: "In an unstable relationship" },
          { text: "It's complicated" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_DEM_11.2",
    mandatory: false,
    plainTitle: "Currently live with someone",
    title: <span>Do you currently live with a partner?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "Sometimes" }]}
      />
    )
  },
  {
    id: "LQ_DEM_12",
    mandatory: false,
    plainTitle: "Sexual orientation",
    title: <span>Which gender are you most attracted to: </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Male", "Either", "Female"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];

export default questions;
