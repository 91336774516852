// INSERTION FOR TEST COMMIT

import React from "react";

import * as Radio from "ewoq/prototypes/Radio";
import * as YesNoQ from "ewoq/prototypes/YesNo";

import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";
import * as GroupYesNo from "ewoq/prototypes/YesNoGroup";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import * as Input from "ewoq/prototypes/TypeInput";
import * as RankList from "ewoq/prototypes/RankList";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import ImageCoordinates from "ewoq/prototypes/ImageCoordinates";
import {
  Question,
  QuestionType,
  Interrogator
} from "ewoq/containers/Interrogator";
import { str2Time } from "ewoq/utils";
import { En, De, l } from "ewoq/components/Language";

//icons
import Muffin from "ewoq/prototypes/icons/muffin.png";
import Plate from "ewoq/prototypes/icons/grayplate.png";
import WorldMap from "ewoq/prototypes/icons/worldmap.jpg";

const questions: QuestionType[] = [
  {
    id: "SAMPLE_1",
    mandatory: true,
    title: "Where do you live?",
    component: v => <ImageCoordinates src={WorldMap} onValid={v} />
  },
  {
    id: "DQ_1.1",
    mandatory: false,
    title: (
      <span>
        How did you experience participating for this whole experiment?
      </span>
    ),
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["It was awful", "Neutral", "It was fantastic"] },
          { labels: ["Boring", "Neutral", "Interesting"] },
          { labels: ["Stressful", "Neutral", "Relaxing"] }
        ]}
        // extraRadio = {[{text: "Other", inputType: "string"}]} //TODOe make this a Comment separate question
      />
    )
  },

  {
    id: "LQ_1.7dede.1.2",
    mandatory: false,
    title: <span>How often?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "usually", "Daily mwwa aa hwwwaaa aaaaaaa"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "SQ_3.2.12",

    title: (
      <span>
        <En>When you do, does it affect your:</En>
        <De>Wenn Sie dies tun, wirkt sich dies auf Folgendes aus:</De>
      </span>
    ),

    tip:
      "The next question will appear as soon as you give your first answer, but please go back and check any more that may apply",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        allAbove={true}
        includeDone={true}
        options={[
          { text: l({ en: "Mood", de: "Stimmung" }) },
          {
            text: l({
              en: "Physical performance",
              de: "Körperliche Leistungsfähigkeit"
            })
          },
          {
            text: l({
              en: "Mental performance",
              de: "Mentale Leistungsfähigkeit"
            })
          },
          { text: l({ en: "Safety", de: "Sicherheit" }) },
          { text: l({ en: "Clumsiness", de: "Ungeschicklichkeit" }) },
          { text: l({ en: "Sleepiness", de: "Schläfrigkeit" }) },
          { text: l({ en: "Energy levels", de: "Energieniveau" }) },
          { text: l({ en: "Other", de: "Alles oben Genannte" }) } // TODOe
        ]}
      />
    )
  },
  {
    id: "test 45",
    title: "how much do you eat?",
    component: v => (
      <Slider.Question
        sliderType={Slider.SliderType.picture}
        onValid={v}
        labels={[""]}
        picture={Muffin}
        backgroundPicture={Plate}
      />
    )
  },
  {
    id: "LQ_ddd1.7.1.2",
    mandatory: false,
    title: <span>How often?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly aaaaaaa", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },

  {
    id: "mega group 2",
    mandatory: false,
    title: "The big question",
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "q 1",
            title: "little question",
            component: v => <YesNoQ.Question onValid={v} />
          },
          {
            id: "q2",
            title: "next little question",
            component: v => (
              <Slider.Question
                onValid={v}
                labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
                sliderType={Slider.SliderType.horizontal}
              />
            )
          },
          {
            id: "q3",
            title: "input q",
            component: v => <Input.Question onValid={v} inputType={"string"} />
          }
        ]}
      />
    )
  },
  {
    id: "mega group",
    mandatory: false,
    title: "The big question",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "q 1",
            title: "little question",
            component: v => <YesNoQ.Question onValid={v} />
          },
          {
            id: "q2",
            title: "next little question",
            component: v => (
              <Slider.Question
                onValid={v}
                labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
                sliderType={Slider.SliderType.horizontal}
              />
            )
          }
        ]}
      />
    )
  },

  {
    id: "test33",
    title: "order as you like",
    component: v => (
      <RankList.Question
        onValid={v}
        cards={["thing 1", "thing 2", "thing 3", "thing 4"]}
      />
    )
  },
  {
    id: "test",
    title: "order as you like",
    component: v => <Input.Question onValid={v} inputType="string" />
  },
  {
    id: "DQ_10.3",
    mandatory: true,
    title: (
      <span>What would you have preferred to do to stay awake that long?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        extraRadio={["TV was good"]}
      />
    )
  },
  {
    id: "DQ_10.3234",
    mandatory: true,
    title: (
      <span>2What would you have preferred to do to stay awake that long?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        extraRadio={["TV was good"]}
      />
    )
  },
  {
    id: "SQ_1.11",

    title: (
      <span>
        <En>Do you regularly faint or suffer from dizziness?</En>
        <De>Fühlen Sie sich regelmäßig schwindelig oder fallen in Ohnmacht?</De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SQ_1.29",

    title: (
      <span>
        <En>
          Have any of the following affected you in the past month, enough to
          impact your sleep, restfulness, or mood/psychological wellbeing?
        </En>
        <De>
          Haben Sie im letzten Monat eine der folgenden Situationen erlebt, die
          Ihren Schlaf, Ihre Ruhe oder Ihre Stimmung bzw. Ihr psychisches
          Wohlbefinden beeinträchtigten?
        </De>
      </span>
    ),
    component: v => (
      <GroupYesNo.Question
        onValid={v}
        subQuestions={[
          l({
            en: "Death of a close friend or relative",
            de: "Tod eines engen Freundes oder Verwandten"
          }),
          l({
            en: "severe medical illness, treated or untreated",
            de: "Schwere medizinische Erkrankung, behandelt oder unbehandelt"
          }),
          l({
            en: "A serious accident (e.g. a car crash)",
            de: "Ein schwerer Unfall (z. B. ein Autounfall)"
          }),
          l({
            en: "Taking care of a seriously ill friend or relative",
            de: "Betreuung eines schwerkranken Freundes oder Verwandten"
          }),
          l({
            en: "Yes, but I’d rather not say",
            de: "Ja, aber ich möchte es nicht sagen "
          }),
          l({ en: "Other", de: "Andere" })
        ]}
      />
    )
  },
  {
    id: "SQ_2.1.8",

    title: (
      <span>
        <En>
          During the past month, how often have you had trouble staying awake
          while driving, eating meals, or engaging in social activity?
        </En>
        <De>
          Wie oft hatten Sie im vergangenen Monat Probleme damit, beim
          Autofahren, beim Essen oder bei sozialen Aktivitäten wach zu bleiben?
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "Not during the past month",
              de: "Nie während dem letzten Monat"
            })
          },
          {
            text: l({
              en: "Less than once a week",
              de: "Weniger als einmal pro Woche"
            })
          },
          { text: l({ en: "Once or twice a week", de: "1-2 Mal pro Woche" }) },
          {
            text: l({
              en: "Three or more times per week",
              de: "3 Mal oder mehr pro Woche"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_2.1.5",

    title: (
      <span>
        <En>
          During the past month, how often have you had trouble sleeping because
          you...
        </En>
        <De>
          Wie oft hatten Sie im Verlauf des vergangenen Monats Probleme zu
          schlafen weil Sie...
        </De>
      </span>
    ),
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          // NOTE: order matters!
          l({
            en: "Cannot go to sleep within 30 minutes",
            de: "Nicht innerhalb von 30 Minuten einschlafen können"
          }),
          l({
            en: "Wake up in the middle of the night or early morning",
            de: "Mitten in der Nacht oder früh am Morgen aufwachen"
          }),
          l({
            en: "Have to get up to use the bathroom",
            de: "Aufstehen müssen um die Toilette zu benützen"
          }),
          l({
            en: "Cannot breath comfortably",
            de: "Nicht bequem atmen können"
          }),
          l({
            en: "Cough or snore loudly",
            de: "Husten müssen oder laut schnarchen"
          }),
          l({ en: "Feel too cold", de: "Zu kalt haben" }),
          l({ en: "Feel too hot", de: "Zu heiss haben" }),
          l({ en: "Have bad dreams", de: "Schlecht träumen" }),
          l({ en: "Have pain", de: "Schmerzen haben" }),
          l({ en: "Other", de: "Andere" })
        ]}
        options={[
          l({
            en: "Not during the past month",
            de: "Nie während dem letzten Monat"
          }),
          l({
            en: "Less than once a week",
            de: "Weniger als einmal pro Woche"
          }),
          l({ en: "Once or twice a week", de: "1-2 Mal pro Woche" }),
          l({
            en: "Three or more times per week",
            de: "3 Mal oder mehr pro Woche"
          })
        ]}
      />
    )
  },
  {
    id: "SQ_2.1.1",

    title: (
      <span>
        <En>
          During the past month, at what time have you usually gone to bed at
          night?
        </En>
        <De>
          Um welche Zeit sind Sie im vergangenen Monat normalerweise ins Bett
          gegangen?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          If you read or use your phone before bed, then give the time at which
          you stop reading and/or put your phone away.
        </En>
        <De>
          Wenn Sie noch lesen oder Ihr Handy brauchen vor dem Schlafen gehen,
          geben Sie die Uhrzeit an, bei welcher Sie aufhören zu lesen oder Ihr
          Handy weglegen.
        </De>
      </span>
    ),
    warning: "Are you sure?",
    isOk: (s: Input.State) => {
      const BT = str2Time(s.text) % 24;
      return BT > 19 || BT < 2;
    }, //bedtime earlier than 19 or later than 2 is a problem
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },
  {
    id: "LQ_1.7.1.2",
    mandatory: false,
    title: <span>How often?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_1.2.2",
    mandatory: false,
    title: <span>Which hand/foot do you prefer when: </span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { question: "Writing", labels: ["Left", "Either", "Right"] },
          { question: "Throwing a ball" },
          { question: "Using scissors" },
          { question: "Eating with a fork" },
          { question: "Kicking a ball" },
          { question: "First step walking" },
          { question: "Jumping on one foot" }
        ]}
      />
    )
  }
];

class SensitiveChecklist extends React.Component {
  render() {
    return (
      <div>
        <Interrogator
          questions={questions}
          onDone={s => alert(s)}
          viewAll={true}
        />
      </div>
    );
  }
}

export default SensitiveChecklist;

//TODO: add plain titles and change numbering, and update questions
