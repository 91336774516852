import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./SleepLogNightQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import ChooseSessionVertical from ".././DayPickerVertical";


import { En, De, l, LanguageContext } from "ewoq/components/Language";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

interface State {
  name?: string;
  week: string;
  weekNumber: string;
  night: string;
  nightNumber: string;
  weekday: string;
  weekdaysAll: any;
}

export class SleepLogNight extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { 
      name: this.props.name || "",
      week: "",
      weekNumber: "",
      night: "",
      nightNumber: "",
      weekday: "",
      weekdaysAll: this.writeWeekdays(),
    };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "Week-" + this.state.weekNumber + "-Night-" + this.state.nightNumber + "-Studynightweekday-" + this.state.weekday + "-SleepLogBook", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };  

  writeWeekdays = () => {
    const weekdaysAll = new Array;
    weekdaysAll[0] = l({en: "Monday",    de: "Montag"});
    weekdaysAll[1] = l({en: "Tuesday",   de: "Dienstag"});
    weekdaysAll[2] = l({en: "Wednesday", de: "Mittwoch"});   
    weekdaysAll[3] = l({en: "Thursday",  de: "Donnerstag"});    
    weekdaysAll[4] = l({en: "Friday",    de: "Freitag"});    
    weekdaysAll[5] = l({en: "Saturday",  de: "Samstag"});   
    weekdaysAll[6] = l({en: "Sunday",    de: "Sonntag"});    
    weekdaysAll[7] = l({en: "Monday",    de: "Montag"});    
    return(weekdaysAll)
  }

  onClick = (selection: string) => {
    this.setState({ name: this.props.name + "-" + selection });
  };

  chooseWeek = (selection: string) => {
    this.setState({ week: selection, weekNumber: selection.slice(-1) });
  };

  chooseNight = (selection: string) => {
    this.setState({ night: selection, nightNumber: selection.split(':', 1)[0].slice(-1) });
  };

  chooseWeekday = (selection: string) => {
    this.setState({ weekday: selection });
  };  

  getWeekday(weekday: string, week: string) {
    const weekdaysAll = this.state.weekdaysAll;
    const thisWeekdayIndex = weekdaysAll.indexOf(weekday); // index of chosen weekday
    var weekdaysIndex = Array.from({length: 8}, (_, i) => i + thisWeekdayIndex); 
    var weekdaysIndexSorted = new Array();
    weekdaysIndex.forEach(function(digit){
        if(digit >= 7){
          weekdaysIndexSorted.push(digit - 7);
        } else {
          weekdaysIndexSorted.push(digit);
        }
    }); 
    var W2 = 0;
    if (week == "Week 2" || week == "Woche 2" ){
       W2 = 1;
    }
    var days = new Array();
    weekdaysIndexSorted.forEach(function(digit, index){
      if(index < weekdaysIndexSorted.length - 1){
        days.push(`${l({en: "Night", de: "Nacht"})} ${index-W2+1}: ${weekdaysAll[digit]} ${l({en: "to", de: "auf"})} ${weekdaysAll[digit + 1]}`);
        console.log("digit:" + digit)
        console.log("index:" + index)
      }
    });
   console.log("Weekday: " + weekday)
   console.log("This weekday index: " + thisWeekdayIndex)
   console.log("Weekdays index: " + weekdaysIndexSorted)
   console.log("Weekdays index length:" + weekdaysIndex.length)
   days = W2===1 ? days.slice(1, 7) : days
    // if (week == "Week 2" || week == "Woche 2" ){
    //   days = days.slice(1, 7)
    // }        
    return (days);
  };  


  render() {
    console.log("Weekdays: " + this.state.weekdaysAll);  
    console.log("Output name: " + "Week-" + this.state.weekNumber + "-Night-" + this.state.nightNumber + "-Studynightweekday-" + this.state.weekday + "-SleepLogBook")    
    return (   
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">
            <En>How was your night?</En>
            <De>Wie war Deine Nacht?</De>
          </Ui.Container>
        </HeaderContainer>

        <Ui.Container>
        <ChooseSession
            sessions={[this.state.weekdaysAll.slice(0, 7)]}
            selection={this.state.weekday}
            onClick={this.chooseWeekday}
            title={l({en: "Weekday of your first experiment night", de: "Wochentag deiner ersten Labornacht"})}
          />          
          <ChooseSession
            sessions={[[l({en: "Week 1", de: "Woche 1"}), l({en: "Week 2", de: "Woche 2"})]]}
            selection={this.state.week}
            onClick={this.chooseWeek}
            title={l({en: "control week", de: "Kontrollwoche"})}
          />
          <ChooseSessionVertical
            // sessions={[["Night-1", "Night-2", "Night-3", "Night-4", "Night-5", "Night-6", "Night-7"]]}
            sessions={[this.getWeekday(this.state.weekday, this.state.week)]}
            selection={this.state.night}
            onClick={this.chooseNight}
            title={l({en: "Night", de: "Nacht"})}
          />
        </Ui.Container>

        {this.state.week && this.state.night ? (
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          /> ) : null}
      </div>
    );
  }
}

export default SleepLogNight;
