import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { Language } from "./ScreeningQuestionnaire/misc";
import { getUserId, setUserId } from "ewoq/utils";
import { En, De, l, LanguageContext } from "ewoq/components/Language";


import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";
import { Link } from "react-router-dom";
import { SPChoice, StudyPopulation, StudyPopulationContext, defaultStudyPopulation } from "StudyPopulationChoice";

interface Props {
  onLanguageChoice: (language: Language) => void;
  onStudyPopulationChoice: (population: StudyPopulation) => void;
  studyPopulation: StudyPopulation;
}

const Container = styled.div`
 text-align: center;
`;

// hey Sven!

interface State {
  cookie: string;
  words: string[];
  isQuestionnaireClick: boolean;
}

// interface Props {
//   onDone: (s: boolean) => void;
//   onChangeLanguage: (l: Language) => void;
//   language: Language;
// }

const ButtonArea = styled.div`
margin: auto;
margin-bottom: 2em;
margin-top: 2em;
text-align: justify;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 800px) {
  width: 70%;
`;

const SegmentBox = styled.div`
  margin: 1em;
`;

class Questionnaires extends React.Component<Props, State> {
  static contextType = LanguageContext;
  constructor(props: Props) {
    super(props);
    const cookie = getUserId() || ""; //I shouldn't have to do this
    this.state = {
      isQuestionnaireClick: false,
      cookie,
      words: Array(3).fill(undefined),
    };
  }

  updateCookie = () => {
    const words = this.state.words;
    if (words.every((w) => w.length > 0)) {
      const cookie = words[0] + "-" + words[1] + "-" + words[2];
      setUserId(cookie);
      this.setState({ cookie });
    }
  };

  onChange = (idx: number) => (a: any, b: any) => {
    let words = this.state.words.slice();
    words[idx] = b.value.trim();
    this.setState({ words });
  };

  // updateQuestionnaireClick = () => {
  //   const isQuestionnaireClick = true;
  //   if (isQuestionnaireClick && !this.state.words.every((w) => w && w.length > 0) || this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation) {
  //     return (
  //       <Ui.Message warning>
  //         <Ui.MessageHeader>
  //           <span>
  //             <En>Be careful!</En>
  //             <De>Seien Sie vorsichtig!</De>
  //           </span>
  //         </Ui.MessageHeader>
  //         <Ui.Message>
  //             <En> Please choose your <strong>health status and age</strong>. </En>
  //             <De> Bitte wählen Sie Ihren <strong>Gesundheitsstatus und Ihr Alter</strong> aus. </De>
  //         </Ui.Message> 
  //       </Ui.Message>       
  //     );};};   
  // };


  render() {
    console.log("Cookie: " + this.state.cookie)
    const isQuestionnaireClick = this.state.isQuestionnaireClick;
    return (
      <div> 

        <HeaderContainer>
          <En>Choice of questionnares</En>
          <De>Fragebogenauswahl</De>
        </HeaderContainer> 

        <Ui.Container>
        {/* <SegmentBox> */}
          {/* <ParticipantID>{getUserId() || ""}</ParticipantID> */}
          <Ui.Segment>
            {/* {this.state.questionnaire === undefined ? ( */}
            <Ui.ButtonGroup floated="right" size="mini">
              <Ui.Button
                onClick={() => this.props.onLanguageChoice(Language.en)}
                color={this.context === Language.en ? "teal" : undefined}
              >
                <Ui.Flag name="gb" />
              </Ui.Button>
              <Ui.ButtonOr />
              <Ui.Button
                onClick={() => this.props.onLanguageChoice(Language.de)}
                color={this.context === Language.de ? "teal" : undefined}
              >
                <Ui.Flag name="de" />
              </Ui.Button>
            </Ui.ButtonGroup>

            &nbsp;&nbsp;&nbsp;

             {/* Study population choice */}
             <Ui.ButtonGroup vertical size="mini">

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.EpiKids)}
                            color={studyPopulation === StudyPopulation.EpiKids ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Parent + child", de: "Eltern + Kind" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "4 - 12 years", de: "4 - 12 Jahre" })}>
                            </Ui.ButtonContent>                              
                          </Ui.Button>                        
                        </div>
                      )} />                        

                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.HealthyTeens)}
                            color={studyPopulation === StudyPopulation.HealthyTeens ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Teenager", de: "Jugendliche" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "13 - 17 years", de: "13 - 17 Jahre" })}>
                            </Ui.ButtonContent>                                
                          </Ui.Button>
                        </div>
                      )} />                                               


                      <StudyPopulationContext.Consumer children={studyPopulation => (
                        <div>
                          <Ui.Button
                            animated='fade'
                            content={l({ en: "Adult", de: "Erwachsene" })}
                            onClick={() => this.props.onStudyPopulationChoice(StudyPopulation.HealthyAdults)}
                            color={studyPopulation === StudyPopulation.HealthyAdults ? "teal" : undefined}>

                            <Ui.ButtonContent
                              visible
                              icon="child"
                              content = {l({ en: "Adult", de: "Erwachsene" })}>
                            </Ui.ButtonContent>
                            <Ui.ButtonContent
                              hidden
                              content = {l({ en: "18 - 30 years", de: "18 - 30 Jahre" })}>
                            </Ui.ButtonContent>                                   
                          </Ui.Button>
                        </div>
                      )} />                

              </Ui.ButtonGroup>

        <p> &nbsp; </p>
        {/* <HeaderContainer>All Questionnaires</HeaderContainer> */}
        {/* <Ui.Container> */}
          {/* <Ui.Segment> */}
            <Ui.Divider horizontal>
              <En>Please enter the 3-word-code</En>
              <De>Bitte 3-Wort-Code eingeben</De>
            </Ui.Divider>
            <SectionBody>
              <Ui.Form>
                <Ui.Form.Group widths="equal">
                  <Ui.Form.Input
                    onChange={this.onChange(0)}
                    value={this.state.words[0]}
                    placeholder="Wort 1"
                    color="red"
                  />
                  <Ui.Form.Input
                    onChange={this.onChange(1)}
                    value={this.state.words[1]}
                    placeholder="Wort 2"
                  />
                  <Ui.Form.Input
                    onChange={this.onChange(2)}
                    value={this.state.words[2]}
                    placeholder="Wort 3"
                  />
                </Ui.Form.Group>
                <Ui.Form.Field>
                <ParticipantID>{this.state.cookie}</ParticipantID>
                {/* <ParticipantID>{this.state.cookie.length}</ParticipantID> */}
                {/* <ParticipantID>{this.props.studyPopulation}</ParticipantID> */}
                <Container>
                  <Ui.Label color="violet" justify="center" pointing>
                    <En> {"Is this your 3-word-code?"} </En>
                    <De> {"Ist das Dein 3-Word-code?"} </De>                                      
                  </Ui.Label>
                </Container>                
                  <Ui.Button
                    onClick={this.updateCookie}
                    disabled={!this.state.words.every((w) => w && w.length > 0)}
                  >
                    <En> Change </En>
                    <De> Ändern </De>
                  </Ui.Button>
                </Ui.Form.Field>
              </Ui.Form>
{/* <form class="ui success form"><div class="field"><label>Email</label><div class="ui input"><input type="text" placeholder="joe@schmoe.com"/></div></div><div class="ui success message"><div class="content"><div class="header">Form Completed</div><p>You&#x27;re all signed up for the newsletter</p></div></div><button class="ui button">Submit</button></form> */}

            </SectionBody>
          </Ui.Segment>


          <Ui.Segment>
          <Ui.Divider horizontal>
              <En>Please choose one of the following questionnaries</En>
              <De>Bitte wählen Sie einen der folgenden Fragebögen</De>
            </Ui.Divider>
            <Squeeze>

              <SectionBody>
                <Ui.Button 
                  color="teal" 
                  fluid 
                  // onClick={this.updateQuestionnaireClick}
                  // disabled={!this.state.words.every((w) => w && w.length > 0) || this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation}
                  disabled={this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation}                  
                >

                  <Link to="/basicinfo" style={{ color: '#FFF' }}>
                    <En> Basic participant information </En>
                    <De> Grundlegende Probandeninformationen </De>
                  </Link>              

                </Ui.Button>
              </SectionBody>

                {/* if (!this.state.words.every((w) => w && w.length > 0) || this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation) {
                  return (
                    <Ui.Message warning>
                      <Ui.MessageHeader>
                        <span>
                          <En>Be careful!</En>
                          <De>Seien Sie vorsichtig!</De>
                        </span>
                      </Ui.MessageHeader>
                      <Ui.Message>
                          <En> Please choose your <strong>health status and age</strong>. </En>
                          <De> Bitte wählen Sie Ihren <strong>Gesundheitsstatus und Ihr Alter</strong> aus. </De>
                      </Ui.Message> 
                    </Ui.Message>       
                  )}          */}

              {/* <LinkQ href="/screening">
                <Ui.Button color="grey" fluid>
                <span>
                  <En> Screening Questionnaire </En>
                  <De> Eintrittsfragebogen </De>
                </span>                  
                </Ui.Button>
              </LinkQ>

              <LinkQ href="/lifestyle">
                <Ui.Button color="grey" fluid>
                <span>
                  <En> Lifestyle Questionnaire </En>
                  <De> Fragebogen zum Lebensstil </De>
                </span>                   
                  
                </Ui.Button>
              </LinkQ> */}

                <Ui.Divider></Ui.Divider>
                <SectionBody>
                  <Ui.Button 
                    // color="grey" 
                    fluid 
                    // disabled={!this.state.words.every((w) => w && w.length > 0) || this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation}
                    disabled={this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation}                  
                  >

                    <Link to="/sleepreport" style={{ color: '#666' }}>
                      <En> Study night questionnaires </En>
                      <De> Fragebögen während der Studiennacht </De>
                    </Link>              

                  </Ui.Button>   
                </SectionBody>

              {/* <LinkQ href="resttest">
                <Ui.Button color="grey" fluid>
                <span>
                  <En> Regular Rest Test Questionnaire </En>
                  <De> Regulärer Ruhetestfragebogen </De>
                </span>                   
                  
                </Ui.Button>
              </LinkQ>
              <LinkQ href="/debriefing">
                <Ui.Button color="grey" fluid>
                <span>
                  <En> Debriefing Questionnaire </En>
                  <De> Nachbesprechungsfragebogen </De>
                </span>                   
                  
                </Ui.Button>
              </LinkQ>  */}

                <SectionBody>
                  <Ui.Button 
                    // color="grey" 
                    fluid 
                    disabled={!this.state.words.every((w) => w && w.length > 0) || this.state.cookie.length === 0 || this.props.studyPopulation === defaultStudyPopulation}
                  >

                    <Link to="/debriefing" style={{ color: '#666' }}>
                      <En> Debriefing Questionnaire </En>
                      <De> Nachbesprechungsfragebogen </De>
                    </Link>              
                  </Ui.Button>   
                </SectionBody>

              
            </Squeeze>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Questionnaires;
