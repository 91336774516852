import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_HAN_1",
    mandatory: false,
    plainTitle: "Handedness:",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Are you       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Are you       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Are you       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Are you       </SPChoice>          
          left or right handed?
        </En>
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Bist Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Sind Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Bist Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Sind Sie     </SPChoice>             
          Links- oder Rechtshänder?
        </De>
      </span>
    ),
    component: (v) => (
      <Radio.Question
        onValid={v}
        options={[
          {text: l({en:  "Left",  de: "Linkshänder" }) },
          {text: l({ en: "Right", de: "Rechtshänder" }) },
        ]}        
        // options={[
        //   {
        //     text: l({
        //       en: sp({
        //         [StudyPopulation.HealthyKids]: "Left kid",
        //         [StudyPopulation.HealthyKids]: "Left adult",
        //       }),
        //       de: "Linkshänder",
        //     }),
        //   },
        //   { text: l({ en: "Right", de: "Rechtshänder" }) },
        // ]}
      />
    ),
  },
  
  {
    id: "BQ_HAN_2",
    mandatory: false,
    plainTitle: "Preffered hand when...",
    title: (
      <span>
        <En>
          Which hand/foot
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          
          prefer when:
        </En>       
        <De>
          Welche Hand/ welchen Fuss
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   benutzt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  benutzt Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> benutzen Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       benutzt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      benutzt Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     benutzen Sie     </SPChoice>             
          beim:
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            question: l({ en: "Writing", de: "Schreiben" }),
            labels: [
              l({ en: "Always left", de: "Nur links" }),
              l({ en: "Either", de: "Keine Bevorzugung" }),
              l({ en: "Always right", de: "Nur rechts" }),
            ],
          },
          { question: l({ en: "Drawing",                            de: "Zeichnen" }) },
          { question: l({ en: "Throwing a ball",                    de: "Einen Ball werfen" }) },
          { question: l({ en: "Using Scissors",                     de: "Schere benutzen" }) },
          { question: l({ en: "Using toothbrush",                   de: "Zahnbürste benutzen" }) },
          { question: l({ en: "Eating with a fork (without knife)", de: "Mit einer Gabel essen (ohne Messer)" }) },
          { question: l({ en: "Eating with a spoon",                de: "Mit einem Löffel essen" }) },
          { question: l({ en: "Using a broom (upper hand)",         de: "Einen Besen benutzen (obere Hand)" }) },
          { question: l({ en: "Lighting a match",                   de: "Streichhölzer anzünden"}) },
          { question: l({ en: "Open a lid",                         de: "Einen Deckel öffnen" }) },
          { question: l({ en: "Kicking a ball",                     de: "Einen Ball treten" }) },
          { question: l({ en: "First step walking",                 de: "Gehen (der erste Schritt)" }) },
          { question: l({ en: "Jumping on one foot",                de: "Auf einem Bein hüpfen" }) },
          { question: l({ en: "Looking with one eye only",          de: "Nur mit einem Auge gucken" }) },
        ]}
      />
    ),
  },

  {
    id: "BQ_HAN_3",
    mandatory: false,
    plainTitle: "Forced hand switch",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Has your child ever used their left hand (for writing/drawing) and was     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  As a child, did you ever use your left hand (for writing/drawing) and were </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> As a child, did you ever use your left hand (for writing/drawing) and were </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Has your child ever used their left hand (for writing/drawing) and was     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      As a child, did you ever use your left hand (for writing/drawing) and were </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     As a child, did you ever use your left hand (for writing/drawing) and were </SPChoice>          
           obliged to switch to the right hand by a teacher or relative?
        </En> 
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hat Ihr Kind jemals seine linke Hand (zum Schreiben / Zeichnen) benutzt und wurde        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Hast Du als Kind jemals Deine linke Hand (zum Schreiben / Zeichnen) benutzt und wurdest  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Haben Sie als Kind jemals Ihre linke Hand (zum Schreiben / Zeichnen) benutzt und wurden  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hat Ihr Kind jemals seine linke Hand (zum Schreiben / Zeichnen) benutzt und wurde        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Hast Du als Kind jemals Deine linke Hand (zum Schreiben / Zeichnen) benutzt und wurdest  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Haben Sie als Kind jemals Ihre linke Hand (zum Schreiben / Zeichnen) benutzt und wurden  </SPChoice>             
          von einem Lehrer oder Verwandten gezwungen, zur rechten Hand zu wechseln?
        </De>        
      </span>
    ),
    component: (v) => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Yes",          de: "Ja" }) },
          { text: l({ en: "No",           de: "Nein" }) },
          { text: l({ en: "I don't know", de: "Ich weiss nicht" }) },
        ]}
      />
    ),
  },
];

export default questions;
