import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import { En, De, l } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "BQ_ESS_1",
    mandatory: false,
    plainTitle: "ESS",
    title: (
      <span>
      <En>Please select one answer per situation:</En>
      <De>Bitte wählen Sie eine Antwort pro Situation aus:</De>
    </span>
    ),

     
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "Sitting and reading",        
              de: "Im Sitzen lesend" }),
          l({ en: "Watching TV",                
              de: "Beim Fernsehen " }),
          l({ en: "Sitting inactive in a public place (e.g. a theater or a meeting)",     
              de: "Wenn Sie passiv (als Zuhörer) in der Öffentlichkeit sitzen (z.B. im Theater oder bei einem Vortrag)" }),
          l({ en: "As a passenger in a car for an hour without a break",     
              de: "Als Beifahrer im Auto während einer einstündigen Fahrt ohne Pause" }),
          l({ en: "Lying down to rest in the afternoon when circumstances permit",     
              de: "Wenn Sie sich am Nachmittag hingelegt haben, um auszuruhen " }),
          l({ en: "Sitting and talking to someone",     
              de: "Wenn Sie sitzen und sich mit jemand unterhalten" }),
          l({ en: "Sitting quietly after a lunch without alcohol",     
              de: "Wenn Sie nach dem Mittagessen (ohne Alkohol) ruhig dasitzen" }),
          l({ en: "In a car, while trapped for a few minutes in traffic",     
              de: "Wenn Sie als Fahrer eines Autos verkehrsbedingt einige Minuten halten müssen" }),          
          
        ]}
        options={[
          l({ en: "0",   de: "0" }),
          l({ en: "1",   de: "1" }),
          l({ en: "2",   de: "2" }),
          l({ en: "3",   de: "3" }),
        ]}
      />
    )
  },
  
];

export default questions;
