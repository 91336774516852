import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_SLQ_1",
    mandatory: false,
    plainTitle: "Sleep Quality",
    title: (
      <span>
        <En>      
          *Platzhalter*
        </En>
        <De>
          Im Vergleich zum normalen Schlaf war der Schlaf heute…
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "unruhig" }),
              l({ en: "PLATZHALTER", de: "ruhig" }),
            ],
            
          },

          {
            labels: [
              l({ en: "PLATZHALTER", de: "oberflächlich" }),
              l({ en: "PLATZHALTER", de: "tief" }),
            ],
            
          },
        
        ]}
      />
    ),
  },
  
  {
    id: "BQ_SLQ_2",
    mandatory: false,
    plainTitle: "Dreams",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Platzhalter </SPChoice>          

        </En> 
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Kann Ihr Kind sich an Träume erinnern? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Kann Ihr Kind sich an Träume erinnern? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Platzhalter  </SPChoice>             

        </De>        
      </span>
    ),
    component: (v) => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Yes",          de: "Ja" }) },
          { text: l({ en: "No",           de: "Nein" }) },
        ]}
      />
    ),
  },

  {
    id: "BQ_SLQ_3",
    mandatory: false,
    plainTitle: "Dream Quality",
    title: (
      <span>
        <En>      
          *Platzhalter*
        </En>
        <De>
          Wie waren die Träume?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "unangenehm" }),
              l({ en: "PLATZHALTER", de: "angenehm" }),
            ],
            
          },        
        ]}
      />
    ),
  },

  {
    id: "BQ_SLQ_4",
    mandatory: false,
    plainTitle: "current state",
    title: (
      <span>
        <En>      
          *Platzhalter*
        </En>
        <De>
          Im Vergleich zur normalen Verfassung tagsüber fühlt sich Ihr Kind <b>momentan</b>...
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "müde" }),
              l({ en: "PLATZHALTER", de: "erholt" }),
            ],
            
          },

          {
            labels: [
              l({ en: "PLATZHALTER", de: "schlecht gestimmt" }),
              l({ en: "PLATZHALTER", de: "gut gestimmt" }),
            ],
            
          },
          
          {
            labels: [
              l({ en: "PLATZHALTER", de: "lustlos, leer" }),
              l({ en: "PLATZHALTER", de: "voller Energie" }),
            ],
            
          },

          {
            labels: [
              l({ en: "PLATZHALTER", de: "unruhig, angespannt" }),
              l({ en: "PLATZHALTER", de: "ruhig, gelassen" }),
            ],
            
          },

          {
            labels: [
              l({ en: "PLATZHALTER", de: "unkonzentriert" }),
              l({ en: "PLATZHALTER", de: "konzentriert" }),
            ],
            
          },

        ]}
      />
    ),
  },

  {
    id: "BQ_SLQ_5",
    mandatory: false,
    plainTitle: "additionally noticed",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Platzhalter </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Platzhalter </SPChoice>          

        </En> 
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ist Ihrem Kind in der Nacht etwas Aussergewöhnliches aufgefallen (Gerüche, Geräusche usw.)? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ist Ihrem Kind in der Nacht etwas Aussergewöhnliches aufgefallen (Gerüche, Geräusche usw.)? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Platzhalter  </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Platzhalter  </SPChoice>             

        </De>        
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
            placeHolder:  l({ en: "Please specify in detail", de: "Bitte erläutern Sie" }),
            extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },




];

export default questions;
