import React from "react";
import styled from "styled-components";
import SampleQ from "./Questionnaires/SampleQuestionnaire";
import ScreeningQ from "./Questionnaires/ScreeningQuestionnaire/";
import ScreeningQ_alwaysEligible from "./Questionnaires/ScreeningQuestionnaire_alwaysEligible/";
import {default as Eligible_AE} from "./Questionnaires/ScreeningQuestionnaire_alwaysEligible/EligibleForms";

import ControlWeek from "./ControlWeek/";
import SleepLogDay from "./ControlWeek/SleepLogDay";
import SleepLogNight from "./ControlWeek/SleepLogNight";

import Questionnaires from "./Questionnaires/";
import AllQs from "./Questionnaires/AllQuestionnaires";
import PSQI from "./Questionnaires/ScreeningQuestionnaire/PSQI";
import MCTQ from "./Questionnaires/ScreeningQuestionnaire/MCTQ";
import Wake from "./Questionnaires/ScreeningQuestionnaire/Wake";
import Sleep from "./Questionnaires/ScreeningQuestionnaire/Sleep";
import BasicExclusion from "./Questionnaires/ScreeningQuestionnaire/BasicExclusion";
import Entrance from "./Questionnaires/ScreeningQuestionnaire/Entrance";
import Eligible from "./Questionnaires/ScreeningQuestionnaire/EligibleForms";
import Ineligible from "./Questionnaires/ScreeningQuestionnaire/Ineligible";
import SciencePage from "./SciencePage";
import OtherExperiments from "./OtherExperiments";
import Home from "./HomePage";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Lifestyle from "./Questionnaires/LifestyleQuestionnaire";
import { Language } from "./Questionnaires/ScreeningQuestionnaire/misc";
import { LanguageProvider } from "ewoq/components/Language";

import Debriefing from "./Questionnaires/DebriefingQuestionnaire";

import RestTest from "./Questionnaires/RestTestQuestionnaire";

import BasicInfo from "./Questionnaires/BasicInfo";

import SleepReport from "./Questionnaires/SleepReport";
import Rebound from "OtherExperiments/Rebound";

import {
  StudyPopulation,
  StudyPopulationContext,
  SPProvider,
  defaultStudyPopulation,
} from "StudyPopulationChoice";

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomContainer = styled.div`
  background-color: #eee;
  min-height: 90vh;
  font-size: 100%;
  padding: 0.5em;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 100%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 90%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 80%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 45%;
  }
`;

interface Props {}

interface State {
  language: Language;
  studyPopulation: StudyPopulation;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: Language.de,
      studyPopulation: defaultStudyPopulation,
    };
  }

  onLanguageChoice = (language: Language) => this.setState({ language });

  onStudyPopulationChoice = (population: StudyPopulation) => {
    this.setState({ studyPopulation: population });
  };

  render() {
    return (
      <BrowserRouter>
        <ParentContainer>
          <CustomContainer>
            <LanguageProvider value={this.state.language}>
              <SPProvider value={this.state.studyPopulation}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/questionnaires">
                    <Questionnaires
                      studyPopulation={this.state.studyPopulation}                    
                      onLanguageChoice={this.onLanguageChoice}
                      onStudyPopulationChoice={this.onStudyPopulationChoice}
                    />
                  </Route>
                  <Route exact path="/allqs">
                    <AllQs />
                  </Route>
                  <Route exact path="/cweek">
                    <ControlWeek                      
                      studyPopulation={this.state.studyPopulation}                    
                      onLanguageChoice={this.onLanguageChoice}
                      onStudyPopulationChoice={this.onStudyPopulationChoice}/>
                  </Route>
                  <Route exact path="/cweek/sleeplogday">
                    <SleepLogDay />
                  </Route>    
                  <Route exact path="/cweek/sleeplognight">
                    <SleepLogNight />
                  </Route>                     
                                
                  <Route exact path="/basicexclusion">
                    <BasicExclusion onDone={() => true} />
                  </Route>
                  <Route exact path="/entrance">
                    <Entrance onDone={() => true} />
                  </Route>
                  <Route exact path="/questions">
                    <SampleQ />
                  </Route>
                  <Route exact path="/screening">
                    <ScreeningQ 
                      onLanguageChoice={this.onLanguageChoice}
                      onStudyPopulationChoice={this.onStudyPopulationChoice}/>
                  </Route>
                  <Route exact path="/screening_eligible">
                    <ScreeningQ_alwaysEligible                   
                      onLanguageChoice={this.onLanguageChoice}
                      onStudyPopulationChoice={this.onStudyPopulationChoice}/>
                  </Route>
                  <Route exact path="/screening_eligible/eligible">
                    <Eligible_AE />
                  </Route>                  
                  <Route exact path="/lifestyle">
                    <Lifestyle />
                  </Route>
                  <Route exact path="/sleepreport">
                    <SleepReport />
                  </Route>
                  <Route exact path="/resttest">
                    <RestTest />
                  </Route>
                  <Route exact path="/debriefing">
                    <Debriefing />
                  </Route>
                  <Route exact path="/scienceinfo">
                    <SciencePage />
                  </Route>
                  <Route exact path="/sample">
                    <SampleQ />
                  </Route>
                  <Route exact path="/basicinfo">
                    <BasicInfo />
                  </Route>
                  <Route exact path="/screening/PSQI">
                    <PSQI onDone={() => true} />
                  </Route>
                  <Route exact path="/screening/MCTQ">
                    <MCTQ onDone={() => true} />
                  </Route>
                  <Route exact path="/screening/wake">
                    <Wake onDone={() => true} />
                  </Route>
                  <Route exact path="/screening/sleep">
                    <Sleep onDone={() => true} />
                  </Route>
                  <Route exact path="/screening/eligible">
                    <Eligible />
                  </Route>
                  <Route exact path="/screening/ineligible">
                    <Ineligible />
                  </Route>
                  <Route exact path="/studies">
                    <OtherExperiments />
                  </Route>
                  <Route exact path="/rebound">
                    <Rebound />
                  </Route>
                  <Route exact path="/blueflier">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </SPProvider>
            </LanguageProvider>
          </CustomContainer>
        </ParentContainer>
      </BrowserRouter>
    );
  }
}

export default App;
