// To Do:
// Make all mandatory?
// Check isOk
// Go through english and german version and see if everything works
// Check plainTitles
// ENTR_02: for epilepsy isOk must be <=1
// ENTR_09: how to do isOk for hours?
// ENTR_26: isOk for multipleChoice?

import React from "react";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Radio from "ewoq/prototypes/Radio";
import * as GroupYesNo from "ewoq/prototypes/YesNoGroup";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { str2Time } from "ewoq/utils";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import { Placeholder } from "semantic-ui-react";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import WhyPopup from "ewoq/components/WhyPopup";


const questions = (studyPopulation: StudyPopulation): QuestionType[] => [

  
  {
    id: "ENTR_01",
    mandatory: true,
    plainTitle: "Already participated",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
            Has your child ever participated in a sleep study in our lab?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
            Have you ever participated in a sleep study in our lab?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
            Hat Ihr Kind schon an einem Schlafexperiment bei uns teilgenommen? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
            Hast du schon an einem Schlafexperiment bei uns teilgenommen? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
            Haben Sie schon an einem Schlafexperiment bei uns teilgenommen? </SPChoice>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // They must sleep well
  {
    id: "ENTR_02",
    mandatory: true,
    plainTitle: "Sleep quality",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
            How does your child generally sleep?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
            How do you generally sleep?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
            Wie schläft Ihr Kind gewöhnlich?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
            Wie schläfst Du gewöhnlich?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
            Wie schlafen Sie gewöhnlich?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === 0;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "good", de: "gut" }) },
          { text: l({ en: "fairly well",   de: "mittel" }) },
          { text: l({ en: "poorly",  de: "schlecht" }) }
        ]}
      />
    )
  },

  {
    id: "ENTR_02A",
    plainTitle: "Reasons sleeping problems",
    mandatory: true,
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          What causes your childs sleep difficulties? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          What causes your sleep difficulties? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Was glauben Sie sind die Gründe für die Schlafschwierigkeiten Ihres Kindes? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Was glaubst Du sind die Gründe für Deine Schlafschwierigkeiten? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Was glauben Sie sind die Gründe für Ihre Schlafschwierigkeiten? </SPChoice>
        </De>
      </span>
    ), 
    
    shouldShow: (a: Question[]) => {
      const idxSleepQuali = a.findIndex(a => a.id === "ENTR_02");

      return (
        a[idxSleepQuali].data !== undefined &&
        a[idxSleepQuali].data["answer"] === 2
      );  // asks if person sleeps poorly
    },

    component: v => <Input.Question onValid={v} inputType="string" />
  },  

  // Must not have sleeping disorder
  {
    id: "ENTR_03",
    mandatory: true,
    plainTitle: "Sleep disorder",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child have a sleep disorder?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you have a sleep disorder?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Leidet oder litt Ihr Kind an einer Schlafstörung?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Leidest oder littst Du an einer Schlafstörung?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Leiden oder litten Sie an einer Schlafstörung?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // Must not have a sleep disorder in their family
  {
    id: "ENTR_04",
    mandatory: true,
    plainTitle: "Family sleep disorders",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does anyone in your family have sleep disorders?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Does anyone in your family have sleep disorders?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Gibt es Schlafstörungen in Ihrer Familie?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Gibt es Schlafstörungen in Deiner Familie?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Gibt es Schlafstörungen in Ihrer Familie?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // Must not snore nor have a racing heart
  {
    id: "ENTR_05",
    mandatory: true,
    plainTitle: "Snoring/racing heart",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child snore or frequently wake up with a racing heart?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you snore or do you frequently wake up with a racing heart?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Schnarcht Ihr Kind nachts oder wacht es häufig mit Herzklopfen auf?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Schnarchst Du nachts oder wachst häufig mit Herzklopfen auf?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Schnarchen Sie nachts oder wachen Sie häufig mit Herzklopfen auf?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // Must not have restless leg syndrome
  {
    id: "ENTR_06",
    mandatory: true,
    plainTitle: "Restless Leg Syndrom",    
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child have tingling, twitching, or involuntary leg movements while falling asleep (Restless Leg Syndrome)?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you have tingling, twitching, or involuntary leg movements while falling asleep (Restless Leg Syndrome)?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Hat Ihr Kind Kribbeln, Zuckungen oder unwillkürliche Beinbewegungen beim Einschlafen (Restless Leg Syndrom)? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Hast Du Kribbeln, Zuckungen oder unwillkürliche Beinbewegungen beim Einschlafen (Restless Leg Syndrom)? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie Kribbeln, Zuckungen oder unwillkürliche Beinbewegungen beim Einschlafen (Restless Leg Syndrom)? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // Must not have narcolepsy
  {
    id: "ENTR_07",
    mandatory: true,
    plainTitle: "Narcolepsy",    
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
        Does your child have excessive daytime sleepiness (Narcolepsy)?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
        Do you have excessive daytime sleepiness (Narcolepsy)?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Hat Ihr Kind einen erhöhten Schlafdrang am Tag (Narkolepsie)? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Hast Du einen erhöhten Schlafdrang am Tag (Narkolepsie)? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie einen erhöhten Schlafdrang am Tag (Narkolepsie)? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  // Must sleep 6.5 - 9h
  {
    id: "ENTR_08",
    mandatory: true,
    plainTitle: "Sleep duration workdays",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How long does your child sleep on <b>schooldays</b> on average per night?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How long do you sleep on <b>school or workdays</b> on average per night?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie lange schläft Ihr Kind an <b>Schultagen</b> durchschnittlich pro Nacht?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie lange schläfst Du an <b>Arbeits- oder Schultagen</b> durchschnittlich pro Nacht?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie lange schlafen Sie an <b>Arbeits- oder Schultagen</b> durchschnittlich pro Nacht?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 9 && Number(s.text) >= 6.5;
        default:
          return true;
      }
    },
    component: v => (<Input.Question onValid={v} inputType={"number"} placeholder={l({en: "hours", de: "Stunden" })} />)
  },

  // Must sleep between 21:30 and 24:00h
  {
    id: "ENTR_09",
    mandatory: true,
    plainTitle: "Sleep time workdays",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          At what time does your child normally go to sleep on <b>schooldays</b>?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          At what time do you normally go to sleep on <b>school or workdays</b>?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Um welche Zeit geht Ihr Kind an <b>Schultagen</b> in der Regel zu Bett? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Um welche Zeit gehst Du an <b>Arbeits- oder Schultagen</b> in der Regel zu Bett? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Um welche Zeit gehen Sie an <b>Arbeits- oder Schultagen</b> in der Regel zu Bett? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const t = str2Time(s.text);
          return t >= 21.5 || t == 0;
        default:
          return true;
      }
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },  

  // Must wake up between 5:30 and 9:00
  {
    id: "ENTR_10",
    mandatory: true,
    plainTitle: "Wake up time workdays",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          At what time does your child normally wake up on <b>schooldays</b>? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          At what time do you normally wake up on <b>school or workdays</b>? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Um welche Zeit steht Ihr Kind an <b>Schultagen</b> in der Regel auf? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Um welche Zeit stehst Du an <b>Arbeits- oder Schultagen</b> in der Regel auf?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Um welche Zeit stehen Sie an <b>Arbeits- oder Schultagen</b> in der Regel auf?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s:  Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const t = str2Time(s.text);
          return t >= 5.5 && t <= 9;
        default:
          return true;
      }
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },    

  // Must not feel tired in the morning
  {
    id: "ENTR_11",
    mandatory: true,
    plainTitle: "Refreshed in the morning?",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How does your child feel in the morning? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How do you feel in the morning? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie fühlt sich Ihr Kind morgens? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie fühlst Du dich morgens?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie fühlen Sie sich morgens?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer <= 1;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Rather refreshed",            de: "Eher ausgeruht" }) },
          { text: l({en: "A bit tired",          de: "Eher müde" }) },
          { text: l({en: "Tired",                de: "Müde" }) }
        ]}
      />
    )
  },  

  // Must sleep between 21:30 and 01:00h on weekends
  {
    id: "ENTR_12",
    mandatory: true,
    plainTitle: "Sleep time weekend",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          At what time does your child normally go to sleep on the <b>weekend or days off</b>  </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          At what time do you normally go to sleep on the <b>weekend or days off</b>  </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Um welche Zeit geht Ihr Kind in der Regel am <b>Wochenende oder an freien Tagen</b> zu Bett? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Um welche Zeit gehst Du in der Regel am <b>Wochenende oder an freien Tagen</b> zu Bett? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Um welche Zeit gehen Sie in der Regel am <b>Wochenende oder an freien Tagen</b> zu Bett? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const t = str2Time(s.text);
          return t >= 21.5 || t <= 1;
        default:
          return true;
      }
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },   

  // Must wake up between 5:30h and 10:00h
  {
    id: "ENTR_13",
    mandatory: true,
    plainTitle: "Wake up time weekend",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          At what time does your child normally wake up on the <b>weekend or days off</b></SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          At what time do you normally wake up on the <b>weekend or days off</b></SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Um welche Zeit steht Ihr Kind in der Regel am <b>Wochenende oder an freien Tagen</b> auf?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Um welche Zeit stehst Du in der Regel am <b>Wochenende oder an freien Tagen</b> auf?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Um welche Zeit stehen Sie in der Regel am <b>Wochenende oder an freien Tagen</b> auf?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const t = str2Time(s.text);
          return t >= 5.5 && t <= 10;
        default:
          return true;
      }
    },
    component: v => <Input.Question inputType={"time"} onValid={v} />
  },     

  // Must fall asleep within 30 minutes
  {
    id: "ENTR_14",
    mandatory: true,
    plainTitle: "Time to fall asleep",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How long does it normally take your child to fall asleep?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How long does it normally take you to fall asleep?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie lange braucht Ihr Kind gewöhnlich um einzuschlafen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie lange brauchst Du gewöhnlich um einzuschlafen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie lange brauchen Sie gewöhnlich um einzuschlafen?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 30
        default:
          return true;
      }
    },
    component: v => ( <Input.Question onValid={v} inputType={"number"} placeholder={l({en: "minutes", de: "Minuten" })} />)
  },  

  {
    id: "ENTR_15",
    mandatory: true,
    plainTitle: "Subejctive feeling about falling asleep time",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child find it rather long or rather fast?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you find it rather long or rather fast?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie findet Ihr Kind diese Einschlafzeit?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie findest Du diese Einschlafzeit?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie finden Sie diese Einschlafzeit?</SPChoice>
        </De>
      </span>
    ),
    // isOk: (s: Radio.State) => s.answer === 0,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Rather short",         de: "Eher kurz" }) },
          { text: l({en: "Rather long",          de: "Eher lang" }) },
        ]}
      />
    )
  },    

  // Must not wake up regularly during the night
  {
    id: "ENTR_16",
    mandatory: true,
    plainTitle: "Wake up during night",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How often does your child knowingly wake up during the night?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How often do you knowingly wake up during the night?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie oft erwacht Ihr Kind wissentlich in der Nacht?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie oft erwachst Du wissentlich in der Nacht?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie oft erwachen Sie wissentlich in der Nacht?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer >= 2;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Every night",                 de: "Jede Nacht" }) },
          { text: l({en: "3-5 times a week",            de: "3-5 mal pro Woche" }) },
          { text: l({en: "1-2 times a week",            de: "1-2 mal pro Woche" }) },
          { text: l({en: "Irregular from time to time", de: "In unregelmässigen abständen" }) },
          { text: l({en: "Never",                       de: "Nie" }) },
        ]}
      />
    )
  },    

  {
    id: "ENTR_17",
    mandatory: true,
    plainTitle: "Reasons night awakenings",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          What does your child think are the reasons for the nocturnal awakenings? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          What do you think are the reasons for the nocturnal awakenings? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Was glaubt Ihr Kind, sind die Gründe für die Schlafschwierigkeiten? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Was glaubst Du sind die Gründe für Deine Schlafschwierigkeiten? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Was glauben Sie sind die Gründe für Ihre Schlafschwierigkeiten? </SPChoice>
        </De>
      </span>
    ),
    
    shouldShow: (a: Question[]) => {
      const idxWakeUp = a.findIndex(a => a.id === "ENTR_16");

      return (
        a[idxWakeUp].data !== undefined &&
        a[idxWakeUp].data["answer"] <= 3
      );  // asks if person awakes often 
    },

    component: v => <Input.Question onValid={v} inputType="string" />
  },      

  // Must fall asleep quickly again
  {
    id: "ENTR_18",
    mandatory: true,
    plainTitle: "Time to fall back asleep",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How long does it normally take your child to fall asleep after it wakes up in the night? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How long does it normally take you to fall asleep after you wake up in the night?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wenn Ihr Kind nachts erwacht, wie lange dauert es gewöhnlich, bis es wieder einschläft? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wenn Du nachts erwachst, wie lange dauert es gewöhnlich, bis Du wieder einschläfst? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wenn Sie nachts erwachen, wie lange dauert es gewöhnlich, bis Sie wieder einschlafen? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 10;
        default:
          return true;
      }
    },
    component: v => ( <Input.Question onValid={v} inputType={"number"} placeholder={l({en: "minutes", de: "Minuten" })} />)
  },   

  // Must not awake early often
  {
    id: "ENTR_19",
    mandatory: true,
    plainTitle: "Early awakenings",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How often does your child wake up early in the morning without being able to fall asleep again?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How often do you wake up early in the morning without being able to fall asleep again?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie oft erwacht Ihr Kind morgens vorzeitig, ohne wieder einschlafen zu können?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie oft erwachst Du morgens vorzeitig, ohne wieder einschlafen zu können?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie oft erwachen Sie morgens vorzeitig, ohne wieder einschlafen zu können?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer >= 2;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Every morning",               de: "Jeden Morgen" }) },
          { text: l({en: "3-5 times a week",            de: "3-5 mal pro Woche" }) },
          { text: l({en: "1-2 times a week",            de: "1-2 mal pro Woche" }) },
          { text: l({en: "Irregular from time to time", de: "In unregelmässigen abständen" }) },
          { text: l({en: "Never",                       de: "Nie" }) },
        ]}
      />
    )
  },   


  {
    id: "ENTR_20",
    plainTitle: "Reasons early awakenings",
    mandatory: true,
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          What does your child think cause these early morning awakening? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          What do you think cause these early morning awakening? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Was glaubt Ihr Kind, sind die Gründe für das vorzeitige Erwachen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Was glaubst Du sind die Gründe für das vorzeitige Erwachen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Was glauben Sie sind die Gründe für das vorzeitige Erwachen?</SPChoice>
        </De>
      </span>
    ),
    
    shouldShow: (a: Question[]) => {
      const idxEarlyWake = a.findIndex(a => a.id === "ENTR_19");

      return (
        a[idxEarlyWake].data !== undefined &&
        a[idxEarlyWake].data["answer"] <= 3
      );  // asks if person awakes early often
    },
    
    component: v => <Input.Question onValid={v} inputType="string" />
  },   

  // Must not nap during the day
  {
    id: "ENTR_21",
    mandatory: true,
    plainTitle: "Naps",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How often does your child have a nap during the day?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How often do you have a nap during the day?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie häufig schläft Ihr Kind über den Mittag?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie häufig schläfst Du über den Mittag?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie häufig schlafen Sie über den Mittag?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer >= 3;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Every day",                   de: "Jeden Tag" }) },
          { text: l({en: "3-5 times a week",            de: "3-5 mal pro Woche" }) },
          { text: l({en: "1-2 times a week",            de: "1-2 mal pro Woche" }) },
          { text: l({en: "From time to time",           de: "Gelegentlich" }) },
          { text: l({en: "Never",                       de: "Nie" }) },
        ]}
      />
    )
  }, 

  // Must sleep 6.5 to 9h on average
  {
    id: "ENTR_22",
    mandatory: true,
    plainTitle: "Sleep duration",
    tip: (
      <span>
        <En>Decimal numbers are possible.</En>
        <De>Kommazahlen sind möglich.</De> 
      </span>
    ),    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How long does your child sleep on average?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How long do you sleep on average?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie lange schläft Ihr Kind durchschnittlich? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie lange schläfst Du durchschnittlich? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie lange schlafen Sie durchschnittlich? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 9 && Number(s.text) >= 6.5;
        default:
          return true;
      }
    },
    component: v => ( <Input.Question onValid={v} inputType={"number"} placeholder={l({en: "hours", de: "Stunden" })} />)
  },    

  // Must not work at night or shifts
  {
    id: "ENTR_23",
    mandatory: true,
    plainTitle: "Work schedule",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          What are your childs school time? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          What are your working hours / study period / school time? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie sind die Schulzeiten Ihres Kindes? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie sind Deine Arbeitszeiten / Studienzeiten / Schulzeiten?  </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie sind Ihre Arbeitszeiten / Studienzeiten / Schulzeiten?  </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer <= 3 || s.answer >= 6;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Full-time (all day)",  de: "Ganztags" }) },
          { text: l({en: "Mornings",             de: "Vormittags" }) },
          { text: l({en: "Afternoon",            de: "Nachmittags" }) },
          { text: l({en: "Evenings",             de: "Abends" }) },
          { text: l({en: "Nights",               de: "Nachts" }) },
          { text: l({en: "Shift work",           de: "Schichtarbeit" }) },
          { text: l({en: "Irregular",            de: "Unregelmässig" }) },
          { text: l({en: "Other",                de: "Anders" }),
            extraInput: "string"}                  
        ]}
      />
    )
  },   

  // Must not have serious injuries
  {
    id: "ENTR_24",
    mandatory: true,
    plainTitle: "Serious injuries",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Has our child ever been seriously ill (hospitalizations, accidents, head injuries (e.g. traumatic brain injury, ...), convulsions, etc.)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Have you ever been seriously ill (hospitalizations, accidents, head injuries (e.g. traumatic brain injury, ...), convulsions, etc.)?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          War Ihr Kind je ernsthaft krank (Hospitalisationen, Unfälle, Kopfverletzungen (z. B. Schädelhirntrauma, ...), Krampfanfälle, etc.)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Warst Du je ernsthaft krank (Hospitalisationen, Unfälle, Kopfverletzungen (z. B. Schädelhirntrauma, ...), Krampfanfälle, etc.)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Waren Sie je ernsthaft krank (Hospitalisationen, Unfälle, Kopfverletzungen (z. B. Schädelhirntrauma, ...), Krampfanfälle, etc.)?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === 1;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
            placeHolder:  l({ en: "Please specify in detail", de: "Bitte erläutern Sie" }),
            extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },   

  // ENTR_25 does not exist :)
  // Numbers now correspond to the numbers in the PDF

  // Must not have serious health conditions
  {
    id: "ENTR_26",
    mandatory: true,
    plainTitle: "Complaints",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Is your child suffering from any of these health conditions? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Are you suffering from any of these health conditions? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Leidet Ihr Kind zurzeit oder litt es an einer oder an mehreren der genannten Beschwerden? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Leidest Du zurzeit oder littst Du an einer oder an mehreren der genannten Beschwerden?  </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Leiden Sie zurzeit oder litten Sie an einer oder an mehreren der genannten Beschwerden? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: MultipleChoice.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (Number(s.answers[0]) !== 1 &&
                  Number(s.answers[1]) !== 1 &&
                  Number(s.answers[3]) !== 1 &&
                  Number(s.answers[4]) !== 1 &&
                  Number(s.answers[5]) !== 1);
        default:
          return true;
      }
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        includeDone={true}
        options={[
          { text: l({en: "Cardiovascular diseases",         de: "Herz-Kreislauf-Krankheiten" }) },
          { text: l({en: "Headache (migraine)",             de: "Kopfweh (Migräne)" }) },
          { text: l({en: "Epilepsy",                        de: "Epilepsie" }) },
          { text: l({en: "Depression",                      de: "Depressionen" }) },
          { text: l({en: "general mental health problems",  de: "allg. psychische Probleme" }) },
          { text: l({en: "Gastrointestinal complaints",     de: "Magen-Darm Beschwerden" }) },
          { text: l({en: "Hay fever",                       de: "Heuschnupfen" }) },
          { text: l({en: "Allergies",                       de: "Allergien" }) },
          { text: l({en: "Asthma",                          de: "Asthma" }) },
          { text: l({en: "Other",                           de: "Andere" }),
            extraInput: "string"},
        ]}
      />
    )
  },   

  {
    id: "ENTR_27",
    mandatory: true,
    plainTitle: "Allergies",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child have any allergies?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you have any allergies?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Hat Ihr Kind Allergien?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Hast Du Allergien?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie Allergien?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
            placeHolder:  l({ en: "Please specify in detail", de: "Bitte erläutern Sie" }),
            extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },   

  // Must not have skin alleries
  {
    id: "ENTR_28",
    mandatory: true,
    plainTitle: "Skin allergy",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child have any skin allergies or skin sensitivities?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you have any skin allergies or skin sensitivities?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Besitzt Ihr Kind eine Hautallergie/ Überempfindliche Haut?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Besitzt Du eine Hautallergie/ Überempfindliche Haut?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Besitzen Sie eine Hautallergie/ Überempfindliche Haut?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === 1;
        default:
          return true;
      }
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
            placeHolder:  l({ en: "Please specify in detail", de: "Bitte erläutern Sie" }),
            extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  },       

  // Must not have mental illnesses in family
  {
    id: "ENTR_29",
    plainTitle: "Mental illnes in family",
    mandatory: true,
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Has anyone in your family been diagnosed with a mental illness?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Has anyone in your family been diagnosed with a mental illness?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Gibt es in Ihrer Familie psychische Erkrankungen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Gibt es in Deiner Familie psychische Erkrankungen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Gibt es in Ihrer Familie psychische Erkrankungen?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },   

  // Must not be in medical care
  {
    id: "ENTR_30",
    plainTitle: "Ongoing medication",
    mandatory: true,
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Is your child currently under medical care?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Are you currently under medical care?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wird Ihr Kind zurzeit medizinisch behandelt?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wirst Du zurzeit medizinisch behandelt?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Werden Sie zurzeit medizinisch behandelt?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },   

  {
    id: "ENTR_31",
    plainTitle: "Reason for ongoing treatment",
    mandatory: true,
    title: (
      <span>
        <En>What is the reason for treatment?</En>
        <De>Was ist der Behandlungsgrund?</De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxTreatment = a.findIndex(a => a.id === "ENTR_30");

      return (
        a[idxTreatment].data !== undefined &&
        a[idxTreatment].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person is currently in treatment
    },

    component: v => <Input.Question onValid={v} inputType="string" />
  },   
  
  // Must not take those medications
  {
    id: "ENTR_32",
    mandatory: true,
    plainTitle: "Current medication",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child use any of these medications?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you use any of these medications?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Benötigt Ihr Kind zurzeit eines oder mehrere der genannten Medikamente?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Benötigst Du zurzeit eines oder mehrere der genannten Medikamente?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Benötigen Sie zurzeit eines oder mehrere der genannten Medikamente?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: MultipleChoice.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (Number(s.answers[1]) !== 1 &&
                  Number(s.answers[2]) !== 1 &&
                  Number(s.answers[3]) !== 1 &&
                  Number(s.answers[4]) !== 1 &&
                  Number(s.answers[5]) !== 1 &&
                  Number(s.answers[6]) !== 1);
        default:
          return true;
      }
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        includeDone={true}
        options={[
          { text:        l({en: "painkiller",      de: "Schmerzmittel" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "sleep medication",de: "Schlafmittel" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "sedatives",       de: "Beruhigungsmittel" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "antidepressants", de: "Antidepressiva" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "antihistamines",  de: "Antihistaminika" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "melatonin",       de: "Melatonin" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "valerian",        de: "Baldrian" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "cardiac medications",    de: "Herz-Kreislauf Medikamente" }),
            placeHolder: l({en: "Dose",            de: "Dosis" }),
            extraInput: "string"},
          { text:        l({en: "Other",           de: "Andere" }),
            placeHolder: l({en: "Name + Dose",     de: "Name + Dosis" }),
            extraInput: "string"},
        ]}
      />
    )
  },   

  // Must not consume a lot of caffeine
  {
    id: "ENTR_34",
    mandatory: true,
    plainTitle: "Caffeine consumption frequency",
    title: (
      <span>
        <En> 
          All of the following beverages contain caffeine.<br/>             
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How much of each beverage does your child consume per day? Please provide an answer for each beverage. <br/>
          If your child consumes something not listed, please choose the beverage in the list most similar to what it consumes.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How much of each beverage do you consume per day? Please provide an answer for each beverage. <br/>
          If you consume something not listed, please choose the beverage in the list most similar to what you consume.</SPChoice>
        </En>

        <De>
          Alle folgenden Getränke enthalten Koffein.<br/>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie viel konsumiert Ihr Kind jeweils pro Tag? Bitte antworten Sie für jedes Getränk. <br/>
          Falls Ihr Kind etwas konsumiert was nicht gelistet ist, wählen Sie bitte das ähnlichste Getränk in der Liste aus.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie viel konsumierst Du jeweils pro Tag? Bitte antworte für jedes Getränk.<br/>
          Falls Du etwas konsumiert was nicht gelistet ist, wähle bitte das ähnlichste Getränk in der Liste aus.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie viel konsumieren Sie jeweils pro Tag? Bitte antworten Sie für jedes Getränk.<br/>
          Falls Sie etwas konsumieren was nicht gelistet ist, wählen Sie bitte das ähnlichste Getränk in der Liste aus.</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: RadioTable.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const notExtremes =       
            !s.answers.includes(6) && // excludes extreme quantities of alcohol; 
            s.answers[0] <= 3 && // Coffee
            s.answers[1] <= 3 && // Espresso
            s.answers[9] <= 3;   // Red bul
          const highCaffeineBeverages = s.answers[0] + s.answers[1] + s.answers[9];
          const lowCaffeineBeverages  = s.answers[2] + s.answers[3] + s.answers[4] + s.answers[5] + s.answers[6] + s.answers[7] + s.answers[8];
          const Total = s.answers.reduce((a, b) => a + b, 0); // this sums all the numbers in an array
          return notExtremes && highCaffeineBeverages <= 3 && lowCaffeineBeverages <= 6; 
        default:
          return true;
      }
    }, // number in brackets is the subquestion index, the other number refers to the answer option index
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "Coffee (200ml ~ 1 cup)",        de: "Kaffee (200ml ~ 1 Tasse)" }),
          l({ en: "Espresso (25ml ~ 1 cup)",       de: "Espresso (25ml ~ 1 Tasse)" }),
          l({ en: "Black tea (200ml ~ 1 cup)",     de: "Schwarzer Tee (200ml ~ 1 Tasse)" }),
          l({ en: "Green tea (200ml ~ 1 cup)",     de: "Grüner Tee (200ml ~ 1 Tasse)" }),
          l({ en: "Hot chocolate (200ml ~ 1 cup)", de: "Heisse Schokolade (200ml ~ 1 Tasse)" }),
          l({ en: "Cola (33cl ~ 1 glass)",         de: "Cola (33cl ~ 1 Glass)" }),
          l({ en: "Mate (33cl ~ 1 glass)",         de: "Mate (33cl ~ 1 Glass)" }),
          l({ en: "Iced tea (33cl ~ 1 glass)",     de: "Eistee (33cl ~ 1 Glass)" }),
          l({ en: "Guarana  (33cl ~ 1 glass)",     de: "Guarana  (33cl ~ 1 Glass)" }),
          l({ en: "Energy drink (25cl ~ 1 glass)", de: "Energy drink (25cl ~ 1 Glass)" })

        ]}
        options={[
          l({ en: "<1 unit per day",   de: "<1 Einheit pro Tag" }),
          l({ en: "1 unit per day",     de: "1 Einheit pro Tag" }),
          l({ en: "2 units per day",    de: "2 Einheiten pro Tag" }),
          l({ en: "3 units per day",    de: "3 Einheiten pro Tag" }),
          l({ en: "4 units per day",    de: "4 Einheiten pro Tag" }),
          l({ en: "5 units per day",    de: "5 Einheiten pro Tag" }),
          l({ en: "> 5 units per day",  de: "> 5 Einheiten pro Tag" }),
        ]}
      />
    )
  },

  {
    id: "ENTR_35",
    mandatory: true,
    plainTitle: "Caffein in the evening",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child drink caffeinated beverages (including coffee, black tea, green tea, cola,
            Red Bull, iced tea, hot chocolate, mate) also in the evening?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you drink caffeinated beverages (including coffee, black tea, green tea, cola,
            Red Bull, iced tea, hot chocolate, mate) also in the evening?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Trinkt Ihr Kind koffeinhaltige Getränke (wie etwa in Kaffee, schwarzer und grüner Tee,
            Energy Drinks, Cola, Eistee, heisse Schokolade, Mate) auch abends?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Trinkst Du koffeinhaltige Getränke (wie etwa in Kaffee, schwarzer und grüner Tee,
            Energy Drinks, Cola, Eistee, heisse Schokolade, Mate) auch abends?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Trinken Sie koffeinhaltige Getränke (wie etwa in Kaffee, schwarzer und grüner Tee,
            Energy Drinks, Cola, Eistee, heisse Schokolade, Mate) auch abends?</SPChoice>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },  
  
  {
    id: "ENTR_36",
    mandatory: true,
    plainTitle: "Trouble falling asleep after caffein",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child have trouble falling asleep after consuming caffeine?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you have trouble falling asleep after consuming caffeine?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Hat Ihr Kind nach Koffeingenuss Einschlafschwierigkeiten?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Hast Du nach Koffeingenuss Einschlafschwierigkeiten?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie nach Koffeingenuss Einschlafschwierigkeiten?</SPChoice>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },    

  {
    id: "ENTR_37",
    mandatory: true,
    plainTitle: "Certain Foods and diats",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Are there certain foods that your child does not eat (because you are vegetarian, you are on a special diet, you have a food allergy)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Are there certain foods that you do not eat (because you are vegetarian, you are on a special diet, you have a food allergy)?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Gibt es gewisse Nahrungsmittel, die Ihr Kind nicht isst (z. B. weil vegan, vegetarisch, spezielle Diäten, Lebensmittelallergie)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Gibt es gewisse Nahrungsmittel, die Du nicht isst (z. B. weil vegan, vegetarisch, spezielle Diäten, Lebensmittelallergie)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Gibt es gewisse Nahrungsmittel, die Sie nicht essen (z. B. weil vegan, vegetarisch, spezielle Diäten, Lebensmittelallergie)?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
            placeHolder:  l({ en: "Please specify", de: "Bitte erläutern Sie" }),
            extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) }
        ]}
      />
    )
  }, 

  // ENTR_38 doesn't exist :)
  // Numbers now correspond to the PDF
  
  {
    id: "ENTR_39",
    mandatory: true,
    plainTitle: "Smoking habits",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          To guarantee the safety of your child throughout the course of the study, we need to know whether there is a chance that your child drinks alcohol,
          smokes, vapes (e-cigarettes), consumes medication or other drugs?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you smoke, vape (e-cigarettes) or use nicotine patches?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Um die Sicherheit Ihres Kindes in der Studie zu gewährleisten, müssen wir wissen ob die Möglichkeit besteht, 
          dass Ihr Kind Alkohl trinkt, raucht, vapet (E-Zigarette), oder Medikamente bzw. andere Drogen konsumiert?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bist Du Raucher, vapest Du (E-Zigarette) oder benutzt Du Nikotinpflaster?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Sind Sie Raucher, vapen Sie (E-Zigarette) oder benutzen Sie Nikotinpflaster?</SPChoice>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },  

  // Must not smoke a lot
  {
    id: "ENTR_40",
    mandatory: true,
    plainTitle: "Smoking frequency",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How many days per week do you smoke, vape (e-cigarettes), or use nicotine patches?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          An wie vielen Tagen pro Woche rauchst, vapest oder nutzt Du Nikotinpflaster?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          An wie vielen Tagen pro Woche rauchen, vapen oder nutzen Sie Nikotinpflaster?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 4;
        default:
          return true;
      }
    },
    shouldShow: (a: Question[]) => {
      const idxSmoking = a.findIndex(a => a.id === "ENTR_39");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxSmoking].data !== undefined &&
                  a[idxSmoking].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
        }
      },// asks if person is smoking
    component: v => (<Input.Question onValid={v} inputType={"number"} placeholder={l({en: "Days",de: "Tage" })} />)
  },  

  {
    id: "ENTR_41",
    mandatory: true,
    plainTitle: "Cannabis habits",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you consume cannabis?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Konsumierst Du Cannabis?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Konsumieren Sie Cannabis?</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return true;
        default:
          return false;
      }
    },// only visible for HealthyAdults
    component: v => <YesNoQ.Question onValid={v} />
  },  

  // Must not smoke pot a lot
  {
    id: "ENTR_42",
    mandatory: true,
    plainTitle: "Cannabis frequency",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          On how many days per month do you consume cannabis?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          An wie vielen Tagen pro Monat konsumierst Du Cannabis?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          An wie vielen Tagen pro Monat konsumieren Sie Cannabis?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 4;
        default:
          return true;
      }
    },
    shouldShow: (a: Question[]) => {
      const idxCannabis = a.findIndex(a => a.id === "ENTR_39");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxCannabis].data !== undefined &&
                  a[idxCannabis].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
      }
    },// asks if person is smoking cannabis
  
    component: v => (<Input.Question onValid={v} inputType={"number"} placeholder={l({en: "Days",de: "Tage" })} />)
  },  

  {
    id: "ENTR_43",
    mandatory: true,
    plainTitle: "Other drugs habits",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you consume any other drug such as MDMA, ecstasy, amphetamines, LSD,
          psilocybin (magic mushrooms), steroids, speed, cocaine, methamphetamine (Crystal),
          heroin, methadone, bath salts (Meph), G, Ketamine, liquid ecstasy (GHB, GBL, G),
          benzodiazepines, opium, etc.?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Konsumierst Du andere Drogen wie etwa MDMA, Ecstasy, Amphetamine, LSD,
          psilocybinhaltige Pilze (magic mushroom), Steroide, Speed, Kokain, Methamphetamin (Crystal),
          Heroin, Methadon, Badesalze (Meph), G, Ketamin, Liquid Ecstasy (GHB, GBL, G), Benzodiazepine,
          Opium, oder ähnliches?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Konsumieren Sie andere Drogen wie etwa MDMA, Ecstasy, Amphetamine, LSD,
          psilocybinhaltige Pilze (magic mushroom), Steroide, Speed, Kokain, Methamphetamin (Crystal),
          Heroin, Methadon, Badesalze (Meph), G, Ketamin, Liquid Ecstasy (GHB, GBL, G), Benzodiazepine,
          Opium, oder ähnliches?</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return true;
        default:
          return false;
      }
    },// only visible for HealthyAdults
    component: v => <YesNoQ.Question onValid={v} />
  },  

  // Must not consume other drugs frequently
  {
    id: "ENTR_44",
    mandatory: true,
    plainTitle: "Other drugs frequency",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          On how many days per year do you consume any other drug?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          An wie vielen Tagen pro Jahr konsumierst Du andere Drogen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          An wie vielen Tagen pro Jahr konsumieren Sie andere Drogen?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 6;
        default:
          return true;
      }
    },
    shouldShow: (a: Question[]) => {
      const idxOtherDrugs = a.findIndex(a => a.id === "ENTR_43");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxOtherDrugs].data !== undefined &&
                  a[idxOtherDrugs].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
      }
    }, // asks if person is taking other drugs
    component: v => (<Input.Question onValid={v} inputType={"number"} placeholder={l({en: "Days",de: "Tage" })} />)
  },  

  {
    id: "ENTR_45",
    mandatory: true,
    plainTitle: "List of drugs consumed",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Please list which drugs:</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bitte erläutere welche Drogen:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Bitte erläutern Sie welche Drogen:</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxOtherDrugs = a.findIndex(a => a.id === "ENTR_43");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxOtherDrugs].data !== undefined &&
                  a[idxOtherDrugs].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
      }
    }, // asks if person is taking other drugs
    component: v => <Input.Question onValid={v} inputType="string" />
  }, 
  
  {
    id: "ENTR_46",
    mandatory: true,
    plainTitle: "Medication use",    
    tip: (
      <span>
        <En>For this questionnaire, we do not consider contraception as a medical drug.</En>
        <De>Für diesen Fragebogen werden Verhütungsmittel (z. B. Pille) nicht als Medikament angesehen.</De> 
      </span>
    ),          
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you use any medical drugs?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Konsumierst Du Medikamente?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Konsumieren Sie Medikamente?</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return true;
        default:
          return false;
      }
    },// only visible for HealthyAdults
    //isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },  

  // Must not use medication a lot
  {
    id: "ENTR_47",
    mandatory: true,
    plainTitle: "Medication frequency",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          On how many days per month do you consume medical drugs?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          An wie vielen Tagen pro Monat konsumierst Du Medikamente?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          An wie vielen Tagen pro Monat konsumieren Sie Medikamente?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: Input.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return Number(s.text) <= 4;
        default:
          return true;
      }
    },
    shouldShow: (a: Question[]) => {
      const idxMedDrugs = a.findIndex(a => a.id === "ENTR_46");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxMedDrugs].data !== undefined &&
                  a[idxMedDrugs].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
      }
    },// asks if person is taking medication
    component: v => (<Input.Question onValid={v} inputType={"number"} placeholder={l({en: "Days",de: "Tage" })} />)
  },  

  {
    id: "ENTR_48",
    mandatory: true,
    plainTitle: "Medication listed",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Please list which medical drugs:</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bitte erläutere welche Medikamente:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Bitte erläutern Sie welche Medikamente:</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxMedDrugs = a.findIndex(a => a.id === "ENTR_46");
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return (a[idxMedDrugs].data !== undefined &&
                  a[idxMedDrugs].data["answer"] === YesNoQ.AnswerType.yes);
        default:
          return false;
      }
    },// asks if person is taking medication
    component: v => <Input.Question onValid={v} inputType="string" />
  },
  
  // Must not consume alcohol a lot
  {
    id: "ENTR_49",
    mandatory: true,
    plainTitle: "Alcohol consumption frequency",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How many alcoholic beverages (beer, wine, Schnapps, liquor) do you usually drink? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          NOT ASKED</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie viel Alkohol (Bier, Wein, Schnaps, Likör) trinkst Du normalerweise? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie viel Alkohol (Bier, Wein, Schnaps, Likör) trinken Sie normalerweise? </SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return true;
        default:
          return false;
      }
    },// only visible for HealthyAdults

    isOk: (s: RadioTable.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          const notExtremes = !s.answers.includes(5) && !s.answers.includes(6) // excludes extreme quantities of alcohol; 
          const Total       = s.answers.reduce((a, b) => a + b, 0);            // this sums all the numbers in an array
          return notExtremes && Total <= 12; 
        default:
          return true;
      }
    },
      // !s.answers.includes(5) && !s.answers.includes(6), // excludes extreme quantities of alcohol; 
      // // && s.answers[2] <= 3 && s.answers[3] <= 3 // number in brackets is the subquestion index, the other number refers to the answer option index
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          l({ en: "Beer (3dl)",      de: "Bier (3dl)" }),
          l({ en: "Wine (1.5dl)",    de: "Wein (1.5dl) " }),
          l({ en: "Schnapps (25ml)", de: "Schnaps (25ml)" }),
          l({ en: "Liquor (25ml)",   de: "Likör (25ml)" })
        ]}
        options={[
          l({ en: "Not a drop",            de: "Keinen Tropfen " }),
          l({ en: "< 1 drink per week",    de: "< 1 Glas pro Woche" }),
          l({ en: "1-2 drinks per week",   de: "1-2 Gläser pro Woche" }),
          l({ en: "3-5 drinks per week",   de: "3-5 Gläser pro Woche" }),
          l({ en: "6-10 drinks per week",  de: "6-10 Gläser pro Woche" }),
          l({ en: "11-15 drinks per week", de: "11-15 Gläser pro Woche" }),
          l({ en: "> 15 drinks per week",  de: "> 15 Gläser pro Woche" }),
        ]}
      />
    )
  },

  {
    id: "ENTR_50",
    mandatory: true,
    plainTitle: "Sport habits",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Does your child do any sports?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you do any sports?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Treibt Ihr Kind Sport?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Treibst Du Sport?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Treiben Sie Sport?</SPChoice>
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },  

  {
    id: "ENTR_51",
    mandatory: true,
    plainTitle: "Sport listed",    
    title: (
      <span>
        <En>Please list which sports:</En>
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Bitte erläutern Sie welchen Sport:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bitte erläutern Sie welchen Sport:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Bitte erläutern Sie welchen Sport:
          </SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxSport= a.findIndex(a => a.id === "ENTR_50");

      return (
        a[idxSport].data !== undefined &&
        a[idxSport].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person is taking other drugs
    },

    component: v => <Input.Question onValid={v} inputType="string" />
  },  

  {
    id: "ENTR_52",
    mandatory: true,
    plainTitle: "Sport frequency",    
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          How often does your child do sports?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          How often do you do sports?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wie intensiv treibt Ihr Kind Sie Sport?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wie intensiv treibst Du Sport?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wie intensiv treiben Sie Sport?</SPChoice>
        </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxSport = a.findIndex(a => a.id === "ENTR_50");

      return (
        a[idxSport].data !== undefined &&
        a[idxSport].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person is taking medication
    },

    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({en: "Never",                de: "Nie" }) },
          { text: l({en: "< 1h per week",        de: "< 1h pro Woche" }) },
          { text: l({en: "1-3h per week",        de: "1-3h pro Woche" }) },
          { text: l({en: "3-6h per week",        de: "4-6h pro Woche" }) },
          { text: l({en: "> 6h per week",        de: "> 6h pro Woche" }) },
        ]}
      />
    )
  },  

  // No time zone travelling
  {
    id: "ENTR_53",
    mandatory: true,
    plainTitle: "Travelling through timezones",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Has your child travelled across two time zones within the last 30 days? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Have you travelled across two time zones within the last 30 days? </SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Hat Ihr Kind in den letzten 30 Tagen Flugreisen unternommen, bei denen zwei Zeitzonen überflogen wurden? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Hast Du in den letzten 30 Tagen Flugreisen unternommen, bei denen zwei Zeitzonen überflogen wurden? </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie in den letzten 30 Tagen Flugreisen unternommen, bei denen zwei Zeitzonen überflogen wurden? </SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },  

  { id: "ENTR_54",
    plainTitle: "Trips through timezones listed",    
    mandatory: true,
    title: (
      <span>
        <En> Please list the start and destination of each flight: </En>
        <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Bitte geben Sie den Startort und das Reiseziel jedes Flugs an: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bitte gib den Startort und das Reiseziel jedes Flugs an: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Bitte geben Sie den Startort und das Reiseziel jedes Flugs an: </SPChoice>
          </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxTravelling = a.findIndex(a => a.id === "ENTR_53");

      return (
        a[idxTravelling].data !== undefined &&
        a[idxTravelling].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person has been travelling
    },    
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "ENTR_54_Q1",
            title: l({en: "Your trip:",   de: "Ihre Reise" }),
            component: v => (<Input.Question 
              onValid     = {v} 
              inputType   = {"text"} 
              placeholder = {l({en: "From - to",        de: "Von - nach" })} />)
          },
        ]}
      />
    )
  },   

  {
    id: "ENTR_55",
    mandatory: true,
    plainTitle: "Planned trips through timezones",   
    tip: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          We want to avoid that you and your child have travels planned during the period of the experiment.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          We want to avoid that you have travels planned during the period of the experiment.</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wir wollen vermeiden, dass Sie und Ihr Kind während der Studienzeit weitere Flugreisen geplant haben.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wir wollen vermeiden, dass Du während der Studienzeit weitere Flugreisen geplant hast.</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wir wollen vermeiden, dass Sie während der Studienzeit weitere Flugreisen geplant haben.</SPChoice>
        </De>
      </span>
    ),     
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Do you and your child plan to travel to a different time zone in the nearest future?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Do you plan to travel to a different time zone in the nearest future?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Planen Sie und Ihr Kind in nächster Zeit Flugreisen, bei denen Zeitzonen überflogen werden?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Planst Du in nächster Zeit Flugreisen, bei denen Zeitzonen überflogen werden?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Planen Sie in nächster Zeit Flugreisen, bei denen Zeitzonen überflogen werden?</SPChoice>
        </De>
      </span>
    ),
    //isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },  

  { id: "ENTR_56",
    plainTitle: "Planned trips through timezones listed",    
    mandatory: true,
    title: (
      <span>
        <En>Please specify when and where to:</En>
        <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Bitte erläutern Sie wann und wohin:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Bitte erläutere wann und wohin:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Bitte erläutern Sie wann und wohin:</SPChoice>
          </De>
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxTravellingFuture = a.findIndex(a => a.id === "ENTR_55");

      return (
        a[idxTravellingFuture].data !== undefined &&
        a[idxTravellingFuture].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person has been travelling
    },    
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "ENTR_54_Q1",
            title: l({en: "Your trip:",   de: "Ihre Reise" }),
            component: v => (<Input.Question 
              onValid     ={v} 
              inputType   ={"text"} 
              placeholder ={l({en: "Date: from - to",        de: "Datum: von - nach" })} />)
          },
        ]}
      />
    )
  },    

  
  // No learning disorder
  {
    id: "ENTR_57",
    mandatory: true,
    plainTitle: "learning disorder ",    
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Is your child suffering or has ever suffered of learning disorders / learning disabilities?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Are you suffering or have you ever suffered of learning disorders / learning disabilities?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Leidet Ihr Kind an einer Lernschwäche oder Lernstörung?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Leidest Du an einer Lernschwäche oder Lernstörung?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Leiden Sie an einer Lernschwäche oder Lernstörung?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  {
    id: "ENTR_58",
    mandatory: true,
    plainTitle: "Learning disorder in family",    
    title: (
      <span>
         <En>
          Has anyone in your family been diagnosed with a learning disorders / learning disability?
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Gibt es in Ihrer Familie Lernschwächen oder Lernstörungen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Gibt es in Deiner Familie Lernschwächen oder Lernstörungen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Gibt es in Ihrer Familie Lernschwächen oder Lernstörungen?</SPChoice>
        </De>
      </span>
    ),
    // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },    

  //  No autism, etc.
  {
    id: "ENTR_59",
    mandatory: true,
    plainTitle: "Autism, ADHD etc.",    
    title: (
      <span>
         <En>
         <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Has your child ever been diagnosed with autism, speech disorder, attention disorder or mental disability?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Have you ever been diagnosed with autism, speech disorder, attention disorder or mental disability?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Wurde bei Ihrem Kind jemals die Diagnose Autismus, Sprachstörung, Aufmerksamkeitsstörung oder geistige Behinderung festgestellt?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Wurde bei Dir jemals die Diagnose Autismus, Sprachstörung Aufmerksamkeitsstörung oder geistige Behinderung festgestellt?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Wurde bei Ihnen jemals die Diagnose Autismus, Sprachstörung Aufmerksamkeitsstörung oder geistige Behinderung festgestellt?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    

  //  No special needs school
  {
    id: "ENTR_60",
    mandatory: true,
    plainTitle: "Special-needs school",    
    title: (
      <span>
         <En>
         <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
         Is your child attending a special-needs school an inclusive education system to meet his or her special needs? 
         Or has it ever been the case in the past?</SPChoice>
         <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens, StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
         Have you attended a special-needs school or an inclusive education system to meet your special needs?</SPChoice>
        </En>

        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
          Besucht Ihr Kind eine heilpädagogische Schule, Sprachheilschule oder wird es integrativ beschult? 
          Oder war dies jemals in der Vergangenheit der Fall?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens, StudyPopulation.EpiTeens]}>
          Haben Sie eine heilpädagogische Schule oder Sprachheilschule besucht oder wurden Sie integrativ beschult?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults, StudyPopulation.EpiAdults]}>
          Haben Sie eine heilpädagogische Schule oder Sprachheilschule besucht oder wurden Sie integrativ beschult?</SPChoice>
        </De>
      </span>
    ),
    isOk: (s: YesNoQ.State) => {
      switch(studyPopulation){
        case StudyPopulation.HealthyAdults:
          return s.answer === YesNoQ.AnswerType.no;
        default:
          return true;
      }
    },
    component: v => <YesNoQ.Question onValid={v} />
  },    
];

export default questions;
