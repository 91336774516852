import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l, LanguageContext } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import * as Slider from "ewoq/prototypes/Slider";

import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as Input from "ewoq/prototypes/TypeInput";



const questions: QuestionType[] = [

  {
    id: "Att-1",
    plainTitle: "Tiredness",
    mandatory: true,
    title: (
      <span>
        <En>
          How tired
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> is your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} >are you </SPChoice>
          at the moment? 
        </En>

      <De> 
        Wie müde
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> ist Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> bist Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> sind Sie </SPChoice>
        im Moment? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "not at all, completely awake", de: "überhaupt nicht müde, vollkommen wach" }), 
              l({ en: "very tired, close to falling asleep", de: "sehr müde, nahe beim Einschlafen" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  },
  {
    id: "Att-2",
    plainTitle: "Mood",
    mandatory: true,
    title: (
      <span>
        <En>
          How is 
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> your child's </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} > your </SPChoice>
          mood at the moment? 
        </En>
  
      <De> 
        Wie ist 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> die Stimmung Ihres Kindes </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> deine Stimmung </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> Ihre Stimmung </SPChoice>
        im Moment? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "very bad", de: "sehr schlecht gestimmt" }), 
              l({ en: "very good", de: "sehr gut gestimmt" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  },
  {
    id: "Att-3",
    plainTitle: "Energy",
    mandatory: true,
    title: (
      <span>
        <En>
          How energetic
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> does your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} >do you </SPChoice>
          feel at the moment? 
        </En>
  
      <De> 
        Wie energiegeladen 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> fühlt sich Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> fühlst Du dich </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> fühlen Sie sich </SPChoice>
        im Moment? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "full of energy", de: "voller Energie" }), 
              l({ en: "dull, apathetic, lethargic", de: "energielos, lustlos, leer" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  },
  {
    id: "Att-4",
    plainTitle: "Calmness",
    mandatory: true,
    title: (
      <span>
        <En>
          How calm and composed
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> is your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} >are you </SPChoice>
          at the moment? 
        </En>
  
      <De> 
        Wie ruhig und gelassen 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> fühlt sich Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> fühlst Du dich </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> fühlen Sie sich </SPChoice>
        im Moment? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "completely calm and composed", de: "vollkommen ruhig und gelassen" }), 
              l({ en: "very restless and tense", de: "sehr unruhig und angespannt" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  },
  {
    id: "Att-5",
    plainTitle: "Focus",
    mandatory: true,
    title: (
      <span>
        <En>
          How focused
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}> is your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]} >are you </SPChoice>
          at the moment? 
        </En>
  
      <De> 
        Wie konzentriert
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> ist Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> bist Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults,StudyPopulation.NotChosen]}> sind Sie </SPChoice>
        im Moment? 
      </De> 
      </span>
    ),    
    component: v => (
        <Slider.Question
          onValid={v}
          labels={[
              l({ en: "very distracted", de: "sehr unkonzentriert" }), 
              l({ en: "very focused", de: "sehr konzentriert" })  ]}
          sliderType={Slider.SliderType.horizontal}
        />
      )
  }
];

export default questions;


