import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";

const questions: QuestionType[] = [
  {
    id: "SR_FEE_1.1",
    mandatory: true,
    plainTitle: "Yesterday's mood:",
    title: (<span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Please indicate overall your childs mood over the past day:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Please indicate overall your mood over the past day:</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Bitte beschreiben Sie di allgemeine Laune Ihres Kindes über den gestrigen Tag hinweg:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bitte beschreibe deine allgemeine Laune über den gestrigen Tag hinweg:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Bitte beschreiben Sie Ihre allgemeine Laune über den gestrigen Tag hinweg:</SPChoice>
    </De>
     
      </span>),
    component: v => feelings(v)
  },
  {
    id: "SR_FEE_1.2",
    mandatory: true,
    title: (<span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child feel...</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you feel...</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Fühlte sich Ihr Kind...</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Fühltest du dich...</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Fühlten Sie sich...</SPChoice>
    </De>
      </span>),
    plainTitle: "Yesterday's stress:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Relaxed",  de: "Entspannt" }),
          l({ en: "Tense",    de: "Angespannt" }),
          l({ en: "Stressed",  de: "Gestresst" }) ]}
      />
    )
  },
  {
    id: "SR_FEE_1.3",
    mandatory: false,
    plainTitle: "Extra feelings yesterday:",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Check all that characterize a significant amount of your childs day:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Check all that characterize a significant amount of your day:</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Markieren Sie alles, was einen signifikanten Teil des Tages Ihres Kindes charakterisiert:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Markiere alles, was einen signifikanten Teil Deines Tages charakterisiert:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Markieren Sie alles, was einen signifikanten Teil Ihres Tages charakterisiert:</SPChoice>
    </De></span>
    ),
    component: v => extraFeelings(v)
  },
  {
    id: "SR_FEE_2",
    mandatory: false,
    plainTitle: "Yesterday's positiveness:",
    title: (<span>
    <En>
      Generally, was it a good day or bad day?
    </En>

    <De>
      Generell, war es ein guter oder schlechter Tag?
    </De>
      </span>),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Extremely negative",  de: "Extrem negativ" }),
          l({ en: "Neutral",             de: "Neutral" }),
          l({ en: "Extremely positive",   de: "Extrem positiv" })]}
      />
    )
  },
  {
    id: "SR_FEE_3",
    mandatory: true,
    plainTitle: "Yesterday's pain/sickness:",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child experience any pain or sickness yesterday?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you experience any pain or sickness yesterday?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      War Ihr Kind gesten krank oder hatte Schmerzen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Warst Du gesten krank oder hattest Schmerzen?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Waren Sie gesten krank oder hatten Schmerzen?</SPChoice>
    </De>
    </span>),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Not at all" ,                                          de: "Überhaupt nicht" })},
          { text: l({ en: "Not sure / didn't notice",                             de: "Nicht sicher / nicht bemerkt" }) },
          { text: l({ en: "Something at some point, but it went away quickly" ,   de: "Irgendwann mal etwas, aber es ging schnell wieder weg" })},
          { text: l({ en: "Yes, I felt a little pain or sickness",                de: "Ja, ich hatte ein wenig schmerzen / war ein wenig krank" }) },
          { text: l({ en: "I felt some pain or sickness" ,                        de: "Ich hatte etwas schmerzen / war etwas krank" })},
          { text: l({ en: "I felt substantial pain or sickness",                  de: "Ich hatte beträchtliche Schmerzen / war beträchtlich krank" }) }
        ]}
      />
    )
  },
  {
    id: "SR_FEE_4",
    mandatory: true,
    plainTitle: "Take any over the counter drugs?",
    title: (
      <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child take any over the counter drugs (e.g. ibuprofen)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you take any over the counter drugs (e.g. ibuprofen)?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind rezeptfreie Medikamente eingenommen (bspw. Ibuprofen)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du rezeptfreie Medikamente eingenommen (bspw. Ibuprofen)?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie rezeptfreie Medikamente eingenommen (bspw. Ibuprofen)?</SPChoice>
    </De>
    </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: l({ en: "Yes" , de: "Ja" }), extraInput: "string" },
                  { text: l({ en: "No" ,  de: "Nein" })}]}
      />
    )
  }
];
export default questions;
