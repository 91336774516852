import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_DRE_1",
    mandatory: true,
    title: (
    <span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Did your child </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Did you </SPChoice>
      dream?
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Hat Ihr Kind </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Hast Du </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Haben Sie </SPChoice>
      geträumt? 
    </De>
  </span>),
    plainTitle: "Did you dream?",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Yes",                                de: "Ja" })},
          { text: l({ en: "No",                                 de: "Nein" }) },
          { text: l({ en: "Not sure",                           de: "Nicht sicher" }) },
          { text: l({ en: "Yes, but no memoriers of it",            de: "Ja, aber keine Erinnerungen daran" }) }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_2",
    mandatory: false,
    plainTitle: "Dream description:",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Please describe your childs dreams in as much detail as it can remember: </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Please describe your dreams in as much detail as you can remember:</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Bitte beschreiben Sie die Träume Ihres Kindes mit allen Details, an die es sich erinnert: </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Bitte beschreibe deine Träume mit allen Details, an die Du Dich erinnerst:</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Bitte beschreiben Sie Ihre Träume mit allen Details, an die Sie Sich erinnern:</SPChoice>
    </De>
    </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Input.Question onValid={v} large={true} inputType="string" />
    )
  },
  {
    id: "SR_DRE_3",
    mandatory: false,
    title: (
      <span>
    <En>
      Was it a lucid dream? 
    </En>
    <De>
      War es ein luzider Traum?
    </De>
    </span>
    ),
    plainTitle: "Lucid dream?",
    explanation: (
      <span>
    <En>
      This is a dream in which you are aware you are dreaming.
    </En>
    <De>
      Das ist ein Traum, in dem man sich bewusst ist, dass man träumt.
    </De>
    </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },

  {
    id: "SR_DRE_4",
    mandatory: false,
    plainTitle: "Related to real life?",
    title: (
      <span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Was the content related to any recent experiences in your childs life?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Was the content related to any recent experiences in your life?</SPChoice>
    </En>

    <De>
      Hatte der Inhalt einen Zusammenhang zu etwas kürzlich Erlebten?
    </De>
    </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_DRE_5.1",
    mandatory: false,
    plainTitle: "Emotion level of dream:",
    title: (
      <span>
    <En>
      In general, how emotional was the dream?
    </En>

    <De>
      Generell, wie emotional war der Traum?
    </De>
    </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Completely neutral",  de: "Komplet neutral" }),
          l({ en: "Extremely emotional", de: "Extrem emotional" }),
        ]}
      />
    )
  },
  {
    id: "SR_DRE_5.2",
    mandatory: false,
    plainTitle: "Emotions in dream:",
    title: (<span>
    <En>
      What was the emotional tone during the dream?
    </En>

    <De>
      Wie war der emotionale Ton (Atmosphäre) während dem Traum?
    </De>
    </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { question: l({ en: "Happy",            de: "Fröglich" }), labels: [l({ en: "Not at all",  de: "Überhaupt nicht" }), l({ en: "Dominant",    de: "Dominant" }),] },
          { question: l({ en: "Fearful",          de: "Furchtsam" }) },
          { question: l({ en: "Angry",            de: "Wütend" }) },
          { question: l({ en: "Frustrated",       de: "Frustriert" }) },
          { question: l({ en: "Embarrassed",      de: "Verlegen" }) },
          { question: l({ en: "Confused",         de: "Verwirrt" }) },
          { question: l({ en: "Comforting",       de: "Beruhigend" }) },
          { question: l({ en: "Afraid/Desparate", de: "Ängstlich/Verzweifelt" }) },
          { question: l({ en: "Disgusted",        de: "Angeekelt" }) },
          { question: l({ en: "Sad/Sorrowful",    de: "Traurig/Betrübt" }), labels: [l({ en: "Not at all",  de: "Überhaupt nicht" }), l({ en: "Dominant",    de: "Dominant" }),] },
          { question: l({ en: "Depressed",        de: "Deprimiert" }) },
          { question: l({ en: "Hollow/empty",     de: "Leer" }) },
          { question: l({ en: "Tense/Stressed" ,  de: "Angespannt/Gestresst" })},
          { question: l({ en: "Worried" ,         de: "Besorgt" })},
          { question: l({ en: "Excited",          de: "Begeistert" }) },
          { question: l({ en: "Funny/amused",     de: "Vergnügt/Amüsiert" }) },
          { question: l({ en: "Bored",            de: "Gelangweilt" }) },
          { question: l({ en: "Lonely",           de: "Einsam/Allein" }) },
          { question: l({ en: "Busy",             de: "Beschäftigt" }) }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_5.3",
    mandatory: false,
    plainTitle: "Other emotions:",
    title: (<span>
      <En>
      Were other emotions involved?
      </En>
      <De>
      Waren noch andere Emotionen beteiligt?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <Input.Question onValid={v} inputType="string" extraRadio={["None"]} />
    )
  },
  {
    id: "SR_DRE_6",
    mandatory: false,
    plainTitle: "Senses involved:",
    title: (<span>
      <En>
      Which senses were involved?
      </En>
      <De>
      Welche Sinne waren beteiligt?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: l({ en: "Visual" ,            de: "Seh-Sinn" })},
          { text: l({ en: "Auditory" ,          de: "Hör-Sinn" })},
          { text: l({ en: "Olfactory (smell)" , de: "Geruchs-Sinn" })},
          { text: l({ en: "Taste" ,             de: "Geschmacks-Sinn" })},
          { text: l({ en: "Balance" ,           de: "Gleichgewichts-Sinn" })},
          { text: l({ en: "Other" ,             de: "Andere" }), extraInput: "string" }
        ]}
        allAbove
      />
    )
  },
  {
    id: "SR_DRE_7.1",
    mandatory: false,
    plainTitle: "Dream vividness:",
    title: (<span>
      <En>
      How vivid was the dream?
      </En>
      <De>
      Wie lebhaft/klar war der Traum?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Extremely hazy",  de: "Extrem unklar/schwammig" }),
          l({ en: "Extremely vivid", de: "Extrem klar/lebhaft" }),
         ]}
      />
    )
  },
  {
    id: "SR_DRE_7.2",
    mandatory: false,
    plainTitle: "Dream realism:",
    title: (<span>
      <En>
      How realistic was the dream?
      </En>
      <De>
      Wie realistisch war der Traum?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Extremely crazy",      de: "Extrem verrückt" }),
          l({ en: "Extremely realistic",  de: "Extrem realistisch" })]}
      />
    )
  },
  {
    id: "SR_DRE_7.3",
    mandatory: false,
    plainTitle: "Dream complexity:",
    title: (<span>
      <En>
      How complex was the dream?
      </En>
      <De>
      Wie komplex war der Traum?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Simple",             de: "Simpel" }),
          l({ en: "Extremely complex",  de: "Extrem komplex" }), ]}
      />
    )
  },
  {
    id: "SR_DRE_7.4",
    mandatory: false,
    plainTitle: "Dream pleasantness:",
    title: (<span>
      <En>
      How pleasant overall was the dream?
      </En>
      <De>
      Wie angenehm war der Traum insgesamt?
      </De>
    </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Nightmare",  de: "Albtraum" }),
          l({ en: "Wonderful",  de: "Wundervoll" })]}
      />
    )
  },
  {
    id: "SR_DRE_8.1",
    mandatory: false,
    plainTitle: "Dreamer's movement:",
    title: (<span>
    <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How much did your child move its body in the dream? </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How much did you move your body in the dream?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie viel/stark hat Ihr Kind seinen Körper bewegt im Traum?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie viel/stark hast Du Deinen Körper bewegt im Traum?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie viel/stark haben Sie Ihren Körper bewegt im Traum?</SPChoice>
    </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Completely still",     de: "Komplett still" }),
          l({ en: "Moving all the time",  de: "Die ganze Zeit bewegt" }) ]}
      />
    )
  },
  {
    id: "SR_DRE_8.2",
    mandatory: false,
    plainTitle: "Other movement in dream:",
    title: (<span>
      <En>
      How much did other things move?
      </En>
      <De>
      Wie stark haben sich andere Dinge bewegt?
      </De>
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Completely still",     de: "Komplett still" }),
          l({ en: "Moving all the time",  de: "Die ganze Zeit bewegt" }) ]}
      />
    )
  },
  {
    id: "SR_DRE_8.3",
    mandatory: false,
    plainTitle: "Travelling in dream:",
    title: (<span>
      <En>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      How much did your child travel from place to place? </SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      How much did you travel from place to place?</SPChoice>
    </En>

    <De>
      <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
      Wie häufig ist Ihr Kind von Ort zu Ort gereist?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
      Wie häufig bist Du von Ort zu Ort gereist?</SPChoice>
      <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
      Wie häufig sind Sie von Ort zu Ort gereist?</SPChoice>
    </De>
     
      </span>),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Stayed in one place", de: "Blieb an einem ort" }),
          l({ en: "Always travelling",   de: "Reiste die ganze Zeit" })  ]}
      />
    )
  }
];

export default questions;
