import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as Input from "ewoq/prototypes/TypeInput";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_AtQ_1",
    mandatory: false,
    plainTitle: "Tiredness",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie müde
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> sind Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     sind Sie     </SPChoice>             
          im Moment?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "überhaupt nicht müde, vollkommen wach" }),
              l({ en: "PLATZHALTER", de: "sehr müde, nahe beim Einschlafen" }),
            ],
            
          },
        ]}
      />
    ),
  },
  
  {
    id: "BQ_AtQ_2",
    mandatory: false,
    plainTitle: "Mood",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie ist
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   die Stimmung Ihres Kindes </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Deine Stimmung            </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ihre Stimmung             </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       die Stimmung Ihres Kindes </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ihre Stimmung             </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     sind Sie                  </SPChoice>             
          im Moment?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "sehr schlecht gestimmt" }),
              l({ en: "PLATZHALTER", de: "sehr gut gestimmt" }),
            ],
            
          },
        ]}
      />
    ),
  },

  {
    id: "BQ_AtQ_3",
    mandatory: false,
    plainTitle: "Energy",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie energiegeladen 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   fühlt sich Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  fühlst Du dich      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> fühlen Sie sich     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       fühlt sich Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      fühlst Du dich      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     fühlen Sie sich     </SPChoice>             
          im Moment?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "voller Energie" }),
              l({ en: "PLATZHALTER", de: "energielos lustlos/leer" }),
            ],
            
          },
        ]}
      />
    ),
  },

  {
    id: "BQ_AtQ_4",
    mandatory: false,
    plainTitle: "Relaxation",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie ruhig und gelassen 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   fühlt sich Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  fühlst Du dich      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> fühlen Sie sich     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       fühlt sich Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      fühlst Du dich      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     fühlen Sie sich     </SPChoice>             
          im Moment?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "sehr unruhig und angespannt" }),
              l({ en: "PLATZHALTER", de: "vollkommen ruhig und gelassen" }),
            ],
            
          },
        ]}
      />
    ),
  },

  {
    id: "BQ_AtQ_5",
    mandatory: false,
    plainTitle: "Concentration",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie konzentriert
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  bist Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> sind Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     sind Sie     </SPChoice>             
          im Moment?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "sehr unkonzentriert" }),
              l({ en: "PLATZHALTER", de: "sehr konzentriert" }),
            ],
            
          },
        ]}
      />
    ),
  },

  {
    id: "BQ_AtQ_6",
    mandatory: false,
    plainTitle: "Motivation",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          Wie motiviert
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  bist Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> sind Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      bist Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     sind Sie     </SPChoice>             
          für die Durchführung der folgenden Tests?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "PLATZHALTER", de: "sehr motiviert" }),
              l({ en: "PLATZHALTER", de: "überhaupt nicht motiviert" }),
            ],
            
          },
        ]}
      />
    ),
  },

  {
    id: "BQ_AtQ_6",
    plainTitle: "Additional comments",
    mandatory: false,
    title: (
      <span>
        <En> PLATZHALTER </En>
        <De> Sonstige Bemerkungen: bitte allfällige sonstige Bemerkungen zur Gemütslage (Motivation, Konzentration, ...) notieren</De>
      </span>
    ), 
    
    component: v => <Input.Question onValid={v} inputType="string" />
  },  
];

export default questions;
