import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_DSQ_1",
    mandatory: false,
    plainTitle: "Who answers",
    title: (
      <span>
        <En>
          Who fills out that questionnaire?
        </En> 
        <De>
          Wer füllt den Fragebogen aus?
        </De>        
      </span>
    ),
    component: (v) => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Mother (together with child)",     de: "Mutter (zusammen mit dem Kind)" }) },
          { text: l({ en: "Father (together with child)",     de: "Vater (zusammen mit dem Kind)" }) },
          { text: l({ en: "Mother (alone)",                   de: "Mutter (alleine)" }) },
          { text: l({ en: "Vater (alone)",                    de: "Vater (alleine)" }) },   
          { text: l({ en: "Child / Teenager (alone)",         de: "Kind / Teenager (alleine)" }) },          
          { text: l({ en: "Other",                            de: "Andere" }),
          placeHolder:l({ en: "Please specify in detail",     de: "Bitte erläutern Sie" }),
          extraInput:   "string"},        ]}
      />
    ),
  },

  {
    id: "BQ_DSQ_2",
    mandatory: false,
    plainTitle: "Class",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED         </SPChoice>          
          fall asleep or get drowsy during class periods?
        </En>       
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   schläft oder nickt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  schläfst oder nickst Du     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       schläft oder nickt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      schläfst oder nickst Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED  </SPChoice>             
          während des Unterrichts ein? 
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  
  {
    id: "BQ_DSQ_3",
    mandatory: false,
    plainTitle: "Homework",
    title: (
      <span>
        <En>
        How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED       </SPChoice>          
        get sleepy or drowsy while doing homework?
        </En>       
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   schläft oder nickt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  schläfst oder nickst Du      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       schläft oder nickt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      schläfst oder nickst Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED </SPChoice>             
          während der Hausaufgaben ein? 
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_4",
    mandatory: false,
    plainTitle: "Wake/alert",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Are you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Are you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED        </SPChoice>          
          usually alert most of the day?
        </En>       
        <De>
           
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ist ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ist ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED     </SPChoice>             
          während der grössten Zeit des Tages wach und munter?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_5",
    mandatory: false,
    plainTitle: "Tired/Grumpy",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  are you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       is your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      are you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED     </SPChoice>
          tired and grumpy during the day?
        </En>
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       ist Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED   </SPChoice>             
          müde und mürrisch während des Tages?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_6",
    mandatory: false,
    plainTitle: "Getting up",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED       </SPChoice>          
          have trouble getting out of bed in the morning?
        </En>       
        <De>
          Wie oft fällt es
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ihrem Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Dir       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ihrem Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Dir       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED </SPChoice>             
          schwer, morgens aus dem Bett zu kommen?

        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_7",
    mandatory: false,
    plainTitle: "Back to sleep",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED    </SPChoice>          
          fall back to sleep after being awakened in the morning?
        </En>       
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   schläft Ihr Kind, nachdem es morgens geweckt wurde, </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  schläfst Du, nachdem Du morgens geweckt wurdest,       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED    </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       schläft Ihr Kind, nachdem es morgens geweckt wurde, </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      schläfst Du, nachdem Du morgens geweckt wurdest,       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED    </SPChoice>             
          noch einmal ein?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_8",
    mandatory: false,
    plainTitle: "Woken up freq",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED        </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED        </SPChoice>          
          need someone to wake it up in the morning?
        </En>       
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   muss Ihr Kind morgens </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  musst Du morgens      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       muss Ihr Kind morgens </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      musst Du morgens       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED    </SPChoice>             
          geweckt werden?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
  {
    id: "BQ_DSQ_9",
    mandatory: false,
    plainTitle: "Lacking sleep",
    title: (
      <span>
        <En>
          How often
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child think it needs </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you think you need          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED                       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child think it needs </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you think you need          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED                       </SPChoice>          
          more sleep?
        </En>       
        <De>
          Wie oft
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   haben Sie das Gefühl, Ihr Kind bräuchte </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  hast Du das Gefühl, Du bräuchtest       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> NOT USED   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       haben Sie das Gefühl, Ihr Kind bräuchte </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      hast Du das Gefühl, Du bräuchtest    </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     NOT USED    </SPChoice>             
          mehr Schlaf?
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "never",      de: "nie" }),
              l({ en: "sometimes",  de: "manchmal" }),
              l({ en: "very often", de: "sehr oft" }),
            ],
            
          },
        ]}
      />
    ),
  },
];

export default questions;
