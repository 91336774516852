// component for framing a picture with a little caption and label

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

const Caption = styled.p`
  color: gray;
  font-size: 0.88em;
`;

interface Props {
  href: string;
  label: string;
  color?: any;
}

class Figure extends React.Component<Props> {
  render() {
    return (
      <Ui.SegmentGroup>
        <Ui.Segment>
          <Ui.Label
            color={this.props.color ? this.props.color : undefined}
            ribbon
          >
            {this.props.label}
          </Ui.Label>
          <Ui.Image src={this.props.href} fluid />
        </Ui.Segment>
        <Ui.Segment>
          <Caption>{this.props.children}</Caption>
        </Ui.Segment>
      </Ui.SegmentGroup>
    );
  }
}

export default Figure;
