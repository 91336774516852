import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "RadioTable";

const OptionText = styled.div`
  text-align: center;
`;

interface Props {
  onValid: (state?: AnswerObj) => void;
  subQuestions: string[];
  options: string[];
}

export interface State {
  answers: any[];
}

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: Array(this.props.subQuestions.length).fill(null)
    };
  }
  setAnswer = (answer: number, idx: number) => () => {
    const answers = this.state.answers.slice();
    answers[idx] = answer;

    this.setState({ answers });
    if (!answers.includes(null)) {
      this.props.onValid({ data: this.state, questionProps: this.props, type });
    }
  };
  render() {
    return (
      <Ui.Table definition unstackable={true}>
        <Ui.Table.Header>
          <Ui.Table.Row>
            <Ui.Table.HeaderCell />

            {this.props.options.map((option, idx) => (
              <Ui.Table.HeaderCell key={idx}>
                {" "}
                <OptionText>{option}</OptionText>
              </Ui.Table.HeaderCell>
            ))}
          </Ui.Table.Row>
        </Ui.Table.Header>
        <Ui.Table.Body>
          {this.props.subQuestions.map((question, idx) => (
            <Ui.Table.Row key={idx}>
              <Ui.Table.Cell>{question}</Ui.Table.Cell>
              {this.props.options.map((option, idx_op) => (
                <Ui.Table.Cell textAlign="center" key={idx_op}>
                  <Ui.Radio
                    checked={idx_op === this.state.answers[idx]}
                    onClick={this.setAnswer(idx_op, idx)}
                  />
                </Ui.Table.Cell>
              ))}
            </Ui.Table.Row>
          ))}
        </Ui.Table.Body>
      </Ui.Table>
    );
  }
}
