import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

import * as RankList from "ewoq/prototypes/RankList";

const questions: QuestionType[] = [
  {
    id: "DQ_1.1",
    mandatory: false,
    plainTitle: "Whole experience",
    title: (
      <span>
        How did you experience participating for this whole experiment?
      </span>
    ),
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["It was awful", "Neutral", "It was fantastic"] },
          { labels: ["Boring", "Neutral", "Interesting"] },
          { labels: ["Stressful", "Neutral", "Relaxing"] }
        ]}
        // extraRadio = {[{text: "Other", inputType: "string"}]} //TODOe make this a Comment separate question
      />
    )
  },
  {
    id: "DQ_1.2",
    mandatory: false,
    plainTitle: "comments on experience",
    title: <span>Other comments on your experience:</span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_2",
    mandatory: false,
    plainTitle: "repeat experiment?",
    title: <span>Would you do it again?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No", "Maybe", "Yes"]}
      />
    )
  },
  {
    id: "DQ_3.1",
    mandatory: false,
    plainTitle: "like screening q?",
    title: <span>How did you like the screening questionnaire?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_3.2",
    mandatory: false,
    plainTitle: "screening q properties",
    title: <span>Tick all that apply for the screening questionnaire: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_3.3",
    mandatory: false,
    plainTitle: "screening q honesty",
    title: (
      <span>
        How accurate/honest were your answers in the screening questionnaire?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate/untruthful",
          "Somewhat accurate/truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_3.4",
    mandatory: false,
    plainTitle: "comments screening q",
    title: <span>Comments on the screening questionnaire: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_4.1",
    mandatory: false,
    plainTitle: "like lifestyle q?",
    title: <span>How did you like the lifestyle questionnaire?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_4.2",
    mandatory: false,
    plainTitle: "lifestyle q properties",
    title: <span>Tick all that apply for the lifestyle questionnaire: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_4.3",
    mandatory: false,
    plainTitle: "lifesyle q honesty",
    title: (
      <span>
        How accurate/honest were your answers in the lifestyle questionnaire?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate/untruthful",
          "Somewhat accurate/truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_4.4",
    mandatory: false,
    plainTitle: "comments lifestyle q",
    title: <span>Comments on the lifestyle questionnaire: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_5.1.1",
    mandatory: false,
    plainTitle: "like sleep reports?",
    title: (
      <span>
        What did you think of the sleep reports in the preparation week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.2",
    mandatory: false,
    plainTitle: "like previous day reports?",
    title: (
      <span>
        What did you think of the previous day reports in the preparation week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.3",
    mandatory: false,
    plainTitle: "like regular sleep wake?",
    title: (
      <span>
        What did you think of the regular sleep-wake schedule during the
        preparation week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.4",
    mandatory: false,
    plainTitle: "like caffeine abstinance",
    title: (
      <span>
        What did you think of the abstinence from caffeine in the preparation
        week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.5",
    mandatory: false,
    plainTitle: "like alcohol abstinance?",
    title: (
      <span>
        What did you think of the abstinence from alcohol in the preparation
        week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.6",
    plainTitle: "like party and drug abstinance?",
    mandatory: false,
    title: (
      <span>
        What did you think of the abstinence from from parties, drugs and
        excitement?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
        extraRadio={["I don't do that anyway"]} //TODO, apply to other qs
      />
    )
  },
  {
    id: "DQ_5.2",
    mandatory: false,
    plainTitle: "Sleep report properties",
    title: <span>Tick all that apply to the Sleep Report: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_5.3",
    mandatory: false,
    plainTitle: "honesty sleep report",
    title: (
      <span>
        How accurate/honest were your answers in the questionnaire during the
        preparation week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate/untruthful",
          "Somewhat accurate/truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_5.4",
    mandatory: false,
    plainTitle: "comments prep week",
    title: <span>Comments on the preparation week: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_5.5.1",
    mandatory: false,
    plainTitle: "ease of filling sleep report",
    title: (
      <span>
        How easy was it to fill the report out every day at the same time?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", "I struggled a lot"]}
      />
    )
  },
  {
    id: "DQ_5.5.2",
    mandatory: false,
    plainTitle: "ease of regular sleep wake",
    title: (
      <span>
        How easy was it for you to comply with the regular sleep schedule?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", " Neutral", "I struggled a lot"]}
      />
    )
  },
  {
    id: "DQ_5.5.3",
    mandatory: false,
    plainTitle: "ease avoiding coffee",
    title: <span>How easy was it for you to avoid coffee/caffeine?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", " Neutral", "I struggled a lot"]}
      />
    )
  },
  {
    id: "DQ_5.5.4",
    mandatory: false,
    plainTitle: "ease avoiding alcohol",
    title: <span>How easy was it to avoid alcohol?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", " Neutral", "I struggled a lot"]}
      />
    )
  },
  {
    id: "DQ_5.5.5",
    mandatory: false,
    plainTitle: "ease avoiding drugs",
    title: (
      <span>
        How easy was it to avoid drugs, parties, and other sleep-disrupting
        activities?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", " Neutral", "I struggled a lot"]}
      />
    )
  },
  {
    id: "DQ_6.1",
    mandatory: false,
    plainTitle: "watch bothersome?",
    title: <span>Did it bother you to wear the watch?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "A lot"]}
      />
    )
  },
  {
    id: "DQ_6.2",
    mandatory: false,
    plainTitle: "privacy concerns?",
    title: <span>Were you concerned about your privacy?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "A lot"]}
      />
    )
  }, //Maybe et rid of
  {
    id: "DQ_7.1",
    mandatory: false,
    plainTitle: "adaptation night like?",
    title: <span>What did you think of the adaptation night?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_7.2",
    mandatory: false,
    plainTitle: "was adaptation helpful?",
    title: (
      <span>
        Do you feel the adaption night helped you sleep better when you came
        back for the main experiment?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No benefit at all", "Extremely helpful"]}
      />
    )
  },
  {
    id: "DQ_7.3",
    mandatory: false,
    plainTitle: "comments adaptation",
    title: <span>Comments on the adaption night: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_8.1",
    mandatory: false,
    plainTitle: "rest test like?",
    title: <span>What did you think of the rest tests?</span>,
    explanation: "Specifically, staying seated for 10 minutes doing nothing.",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated them", "They were fine", "They were great!"]}
      />
    )
  },

  {
    id: "DQ_9.1",
    mandatory: false,
    plainTitle: "rest test q like?",
    title: <span>What did you think of the rest test questionnaires?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated them", "They were fine", "They were great!"]}
      />
    )
  },
  {
    id: "DQ_9.2",
    mandatory: false,
    plainTitle: "rest test q properties",
    title: <span>Tick all that apply to the rest test questionnaires: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Each was too long" },
          { text: "There were to many" },
          { text: "They made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_9.3",
    mandatory: false,
    plainTitle: "rest test q honesty",
    title: (
      <span>
        How accurate/honest were your answers in the rest test questionnaires?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate/untruthful",
          "Somewhat accurate/truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_9.4",
    mandatory: false,
    plainTitle: "rest test comments",
    title: <span>Comments on the rest test and questionnaires: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_10.1",
    mandatory: false,
    plainTitle: "24 h wake like?",
    title: <span>What did you think about staying awake for 24 hours?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_10.2",
    mandatory: false,
    plainTitle: "what was worse",
    title: <span>Which would you have preferred not to do?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Waking up early" },
          { text: "Staying up late" },
          { text: "Equally bad" },
          { text: "Equally fine" }
        ]}
      />
    )
  },
  {
    id: "DQ_10.3",
    mandatory: false,
    plainTitle: "preferred time filler",
    title: (
      <span>What would you have preferred to do to stay awake that long?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        extraRadio={["TV was good"]}
      />
    )
  },
  {
    id: "DQ_10.4",
    mandatory: false,
    plainTitle: "comments staying awake",
    title: <span>Comments on staying awake for 24 hours: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_11.1",
    mandatory: false,
    plainTitle: "liked binge watching?",
    title: <span>What did you think of binge watching TV series?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_11.2",
    mandatory: false,
    plainTitle: "remember tv content?",
    title: <span>How much do you remember of the content?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Nothing at all", "Some", "Everything, perfectly"]}
      />
    )
  },
  {
    id: "DQ_11.3",
    mandatory: false,
    plainTitle: "hard staying awake with tv?",
    title: <span>How hard was it to stay awake while watching TV?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely easy", "Extremely hard"]}
      />
    )
  },
  {
    id: "DQ_11.4",
    mandatory: false,
    plainTitle: "enjoyed the tv series?",
    title: <span>How much did you enjoy the series?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fantastic"]}
      />
    )
  },
  {
    id: "DQ_11.5",
    mandatory: false,
    plainTitle: "comfort watching tv?",
    title: <span>How comfortable were you during TV watching?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely uncomfortable", "Fine", "Extremely comfortable"]}
      />
    )
  },

  {
    id: "DQ_12.1",
    mandatory: false,
    plainTitle: "cognitive tests like?",
    title: <span>What did you think of the cognitive tests?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated them", "They were fine", "They were great!"]}
      />
    )
  },
  {
    id: "DQ_12.2",
    mandatory: false,
    plainTitle: "cognitive tests challenging?",
    title: <span>Did you find them challenging?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No", "Yes"]}
      />
    )
  },
  {
    id: "DQ_12.3",
    mandatory: false,
    plainTitle: "rank tasks",
    title: (
      <span>
        Please rank the tasks in the order in which you would be happy doing
        them for 1 hour, the first item being the preferred choice.
      </span>
    ),
    explanation:
      "If you don’t remember what names refer to what, just consult the experimenter.",
    component: v => (
      <RankList.Question
        onValid={v}
        cards={[
          "Fixating / doing nothing",
          "TV",
          "Music listening",
          "Two-Sided Oddball",
          "Video game",
          "Free phone activity",
          "PVT"
        ]}
      />
    )
  },
  {
    id: "DQ_12.4",
    mandatory: false,
    plainTitle: "comments cognitive tests",
    title: <span>Comments on the cognitive tests: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  }, //TODO, get rid of all these comments
  {
    id: "DQ_13.1",
    mandatory: false,
    plainTitle: "enough breaks?",
    title: (
      <span>Do you feel the breaks every 3 hours were frequent enough?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "I prefer fewer breaks",
          "It was fine",
          "I wanted more breaks"
        ]}
      />
    )
  },
  {
    id: "DQ_13.2",
    mandatory: false,
    plainTitle: "long enough breaks",
    title: <span>Were the breaks long enough?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "I prefer shorter breaks",
          "It was fine",
          "I wanted longer breaks"
        ]}
      />
    )
  },
  {
    id: "DQ_13.3",
    mandatory: false,
    plainTitle: "breaks helped stay awake?",
    title: <span>Did they help keep you awake?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "They were essential"]}
      />
    )
  },
  {
    id: "DQ_13.4",
    mandatory: false,
    plainTitle: "breaks help relax?",
    title: <span>Did they help you relax?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "They were essential"]}
      />
    )
  },
  {
    id: "DQ_13.5",
    mandatory: false,
    plainTitle: "comment on breaks",
    title: <span>Comments on the breaks: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_14.1",
    mandatory: false,
    plainTitle: "thoughts on small meals?",
    title: <span>What did you think of the regular small meals?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated them", "They were fine", "They were great!"]}
      />
    )
  },
  {
    id: "DQ_14.2",
    mandatory: false,
    plainTitle: "ate enough?",
    title: <span>Did you feel you ate enough overall?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No", "Yes", "Too much"]}
      />
    )
  },
  {
    id: "DQ_14.3",
    mandatory: false,
    plainTitle: "preffered different food?",
    title: <span>Would you have preferred eating something else?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes", placeHolder: "What?", extraInput: "string" },
          { text: "No" }
        ]}
      />
    )
  },
  {
    id: "DQ_14.4",
    mandatory: false,
    plainTitle: "comments on food",
    title: <span>Comments on the meals: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_15.1",
    mandatory: false,
    plainTitle: "eeg cap ok?",
    title: <span>Was it ok to wear the EEG cap for the whole experiment?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I'll never do it again" },
          { text: "It was uncomfortable" },
          { text: "It was ok" },
          { text: "I barely noticed it" }
        ]}
      />
    )
  },
  {
    id: "DQ_15.2",
    mandatory: false,
    plainTitle: "eeg cap pain?",
    title: (
      <span>Did you feel any of the following while wearing the EEG cap?</span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Itchiness" },
          { text: "Too much pressure" },
          { text: "Unspecified pain" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "DQ_15.2",
    mandatory: false,
    plainTitle: "eeg cap comments",
    title: <span>Comments on the EEG cap: </span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  },
  {
    id: "DQ_16.1",
    mandatory: false,
    plainTitle: "recovered in the second night?",
    title: (
      <span>
        Did you feel you recovered completely during the second night of sleep
        from staying awake for so long the day before?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "Completely"]}
      />
    )
  },
  {
    id: "DQ_17",
    mandatory: false,
    plainTitle: "most bothersome?",
    title: (
      <span>
        Overall, what bothered you the most about what we asked you to do / not
        do:{" "}
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        extraRadio={["No problems"]}
      />
    )
  },
  {
    id: "DQ_18",
    mandatory: false,
    plainTitle: "final comments?",
    title: <span>Do you have any other comments or suggestions?</span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
