import React from "react";
import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import styled from "styled-components";
import questions from "./Questions/EpworthSleepinessScaleQuestions";
import { answerQuestionnaire } from "ewoq/api";


import { En, De } from "ewoq/components/Language";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  viewAll?: boolean;
  onDone?: () => void;
  // language: Language;
}

class EpworthSleepinessScale extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "BasicInfo-EpworthSleepinessScale", { answers: answers });
    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
    
          <Ui.Segment>
          <Ui.Divider horizontal>  
          <En>Instructions</En>
          <De>Anleitung</De> 
          </Ui.Divider>      
          <SectionBody>              
              <En>

                  The following question refers to your usual way of life in recent times: <br />
                  <strong>
                  How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just
                  tired? 
                  </strong><br />
                  Even if you have not done some of these things recently try to work out how they would have affected you. 
                  Use the following scale to choose the most appropriate number for each situation: 
                  <br />

                  <strong>
                  0 = no chance of dozing<br />
                  1 = slight chance of dozing<br />
                  2 = moderate chance of dozing<br />
                  3 = high chance of dozing<br />
                  </strong>

              </En>
              <De>

                  Die folgende Frage bezieht sich auf Ihr normales Alltagsleben in der letzten Zeit:<br />
                  <strong>
                  Für wie wahrscheinlich halten Sie es, dass Sie in einer der folgenden Situationen einnicken
                  oder einschlafen würden, - sich also nicht nur müde fühlen?
                  </strong><br />
                  Auch wenn Sie in der letzten Zeit einige dieser Situationen nicht erlebt haben, versuchen Sie sich
                  trotzdem vorzustellen, wie sich diese Situationen auf Sie ausgewirkt hätten.
                  Benutzen Sie bitte die folgende Skala, um für jede Situation eine möglichst genaue Einschätzung
                  vorzunehmen und kreuzen Sie die entsprechende Zahl an:<br />

                  <strong>
                  0 = würde niemals einnicken<br />
                  1 = geringe Wahrscheinlichkeit einzunicken<br />
                  2 = mittlere Wahrscheinlichkeit einzunicken<br />
                  3 = hohe Wahrscheinlichkeit einzunicken<br />
                  </strong>
              </De>
      
</SectionBody> 
</Ui.Segment>        
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default EpworthSleepinessScale;
