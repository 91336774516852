import React from "react";
import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import styled from "styled-components";
import questions from "./Questions/DaytimeSleepinessQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De } from "ewoq/components/Language";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ,
} from "ewoq/components/miscStyles";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  viewAll?: boolean;
  onDone?: () => void;
  // language: Language;
}

class DaytimeSleepiness extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "BasicInfo-DaytimeSleepiness", { answers: answers });
    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        {/* <Ui.Message warning>
          <Ui.MessageContent>
            <MessageText> */}
          <Ui.Segment>
          <Ui.Divider horizontal>  
          <En>Instructions</En>
          <De>Anleitung</De> 
          </Ui.Divider>      
          <SectionBody>              
              <En>
                <p>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                The following questions refer to your childs <b>normal daily life</b> in the recent past. Please try to imagine 
                how the situations below would affect your child, even if your child has never experienced them 
                before. How likely do you believe it is that your child gets drowsy or falls asleep (not just feeling tired)?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                The following questions refer to your <b>normal daily life</b> in the recent past. Please try to imagine 
                how the situations below would affect you, even if you have never experienced them 
                before. How likely do you believe it is that you get drowsy or fall asleep (not just feel tired)?
                </SPChoice>
               
                  {/* If you are
                  unsure about the meaning of the medical terms, just click on
                  them, and you will be redirected to the relevant Wikipedia
                  page. */}
                </p>{" "}
                <p>
                  {/* <strong>Your answers will not be saved.</strong> */}
                  
                </p>
              </En>
              <De>
                <p>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Die folgenden Fragen beziehen sich auf das <b>normale Alltagsleben</b> Ihres Kindes in der letzten Zeit. 
                Bitte versuchen Sie sich vorzustellen, wie sich die unten beschriebenen Situationen auf Ihr Kind
                auswirken würden, auch wenn Ihr Kind sie in genau dieser Form möglicherweise noch nicht erlebt hat.
                Für wie wahrscheinlich halten Sie es, dass Ihr Kind in den folgenden Situationen tatsächlich einnickt
                oder einschläft (sich nicht einfach nur müde fühlt)?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Die folgenden Fragen beziehen sich auf Dein <b>normales Alltagsleben</b> in der letzten Zeit. 
                Bitte versuche Dir vorzustellen, wie sich die unten beschriebenen Situationen auf Dich
                auswirken würden, auch wenn Du sie in genau dieser Form möglicherweise noch nicht erlebt hast.
                Für wie wahrscheinlich hältst Du es, dass Du in den folgenden Situationen tatsächlich einnickst
                oder einschläfst (Dich nicht einfach nur müde fühlst)?. 
                 </SPChoice>
                
                

                  {/* Falls Sie
                  über die Bedeutung eines medizinischen Ausdruckes unsicher
                  sind, klicken Sie auf ihn und Sie werden zu der entsprechenden
                  Wikipediaseite geleitet. */}
                </p>{" "}
                <p>
                  {/* <strong>Ihre Antworten werden nicht gespeichert.</strong> */}
                </p>
              </De>
            {/* </MessageText>
          </Ui.MessageContent>
        </Ui.Message> */}
</SectionBody> 
</Ui.Segment>        
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default DaytimeSleepiness;
