import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "Range";

export interface Props {
  labels: string[];
  onValid: (state?: AnswerObj) => void;
  includeDone?: boolean;
}

export interface State {
  answers: boolean[];
  done: boolean;
}

const Squeeze = styled.div`
  margin-left: 1.5em;
  margin-bottom: 1em;
`;
const DoneBox = styled.div`
  margin-top: 1em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const done =
      this.props.includeDone === true || this.props.includeDone === undefined
        ? false
        : true;
    this.state = {
      answers: Array(this.props.labels.length).fill(false),
      done
    };
  }

  setValidAndAnswer = (idx: number) => {
    const answers = this.state.answers.slice();
    answers[idx] = !answers[idx]; // this flips the checked option
    this.setState({ answers }); // important that its inside, because set state could happen later
    if (this.state.done) {
      this.onDone();
    }
  };

  checkBox = (idx: number) => () => {
    // this both checks the box and starts writing

    let answers = this.state.answers.slice();
    answers[idx] = answers[idx] ? false : true;
    this.setState({ answers });
    if (this.state.done) {
      this.onDone();
    }
  };

  onDone = () => {
    this.setState({ done: true }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  render() {
    return (
      <div>
        <Squeeze>
          <Ui.Button.Group vertical>
            {this.props.labels.map((l, idx) => (
              <Ui.Button
                key={idx}
                onClick={this.checkBox(idx)}
                color={this.state.answers[idx] ? "violet" : undefined}
              >
                {l}
              </Ui.Button>
            ))}
          </Ui.Button.Group>
          {!this.state.done ? (
            <DoneBox>
              <Ui.Button onClick={this.onDone}>Done</Ui.Button>
            </DoneBox>
          ) : null}
        </Squeeze>
      </div>
    );
  }
}

export default Question;
