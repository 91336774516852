import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

// question type for ordering a set of cards with arrows
const type: string = "RankList";

export interface Props {
  cards: string[];
  onValid: (state?: AnswerObj) => void;
}

export interface State {
  answers: string[];
}

const CardBox = styled.div`
  margin-left: 1.5em;
`;

const RankButtons = styled.span`
  width: 2em;
  line-height: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2.5em;
`;

const CardText = styled.span`
  line-height: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1.5em;
  margin-top: -0.4em;
`;

const Card = styled.div`
  margin: 1em;
  width: 100%;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { answers: this.props.cards };
  }

  moveCard = (direction: "up" | "down", idx: number) => {
    let newOrder = this.state.answers.slice();
    const oldOrder = this.state.answers.slice();
    switch (direction) {
      case "up":
        newOrder[idx - 1] = oldOrder[idx];
        newOrder[idx] = oldOrder[idx - 1];

        break;
      case "down":
        newOrder[idx + 1] = oldOrder[idx];
        newOrder[idx] = oldOrder[idx + 1];

        break;
    }

    this.setState({ answers: newOrder });
  };

  onDone = () => {
    this.props.onValid({ data: this.state, questionProps: this.props, type });
  };

  render() {
    return (
      <CardBox>
        {this.state.answers.map((c, idx) => (
          <Ui.Segment key={idx} raised>
            <Card>
              {/* <RankInput>
                <Ui.Input
                  value={idx + 1}
                  type="number"
                  fluid
                  onChange={this.handleInput(idx)}
                />
              </RankInput> */}
              <RankButtons>
                <Ui.Button.Group compact vertical size="mini">
                  <Ui.Button
                    icon="sort up"
                    disabled={idx === 0}
                    onClick={() => this.moveCard("up", idx)}
                  />

                  <Ui.Button
                    icon="sort down"
                    disabled={idx === this.props.cards.length - 1}
                    onClick={() => this.moveCard("down", idx)}
                  />
                </Ui.Button.Group>
              </RankButtons>

              <CardText>{c}</CardText>
            </Card>
          </Ui.Segment>
        ))}
        <Ui.Button onClick={this.onDone}>Done</Ui.Button>
      </CardBox>
    );
  }
}
