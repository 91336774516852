import React from "react";
import * as Ui from "semantic-ui-react";
import Lifestyle1 from "./Part1/Lifestyle1";
import Handedness from "./Part1/Handedness";
import LifestyleSleep from "./Part1/LifestyleSleep";
import DrinkingQ from "./Part1/Drinking";
import Eating1 from "./Part1/Eating1";
import Disruption from "./Part1/Disruption";
import Demographics from "./Part2/Demographics";
import Health from "./Part1/Health";
import Eating2 from "./Part2/Eating2";
import Lifestyle2 from "./Part2/Lifestyle2";
import PainSensitivity from "./Part2/PainSensitivity";
import { getUserId } from "ewoq/utils";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  LinkQ
} from "ewoq/components/miscStyles";

enum Phase {
  Start,
  Basics,
  Demographics,
  Handedness,
  Eating1,
  Eating2,
  Drinking,
  Lifestyle1,
  Lifestyle2,
  Health,
  LifestyleSleep,
  Disruption,
  Pain
}

interface Props {}
interface State {
  phase: Phase;
}

class Lifestyle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phase: Phase.Start
    };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase }, () => console.log(this.state));
  };

  render() {
    switch (this.state.phase) {
      case Phase.Start: {
        return (
          <div>
            <ParticipantID>{getUserId()}</ParticipantID>
            <HeaderContainer>Lifestyle Questionnaire</HeaderContainer>
            <Ui.Container>
              <Ui.Segment>
                <Ui.Divider horizontal>Instructions</Ui.Divider>
                <SectionBody>
                  <p>
                    This questionnaire will ask you about yourself, lifestyle,
                    health, and sleep habits/quality. Questions that are not
                    mandatory will let you skip ahead if you don't feel
                    comfortable answering, or just don't want to, although we
                    appreciate every answer you give us. We recognize that some
                    questions are intrusive, and rather than risk a false
                    answer, we prefer if you skip them. You can always ask the
                    experimenter why we bother asking any question, and we will
                    be happy to explain. If you don’t understand the question,
                    please ask for clarification.
                  </p>
                </SectionBody>

                <SectionBody>
                  <Ui.Form>
                    <Ui.Form.Field>
                      <Ui.Button
                        fluid
                        onClick={this.advancePhase(Phase.LifestyleSleep)}
                      >
                        Lifestyle Questionnaire Part 1
                      </Ui.Button>
                    </Ui.Form.Field>
                    <Ui.Form.Field>
                      <Ui.Button
                        fluid
                        onClick={this.advancePhase(Phase.Demographics)}
                      >
                        Lifestyle Questionnaire Part 2
                      </Ui.Button>
                    </Ui.Form.Field>
                  </Ui.Form>
                </SectionBody>
              </Ui.Segment>
            </Ui.Container>
          </div>
        );
      }
      case Phase.LifestyleSleep: {
        return <LifestyleSleep onDone={this.advancePhase(Phase.Handedness)} />;
      }
      case Phase.Handedness: {
        return <Handedness onDone={this.advancePhase(Phase.Health)} />;
      }

      case Phase.Health: {
        return <Health onDone={this.advancePhase(Phase.Eating1)} />;
      }
      case Phase.Drinking: {
        return <DrinkingQ onDone={this.advancePhase(Phase.Lifestyle1)} />;
      }
      case Phase.Eating1: {
        return <Eating1 onDone={this.advancePhase(Phase.Drinking)} />;
      }
      case Phase.Lifestyle1: {
        return <Lifestyle1 onDone={this.advancePhase(Phase.Disruption)} />;
      }
      case Phase.Disruption: {
        return <Disruption onDone={this.advancePhase(Phase.Start)} />;
      }
      case Phase.Demographics: {
        return <Demographics onDone={this.advancePhase(Phase.Eating2)} />;
      }
      case Phase.Eating2: {
        return <Eating2 onDone={this.advancePhase(Phase.Lifestyle2)} />;
      }
      case Phase.Lifestyle2: {
        return <Lifestyle2 onDone={this.advancePhase(Phase.Pain)} />;
      }
      case Phase.Pain: {
        return <PainSensitivity onDone={this.advancePhase(Phase.Start)} />;
      }
    }
  }
}

export default Lifestyle;
