import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  
  {
    id: "BQ_SES_1",
    mandatory: false,
    plainTitle: "Father(Job)",    
    title: (
      <span>
        <En>
        What is the present occupational position of 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   the childs father? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  your father? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> your father? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       the childs father? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      your father?</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     your father?</SPChoice>          

        </En> 
        <De>
          Was ist aktuelle berufliche Tätigkeit 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   des Vaters?      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Deines Vaters?   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ihres Vaters?    </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       des Vaters?      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Deines Vaters?   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ihres Vaters?    </SPChoice>             

        </De>  
      </span>
    ),

    component: v => (<Input.Question onValid={v} inputType={"string"}/>)
  },

  {
    id: "BQ_SES_2",
    mandatory: false,
    plainTitle: "Father(detailed)",    
    title: (
      <span>
        <En>
          What kind of work does he do in his job?        
        </En> 
        <De>
          Was genau ist seine Tätigkeit?             
        </De>  
      </span>
    ),

    component: v => (<Input.Question onValid={v} inputType={"string"}/>)
  },

  {
      id: "BQ_SES_3",
      mandatory: false,
      plainTitle: "Father(name)",    
      title: (
        <span>
          <En>
            Does his job have other titles?    
          </En> 
          <De>
            Hat sein Beruf noch einen besonderen Namen? 
          </De>  
        </span>
      ),

      component: v => (<Input.Question onValid={v} inputType={"string"}/>)
    },

  {
    id: "BQ_SES_4",
    mandatory: false,
    plainTitle: "Mother(Job)",    
    title: (
      <span>
        <En>
        What is the present occupational position of 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   the childs mother? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  your mother? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> your mother? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       the childs mother? </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      your mother?</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     your mother?</SPChoice>          

        </En> 
        <De>
          Was ist aktuelle berufliche Tätigkeit 
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   der Mutter?      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Deiner Mutter?   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ihrer Mutter?    </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       der Mutter?      </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Deiner Mutter?   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ihrer Mutter?    </SPChoice>             

        </De> 
      </span>
    ),

    component: v => (<Input.Question onValid={v} inputType={"string"}/>)
  },

  

  

  {
    id: "BQ_SES_5",
    mandatory: false,
    plainTitle: "Mother(detailed)",    
    title: (
      <span>
        <En>
          What kind of work does she do in her job?        
        </En> 
        <De>
          Was genau ist ihre Tätigkeit?             
        </De>   
      </span>
    ),

    component: v => (<Input.Question onValid={v} inputType={"string"}/>)
  },

  {
    id: "BQ_SES_6",
    mandatory: false,
    plainTitle: "Mother(name)",    
    title: (
      <span>
        <En>
            Does her job have other titles?    
          </En> 
          <De>
            Hat ihr Beruf noch einen besonderen Namen? 
          </De>
      </span>
    ),

    component: v => (<Input.Question onValid={v} inputType={"string"}/>)
  },
  




];

export default questions;
