import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_SDQ_1",
    mandatory: false,
    plainTitle: "Considerate",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Considerate of other people's feelings</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I try to be nice to other people; I care about their feelings</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I try to be nice to other people; I care about their feelings</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Considerate of other people's feelings</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I try to be nice to other people; I care about their feelings</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I try to be nice to other people; I care about their feelings</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Rücksichtsvoll   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich versuche, nett zu anderen Menschen zu sein, ihre Gefühle sind mir wichtig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich versuche, nett zu anderen Menschen zu sein, ihre Gefühle sind mir wichtig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Rücksichtsvoll   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich versuche, nett zu anderen Menschen zu sein, ihre Gefühle sind mir wichtig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich versuche, nett zu anderen Menschen zu sein, ihre Gefühle sind mir wichtig</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_2",
    mandatory: false,
    plainTitle: "Hyperactive",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Restless, overactive, cannot stay still for long</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am restless, I cannot stay still for long</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am restless, I cannot stay still for long</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Restless, overactive, cannot stay still for long</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am restless, I cannot stay still for long</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am restless, I cannot stay still for long</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Unruhig, überaktiv, kann nicht lange still sitzen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin oft unruhig; ich kann nicht lange stillsitzen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin oft unruhig; ich kann nicht lange stillsitzen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Unruhig, überaktiv, kann nicht lange still sitzen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin oft unruhig; ich kann nicht lange stillsitzen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin oft unruhig; ich kann nicht lange stillsitzen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_3",
    mandatory: false,
    plainTitle: "Sickly",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often complains of headaches, stomach-aches or sickness</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I get a lot of headaches, stomach-aches or sickness</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I get a lot of headaches, stomach-aches or sickness</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often complains of headaches, stomach-aches or sickness</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I get a lot of headaches, stomach-aches or sickness</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I get a lot of headaches, stomach-aches or sickness</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Klagt häufig über Kopfschmerzen, Bauchschmerzen oder Übelkeit   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich habe häufig Kopfschmerzen oder Bauchschmerzen; mir wird oft schlecht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich habe häufig Kopfschmerzen oder Bauchschmerzen; mir wird oft schlecht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Klagt häufig über Kopfschmerzen, Bauchschmerzen oder Übelkeit   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich habe häufig Kopfschmerzen oder Bauchschmerzen; mir wird oft schlecht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich habe häufig Kopfschmerzen oder Bauchschmerzen; mir wird oft schlecht</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_4",
    mandatory: false,
    plainTitle: "Sharing",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Shares readily with other children, for example toys, treats, pencils</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I usually share with others, for example CD's, games, food</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I usually share with others, for example food or drinks</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Shares readily with other children, for example toys, treats, pencils</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I usually share with others, for example CD's, games, food</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I usually share with others, for example food or drinks</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Teilt gerne mit anderen Kindern (Süssigkeiten, Spielzeuge, Buntstifte usw.)   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich teile normalerweise mit Anderen (z.B. Essen, Spiele, Stfite)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich teile normalerweise mit Anderen (z.B. Essen, Trinken)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Teilt gerne mit anderen Kindern (Süssigkeiten, Spielzeuge, Buntstifte usw.)   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich teile normalerweise mit Anderen (z.B. Essen, Spiele, Stfite)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich teile normalerweise mit Anderen (z.B. Essen, Trinken)</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_5",
    mandatory: false,
    plainTitle: "Temper",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often loses temper</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I get very angry and often lose my temper</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I get very angry and often lose my temper</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often loses temper</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I get very angry and often lose my temper</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I get very angry and often lose my temper</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hat oft Wutanfälle; ist aufbrausend   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich werde leicht wütend; ich verliere oft meine Beherrschung</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich werde leicht wütend; ich verliere oft meine Beherrschung</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hat oft Wutanfälle; ist aufbrausend   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich werde leicht wütend; ich verliere oft meine Beherrschung</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich werde leicht wütend; ich verliere oft meine Beherrschung</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_6",
    mandatory: false,
    plainTitle: "Solitary",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Rather solitary, prefers to play alone</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I would rather be alone than with people of my age</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I would rather be alone than with other people</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Rather solitary, prefers to play alone</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I would rather be alone than with people of my age</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I would rather be alone than with other  people</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Einzelgänger; spielt meist alleine   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin meistens für mich alleine; ich beschäftige mich lieber mit mir selbst</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin lieber alleine als mit anderen Menschen zusammen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Einzelgänger; spielt meist alleine   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin meistens für mich alleine; ich beschäftige mich lieber mit mir selbst</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin lieber alleine als mit anderen Menschen zusammen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_7",
    mandatory: false,
    plainTitle: "Obedient",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Generally well behaved, usually does what adults request</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I usually do as I am told</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am generally willing to do what other people want</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Generally well behaved, usually does what adults request</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I usually do as I am told</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am generally willing to do what other people want</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Im Allgemeinen folgsam; macht meist, was Erwachsene verlangen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Normalerweise tue ich, was man mir sagt</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Im Allgemeinen bin ich gewillt zu tun, was andere Menschen von mir verlangen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Im Allgemeinen folgsam; macht meist, was Erwachsene verlangen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Normalerweise tue ich, was man mir sagt</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Im Allgemeinen bin ich gewillt zu tun, was andere Menschen von mir verlangen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_8",
    mandatory: false,
    plainTitle: "Worried",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Many worries or often seems worried</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I worry a lot</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I worry a lot</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Many worries or often seems worried</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I worry a lot</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I worry a lot</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hat viele Sorgen; erscheint häufig bedrückt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich mache mir häufig Sorgen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich mache mir häufig Sorgen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hat viele Sorgen; erscheint häufig bedrückt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich mache mir häufig Sorgen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich mache mir häufig Sorgen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_9",
    mandatory: false,
    plainTitle: "Helpful(Health)",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Helpful if someone is hurt, upset or feeling ill</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am helpful if someone is hurt, upset or feeling ill</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am helpful if someone is hurt, upset or feeling ill</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Helpful if someone is hurt, upset or feeling ill</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am helpful if someone is hurt, upset or feeling ill</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am helpful if someone is hurt, upset or feeling ill</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hilfsbereit, wenn andere verletzt, krank oder betrübt sind   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin hilfsbereit, wenn andere verletzt, krank oder traurig sind</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin hilfsbereit, wenn andere verletzt, krank oder traurig sind</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hilfsbereit, wenn andere verletzt, krank oder betrübt sind   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin hilfsbereit, wenn andere verletzt, krank oder traurig sind</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin hilfsbereit, wenn andere verletzt, krank oder traurig sind</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_10",
    mandatory: false,
    plainTitle: "Fidgeting",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Constantly fidgeting or squirming</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am constantly fidgeting or squirming</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am constantly fidgeting or squirming</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Constantly fidgeting or squirming</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am constantly fidgeting or squirming</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am constantly fidgeting or squirming</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Ständig zappelig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin dauernd in Bewegung und zappelig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin dauernd in Bewegung und zappelig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Ständig zappelig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin dauernd in Bewegung und zappelig</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin dauernd in Bewegung und zappelig</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_11",
    mandatory: false,
    plainTitle: "Friends",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Has at least one good friend</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I have one good friend or more</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I have at least one good friend</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Has at least one good friend</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I have one good friend or more</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I have at least one good friend</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hat wenigstens einen guten Freund oder eine gute Freundin   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich habe einen oder mehrere gute Freunde oder Freundinnen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich habe mindestens eine gute Freundin/einen guten Freund</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hat wenigstens einen guten Freund oder eine gute Freundin   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich habe einen oder mehrere gute Freunde oder Freundinnen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich habe mindestens eine gute Freundin/einen guten Freund</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_12",
    mandatory: false,
    plainTitle: "Conflicts",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often fights with other Children or bullies them</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I fight a lot. I can make other people do what I want</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I fight a lot. I can make other people do what I want</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often fights with other Children or bullies them</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I fight a lot. I can make other people do what I want</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I fight a lot. I can make other people do what I want</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Streitet sich oft mit anderen Kindern oder schikaniert sie   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich schlage mich häufig; ich kann Andere zwingen zu tun, was ich will</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich schlage mich häufig; ich kann Andere zwingen zu tun, was ich will</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Streitet sich oft mit anderen Kindern oder schikaniert sie   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich schlage mich häufig; ich kann Andere zwingen zu tun, was ich will</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich schlage mich häufig; ich kann Andere zwingen zu tun, was ich will</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_13",
    mandatory: false,
    plainTitle: "Unhappy",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often unhappy, depressed or tearful</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am often unhappy, depressed or tearful</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am often unhappy, depressed or tearful</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often unhappy, depressed or tearful</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am often unhappy, depressed or tearful</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am often unhappy, depressed or tearful</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Oft unglücklich oder niedergeschlagen, weint häufig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin oft unglücklich oder niedergeschlagen; ich muss häufig weinen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin oft unglücklich oder deprimiert; ich muss häufig weinen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Oft unglücklich oder niedergeschlagen, weint häufig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin oft unglücklich oder niedergeschlagen; ich muss häufig weinen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin oft unglücklich oder deprimiert; ich muss häufig weinen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_14",
    mandatory: false,
    plainTitle: "Popular",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Generally liked by other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Other people my age generally like me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Other people generally like me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Generally liked by other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Other people my age generally like me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Other people generally like me</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Im Allgemeinen bei anderen Kindern beliebt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Im Allgemeinen bin ich bei Gleichaltrigen beliebt</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Andere Menschen mögen mich im Allgemeinen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Im Allgemeinen bei anderen Kindern beliebt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Im Allgemeinen bin ich bei Gleichaltrigen beliebt</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Andere Menschen mögen mich im Allgemeinen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_15",
    mandatory: false,
    plainTitle: "Concentration",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Easily distracted, concentration wanders</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am easily distracted; I find it difficult to concentrate</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am easily distracted; I find it difficult to concentrate</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Easily distracted, concentration wanders</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am easily distracted; I find it difficult to concentrate</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am easily distracted; I find it difficult to concentrate</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Leicht ablenkbar, unkonzentriert   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich lasse mich leicht ablenken; ich finde es schwer, mich zu konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich lasse mich leicht ablenken; ich finde es schwer, mich zu konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Leicht ablenkbar, unkonzentriert   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich lasse mich leicht ablenken; ich finde es schwer, mich zu konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich lasse mich leicht ablenken; ich finde es schwer, mich zu konzentrieren</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_16",
    mandatory: false,
    plainTitle: "Nervous",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Nervous or clingy in new situations, easiy loses confidence</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am nervous in new situations; I easily lose confidence</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am nervous in new situations; I easily lose confidence</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Nervous or clingy in new situations, easiy loses confidence</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am nervous in new situations; I easily lose confidence</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am nervous in new situations; I easily lose confidence</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Nervös oder anklammernd in neuen Situationen; verliert leicht das Selbstvetrauen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Neue Situation machen mich mich nervös; ich verliere leicht das Selbstvertrauen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Neue Situation machen mich mich nervös; ich verliere leicht das Selbstvertrauen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Nervös oder anklammernd in neuen Situationen; verliert leicht das Selbstvetrauen   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Neue Situation machen mich mich nervös; ich verliere leicht das Selbstvertrauen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Neue Situation machen mich mich nervös; ich verliere leicht das Selbstvertrauen</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_17",
    mandatory: false,
    plainTitle: "Kind",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Kind to younger children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am kind to younger children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am kind to children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Kind to younger children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am kind to younger children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am kind to children</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Lieb zu jüngeren Kindern   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich bin nett zu jüngeren Kindern</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich bin nett zu Kindern</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Lieb zu jüngeren Kindern   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich bin nett zu jüngeren Kindern</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich bin nett zu Kindern</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_18",
    mandatory: false,
    plainTitle: "Mischievous",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often lies or cheats</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I am often accused of lying or cheating</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I am often accused of lying or cheating</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often lies or cheats</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I am often accused of lying or cheating</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I am often accused of lying or cheating</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Lügt oder mogelt häufig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Andere behaupten oft, dass ich lüge oder mogele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Andere behaupten oft, dass ich lüge oder mogele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Lügt oder mogelt häufig   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Andere behaupten oft, dass ich lüge oder mogele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Andere behaupten oft, dass ich lüge oder mogele</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_19",
    mandatory: false,
    plainTitle: "Victim",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Picked on or bullied by other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Other children or younger people pick on me or bully me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Other people pick on me or bully me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Picked on or bullied by other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Other children or younger people pick on me or bully me</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Other people pick on me or bully me</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Wird von anderen gehänselt oder schikaniert   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich werde von anderen gehänselt oder schikaniert</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich werde von anderen gehänselt oder schikaniert</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Wird von anderen gehänselt oder schikaniert   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich werde von anderen gehänselt oder schikaniert</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich werde von anderen gehänselt oder schikaniert</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_20",
    mandatory: false,
    plainTitle: "Helpful(Tasks)",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Often offers to help others (parents, teachers, other children)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I often offer to help others (parents, teachers, childrens)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I often offer to help others (family members, friends, colleagues)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Often offers to help others (parents, teachers, other children)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I often offer to help others (parents, teachers, childrens)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I often offer to help others (family members, friends, colleagues)</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hilft anderen oft freiwilig (Eltern, Lehrern oder anderen Kindern)   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich helfe anderen oft freiwlig (Eltern, Lehrern oder Gleichaltrigen)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich biete anderen oft meine Hilfe an (Familienangehörigen, Freunden, Kollegen)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hilft anderen oft freiwilig (Eltern, Lehrern oder anderen Kindern)   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich helfe anderen oft freiwlig (Eltern, Lehrern oder Gleichaltrigen)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich biete anderen oft meine Hilfe an (Familienangehörigen, Freunden, Kollegen)</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_21",
    mandatory: false,
    plainTitle: "Rational",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Thinks things out before acting</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I think before I do things</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I think before I do things</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Thinks things out before acting</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I think before I do things</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I think before I do things</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Denkt nach, bevor er/sie handelt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich denke nach, bevor ich handele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich denke nach, bevor ich handele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Denkt nach, bevor er/sie handelt   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich denke nach, bevor ich handele</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich denke nach, bevor ich handele</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_22",
    mandatory: false,
    plainTitle: "Kleptomania",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Steals from home, school or elsewhere</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I take things that are not mine from home, school or elsewhere</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I take things that are not mine from home, work or elsewhere</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Steals from home, school or elsewhere</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I take things that are not mine from home, school or elsewhere</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I take things that are not mine from home, work or elsewhere</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Stiehlt zu Hause, in der Schule oder anderswo   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich nehme Dinge, die mir nicht gehören (von zu Hause, in der Schule oder anderswo)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich nehme Dinge, die mir nicht gehören (von zu Hause, von der Arbeit oder anderswo)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Stiehlt zu Hause, in der Schule oder anderswo   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich nehme Dinge, die mir nicht gehören (von zu Hause, in der Schule oder anderswo)</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich nehme Dinge, die mir nicht gehören (von zu Hause, von der Arbeit oder anderswo)</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_23",
    mandatory: false,
    plainTitle: "Maturity",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Gets along better with adults than with other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I get along better with adults than with people my own age</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I get along better with older people than with people my own age</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Gets along better with adults than with other children</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I get along better with adults than with people my own age</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I get along better with older people than with people my own age</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Kommt besser mit Erwachsenen aus als mit anderen Kindern   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich komme besser mit Erwachsenen aus als mit Gleichaltrigen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich komme besser mit Menschen aus, die älter sind als ich, als mit Menschen in meinem Alter</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Kommt besser mit Erwachsenen aus als mit anderen Kindern   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich komme besser mit Erwachsenen aus als mit Gleichaltrigen</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich komme besser mit Menschen aus, die älter sind als ich, als mit Menschen in meinem Alter</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_24",
    mandatory: false,
    plainTitle: "Anxious",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Many fears, easily scared </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I have many fears, I am easily scared</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I have many fears, I am easily scared</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Many fears, easily scared</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I have many fears, I am easily scared</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I have many fears, I am easily scared</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Hat viele Ängste; fürchtet sich leicht   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Ich habe viele Ängste; ich fürchte mich leicht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Ich habe viele Ängste; ich fürchte mich leicht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Hat viele Ängste; fürchtet sich leicht   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Ich habe viele Ängste; ich fürchte mich leicht</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Ich habe viele Ängste; ich fürchte mich leicht</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },

  {
    id: "BQ_SDQ_25",
    mandatory: false,
    plainTitle: "Thorough/Concentrated",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Good attention span, sees work through to the end</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  I finish the work I'm doing. My attention is good</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> I finish the work I'm doing. My attention is good</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Good attention span, sees work through to the end</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      I finish the work I'm doing. My attention is good</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     I finish the work I'm doing. My attention is good</SPChoice>          
          
        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Führt Aufgaben zu Ende; gute Konzentratonsspanne   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  Was ich angefangen habe, mache ich zu Ende; ich kann mich lange genug konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> Was ich angefangen habe, mache ich zu Ende; ich kann mich lange genug konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Führt Aufgaben zu Ende; gute Konzentratonsspanne   </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      Was ich angefangen habe, mache ich zu Ende; ich kann mich lange genug konzentrieren</SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     Was ich angefangen habe, mache ich zu Ende; ich kann mich lange genug konzentrieren</SPChoice>             
        </De>
      </span>
    ),
    component: (v) => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            labels: [
              l({ en: "Not true", de: "Nicht zutreffend" }),
              l({ en: "Somewhat true", de: "Teilweise zutreffend" }),
              l({ en: "Certainly true", de: "Eindeutig zutreffend" }),
            ],
          },
          
        ]}
      />
    ),
  },
];

export default questions;

