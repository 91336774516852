import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";

import * as Slider from "ewoq/prototypes/Slider";

import * as Range from "ewoq/prototypes/Ranges";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";
//icons
import Muffin from "ewoq/prototypes/icons/muffin.png";

import Burger from "ewoq/prototypes/icons/Burger.png";
import Plate from "ewoq/prototypes/icons/grayplate.png";

const questions: QuestionType[] = [
  {
    id: "LQ_EA1_1.1",
    mandatory: true,
    plainTitle: "Breakfast frequency",
    title: <span>How often do you eat breakfast?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Monthly", "Weekly", "Every day"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_EA1_1.2",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_EA1_1.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    mandatory: false,
    plainTitle: "Breakfast foods and times",
    title: "Regarding breakfast...",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_EA1_1.2.1",
            title: "When do you usually eat it?",
            component: v => (
              <Range.Question
                onValid={v}
                includeDone={false}
                labels={[
                  "4 AM",
                  "5 AM",
                  "6 AM",
                  "7 AM",
                  "8 AM",
                  "9 AM",
                  "10 AM",
                  "11 AM"
                ]}
              />
            )
          },
          {
            id: "LQ_EA1_1.2.2",
            title: "How large?",
            explanation:
              "Indicate with the slider approximately the volume of food you eat, taking into account side dishes, dessert, etc.",
            component: v => (
              <Slider.Question
                sliderType={Slider.SliderType.picture}
                onValid={v}
                picture={Muffin}
                labels={[""]}
                backgroundPicture={Plate}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_EA1_2.1",
    mandatory: true,
    plainTitle: "Lunch frequency",
    title: <span>How often do you eat lunch?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Monthly", "Weekly", "Every day"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_EA1_2.2",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_EA1_2.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    mandatory: false,
    plainTitle: "Lunch foods and time",
    title: "Regarding lunch...",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_EA1_2.2.1",
            title: "When do you usually eat it?",
            component: v => (
              <Range.Question
                onValid={v}
                labels={["11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM"]}
                includeDone={false}
              />
            )
          },
          {
            id: "LQ_EA1_2.2.2",
            title: "How large?",
            component: v => (
              <Slider.Question
                sliderType={Slider.SliderType.picture}
                onValid={v}
                labels={[""]}
                picture={Burger}
                backgroundPicture={Plate}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },

  {
    id: "LQ_EA1_3.1",
    mandatory: true,
    plainTitle: "Dinner frequency",
    title: <span>How often do you eat dinner?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Monthly", "Weekly", "Every day"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_EA1_3.2",
    plainTitle: "Dinner foods and times",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_EA1_3.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    mandatory: false,
    title: "Regarding dinner...",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_EA1_3.2.1",
            title: "When do you usually eat it?",
            component: v => (
              <Range.Question
                onValid={v}
                includeDone={false}
                labels={[
                  "4 PM",
                  "5 PM",
                  "6 PM",
                  "7 PM",
                  "8 PM",
                  "9 PM",
                  "10 PM",
                  "11 PM"
                ]}
              />
            )
          },
          {
            id: "LQ_EA1_3.2.2",
            title: "How large?",
            component: v => (
              <Slider.Question
                sliderType={Slider.SliderType.picture}
                onValid={v}
                labels={[""]}
                picture={Burger}
                backgroundPicture={Plate}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_EA1_4.1",
    mandatory: true,
    plainTitle: "Snack frequency",
    title: <span>How often do you eat snacks?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Monthly", "Weekly", "Every day", ">1 per day"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_EA1_4.2",
    plainTitle: "Snack foods and times",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_EA1_4.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    mandatory: false,
    title: "Regarding snacks...",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_EA1_4.2.1",
            title: "When do you usually eat it?",
            component: v => (
              <Range.Question
                onValid={v}
                includeDone={false}
                labels={[
                  "12-2 AM",
                  "2-4 AM",
                  "4-6 AM",
                  "6-8 AM",
                  "8-10 AM",
                  "10 AM to 12 PM",
                  "12-2 PM",
                  "2-4 PM",
                  "4-6 PM",
                  "6-8 PM",
                  "8-10 PM",
                  "10AM to 12 PM"
                ]}
              />
            )
          },
          {
            id: "LQ_EA1_4.2.2",
            title: "How large?",
            component: v => (
              <Slider.Question
                sliderType={Slider.SliderType.picture}
                onValid={v}
                labels={[""]}
                picture={Muffin}
                backgroundPicture={Plate}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_EA1_5",
    mandatory: true,
    plainTitle: "Meal regularity",
    title: (
      <span>To what extent do you prefer to have meals at regular times?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "Always at the same time",
          "Within the same hour",
          "Within 2-3 hours",
          "Whenever"
        ]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];
export default questions;
