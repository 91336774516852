import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./SleepLogDayQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import ChooseSessionVertical from ".././DayPickerVertical";


import { En, De, l, LanguageContext } from "ewoq/components/Language";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

interface State {
  name?: string;
  week: string;
  weekNumber: string;
  day: string;
  dayNumber: string; 
  weekday: string;
  weekdaysAll: any;
}

export class SleepLogDay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { 
      name: this.props.name || "",
      week: "",
      weekNumber: "",
      day: "",
      dayNumber: "",
      weekday: "",
      weekdaysAll: this.writeWeekdays(),
    };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "Week-" + this.state.weekNumber + "-Day-" + this.state.dayNumber + "-Studynightweekday-" + this.state.weekday + "-SleepLogBook", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };

  writeWeekdays = () => {
    const weekdaysAll = new Array;
    weekdaysAll[0] = l({en: "Monday",    de: "Montag"});
    weekdaysAll[1] = l({en: "Tuesday",   de: "Dienstag"});
    weekdaysAll[2] = l({en: "Wednesday", de: "Mittwoch"});   
    weekdaysAll[3] = l({en: "Thursday",  de: "Donnerstag"});    
    weekdaysAll[4] = l({en: "Friday",    de: "Freitag"});    
    weekdaysAll[5] = l({en: "Saturday",  de: "Samstag"});   
    weekdaysAll[6] = l({en: "Sunday",    de: "Sonntag"});    
    weekdaysAll[7] = l({en: "Monday",    de: "Montag"});    
    return(weekdaysAll)
  }
  
  getWeekday(weekday: string, week: string) {
    const weekdaysAll = this.state.weekdaysAll;
    const thisWeekdayIndex = weekdaysAll.indexOf(weekday); // index of chosen weekday
    var weekdaysIndex = Array.from({length: 8}, (_, i) => i + thisWeekdayIndex); 
    var weekdaysIndexSorted = new Array();
    weekdaysIndex.forEach(function(digit){
        if(digit >= 7){
          weekdaysIndexSorted.push(digit - 7);
        } else {
          weekdaysIndexSorted.push(digit);
        }
    }); 
    var W2 = 0;
    if (week == "Week 2" || week == "Woche 2" ){
       W2 = 1;
    }
    var days = new Array();
    weekdaysIndexSorted.forEach(function(digit, index){
      if(index < weekdaysIndexSorted.length - 1){
        days.push(`${l({en: "Day", de: "Tag"})} ${index-W2+1}: ${weekdaysAll[digit]}`);
        console.log("digit:" + digit)
        console.log("index:" + index)
      }
    });
   console.log("Weekday: " + weekday)
   console.log("This weekday index: " + thisWeekdayIndex)
   console.log("Weekdays index: " + weekdaysIndexSorted)
   console.log("Weekdays index length:" + weekdaysIndex.length)
   days = W2===1 ? days.slice(1, 7) : days    
    return (days);
  };    

  onClick = (selection: string) => {
    this.setState({ name: this.props.name + "-" + selection });
  };

  chooseWeek = (selection: string) => {
    this.setState({ week: selection, weekNumber: selection.slice(-1) });
  };

  chooseDay = (selection: string) => {
    this.setState({ day: selection, dayNumber: selection.split(':', 1)[0].slice(-1)  });
  };

  chooseWeekday = (selection: string) => {
    this.setState({ weekday: selection });
  };    

  render() {
    console.log("Output name:" + "Week-" + this.state.weekNumber + "-Day-" + this.state.dayNumber + "-Studynightweekday-" + this.state.weekday + "-SleepLogBook")
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">
            <De>Wie war Dein Tag?</De>
            <En>How was your day?</En>
          </Ui.Container>
        </HeaderContainer>

        <Ui.Container>
        <ChooseSession
            sessions={[this.state.weekdaysAll.slice(0, 7)]}
            selection={this.state.weekday}
            onClick={this.chooseWeekday}
            title={l({en: "Weekday of your first experiment night", de: "Wochentag deiner ersten Labornacht"})}
          />            
          <ChooseSession
            sessions={[[l({en: "Week 1", de: "Woche 1"}), l({en: "Week 2", de: "Woche 2"})]]}
            selection={this.state.week}
            onClick={this.chooseWeek}
            title={l({en: "control week", de: "Kontrollwoche"})}
          />
          <ChooseSessionVertical
            // sessions={[["Day-1", "Day-2", "Day-3", "Day-4", "Day-5", "Day-6", "Day-7"]]}
            sessions={[this.getWeekday(this.state.weekday, this.state.week)]}
            selection={this.state.day}
            onClick={this.chooseDay}
            title={l({en: "Day", de: "Tag"})}
          />
        </Ui.Container>

        {this.state.week && this.state.day ? (
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          /> ) : null}
      </div>
    );
  }
}

export default SleepLogDay;
