import React from "react";
import * as Slider from "ewoq/prototypes/Slider";
import * as Radio from "ewoq/prototypes/Radio";
import { En, De, l } from "ewoq/components/Language";

export const kss = (v: any) => (
  <Slider.Question
    onValid={v}
    sliderType={Slider.SliderType.vertical}
    labels={[
      l({ en: "Extremely alert",                                         de: "Äusserst wach" }),
      l({ en: "Very alert",                                              de: "Sehr wach" }),
      l({ en: "Alert",                                                   de: "Normal wach" }),
      l({ en: "Rather alert",                                            de: "Ziemlich wach" }),
      l({ en: "Neither alert nor sleepy",                                de: "Weder wach noch schläfrig" }),
      l({ en: "Some signs of sleepiness",                                de: "Etwas schläfrig" }),
      l({ en: "Sleepy, but no effort to keep awake",                     de: "Schläfrig, ohne Mühe wach zu bleiben" }),
      l({ en: "Sleepy, some effort to keep awake",                       de: "Schläfrig, etwas Mühe wach zu bleiben" }),
      l({ en: "Very sleepy, great effort to keep awake, fighting sleep", de: "Schläfrig, grosse Mühe wach zu bleiben" }), 
    ]}
  />
);

export const standardKSS = (v: any) => (
  <Radio.Question
    onValid={v}
    options={[
      { text: "1 = Extremely alert" },
      { text: "2 = Very alert" },
      { text: "3 = Alert" },
      { text: "4 = Rather alert" },
      { text: "5 = Neither alert nor sleepy" },
      { text: "6 = Some signs of sleepiness" },
      { text: "7 = Sleepy, but no effort to keep awake" },
      { text: "8 = Sleepy, some effort to keep awake" },
      {
        text: "9 = Very sleepy, great effort to keep awake, fighting sleep"
      }
    ]}
  />
);
