import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/MenstrualCycleQuestions";
import { answerQuestionnaire } from "ewoq/api";

interface Props {
    viewAll?: boolean;
    onDone?: () => void;
    // language: Language;
}


class MenstrualCycle extends React.Component<Props> {
    onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "BasicInfo-MenstrualCycle", { answers: answers });
    this.props.onDone && this.props.onDone();
    };
  
    render() {
        return (
          <div>
            <Interrogator
              questions={questions}
              onDone={this.onSubmit}
              viewAll={this.props.viewAll}
            />
          </div>
        );
      }
    }

export default MenstrualCycle;