import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_MRI_1",
    mandatory: false,
    plainTitle: "Pacemaker",
    title: (
      <span>
        <En>
          
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       does your child </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      do you          </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     do you          </SPChoice>          

        </En>       
        <De>
          <SPChoice studyPopulation={StudyPopulation.HealthyKids}>   Trägt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyTeens}>  bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.HealthyAdults}> sind Sie     </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiKids}>       Trägt Ihr Kind </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiTeens}>      bist Du       </SPChoice>
          <SPChoice studyPopulation={StudyPopulation.EpiAdults}>     sind Sie     </SPChoice>             
          einen Herzschrittmacher, Neurostimulator oder eine Liquordrainage (VP) Shunt? 
        </De>      
      </span>
    ),
    component: (v) => (
      <Radio.Question
        onValid={v}
        options={[
          { text:         l({ en: "Yes", de: "Ja" }),
          placeHolder:  l({ en: "Please specify in detail", de: "Bitte erläutern Sie" }),
          extraInput:   "string"},
          { text:         l({ en: "No", de: "Nein" }) } 
          
        ]}
      />
    ),
  },
  
];

export default questions;
