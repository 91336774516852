import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l, LanguageContext } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as Input from "ewoq/prototypes/TypeInput";



const questions: QuestionType[] = [

  {
    id: "SLB-01",
    plainTitle: "Caffeine y/n",
    mandatory: true,
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Did your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Did you </SPChoice>
         drink any beverages or eat any foods (coffee, black tea, Cola, Red Bull, chocolate etc.) that contained caffeine today?
      </En>

      <De> 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Hat Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> Hast Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Haben Sie </SPChoice>
        heute koffeinhaltige Getränke oder Nahrungsmittel (Kaffee, Schwarztee, Cola, Red Bull, Schokolade etc.) eingenommen? 
      </De> 
      </span>
    ),    
    component: v => <YesNoQ.Question onValid={v} />
  },

  {
    id: "SLB-01-1",
    mandatory: true,
    title: l({
      en: "Caffeinated food",
      de: "Koffeinhaltige Lebensmittel"}),
    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "SLB-01");
  
      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person plays an instrument
    },    
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "Q1",
            title: l({
              en: "Time",
              de: "Uhrzeit"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="time"
              />
            )
          },
          {
            id: "Q2",
            title: l({
              en: "Beverage/Food",
              de: "Getränk/Nahrungsmittel"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
              />
            )
          },
          {
            id: "Q3",
            title: l({
              en: "Amount",
              de: "Menge"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
              />
            )
          },
        ]}
      />
    )
  },





  {
    id: "SLB-02",
    plainTitle: "Alcohol y/n",
    mandatory: true,
    title: (
      <span>
      <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Did your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Did you </SPChoice>
         drink drink alcohol today?
      </En>

      <De> 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Hat Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> Hast Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Haben Sie </SPChoice>
        heute alkoholhaltige Getränke eingenommen?  
      </De> 
      </span>
    ),    
    component: v => <YesNoQ.Question onValid={v} />
  },  

  {
    id: "SLB-02-1",
    mandatory: true,
    title: l({
      en: "Alcoholic drinks",
      de: "Alkoholhaltige Getränke"}),
    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "SLB-02");
  
      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person plays an instrument
    },    
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "Q1",
            title: l({
              en: "Time",
              de: "Uhrzeit"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="time"
              />
            )
          },
          {
            id: "Q2",
            title: l({
              en: "Beverage",
              de: "Getränk"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
              />
            )
          },
          {
            id: "Q3",
            title: l({
              en: "Amount",
              de: "Menge"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
              />
            )
          },
        ]}
      />
    )
  },





  {
    id: "SLB-03",
    plainTitle: "Alcohol y/n",
    mandatory: true,
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Did your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Did you </SPChoice>
        remove the activity monitor today? 
      </En>

      <De> 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}> Hat Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}> Hast Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}> Haben Sie </SPChoice>
        heute den Aktivitätsmonitor weggelegt? 
      </De>     
      </span>
    ),    
    component: v => <YesNoQ.Question onValid={v} />
  },   

  {
    id: "SLB-03-1",
    mandatory: true,
    title: l({
      en: "removed activity monitor",
      de: "Aktimeter weggelegt"}),
    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "SLB-03");
  
      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person plays an instrument
    },    
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "Q1",
            title: l({
              en: "From (clock time)",
              de: "Von (Uhrzeit)"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="time"
              />
            )
          },
          {
            id: "Q2",
            title: l({
              en: "To (clock time)",
              de: "Bis (Uhrzeit)"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="time"
              />
            )
          },
          {
            id: "Q3",
            title: l({
              en: "Reason",
              de: "Grund"}),
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
              />
            )
          },
        ]}
      />
    )
  },  
 
];
export default questions;
