import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import { En, De, l } from "ewoq/components/Language";

import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";

const questions: QuestionType[] = [
  {
    id: "CS_1",
    plainTitle: "KSS sleepiness:",
    title: (
      <span>
        <En>
          Please indicate&nbsp; 
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
           your childs </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
           your </SPChoice>
          sleepiness over the past 10 minutes: 
        </En>
  
        <De>
          Bitte&nbsp; 
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          beschreiben Sie die Müdigkeit Ihres Kindes </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          beschreibe Deine Müdigkeit </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          beschreiben Sie Ihre Müdigkeit </SPChoice>
          in den letzten 10 Minuten:
        </De>
      </span>),
    component: v => kss(v)
  },
  {
    id: "CS_2",
    plainTitle: "Energy levels:",
    title: (<span>
        <En>
          How&nbsp; 
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
           does your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
           do you </SPChoice>
           feel:
        </En>
  
        <De>
          Wie&nbsp; 
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          fühlt sich Ihr Kind: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          fühlst Du Dich: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          fühlen Sie Sich: </SPChoice>
        </De>
      </span>),
    component: v => fourEnergiesPresent(v)
  },
  {
    id: "CS_3.1",
    plainTitle: "Alertness:",
    title: (
      <span>
        <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          How alert is your child? How quickly do you think your child would respond to 
          something (e.g. a ball thrown at its head)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          How alert are you? How quickly do you feel you would respond to
          something (e.g. a ball thrown at your head)?</SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Wie wachsam/munter ist Ihr Kind? Was denken Sie, wie schnell es auf etwas reagieren würde (z.B. ein Ball wird ihm an den Kopf geworfen)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Wie wachsam/munter bist Du? Was denkst Du, wie schnell du auf etwas reagieren würdest (z.B. ein Ball wird Dir an den Kopf geworfen)?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Wie wachsam/munter sind Sie? Was denken Sie, wie schnell Sie auf etwas reagieren würden (z.B. ein Ball wird Ihnen an den Kopf geworfen)?</SPChoice>
        </De>
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Slow, sluggish", de: "langsam, träge" }),
          l({ en: "Normal", de: "Normal" }),
          l({ en: "Hyperalert", de: "Hyper-Wachsam" }),
        ]}
      />
    )
  },
  {
    id: "CS_3.2",
    plainTitle: "Focus:",
    title: (
    <span>
      <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          How focused / concentrated is your child at the moment?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          How focused / concentrated are you at the moment?</SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Wie fokusiert / konzentriert ist Ihr Kind jetzt gerade?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Wie fokusiert / konzentriert bist Du jetzt gerade?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Wie fokusiert / konzentriert sind Sie jetzt gerade?</SPChoice>
        </De>
      </span>
      ),
    explanation:(
    <span>
      <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Imagine your child is in a school lesson, how capable would your child be of paying attention?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Imagine you are in a school lesson, how capable would you be of paying attention?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Imagine you are in a lecture, how capable would you be of paying attention?</SPChoice>
      </En>

      <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Stellen Sie sich vor Ihr Kind wäre in einer Schulstunde, wie gut könnte es aufpassen?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Stell Dir vor Du wärst in einer Schulstunde, wie gut könntest Du aufpassen?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Stellen Sie Sich vor Sie wären in einer Vorlesung, wie gut könnten Sie aufpassen?</SPChoice>
      </De>
    </span>
    ),
      
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Very unfocused", de: "Sehr unfokusiert" }),
          l({ en: "Very concentrated", de: "Sehr konzentriert" }),
        ]}
      />
    )
  },
  {
    id: "CS_4",
    plainTitle: "Sleep pressure:",
    title: (
    <span>
      <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        How much need does your child feel to fall asleep right now?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        How much need do you feel to fall asleep right now?</SPChoice>
      </En>

      <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Wie gross ist das Bedürfnis Ihres Kindes einzuschlafen?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Wie gross ist Dein Bedürfnis einzuschlafen?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Wie gross ist Ihr Bedürfnis einzuschlafen?</SPChoice>
      </De>
      </span>
      ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "I can't sleep right now",                         de: "Ich kann jetzt nicht schlafen" })},
          { text: l({ en: "I would have a hard time falling asleep" ,        de: "Ich hätte Schwierigkeiten einzuschlafen" })},
          { text: l({ en: "I might sleep" ,                                  de: "Ich könnte evtl. schlafen" })},
          { text: l({ en: "I would like to sleep",                           de: "Ich würde gerne schlafen" })},
          { text: l({ en: "I really want to sleep",                          de: "Ich will wirklich schlafen" })},
          { text: l({ en: "This is the most I've ever wanted to sleep",      de: "Ich wollte noch nie so sehr schlafen" })},
          { text: l({ en: "I feel tired, but I don't think I could sleep",   de: "Ich bin müde, aber ich denke, ich könnte jetzt nicht schlafen" })}, //this one is still misplaced, right?
        ]}
      />
    )
  },
  {
    id: "CS_5_1",
    mandatory: false,
    plainTitle: "General feelings:",
    title: (
    <span>
       <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        What is your childs mood right now?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        What is your mood right now?</SPChoice>
      </En>

      <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Wie ist Ihr Kind jetzt gerade gelaunt?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Wie bist Du jetzt gerade gelaunt?</SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Wie sind Sie jetzt gerade gelaunt?</SPChoice>
      </De>
      </span>
      ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}labels={[
          l({ en: "Very bad", de: "Sehr schlecht" }),
          l({ en: "Very good", de: "Sehr gut" }),
        ]}
      />
    )
  },
  {
    id: "CS_5_2",
    mandatory: false,
    plainTitle: "Feelings:",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Indicate on the following scales how your child feels right now: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Indicate on the following scales how you feel right now: </SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Beschreiben Sie auf den folgenden Skalen, wie sich Ihr Kind gerade fühlst:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Beschreibe auf den folgenden Skalen, wie Du Dich gerade fühlst:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Beschreiben Sie auf den folgenden Skalen, wie Sie Sich gerade fühlen:</SPChoice>
        </De>
        </span>
        ),
    component: v => feelings(v)
  },
  {
    id: "CS_5_3",
    mandatory: true,
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Does your child feel... </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Do you feel... </SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Fühlt sich Ihr Kind...</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Fühlst Du Dich...</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Fühlen Sie Sich...</SPChoice>
        </De>
        </span>
        ),
    plainTitle: "Stress:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Relaxed", de: "Entspannt" }),
          l({ en: "Tense", de: "Angespannt" }),
          l({ en: "Stressed", de: "Gestresst" }),
        ]}
      />
    )
  },
  {
    id: "CS_6",
    mandatory: false,
    plainTitle: "Extra feelings:",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Check all that apply: </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Check all that apply: </SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Wählen Sie alle aus, die zutreffen:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Wähle alle aus, die zutreffen:</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Wählen Sie alle aus, die zutreffen:</SPChoice>
        </De>
        </span>
        ),
    component: v => extraFeelings(v)
  },
  {
    id: "CS_7",
    mandatory: false,
    plainTitle: "Emotional control:",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          How much control does your child feel it has on its emotions right now?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          How much control do you feel you have on your emotions right now?</SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Was denkt Ihr Kind wie viel Kontrolle über seine Emotionen hat es jetzt gerade?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Was denkst Du wie viel Kontrolle über Deine Emotionen hast Du jetzt gerade?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Was denken Sie, wie viel Kontrolle über Ihre Emotionen haben Sie jetzt gerade?</SPChoice>
        </De>
        </span>
        ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "None", de: "Keine" }),
          l({ en: "Some", de: "Ein bisschen" }),
          l({ en: "Excellent", de: "Exzellente" }),
        ]}
      />
    )
  },
  {
    id: "CS_8",
    plainTitle: "Motivation:",
    title: (
      <span>
         <En>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          How motivated is your child at the moment to carry out the following tests?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          How motivated are you at the moment to carry out the following tests?</SPChoice>
        </En>
  
        <De>
          <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Wie motiviert ist Ihr Kind jetzt gerade, um die folgenden Tests zu machen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Wie motiviert bist Du jetzt gerade, um die folgenden Tests zu machen?</SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Wie motiviert sind Sie jetzt gerade, um die folgenden Tests zu machen?</SPChoice>
        </De>
        </span>
        ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          l({ en: "Not motivated at all", de: "Überhaupt nicht motiviert" }),
          l({ en: "Extremely motivated", de: "Extrem motiviert" }),
        ]}
      />
    )
  }
];
export default questions;
