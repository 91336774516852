import React from "react";
import * as Ui from "semantic-ui-react";
import { AnswerType } from "./YesNo";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "YesNoGroup";

const Text = styled.div`
  width: 100%;
  line-height: auto;
`;

interface Props {
  onValid: (state?: AnswerObj) => void;
  subQuestions: string[];
}

export interface State {
  answers: AnswerType[];
}

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: Array(this.props.subQuestions.length).fill(
        AnswerType.undetermined
      )
    };
  }

  setAnswer = (answer: AnswerType, idx: number) => () => {
    const answers = this.state.answers.slice();
    answers[idx] = answer;
    this.setState({ answers });
    if (!answers.includes(AnswerType.undetermined)) {
      this.setState({ answers }, () =>
        this.props.onValid({
          data: { answers: this.state.answers },
          questionProps: this.props,
          type
        })
      );
    }
  };

  render() {
    return (
      <Ui.SegmentGroup>
        {this.props.subQuestions.map((question, idx) => (
          <Ui.Segment key={idx}>
            <div>
              <Ui.Grid>
                <Ui.Grid.Row>
                  <Ui.Grid.Column width={10} verticalAlign="middle">
                    {" "}
                    <Text>{question}</Text>
                  </Ui.Grid.Column>
                  <Ui.Grid.Column width={5} textAlign="left">
                    {" "}
                    <Ui.Button.Group size="mini" compact>
                      <Ui.Button
                        onClick={this.setAnswer(AnswerType.yes, idx)}
                        color={
                          this.state.answers[idx] === AnswerType.yes
                            ? "violet"
                            : undefined
                        }
                      >
                        Yes
                      </Ui.Button>
                      <Ui.Button.Or />
                      <Ui.Button
                        onClick={this.setAnswer(AnswerType.no, idx)}
                        color={
                          this.state.answers[idx] === AnswerType.no
                            ? "violet"
                            : undefined
                        }
                      >
                        No
                      </Ui.Button>
                    </Ui.Button.Group>
                  </Ui.Grid.Column>
                </Ui.Grid.Row>
              </Ui.Grid>
            </div>
          </Ui.Segment>
        ))}
      </Ui.SegmentGroup>
    );
  }
}

export default Question;
