import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { getUserId } from "ewoq/utils";
import { En, De, l } from "ewoq/components/Language";
import { SPChoice, StudyPopulation, StudyPopulationContext, defaultStudyPopulation } from "StudyPopulationChoice";

const HeaderContainer = styled.div`
  height: 4em;
  font-size: 2em;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;
const SectionBody = styled.p`
  text-align: justify;
  margin-bottom: 3em;
  margin-top: 1em;
`;
const Smiley = styled.span`
  font-size: 1em;
`;
const CodeText = styled.div`
  font-size: 1.5em;
  text-transform: uppercase;
  font-style: bold;
  text-align: center;
  margin: 0.5em;
`;

class Ineligible extends React.Component {
  render() {
    return (
      <div>
        <HeaderContainer>
          <En>
            Unfortunately, you don't meet all the screening criteria for this
            experiment
          </En>
          <De>
            Leider erfüllen Sie nicht alle Kriterien für dieses Experiment.
          </De>
          <Smiley>☹️</Smiley>
        </HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <SectionBody>
              <En>
                <p>
                  However, we are conducting similar sleep studies all the time,
                  with different screening criteria. If you wish to learn more
                  about other opportinities, you can click on the button below, or
                  you may contact me at: later_experiments@sophia.science, and I
                  will keep your email to contact you about future studies that
                  have less stringent criteria.
              </p>
              </En>
              <De>
                <p>
                  Wir führen jedoch permanent ähnliche Studien mit anderen Kriterien durch.
                  Falls Sie gerne mehr über andere Möglichkeiten erfahren möchten,
                  können Sie auf den Button unten klicken oder uns über folgende E-Mail
                  erreichen: later_experiments@sophia.science. Sie werden dann umgehend kontaktiert,
                  falls eine Studie mit anderen Kriterien gestartet wird.
              </p>
              </De>
              <p>
                <a href="./studies">
                  <Ui.Button size="large" fluid color="violet">
                  <En>
                  Other ongoing experiments
                  </En>
                  <De>
                  Andere laufende Experimente
                  </De>
                  </Ui.Button>{" "}
                </a>
              </p>
            </SectionBody>
          </Ui.Segment>
          <Ui.Segment>
            <div>
              <En>
                If you think there may have been a mistake, please email
                elena.schmidt@kispi.uzh.ch, and provide him with the first word of
                the following code:
              </En>
              <De>
                Falls Sie glauben, dass ein Fehler vorliegt, nehmen Sie bitte kontakt
                mit uns auf über elena.schmidt@kispi.uzh.ch und teilen Sie uns das erste 
                Wort dieses Codes mit:
              </De>
            </div>
            <CodeText>{getUserId()}</CodeText>
            <div>
            <En>
            Save this code for future reference, but keep it secret.
            </En>
            <De>
            Notieren Sie sich diesen Code für alle Fälle, aber behandeln Sie ihn vertraulich.
            </De></div>
          </Ui.Segment>
        </Ui.Container>
        <Ui.Container>
          <a href="/">
            <SectionBody>
              <Ui.Button
                content={"Experiment Information"}
                icon="arrow left"
                compact
                size="tiny"
              />
            </SectionBody>
          </a>
        </Ui.Container>
      </div>
    );
  }
}

export default Ineligible;
