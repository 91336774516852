import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

const questions: QuestionType[] = [
  {
    id: "LQ_HAN_1",
    mandatory: true,
    plainTitle: "Handedness:",
    title: <span>Are you left or right handed?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Left" }, { text: "Right" }]}
      />
    )
  },
  {
    id: "LQ_HAN_2",
    mandatory: false,
    plainTitle: "Preffered hand when...",
    title: <span>Which hand/foot do you prefer when: </span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            question: "Writing",
            labels: ["Always left", "Either", "Always right"]
          },
          { question: "Throwing a ball" },
          { question: "Using scissors" },
          { question: "Eating with a fork" },
          { question: "Kicking a ball" },
          { question: "First step walking" },
          { question: "Jumping on one foot" }
        ]}
      />
    )
  },
  {
    id: "LQ_HAN_3",
    mandatory: false,
    plainTitle: "Forced hand switch",
    title: (
      <span>
        As a child, did you ever use your left hand (for writing/drawing) and
        were obliged to switch to the right hand by a teacher or relative?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "I don't know" }]}
      />
    )
  }
];

export default questions;
