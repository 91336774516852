// stduyPopulation must be set for this to work

import React from "react";
import Consent from "./Consent";
// import SensitiveChecklist from "./SensitiveChecklist";
import Ineligible from "./Ineligible";
import Eligible from "./EligibleForms";
// import PSQI from "./PSQI";
// import MCTQ from "./MCTQ";
// import Wake from "./Wake";
// import Sleep from "./Sleep";
import BasicExclusion from "./BasicExclusion";
import Entrance from "./Entrance";

import { Language } from "./misc";
import { LanguageProvider } from "ewoq/components/Language";
import { StudyPopulation, SPProvider} from "StudyPopulationChoice";

// This file controls what is shown during the questionnaires.
// It is used to specify the order of questionnaires, and what
// happens if the person is eligible or ineligible

enum Phase {
  Consent,
  BasicExclusion,
  Entrance,  
  // Sensitive,
  // PSQI,
  // MCTQ,
  // Wake,
  // Sleep,
  Ineligible,
  Eligible
}

interface Props {
  onLanguageChoice: (language: Language) => void;
  onStudyPopulationChoice: (population: StudyPopulation) => void;
}

interface State {
  phase: Phase;
  language: Language;
  studyPopulation: StudyPopulation;
  eligible: boolean;
}

class Screening extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phase: Phase.Consent,
      language: Language.en,
      studyPopulation: StudyPopulation.NotChosen,
      eligible: true
    };
  }

  advancePhase = (eligiblePhase: Phase, ineligiblePhase: Phase) => (
    answer: boolean
  ) => {
    const eligible = this.state.eligible && answer;
    this.setState({
      phase: answer ? eligiblePhase : ineligiblePhase,
      eligible
    });
    console.log("screening ", this.state);
  };

  onLanguageChoice = (language: Language) => this.setState({ language });
  onStudyPopulationChoice = (studyPopulation: StudyPopulation) => this.setState({ studyPopulation });

  render() {
    return (
      <LanguageProvider value={this.state.language}>
        <SPProvider value={this.state.studyPopulation}>
        {this.renderInner()}
        </SPProvider>
      </LanguageProvider>
    );
  }

  renderInner() {
    {
      console.log("screening 2", this.state);
    }
    switch (this.state.phase) {
      case Phase.Consent: {
        return (
          <Consent
            language={this.state.language}
            studyPopulation={this.state.studyPopulation}
            onChangeLanguage={this.onLanguageChoice}
            onChangeStudyPopulation={this.onStudyPopulationChoice}
            onDone={this.advancePhase(Phase.BasicExclusion, Phase.Consent)}
          />
        );
      }
      case Phase.BasicExclusion: {
        return <BasicExclusion onDone={this.advancePhase(Phase.Entrance, Phase.Ineligible)} />;
      }    
      case Phase.Entrance: {
        return <Entrance onDone={this.advancePhase(Phase.Eligible, Phase.Ineligible)} />;
      }          
      // case Phase.Sensitive: {
      //   return (
      //     <SensitiveChecklist
      //       onDone={this.advancePhase(Phase.PSQI, Phase.Ineligible)}
      //     />
      //   );
      // }
      // case Phase.PSQI: {
      //   return <PSQI onDone={this.advancePhase(Phase.MCTQ, Phase.MCTQ)} />;
      // }
      // case Phase.MCTQ: {
      //   return <MCTQ onDone={this.advancePhase(Phase.Wake, Phase.Wake)} />;
      // }
      // case Phase.Wake: {
      //   return <Wake onDone={this.advancePhase(Phase.Sleep, Phase.Sleep)} />;
      // }
      // case Phase.Sleep: {
      //   return (
      //     <Sleep onDone={this.advancePhase(Phase.Eligible, Phase.Ineligible)} />
      //   );
      // }
      case Phase.Ineligible: {
        return <Ineligible />;
      }

      case Phase.Eligible: {
        return <Eligible />;
      }
    }
  }
}

export default Screening;
