import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as SimpleForm from "ewoq/prototypes/TypeInput";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import WhyPopup from "ewoq/components/WhyPopup";
import * as Radio from "ewoq/prototypes/Radio";
import { str2Time } from "ewoq/utils";
import { En, De, l, L } from "ewoq/components/Language";

const questions: QuestionType[] = [
  {
    id: "SQ_PSQI_1",
    plainTitle: "Usual bed time:",
    title: (
      <span>
        <En>
          During the past month, at what time have you usually gone to bed at
          night?
        </En>
        <De>
          Um welche Zeit sind Sie im vergangenen Monat normalerweise ins Bett
          gegangen?
        </De>
      </span>
    ),
    explanation: (
      <span>
        <En>
          If you read or use your phone before bed, then give the time at which
          you stop reading and/or put your phone away.
        </En>
        <De>
          Wenn Sie noch lesen oder Ihr Handy brauchen vor dem Schlafen gehen,
          geben Sie die Uhrzeit an, bei welcher Sie aufhören zu lesen oder Ihr
          Handy weglegen.
        </De>
      </span>
    ),
    warning: "Are you sure?",
    isOk: (s: SimpleForm.State) => {
      const BT = str2Time(s.text) % 24;
      return BT > 19 || BT < 2;
    }, //bedtime earlier than 19 or later than 2 is a problem
    component: v => <SimpleForm.Question inputType={"time"} onValid={v} />
  },
  {
    id: "SQ_PSQI_2",
    plainTitle: "Usual falling asleep time:",
    title: (
      <span>
        <En>
          During the past month, how long has it usually taken you to fall
          asleep each night?
        </En>
        <De>
          Wie lange haben Sie im vergangenen Monat normalerweise gebraucht, um
          einschlafen zu können?
        </De>
      </span>
    ),
    isOk: (s: SimpleForm.State) => {
      return parseInt(s.text) < 60; // less than an hour is ok
    },

    component: v => (
      <SimpleForm.Question
        placeholder={l({ en: "minutes", de: "Minuten" })}
        inputType={"number"}
        onValid={v}
      />
    )
  },
  {
    id: "SQ_PSQI_3",
    plainTitle: "Usual wake up time:",
    title: (
      <span>
        <En>
          During the past month, at what time have you usually gotten up in the
          morning?
        </En>
        <De>
          Um welche Zeit sind Sie im vergangenen Monat am Morgen jeweils
          aufgestanden?
        </De>
      </span>
    ),
    isOk: (s: SimpleForm.State) => {
      const BT = str2Time(s.text);
      return BT >= 6 && BT < 12;
    }, // aything earlier than 6 or later than 11 is a problem.
    warning: "Are you sure?",
    component: v => <SimpleForm.Question inputType={"time"} onValid={v} />
  },
  {
    id: "SQ_PSQI_4",
    plainTitle: "Usual sleep duration:",
    title: (
      <span>
        <En>
          During the past month, how many hours of actual sleep
          <WhyPopup>
            This does not include time in bed trying to fall asleep.
          </WhyPopup>{" "}
          did you get at night?
        </En>
        <De>
          Wie viele Stunden haben Sie im letzten Monat tatsächlich
          <WhyPopup>
            Ohne die Zeit, die Sie gebraucht haben, um einzuschlafen.
          </WhyPopup>{" "}
          in der Nacht geschlafen?
        </De>
      </span>
    ),
    isOk: (s: SimpleForm.State) => {
      const SD = parseInt(s.text);
      return SD <= 12 && SD >= 6;
    },
    component: v => (
      <SimpleForm.Question
        placeholder={l({ en: "hours", de: "Stunden" })}
        inputType={"number"}
        onValid={v}
      />
    )
  },
  {
    id: "SQ_PSQI_5",
    plainTitle: "Sleeping trouble:",
    title: (
      <span>
        <En>
          During the past month, how often have you had trouble sleeping because
          you...
        </En>
        <De>
          Wie oft hatten Sie im Verlauf des vergangenen Monats Probleme zu
          schlafen, weil Sie...
        </De>
      </span>
    ),
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          // NOTE: order matters!
          l({
            en: "Cannot go to sleep within 30 minutes",
            de: "Nicht innerhalb von 30 Minuten einschlafen können"
          }),
          l({
            en: "Wake up in the middle of the night or early morning",
            de: "Mitten in der Nacht oder früh am Morgen aufwachen"
          }),
          l({
            en: "Have to get up to use the bathroom",
            de: "Aufstehen müssen um auf die Toilette zu gehen"
          }),
          l({
            en: "Cannot breath comfortably",
            de: "Nicht bequem atmen können"
          }),
          l({
            en: "Cough or snore loudly",
            de: "Husten müssen oder laut schnarchen"
          }),
          l({ en: "Feel too cold", de: "Zu kalt haben" }),
          l({ en: "Feel too hot", de: "Zu heiss haben" }),
          l({ en: "Have bad dreams", de: "Schlecht träumen" }),
          l({ en: "Have pain", de: "Schmerzen haben" }),
          l({ en: "Other", de: "Andere" })
        ]}
        options={[
          l({
            en: "Not during the past month",
            de: "Nie während dem letzten Monat"
          }),
          l({
            en: "Less than once a week",
            de: "Weniger als einmal pro Woche"
          }),
          l({ en: "Once or twice a week", de: "1-2 Mal pro Woche" }),
          l({
            en: "Three or more times per week",
            de: "3 Mal oder mehr pro Woche"
          })
        ]}
      />
    )
  },
  {
    id: "SQ_PSQI_6",
    plainTitle: "Sleep quality:",
    title: (
      <span>
        <En>
          During the past month, how would you rate your sleep quality overall?
        </En>
        <De>Wie würden Sie Ihre Schlafqualität im letzten Monat bewerten?</De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 2, // any answer is ok except the last two
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "Very good", de: "Sehr gut" }) },
          { text: l({ en: "Fairly good", de: "Ziemlich gut" }) },
          { text: l({ en: "Fairly bad", de: "Ziemlich schlecht" }) },
          { text: l({ en: "Very bad", de: "Sehr schlecht" }) }
        ]}
      />
    )
  },
  {
    id: "SQ_PSQI_7",
    plainTitle: "Medication for sleep:",
    title: (
      <span>
        <En>
          During the past month, how often have you taken medicine to help you
          sleep, “over the counter” or prescribed
          <WhyPopup>
            <p>
              <b>Over the counter</b> means anything you buy at a pharmacy
              without a doctors perscription.
            </p>
            <p>
              <b>Perscribed</b> means anything requiring a doctor's note to buy.
            </p>
          </WhyPopup>
          ?
        </En>
        <De>
          Wie oft haben Sie im vergangenen Monat Medikamente genommen, die Ihnen
          beim Schlafen helfen (rezeptfrei oder verschrieben)
          <WhyPopup>
            <p>
              <b>Rezeptfrei</b> beinhaltet alle Medikamente die Sie in einer
              Apotheke ohne ärztliche Verschreibung kaufen können.
            </p>
            <p>
              <b>Verschrieben</b> bedeutet, dass Sie eine ärztliche Bestätigung
              brauchen, um das Medikament zu kaufen.
            </p>
          </WhyPopup>
          ?
        </De>
      </span>
    ),
    isOk: (s: Radio.State) => s.answer < 2, // any answer is ok except the last two
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "Not during the past month",
              de: "Nie während dem letzten Monat"
            })
          },
          {
            text: l({
              en: "Less than once a week",
              de: "Weniger als einmal pro Woche"
            })
          },
          { text: l({ en: "Once or twice a week", de: "1-2 Mal pro Woche" }) },
          {
            text: l({
              en: "Three or more times per week",
              de: "3 Mal oder mehr pro Woche"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_PSQI_8",
    plainTitle: "Difficulty in daily life:",
    title: (
      <span>
        <En>
          During the past month, how often have you had trouble staying awake
          while driving, eating meals, or engaging in social activity?
        </En>
        <De>
          Wie oft hatten Sie im vergangenen Monat Probleme damit, beim
          Autofahren, beim Essen oder bei sozialen Aktivitäten wach zu bleiben?
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "Not during the past month",
              de: "Nie während dem letzten Monat"
            })
          },
          {
            text: l({
              en: "Less than once a week",
              de: "Weniger als einmal pro Woche"
            })
          },
          { text: l({ en: "Once or twice a week", de: "1-2 Mal pro Woche" }) },
          {
            text: l({
              en: "Three or more times per week",
              de: "3 Mal oder mehr pro Woche"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_PSQI_9",
    plainTitle: "Regular enthusiasm:",
    title: (
      <span>
        <En>
          During the past month, how much of a problem has it been for you to
          keep enough enthusiasm to get things done?
        </En>
        <De>
          War es ein Problem für Sie im letzten Monat, motiviert genug zu sein,
          um Dinge zu erledigen?
        </De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({ en: "No problem at all", de: "Überhaupt kein Problem" })
          },
          {
            text: l({
              en: "Only a very slight problem",
              de: "Nur ein sehr kleines Problem"
            })
          },
          {
            text: l({ en: "Somewhat of a problem", de: "Manchmal ein Problem" })
          },
          {
            text: l({
              en: "A very big problem",
              de: "Ein sehr grosses Problem"
            })
          }
        ]}
      />
    )
  },
  {
    id: "SQ_PSQI_10",
    plainTitle: "Room mate?",
    title: (
      <span>
        <En>Do you have a bed partner or room mate?</En>
        <De>Haben Sie einen Bettpartner oder Mitbewohner?</De>
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: l({
              en: "No bed partner or room mate",
              de: "Kein Bettpartner oder Mitbewohner"
            })
          },
          {
            text: l({
              en: "Partner/room mate in other room",
              de: "Partner/Mitbewohner in anderem Zimmer"
            })
          },
          {
            text: l({
              en: "Partner/room mate in same room, but not same bed",
              de: "Partner/Mitbewohner im gleichen Zimmer, aber anderes Bett"
            })
          },
          {
            text: l({
              en: "Partner in same bed",
              de: "Partner im gleichen Bett"
            })
          }
        ]}
      />
    )
  }
];

export default questions;
