import React from "react";
import * as Ui from "semantic-ui-react";
import { getUserId } from "ewoq/utils";
import { En, De, l, LanguageContext } from "ewoq/components/Language";

import {SectionBody, HeaderContainer, ParticipantID} from "ewoq/components/miscStyles";

interface Props {
  onDone?: () => void;
}

export class ThankYouPage extends React.Component<Props> {
  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">             
            <En>Congrats!</En>
            <De>Glückwunsch!</De>
          </Ui.Container>
        </HeaderContainer>

        <Ui.Container>
            <Ui.Segment>

            <SectionBody>
              <En>Perfect! Your answers were successfully saved.</En>
              <De>Super! Deine Antworten wurden erfolgreich gespeichert.</De>
            </SectionBody>  
          </Ui.Segment>                 
        </Ui.Container>

        <Ui.Container>
          <a href="/cweek">
              <Ui.Button
                content={"Control week"}
                icon="arrow left"
                compact
                size="tiny"
              />
          </a>
        </Ui.Container>
      </div>
    );
  }
}

export default ThankYouPage;
