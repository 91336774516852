import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "TypeInputGroup";

const SubText = styled.div`
  font-size: 1.1em;
  margin-bottom: 1em;
`;

const SubSubText = styled.div`
  font-size: 0.9em;
  color: grey;
  margin-bottom: 1em;
  margin-top: -0.5em;
`;

interface subQuestion {
  text: any;
  placeholder?: string;
  inputType: string;
  explanation?: any;
}

interface Props {
  onValid: (state?: AnswerObj) => void;
  subQuestions: subQuestion[];
}

export interface State {
  answers: any[];
  isEmpty: any[];
}

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: Array(this.props.subQuestions.length).fill(undefined),
      isEmpty: Array(this.props.subQuestions.length).fill(undefined)
    };
  }

  isEmpty = (idx: number) => {
    // checks if the string is empty and that its not the first time the question is presented
    let check = false;

    let isEmpty = this.state.isEmpty.slice();

    if (this.state.answers[idx].length < 1) {
      if (this.state.isEmpty[idx] !== undefined) {
        check = true;
      }
      isEmpty[idx] = true;
    } else {
      isEmpty[idx] = false;
    }
    this.setState({ isEmpty });
    return check;
  };

  handleInput = (idx: number) => (a: any, b: any) => {
    const answers = this.state.answers.slice();
    answers[idx] = b.value;

    this.setState({ answers }, () => this.isEmpty(idx));

    if (!answers.includes(undefined)) {
      this.props.onValid({
        data: { answers: answers },
        questionProps: this.props,
        type
      });
    }
  };

  render() {
    return (
      <Ui.SegmentGroup>
        {this.props.subQuestions.map((question, idx) => (
          <Ui.Segment key={idx}>
            <SubText>
              {" "}
              <label>{question.text}</label>
            </SubText>
            <SubSubText>{question.explanation}</SubSubText>

            <Ui.Input
              placeholder={question.placeholder}
              onChange={this.handleInput(idx)}
              value={this.state.answers[idx]}
              type={question.inputType}
              size={"mini"}
            />
            {this.state.isEmpty[idx] && (
              <Ui.Label color="red">Please fill!</Ui.Label>
            )}
          </Ui.Segment>
        ))}
      </Ui.SegmentGroup>
    );
  }
}

export default Question;
