import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

const questions: QuestionType[] = [
  {
    id: "RT_1.1",

    title: <span>How did you experience the rest period?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Miserable", "Neutral", "Enjoyable"] },
          { labels: ["Frustrating", "Neutral", "Relaxing"] }
        ]}
      />
    )
  },
  {
    id: "RT_1.2",

    title: <span>How much time do you feel passed, in minutes?</span>,
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="minutes" />
    )
  },
  {
    id: "RT_1.3.1",

    title: <span>How hard was it to maintain fixation?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "RT_1.3.2",
    title: <span>How hard was it to stay awake/keep your eyes open?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];

export default questions;

//TODO: add plain titles and change numbering, and update questions
