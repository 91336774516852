// BASIC_EXCL_20: must be isOk === no when we start doing MRI.
// BASIC_EXCL_27: how to do isOk with multiple choice? People should speak German or english
// BASIC_EXCL_27: rather why pop-ups?


import React from "react";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Radio from "ewoq/prototypes/Radio";
import * as GroupYesNo from "ewoq/prototypes/YesNoGroup";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";
import WhyPopup from "ewoq/components/WhyPopup";


const questions = (studyPopulation: StudyPopulation): QuestionType[] => [

    {
        id: "BASIC_EXCL_01",
        mandatory: true,
        plainTitle: "Sex",
        title: (
            <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                What sex was your child assigned at birth? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                What sex were you assigned at birth?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Welches Geschlecht wurde Ihrem Kind bei Geburt zugeordnet? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Welchem Geschlecht wurdest Du bei Geburt zugeordnet? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Welchem Geschlecht wurden Sie bei Geburt zugeordnet?</SPChoice>
                </De>
            </span>
        ),
        component: v => (
          <Radio.Question
            onValid={v}
            options={[
              { text: l({ en: "Female", de: "Weiblich" }) },
              { text: l({ en: "Male",   de: "Männlich" }) },
              { text: l({ en: "Other",  de: "Anders" }), extraInput: "string" }
            ]}
          />
        )
      },           
  
      {
        id: "BASIC_EXCL_02",
        mandatory: true,
        plainTitle: "Age",
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                What is the age of your child?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                What is your age?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie alt ist Ihr Kind?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie alt bist Du?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie alt sind Sie?</SPChoice>
                </De>
          </span>
      ),
        // isOk: (s: Input.State) => Number(s.text) <= 26 && Number(s.text) >= 18,
        component: v => (
          <Input.Question
            onValid={v}
            inputType="number"
            // extraRadio={["TV was good"]}
          />
        )
      },      

      {
        id: "BASIC_EXCL_03",
        mandatory: true,
        plainTitle: "Sleep well",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child generally sleep well? <br/>
                Is your child able to fall asleep and sleep throughout the night also in unfamiliar places?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you generally sleep well?<br/>
                Are you able to fall asleep and sleep throughout the night also in unfamiliar places?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Schläft Ihr Kind grundsätzlich gut?<br/>
                Kann Ihr Kind auch an fremden Orten ein- und durchschlafen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Schläfst Du grundsätzlich gut?<br/>
                Kannst Du auch an fremden Orten ein- und durchschlafen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Schlafen Sie grundsätzlich gut?<br/>
                Können Sie auch an fremden Orten ein- und durchschlafen? </SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.yes,
        component: v => <YesNoQ.Question onValid={v} />
      },  
      
      {
        id: "BASIC_EXCL_04",
        mandatory: true,
        plainTitle: "Wake up during night",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child regularly wake up during the night once or for several times because 
                he or she needs to use the bathroom?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you regularly wake up during the night once or for several times because 
                you need to use the bathroom?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Trifft es zu, dass Ihr Kind regelmässig in der Nacht einmal oder 
                mehrmals aufwacht, um zur Toilette zu gehen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Trifft es zu, dass Du regelmässig in der Nacht einmal oder 
                mehrmals aufwachst, um zur Toilette zu gehen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Trifft es zu, dass Sie regelmässig in der Nacht einmal oder 
                mehrmals aufwachen, um zur Toilette zu gehen? </SPChoice>
                </De>

          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },        

      {
        id: "BASIC_EXCL_05",
        mandatory: true,
        plainTitle: "difficulties falling asleep again",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child often wake up without any reason and has difficulties to fall asleep again?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you often wake up without any reason and have difficulties to fall asleep again?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wacht Ihr Kind oft ohne ersichtlichen Grund auf und hat Schwierigkeiten erneut einzuschlafen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wachst Du oft ohne ersichtlichen Grund auf und hast Schwierigkeiten erneut einzuschlafen? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wachen Sie oft ohne ersichtlichen Grund auf und haben Schwierigkeiten erneut einzuschlafen? </SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },        

      {
        id: "BASIC_EXCL_06",
        mandatory: true,
        plainTitle: "Regular sleep",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How would you describe the sleep of your child during the last months?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How would you describe your sleep during the last months?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie würden Sie den Schlaf Ihres Kindes während den letzten Monaten beschreiben?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie würdest Du Deinen Schlaf während den letzten Monaten beschreiben?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie würden Sie Ihren Schlaf während den letzten Monaten beschreiben?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: Radio.State) => s.answer <= 1,
        component: v => (
          <Radio.Question
            onValid={v}
            options={[
              {
                text: l({
                  en: "Very regular",
                  de: "Sehr regelmässig"
                })
              },
              {
                text: l({
                  en: "Pretty regular",
                  de: "Eher regelmässig"
                })
              },
              { 
                text: l({
                en: "Rather irregular",
                de: "Eher unregelmässig"
                })
              },
              {
                text: l({
                  en: "Very irregular",
                  de: "Sehr unregelmässig"
                })
              }
            ]}
          />
        )
      },
      
      {
        id: "BASIC_EXCL_07",
        mandatory: true,
        plainTitle: "Shift worker",    
        title: (
          <span>
               <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Are you a shift worker?
                <WhyPopup>
                  This means that you work long-term night shifts or
                  work schedules in which you change or rotate shifts.
                </WhyPopup>
                </SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Arbeiten Sie in Schichtarbeit?
                <WhyPopup>
                  Dies bedeutet, dass Du Nachtschichten arbeitest oder
                  Arbeitsplänen unterliegst, in denen sich Deine Schichten ändern.
                </WhyPopup>
                </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Arbeiten Sie in Schichtarbeit?
                <WhyPopup>
                  Dies bedeutet, dass Sie Nachtschichten arbeiten oder
                  Arbeitsplänen unterliegen, in denen sich Ihre Schichten ändern.
                </WhyPopup>
                </SPChoice>
                </De>

          </span>
        ),
        shouldShow: (a: Question[]) => {
          switch(studyPopulation){
            case StudyPopulation.HealthyAdults:
              return true;
            default:
              return false;
          }
        },// only visible for HealthyAdults
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },        

      {
        id: "BASIC_EXCL_08",
        mandatory: true,
        plainTitle: "Timezone travel",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Has your child travelled across 2 or more time zones within the last 30 days?<br/>
                Do you think you will take such a trip with your child before or during the course of the study?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Have you travelled across 2 or more time zones within the last 30 days?<br/>
                Do you think you will take such a trip before or during the course of the study?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Ist Ihr Kind in den letzten 30 Tagen über zwei Zeitzonen gereist?<br/> 
                Planen Sie vor oder während des Studienzeitraumes eine Reise über zwei Zeitzonen mit Ihrem Kind?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Bist Du in den letzten 30 Tagen über zwei Zeitzonen gereist?<br/>
                Planst Du vor oder während des Studienzeitraumes eine Reise über zwei Zeitzonen?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Sind Sie in den letzten 30 Tagen über zwei Zeitzonen gereist?<br/>
                Planen Sie vor oder während des Studienzeitraumes eine Reise über zwei Zeitzonen?
                </SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_09",
        mandatory: true,
        plainTitle: "regular napping",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child regularly sleep during the day? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you regularly sleep during the day? </SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Schläft Ihr Kind regelmässig tagsüber?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Schläfst Du regelmässig tagsüber?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Schlafen Sie regelmässig tagsüber?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_10",
        mandatory: true,
        plainTitle: "sleep disorder diagnosed",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Is your child diagnosed with a sleep disorder? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Are you diagnosed with a sleep disorder?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Leidet Ihr Kind an einer Schlafstörung?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Leidest Du an einer Schlafstörung?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Leiden Sie an einer Schlafstörung?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_11",
        mandatory: true,
        plainTitle: "Shift worker",    
        title: (
          <span>
              <En>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
              Is your child currently suffering or has he or she </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
              Are you currently suffering or have you </SPChoice>
              ever suffered from any{" "}
              <a
              href="https://en.wikipedia.org/wiki/Mental_disorder"
              target="_blank"
              rel="noopener noreferrer"
              >
                psychiatric
              </a>   

              ,{" "}

              <a
              href="https://en.wikipedia.org/wiki/Neurological_disorder"
              target="_blank"
              rel="noopener noreferrer"
              >
                neurological
              </a>      

              , or{" "}

              <a
              href="https://en.wikipedia.org/wiki/Physical_disorder"
              target="_blank"
              rel="noopener noreferrer"
              >
                physical
              </a>                             
              
              {" "}disorder or illness other than epilepsy?
              </En>
              <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
              Leidet Ihr Kind momentan oder hat ihr Kind </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
              Leidest Du momentan oder hast Du </SPChoice>
              <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
              Leiden Sie momentan oder haben Sie </SPChoice>
              jemals an{" "}
              <a
              href="https://de.wikipedia.org/wiki/Psychische_Störung"
              target="_blank"
              rel="noopener noreferrer"
              >
                psychiatrischen
              </a>   

              ,{" "}

              <a
              href="https://de.wikipedia.org/wiki/Neurologie#Die_neurologischen_Erkrankungen"
              target="_blank"
              rel="noopener noreferrer"
              >
                neurologischen
              </a>      

              , oder{" "}

              <a
              href="https://en.wikipedia.org/wiki/Physical_disorder"
              target="_blank"
              rel="noopener noreferrer"
              >
                physischen
              </a>                             
              
              {" "}Störungen oder Erkrankungen ausser Epilepsie gelitten? </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_12",
        mandatory: true,
        plainTitle: "Special needs school",    
        title: (
          <span>
                <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Is your child attending a special-needs school or an inclusive education system to meet his or her special needs? <br/>
                Or has it ever been the case in the past?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Are you attending a special-needs school or an inclusive education system to meet your special needs? <br/>
                Or has it ever been the case in the past?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Have you attended a special-needs school or an inclusive education system to meet your special needs?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Besucht Ihr Kind eine heilpädagogische Schule, Sprachheilschule oder wird es integrativ beschult? <br/>
                Oder war dies jemals in der Vergangenheit der Fall?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Besuchst Du eine heilpädagogische Schule oder Sprachheilschule oder wirst Du integrativ beschult? <br/>
                Oder war dies jemals in der Vergangenheit der Fall?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie eine heilpädagogische Schule oder Sprachheilschule besucht oder wurden Sie integrativ beschult?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_13",
        mandatory: true,
        plainTitle: "Hearing disorder",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child have a hearing disorder?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you have a hearing disorder?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Hat Ihr Kind eine Hörstörung oder Hörbehinderung?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Hast Du eine Hörstörung oder Hörbehinderung?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie eine Hörstörung oder Hörbehinderung?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_14",
        mandatory: true,
        plainTitle: "Right handed",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Is your child right-handed?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Are you right-handed?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Ist Ihr Kind rechtshändig? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Bist Du rechtshändig? </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Sind Sie rechtshändig? </SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.yes,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_15",
        mandatory: true,
        plainTitle: "Height",
        title: (
          <span>
              <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How tall is your child?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How tall are you?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie gross ist Ihr Kind</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie gross bist Du?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie gross sind Sie? </SPChoice>
                </De>
          </span>
      ),
        component: v => (
          <Input.Question
            onValid={v}
            inputType="number"
            placeholder={"cm"}
          />
        )
      },    

      {
        id: "BASIC_EXCL_16",
        mandatory: true,
        plainTitle: "Weight",
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How much does your child weigh?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How much do you weigh?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie viel wiegt Ihr Kind?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie viel wiegst Du?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie viel wiegen Sie?</SPChoice>
                </De>
          </span>
      ),
        component: v => (
          <Input.Question
            onValid={v}
            inputType="number"
            placeholder={"kg"}
          />
        )
      },   
      
      {
        id: "BASIC_EXCL_17",
        mandatory: true,
        plainTitle: "Pregnancy",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Is there a chance that your child could be pregnant?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you think you are pregnant?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Besteht die Möglichkeit, dass Ihr Kind schwanger sein könnte?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Besteht die Möglichkeit, dass Du schwanger sein könntest?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie den Verdacht, schwanger zu sein?</SPChoice>
                </De>
          </span>
        ),

        shouldShow: (a: Question[]) => {
          const idxSex = a.findIndex(a => a.id === "BASIC_EXCL_01");

          return (
            a[idxSex].data !== undefined &&
            a[idxSex].data["answer"] !== 1
          );  // asks if person is biologically female and whether they think they're pregnant
        },
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} /> 
      },      

      {
        id: "BASIC_EXCL_18",
        mandatory: true,
        plainTitle: "excessive sweater",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                When your child is in a warm room, does he or she tend to sweat excessively?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                When you are in a warm room, do you tend to sweat excessively?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wenn sich Ihr Kind in einem warmen Raum befindet, neigt er oder sie dazu exzessiv zu schwitzen?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wenn Du dich in einem warmen Raum befindest, neigst Du dazu exzessiv zu schwitzen?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wenn Sie sich in einem warmen Raum befinden, neigen Sie dazu exzessiv zu schwitzen?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_19",
        mandatory: true,
        plainTitle: "Skin type",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child have a skin allergy or very sensitive skin?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you have a skin allergy or very sensitive skin?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Hat Ihr Kind eine Hautallergie oder überempfindliche Haut?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Hast Du eine Hautallergie oder überempfindliche Haut?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie eine Hautallergie oder überempfindliche Haut?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      }, 
        
      {
        id: "BASIC_EXCL_20",
        mandatory: true,
        plainTitle: "Braces",    
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child wear braces on its teeth or have a permanent retainer?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you wear braces on your teeth or have a permanent retainer?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Trägt Ihr Kind eine feste Zahnspange oder einen Retainer (Draht auf Zahninnenseite)?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Trägst Du eine feste Zahnspange oder einen Retainer (Draht auf Zahninnenseite)?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Tragen Sie eine feste Zahnspange oder einen Retainer (Draht auf Zahninnenseite)?</SPChoice>
                </De>
          </span>
        ),
        // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
        component: v => <YesNoQ.Question onValid={v} />
      },      

      {
        id: "BASIC_EXCL_21",
        mandatory: true,
        plainTitle: "Kid-Alcohol,Smoke,etc.",
        title: (
          <span>
            <En>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
                To guarantee the safety of your child throughout the course of
                the study, we need to know whether there is a chance that
                your child drinks alcohol, smokes, vapes (e-cigarettes),
                consumes medication or other drugs?</SPChoice>
            </En>

            <De>
              <SPChoice studyPopulation={[StudyPopulation.HealthyKids, StudyPopulation.EpiKids]}>
                Um die Sicherheit Ihres Kindes in der Studie zu gewährleisten,
                müssen wir wissen ob die Möglichkeit besteht, dass Ihr Kind
                Alkohol trinkt, raucht, vapet (E-Zigarette), oder Medikamente
                bzw. andere Drogen konsumiert?</SPChoice>
            </De>
          </span>
      ),
      shouldShow: (a: Question[]) => {
        switch(studyPopulation){
          case StudyPopulation.EpiKids:
            return true;
          case StudyPopulation.HealthyKids:
            return true;
          default:
            return false;
          }
        },
        // only visible for Epikids
      component: v => <YesNoQ.Question onValid={v} />
    }, 

      {
        id: "BASIC_EXCL_23",
        mandatory: true,
        plainTitle: "Alcohol intake",
        tip: (
          <span>
        <En>A standard serving refers to the regular amount of beverage for the respective drink (e.g. 33cl for a beer)</En>
        <De>Mit Standardportion meinen wir die reguläre Menge des jeweiligen Getränks (z. B. 33cl für ein Bier)</De> 
          </span>
        ),
        title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How often do you drink alcohol?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How often do you drink alcohol?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie viel Alkohol trinken Sie?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie viel Alkohol trinkst Du?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie viel Alkohol trinken Sie?</SPChoice>
                </De>
          </span>
      ),
      shouldShow: (a: Question[]) => {
        switch(studyPopulation){
          case StudyPopulation.HealthyAdults:
            return true;
          default:
            return false;
          }
        },
        // only visible for HealthyAdults
      // isOk: (s: Radio.State) => s.answer !== 2,
      component: v => (
        <Radio.Question
          onValid={v}
          options={[
            { text: l({ en: "less than 1 standard serving per day",    de: "weniger als 1 Standardportion pro Tag" }) },
            { text: l({ en: "1 standard serving per day",              de: "1 Standardportion pro Tag" }) },
            { text: l({ en: "more than 1 standard serving per day",    de: "mehr als 1 Standardportion pro Tag" }) }
          ]}
        />
      )
    },    
    
    {
      id: "BASIC_EXCL_24",
      mandatory: true,
      plainTitle: "Daily smoking",    
      title: (
        <span>
          <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child smoke, vape (e-cigarettes), or use nicotine patches daily?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you smoke, vape (e-cigarettes), or use nicotine patches daily?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Rauchen, vapen (E-Zigarette) oder benutzt Ihr Kind Nikotinpflaster täglich?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Rauchen, vapen (E-Zigarette) oder benutzt Du Nikotinpflaster täglich?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Rauchen, vapen (E-Zigarette) oder benutzen Sie Nikotinpflaster täglich?</SPChoice>
                </De>
        </span>
      ),
      shouldShow: (a: Question[]) => {
        switch(studyPopulation){
          case StudyPopulation.HealthyAdults:
            return true;
          default:
            return false;
          }
        },
        // only visible for HealthyAdults
      // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
      component: v => <YesNoQ.Question onValid={v} />
    },  

    {
      id: "BASIC_EXCL_25",
      mandatory: true,
      plainTitle: "Caffein intake",
      tip: (
        <span>
      <En>A standard serving refers to the regular amount of beverage for the respective drink (e.g. 125ml for a coffee)</En>
      <De>Mit Standardportion meinen wir die reguläre Menge des jeweiligen Getränks (z. B. 125ml für einen Kaffee)</De> 
        </span>
      ),      
      title: (
          <span>
            <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child regularly drink coffee or other caffeinated beverages, 
                especially during the week (including coffee, black and green tea, mate, 
                cola, energy drinks, chocolate, iced tea)? 
                Indicate how many servings per day for each category.</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you regularly drink coffee or other caffeinated beverages, 
                especially during the week (including coffee, black and green tea, mate, 
                cola, energy drinks, chocolate, iced tea)? 
                Indicate how many servings per day for each category.</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Trinkt Ihr Kind regelmässig Kaffee oder andere koffeinhaltige Getränke, 
                besonders während der Woche (wie etwa Kaffee, schwarzer und grüner Tee, 
                Mate, Cola, Energy Drinks, Schokolade oder Eistee)? 
                Bitte geben Sie an wie viel der jeweiligen Getränke Ihr Kind am Tag konsumiert. </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Trinkst Du regelmässig Kaffee oder andere koffeinhaltige Getränke, 
                besonders während der Woche (wie etwa Kaffee, schwarzer und grüner Tee, 
                Mate, Cola, Energy Drinks, Schokolade oder Eistee)? 
                Bitte gib an wie viel der jeweiligen Getränke Du am Tag konsumierst. </SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Trinken Sie regelmässig Kaffee oder andere koffeinhaltige Getränke, 
                besonders während der Woche (wie etwa Kaffee, schwarzer und grüner Tee, 
                Mate, Cola, Energy Drinks, Schokolade oder Eistee)? 
                Bitte geben Sie an wie viel der jeweiligen Getränke Sie am Tag konsumieren. </SPChoice>
                </De>
          </span>
      ),
      // isOk: (s: Radio.State) => s.answer !== 2,
      component: v => (
        <Radio.Question
          onValid={v}
          options={[
            { text: l({ en: "less than 2 standard servings per day",    de: "weniger als 2 Standardportionen pro Tag" }) },
            { text: l({ en: "2 standard servings per day",              de: "2 Standardportionen pro Tag" }) },
            { text: l({ en: "more than 2 standard servings per day",    de: "mehr als 2 Standardportionen pro Tag" }) }
          ]}
        />
      )
    },


    {
      id: "BASIC_EXCL_26",
      mandatory: true,
      plainTitle: "Responsibilities",    
      title: (
        <span>
          <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                <p>
                  During the study your child will have to stick to certain rules:
                </p>
                <p>
                  <b>7 days in advance to the experiment:</b><br/>
                  Regular sleep-wake cycle, no naps during the day, keeping a sleep diary, wearing an actimetry meter (actimetry meter will be provided by us)
                </p>
                <p>
                  <b>3 days in advance to the experiment:</b><br/>
                  No caffeine consumption (including coffee, black and green tea, mate, cola, energy drinks, chocolate, iced tea)
                </p>
                <p>               
                  <b>At the day of the experiment:</b><br/>
                  All of the above + no excessive sport or sauna visit 
                </p>
                <p>
                <i>Do you think your child can stick to all of these rules?</i>
                </p>
                </SPChoice>

                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                <p>
                  During the study you will have to stick to certain rules:
                </p>
                <p>
                  <b>7 days in advance to the experiment:</b><br/>
                  Regular sleep-wake cycle, no naps during the day, keeping a sleep diary, wearing an actimetry meter (actimetry meter will be provided by us)
                </p>
                <p>
                  <b>3 days in advance to the experiment:</b><br/>
                  No alcohol, no caffeine consumption (including coffee, black and green tea, mate, cola, energy drinks, chocolate, iced tea)
                </p>
                <p>               
                  <b>At the day of the experiment:</b><br/>
                  All of the above + no excessive sport or sauna visit 
                </p>
                <p>
                <i>Do you think you can stick to all of these rules?</i>
                </p>
                </SPChoice>

                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                <p>
                  Während der Studie sollte sich Ihr Kind an einige Pflichten halten:
                </p>
                <p>
                  <b>7 Tage vor Messnächten:</b><br/>
                  Regelmässiger Schlafrhythmus, kein Nickerchen am Tag, Führen eines Schlaftagebuchs, Tragen eines Aktimeters (Aktimeter wird von uns bereitgestellt)
                </p>
                <p>  
                  <b>3 Tage vor Messnächten:</b><br/>
                  Kein Koffeinkonsum (wie etwa Kaffee, schwarzer und grüner Tee, Mate, Cola, Energy Drinks, Schokolade oder Eistee)
                </p>
                <p>   
                  <b>Am Tag der Messnächte:</b><br/>
                  Alles oben genannte + kein exzessiver Sport oder Saunabesuch
                </p>
                <p>
                <i>Denken Sie, dass Ihr Kind diese Pflichten erfüllen kann?</i>
                </p>
                </SPChoice>

                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                <p>
                  Während der Studie solltest Du dich an einige Pflichten halten:
                </p>
                <p>
                  <b>7 Tage vor Messnächten:</b><br/>
                  Regelmässiger Schlafrhythmus, kein Nickerchen am Tag, Führen eines Schlaftagebuchs, Tragen eines Aktimeters (Aktimeter wird von uns bereitgestellt)
                </p>
                <p>  
                  <b>3 Tage vor Messnächten:</b><br/>
                  Kein Alkoholkonsum, kein Koffeinkonsum (wie etwa Kaffee, schwarzer und grüner Tee, Mate, Cola, Energy Drinks, Schokolade oder Eistee)
                </p>
                <p>   
                  <b>Am Tag der Messnächte:</b><br/>
                  Alles oben genannte + kein exzessiver Sport oder Saunabesuch
                </p>
                <p>
                <i>Denkst Du, dass Du diese Pflichten erfüllen kannst?</i>
                </p>
                </SPChoice>

                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                <p>
                  Während der Studie sollten Sie sich an einige Pflichten halten:
                </p>
                <p>
                  <b>7 Tage vor Messnächten:</b><br/>
                  Regelmässiger Schlafrhythmus, kein Nickerchen am Tag, Führen eines Schlaftagebuchs, Tragen eines Aktimeters (Aktimeter wird von uns bereitgestellt)
                </p>
                <p>  
                  <b>3 Tage vor Messnächten:</b><br/>
                  Kein Alkoholkonsum, kein Koffeinkonsum (wie etwa Kaffee, schwarzer und grüner Tee, Mate, Cola, Energy Drinks, Schokolade oder Eistee)
                </p>
                <p>   
                  <b>Am Tag der Messnächte:</b><br/>
                  Alles oben genannte + kein exzessiver Sport oder Saunabesuch
                </p>
                <p>
                <i>Denken Sie, dass Sie diese Pflichten erfüllen können?</i>
                </p>
                </SPChoice>
                </De>
        </span>
      ),
      // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.yes,
      component: v => <YesNoQ.Question onValid={v} />
    },  

    {
      id: "BASIC_EXCL_27",
      mandatory: true,
      plainTitle: "Language",
      title: (
        <span>
          <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                What languages does your child speak fluently?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                What languages do you speak fluently?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Welche Sprachen spricht Ihr Kind fliessend?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Welche Sprachen sprichst Du fliessend?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Welche Sprachen sprechen Sie fliessend?</SPChoice>
                </De>
        </span>
    ),
    // isOk: (s: MultipleChoice.State) => 
    //                                     Number(s.answers[0]) === 1 ||
    //                                     Number(s.answers[1]) === 1,    
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        allAbove={false}
        includeDone={true}
        options={[
          { text: l({ en: "German", de: "Deutsch" }) },
          { text: l({ en: "English", de: "Englisch"}) },
          { text: l({ en: "French", de: "Französisch"}) },
          { text: l({ en: "Spanish", de: "Spanisch" }) },
          { text: l({ en: "Portuguese", de: "Portugiesisch" }) },
          { text: l({ en: "Italian", de: "Italienisch" }) },
          { text: l({ en: "Other",  de: "Andere" }), extraInput: "string" }
        ]}
      />
    )
  },
  
  {
    id: "BASIC_EXCL_28",
    mandatory: true,
    plainTitle: "Epilepsy",    
    title: (
      <span>
         <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Does your child suffer from epilepsy?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Do you suffer from epilepsy?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Leidet Ihr Kind an einer Form der Epilepsie?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Leidest Du an einer Form der Epilepsie?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Leiden Sie an einer Form der Epilepsie?</SPChoice>
                </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />,
    // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no
  },
  
  {
    id: "BASIC_EXCL_29",
    mandatory: true,
    plainTitle: "last EEG 12 Months",    
    title: (
      <span>
        <En>Has the last electroencephalogram (EEG) been recorded during the last 12 months?</En>
        <De>Wurde das letzte Elektroenzephalogramm (EEG) innerhalb der letzten 12 Monate aufgenommen?</De>
      </span>
    ),

    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "BASIC_EXCL_28");

      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person suffers from epilepsy
    },

    // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.yes,
    component: v => <YesNoQ.Question onValid={v} />
  },  

  {
    id: "BASIC_EXCL_30",
    mandatory: true,
    plainTitle: "Status epilepticus",    
    title: (
      <span>
        <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Has your child ever suffered from <i>status epilepticus?</i></SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Have you ever suffered from <i>status epilepticus?</i></SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Hat ihr Kind jemals einen <i>Status epilepticus</i> gehabt?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Hast Du jemals einen <i>Status epilepticus</i> gehabt?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie jemals einen <i>Status epilepticus</i> gehabt?</SPChoice>
                </De>
      </span>
    ),

    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "BASIC_EXCL_28");

      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person suffers from epilepsy
    },

    // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },  

  {
    id: "BASIC_EXCL_31",
    mandatory: true,
    plainTitle: "Seizure sleep deprivation",    
    title: (
      <span>
        <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Has your child ever suffered from a seizure after a night of little or no sleep at all?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Have you ever suffered from a seizure after a night of little or no sleep at all?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Hat Ihr Kind jemals einen epileptischen Anfall nach einer Nacht mit wenig oder ohne Schlaf gehabt?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Hast Du jemals einen epileptischen Anfall nach einer Nacht mit wenig oder ohne Schlaf gehabt?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Haben Sie jemals einen epileptischen Anfall nach einer Nacht mit wenig oder ohne Schlaf gehabt?</SPChoice>
                </De>
      </span>
    ),

    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "BASIC_EXCL_28");

      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person suffers from epilepsy
    },

    // isOk: (s: YesNoQ.State) => s.answer === YesNoQ.AnswerType.no,
    component: v => <YesNoQ.Question onValid={v} />
  },  

  {
    id: "BASIC_EXCL_32",
    mandatory: true,
    plainTitle: "Current medication",    
    title: (
      <span>
        <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Has your child recently or is it currently being treated with corticosteroids, immunosuppressants or vagus nerve stimulation?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Have you recently or are you currently being treated with corticosteroids, immunosuppressants or vagus nerve stimulation?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Beinhaltete die Therapie Ihres Kindes in letzter Zeit oder beinhaltet die Therapie Ihres Kindes aktuell Kortikosteroide, Immunosuppressiva oder Vagusnervstimulation?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Beinhaltete Deine Therapie in letzter Zeit oder beinhaltet Deine Therapie aktuell Kortikosteroide, Immunosuppressiva oder Vagusnervstimulation?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Beinhaltete Ihre Therapie in letzter Zeit oder beinhaltet Ihre Therapie aktuell Kortikosteroide, Immunosuppressiva oder Vagusnervstimulation?</SPChoice>
                </De>
      </span>
    ),

    shouldShow: (a: Question[]) => {
      const idxEpi = a.findIndex(a => a.id === "BASIC_EXCL_28");

      return (
        a[idxEpi].data !== undefined &&
        a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
      );  // asks if person suffers from epilepsy
    },

    // isOk: (s: Radio.State) => s.answer !== 1,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: l({ en: "No", de: "Nein" }) },
          { text: l({ en: "Yes",   de: "Ja" }) },
          { text: l({ en: "I don't know",  de: "ich weiss es nicht" }) }
        ]}
      />
    )
  },
  
  {
    id: "BASIC_EXCL_33",
    mandatory: true,
    plainTitle: "Found study",    
    title: (
      <span>
        <En>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                How did you or your child hear about this study?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                How did you hear about this study?</SPChoice>
                </En>

                <De>
                <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
                Wie haben Sie oder Ihr Kind von dieser Studie gehört?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
                Wie hast Du von dieser Studie gehört?</SPChoice>
                <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
                Wie haben Sie von dieser Studie gehört?</SPChoice>
                </De>
      </span>
    ),

    component: v => (
      <MultipleChoice.Question
        onValid={v}
        allAbove={false}
        includeDone={true}
        options={[
          { text: l({ en: "Uni-Marketplace", de: "Uni-Marktplatz" }) },
          { text: l({ en: "Mailing List", de: "Mailingliste"}) },
          { text: l({ en: "From people who've already participated in the study", de: "Über Personen, die bereits an der Studie teilgenommen haben"}) },
          { text: l({ en: "From friends", de: "Über Freunde" }) },
          { text: l({ en: "My doctor", de: "Meine Ärztin" }) },
          { text: l({ en: "Other",  de: "Andere" }), extraInput: "string" }
        ]}
      />
    )
  },        
];

export default questions;
