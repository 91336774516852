import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/DebriefingQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";

interface Props {
  viewAll?: boolean;
}

export class Debriefing extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", "Debriefing", {
      answers: answers,
    });
  };

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>Debriefing Questionnaire</HeaderContainer>
        <Ui.Container>
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          />
        </Ui.Container>
      </div>
    );
  }
}

export default Debriefing;
