import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./MotivationQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

interface State {
  session: string;
  task: string;
}

export class Motivation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { 
        session: "",
        task: ""};
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("EPI", this.state.task + "-" + this.props.name + "-" + this.state.session + "-Motivation", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };

  onSession = (session: string) => {
    this.setState({ session: session });
  };

  onTask = (task: string) => {
    this.setState({ task: task });
  };  

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">Motivation</Ui.Container>
        </HeaderContainer>        

        {/* {this.state.task + "-" + this.props.name + "-" + this.state.session + "-Motivation"} */}
        <Ui.Container>
          <ChooseSession
            title="Choose session"
            sessions={[
                ["Night", "Morning"],
            ]}
            selection={this.state.session}
            onClick={this.onSession}
          />
        </Ui.Container>           

        <Ui.Container>
          <ChooseSession
            title="Choose tasks"
            sessions={[
              ["TAP", "WPMT", "CFT", "FTT", "WAKE-EEG"],
            ]}
            selection={this.state.task}
            onClick={this.onTask}
          />
          {!(this.state.session && this.state.session.length > 1 && this.state.task) ? null : ( 
            <Interrogator
              questions={questions}
              onDone={this.onSubmit}
              viewAll={this.props.viewAll}
            />
          )}
        </Ui.Container>        
      </div>
    );
  }
}

export default Motivation;
