import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";
import { En, De, l } from "ewoq/components/Language";
import { sp, SPChoice, StudyPopulation } from "StudyPopulationChoice";

const questions: QuestionType[] = [
  {
    id: "BQ_HaDexQ_1",
    mandatory: false,
    plainTitle: "Does play music",
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Does your child </SPChoice>
          <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Do you </SPChoice>
          play a music instrument (e.g. piano, guitar etc.)
        </En>

        <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
          Spielt Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
          Spielst Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
          Spielen Sie </SPChoice>
          ein Musikinstrument?
        </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  
  {
    id: "BQ_HaDexQ_2",
    mandatory: false,
    plainTitle: "Music - extended",
    title: (
    <span>
      <En>
        Please describe&nbsp; 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
         your childs </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
         your </SPChoice>
        experience with music instruments:
      </En>

      <De>
        Bitte&nbsp; 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        beschreiben Sie die Erfahrung Ihres Kindes </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        beschreibe deine Erfahrung </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        beschreiben Sie Ihre Erfahrung </SPChoice>
        im Bezug auf Musikinstrumente:
      </De>
    </span>),
    
  shouldShow: (a: Question[]) => {
    const idxEpi = a.findIndex(a => a.id === "BQ_HaDexQ_1");

    return (
      a[idxEpi].data !== undefined &&
      a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
    );  // asks if person plays an instrument
  },

    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "Instrument",
            title: l({
              en:
                "Which Instrument?",
              de:
                "Welches Instrument"}),
            component: v => <Input.Question onValid={v} inputType="string" />
          },
          {
            id: "Duration",
            title: l({
              en:
                "How many months of experience?",
              de:
                "Wieviele Monate Erfahrung?"}),
                component: v => <Input.Question onValid={v} inputType="number" />
          },

          {
            id: "Frequency",
            title: l({
              en:
                "Frequency of practice (per week)?",
              de:
                "Häufigkeit des Übens (pro Woche)?"}),
                component: v => <Input.Question onValid={v} inputType="number" />
          },


          {
            id: "How skilled",
            title: l({
              en:
                "How skilled?",
              de:
                "Wie geübt?"}),
                component: (v) => (
                  <SliderGroup.Question
                    onValid={v}
                    subQuestions={[
                      {
                        labels: [
                          l({ en: "still beginner", de: "noch Anfänger" }),
                          l({ en: "average", de: "durchschnittlich" }),
                          l({ en: "level of a professional", de: "auf professionellem Niveau" }),
                        ],
                      },
                      
                    ]}
                  />
                ),
          }
        ]}
      />
    )
  },

  // ask same kind of questions regarding video games

  {
    id: "BQ_HaDexQ_3",
    mandatory: false,
    plainTitle: "Does play games",
    title: (
      <span>
        <En>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
         Does your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
         Do you </SPChoice>
        play video games on a regular basis.
      </En>

      <De>
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        Spielt Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        Spielst Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        Spielen Sie </SPChoice>
        regelmässig Video-Spiele?
      </De>
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  
  {
    id: "BQ_HaDexQ_4",
    mandatory: false,
    plainTitle: "Gaming Frequency",
    title: (
    <span>
      <En>
        How many times per month&nbsp;  
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
         does your child </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
         do you </SPChoice>
        play video games?
      </En>

      <De>
        Wie häufig im Monat&nbsp; 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        spielt Ihr Kind </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        spielst Du </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        spielen Sie </SPChoice>
        Video-Spiele?
      </De>
      
    </span>),
    
  shouldShow: (a: Question[]) => {
    const idxEpi = a.findIndex(a => a.id === "BQ_HaDexQ_3");

    return (
      a[idxEpi].data !== undefined &&
      a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
    );  // asks if person plays video games
  },

  component: v => (
    <Input.Question
      onValid={v}
      inputType="number"
    />
  )
  },

  {
    id: "BQ_HaDexQ_5",
    mandatory: false,
    plainTitle: "Gaming skills",
    title: (
    <span>
      <En>
        As how proficient&nbsp;  
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
         does your child assess its </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens,StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
         do you assess your </SPChoice>
        gaming skills in general?
      </En>

      <De>
        Wie gut&nbsp; 
        <SPChoice studyPopulation={[StudyPopulation.HealthyKids,StudyPopulation.EpiKids]}>
        schätzt Ihr Kind sein </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyTeens,StudyPopulation.EpiTeens]}>
        schätzt Du Dein </SPChoice>
        <SPChoice studyPopulation={[StudyPopulation.HealthyAdults,StudyPopulation.EpiAdults]}>
        schätzen Sie Ihr </SPChoice>
        Geschick im Bezug auf Video-Spiele generell ein?
      </De>
      
    </span>),
    
  shouldShow: (a: Question[]) => {
    const idxEpi = a.findIndex(a => a.id === "BQ_HaDexQ_3");

    return (
      a[idxEpi].data !== undefined &&
      a[idxEpi].data["answer"] === YesNoQ.AnswerType.yes
    );  // asks if person plays video games
  },

  component: (v) => (
    <SliderGroup.Question
      onValid={v}
      subQuestions={[
        {
          labels: [
            l({ en: "very bad", de: "sehr schlecht" }),
            l({ en: "average", de: "durchschnittlich" }),
            l({ en: "very good", de: "sehr gut" }),
          ],
        },
        
      ]}
    />
  ),
  },
];

export default questions;
