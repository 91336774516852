import React from "react";
import * as Range from "ewoq/prototypes/Ranges";
import { En, De, l, L } from "ewoq/components/Language";

export const threeHoursDay = (v: any, includeDone?: boolean) => (
  <Range.Question
    onValid={v}
    includeDone={includeDone}
    labels={[
      l({ en: "6-9 AM",       de: "06:00 - 09:00" }),
      l({ en: "9AM -12PM",    de: "09:00 - 12:00" }),
      l({ en: "12-3 PM",      de: "12:00 - 15:00" }),
      l({ en: "3-6 PM",       de: "15:00 - 18:00" }),
      l({ en: "6-9 PM",       de: "18:00 - 21:00" }),
      l({ en: "9PM - 12AM",   de: "21:00 - 00:00" }), 

    ]}
  />
);

export const twoHoursDay = (v: any, includeDone?: boolean) => (
  <Range.Question
    onValid={v}
    includeDone={includeDone}
    labels={[
      l({ en: "6-8 AM",      de: "06:00 - 08:00" }),
      l({ en: "8-10 AM",     de: "08:00 - 10:00" }),
      l({ en: "10AM - 12PM", de: "10:00 - 12:00" }),
      l({ en: "12-2 PM",     de: "12:00 - 14:00" }),
      l({ en: "2-4 PM",      de: "14:00 - 16:00" }),
      l({ en: "4-6 PM",      de: "16:00 - 18:00" }),
      l({ en: "6-8 PM",      de: "18:00 - 20:00" }),
      l({ en: "8-10 PM",     de: "20:00 - 22:00" }),
      l({ en: "10PM - 12AM", de: "22:00 - 24:00" }), 

    ]}
  />
);
