// makes a little popup just for references

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

const Index = styled.span`
  color: #327195;
`;

const RefText = styled.div`
  text-align: justify;
`;

interface Props {
  indexes: number[];
  refs: string[];
}

class References extends React.Component<Props> {
  render() {
    return (
      <Ui.Popup
        trigger={
          <span>
            <Index>[{this.props.indexes.join(",")}]</Index>
          </span>
        }
        hoverable
        wide
      >
        <RefText>
          {this.props.indexes.map(idx => (
            <p key={idx}>{this.props.refs[idx]}</p>
          ))}
        </RefText>
      </Ui.Popup>
    );
  }
}

export default References;
