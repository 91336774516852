import React from "react";
import * as Ui from "semantic-ui-react";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID
} from "ewoq/components/miscStyles";
import SleepAssessment from "./SleepAssessment";
import NeedRescheduling from "./NeedRescheduling";
import Tiredness from "./Tiredness";
import FoodDrinks from "./FoodDrinks";
import Feelings from "./Feelings";
import Dreams from "./Dreams";
import ChooseSession from "ewoq/containers/DayPicker";
import { getUserId } from "ewoq/utils";
import CurrentState from "../CurrentState";
import Motivation from "../Motivation";
import AttentionTAP from "../AttentionTAP";

enum Phase {
  Start,
  Dreams,
  SleepAssessment,
  Activities,
  CurrentState,
  Feelings,
  FoodDrinks,
  Tiredness,
  NeedRescheduling,
  Motivation,
  AttentionTAP
}

interface Props {}

interface State {
  phase: Phase;
  day?: string;
}

class SleepReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { phase: Phase.Start, day: undefined };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase });
  };

  onChange = (a: any, b: any) => {
    this.setState({ day: b.value });
  };

  onClick = (selection: string) => {
    this.setState({ day: selection });
  };

  render() {
    switch (this.state.phase) {
      case Phase.Start: {
        return (
          <div>
            <ParticipantID>{getUserId()}</ParticipantID>
            <HeaderContainer>
              <Ui.Container textAlign="center">Sleep Report</Ui.Container>
            </HeaderContainer>
            <Ui.Container>
              <Ui.Segment>
                <Ui.Divider horizontal>Instructions</Ui.Divider>
                <SectionBody>
                  Please fill in these questionnaires every morning after you’ve
                  woken up. First thing before anything else, please fill in the dream report
                  since memories of dreams fade really quickly. 
                  The sleep report you can fill in any time in the
                  morning, but please always do so at the same time every day.
                  The report on the previous day can either be done the evening
                  before, or the next morning with the sleep report.
                  <p>
                    {" "}
                    Questions that ask more sensitive or less critical
                    information are skipable, although we appreciate all the
                    answers you can give us.{" "}
                  </p>
                </SectionBody>
                <Ui.Segment.Group raised>
                  <ChooseSession
                    sessions={[["Week-1", "Week-2"]]}
                    selection={this.state.day}
                    onClick={this.onClick}
                  />

                  <Ui.Form>
                    <Ui.Form.Field>
                    <SectionBody>
                        <Ui.Button
                          fluid
                          onClick={this.advancePhase(Phase.NeedRescheduling)}
                          disabled={this.state.day === undefined}
                        >
                          Previous Day Report (uppon arrival)
                        </Ui.Button>
                      </SectionBody>                      
                      <SectionBody>
                        <Ui.Button
                          fluid
                          onClick={this.advancePhase(Phase.Dreams)}
                          disabled={this.state.day === undefined}
                        >
                          Sleep Report (uppon awakening)
                        </Ui.Button>
                      </SectionBody>                    
                      <SectionBody>
                        <Ui.Button
                          fluid
                          onClick={this.advancePhase(Phase.CurrentState)}
                          disabled={this.state.day === undefined}
                        >
                          Current State (before wake EEG)
                        </Ui.Button>
                      </SectionBody>
                      <SectionBody>
                        <Ui.Button
                          fluid
                          onClick={this.advancePhase(Phase.AttentionTAP)}
                          disabled={this.state.day === undefined}
                        >
                          Attention (before TAP / wake EEG)
                        </Ui.Button>
                      </SectionBody>
                      <SectionBody>
                        <Ui.Button
                          fluid
                          onClick={this.advancePhase(Phase.Motivation)}
                          disabled={this.state.day === undefined}
                        >
                          Motivation (before tasks)
                        </Ui.Button>
                      </SectionBody>                      
                    </Ui.Form.Field>
                  </Ui.Form>
                </Ui.Segment.Group>
              </Ui.Segment>
            </Ui.Container>
          </div>
        );
      }
      case Phase.Dreams: {
        return (
          <Dreams
            name={this.state.day}
            onDone={this.advancePhase(Phase.SleepAssessment)}
          />
        );
      }
      case Phase.SleepAssessment: {
        return (
          <SleepAssessment
            name={this.state.day}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.NeedRescheduling: {
        return (
          <NeedRescheduling
            name={this.state.day}
            onDone={this.advancePhase(Phase.Tiredness)}
          />
        );
      }
      case Phase.Tiredness: {
        return (
          <Tiredness
            name={this.state.day}
            onDone={this.advancePhase(Phase.FoodDrinks)}
          />
        );
      }
      case Phase.FoodDrinks: {
        return (
          <FoodDrinks
            name={this.state.day}
            onDone={this.advancePhase(Phase.Feelings)}
          />
        );
      }
      case Phase.Feelings: {
        return (
          <Feelings
            name={this.state.day}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.CurrentState: {
        return (
          <CurrentState
            name={this.state.day}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.AttentionTAP: {
        return (
          <AttentionTAP
            name={this.state.day}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.Motivation: {
        return (
          <Motivation
            name={this.state.day}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }      
    }
  }
}

export default SleepReport;
