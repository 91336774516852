import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";

const questions: QuestionType[] = [
  {
    id: "RT_3.1.1",
    mandatory: true,
    title: "Indicate on the following scales how you feel right now:",
    component: v => feelings(v)
  },
  {
    id: "RT_3.1.2",
    mandatory: true,
    title: "Check all that apply:",
    component: v => extraFeelings(v)
  },
  {
    id: "RT_3.2",
    mandatory: true,
    title: "How tolerant do you feel towards the experiment right now?",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Completely tolerant" },
          { text: "Mostly tolerant" },
          { text: "Starting to lose patience" },
          { text: "Somewhat intolerant" },
          { text: "At the end of my rope" }
        ]}
      />
    )
  },
  {
    id: "RT_3.3",
    mandatory: true,
    title: "How much control do you feel you have on your emotions right now?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "Some", "Excellent"]}
      />
    )
  },
  {
    id: "RT_3.4",
    mandatory: true,
    title:
      "Please answer these questions taking into consideration both your current feelings, and what you felt over the past half hour.",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "RT_3.4.1",
            title: "How hungry are you?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          },

          {
            id: "RT_3.4.2",
            title: "How thirsty are you?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          },
          {
            id: "RT_3.4.3",
            title: " Do you have a headache?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          },
          {
            id: "RT_3.4.4",

            title: "Do you feel any other pain or discomfort?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          },
          {
            id: "RT_3.4.5",

            title: "How emotional do you feel?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          },
          {
            id: "RT_3.4.6",

            title: "How motivated do you feel?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["Not at all", "Extremely"]}
              />
            )
          }
        ]}
      />
    )
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
