import { QuestionsObj } from "ewoq/containers/Interrogator";
import { str2Time } from "ewoq/utils";
import { AnswerType } from "ewoq/prototypes/YesNo";

// This calculates the midpoint of sleep for free days (weekends), corrected
// for sleep debt if necessary based on the Munich Chronotype Questionnaire.
// It sees if the sleep durations are different between work and free days,
// if they're not, or freedays are even shorter, then the chronotype is based on
// just the midpoint of sleep on free days. If not, then it corrects this value
// by half of the difference between free sleep duration and average sleep duration.
// The idea being that this adjusts the chronotype by taking into account compensation
// sleep usually conducted on the weekend.

const getAnswers = (s: QuestionsObj) => {
  const workDays = parseInt(s["BQ_MCTQ_1"].data["text"]);

  // let workID = "BQ_MCTQ_2"; //TODO: change IDS!
  // let freeID = "BQ_MCTQ_3";
  // if (workDays === 0) {
  //   workID = freeID;
  // } else if (workDays === 7) {
  //   freeID = workID;
  // }
  return {
    workDays: workDays,
    workSleepStart: str2Time(s["BQ_MCTQ_2_2"].data["text"]),
    workSleepLatency: parseInt(s["BQ_MCTQ_2_3"].data["text"]),
    workWakeup: str2Time(s["BQ_MCTQ_2_4"].data["text"]),
    freeSleepStart: str2Time(s["BQ_MCTQ_5_2"].data["text"]),
    freeSleepLatency: parseInt(s["BQ_MCTQ_5_3"].data["text"]),
    freeWakeup: str2Time(s["BQ_MCTQ_5_4"].data["text"]),
    alarm:
      s["BQ_MCTQ_6"].data === undefined
        ? false
        : s["BQ_MCTQ_6"].data["answer"] === AnswerType.yes
  };
};

const MCTQscore = (s: QuestionsObj) => {
  const a = getAnswers(s);
  const freeSleepOnset = (a.freeSleepStart + a.freeSleepLatency / 60) % 24;
  const workSleepOnset = (a.workSleepStart + a.workSleepLatency / 60) % 24;
  const freeSleepDuration = (a.freeWakeup + 24 - freeSleepOnset) % 24;
  const workSleepDuration = (a.workWakeup + 24 - workSleepOnset) % 24;
  const freeSleepMidpoint = (freeSleepOnset + freeSleepDuration / 2) % 24;
  let Chronotype = undefined;

  if (a.alarm) {
    return Chronotype;
  } else if (freeSleepDuration <= workSleepDuration) {
    Chronotype = freeSleepMidpoint;
  } else {
    const freeDays = 7 - a.workDays;
    const meanSleepDuration =
      (workSleepDuration * a.workDays + freeSleepDuration * freeDays) / 7;
    Chronotype =
      freeSleepMidpoint - (freeSleepDuration - meanSleepDuration) / 2;
  }

  return Chronotype;
};

export default MCTQscore;
