import React from "react";

export enum StudyPopulation {
  HealthyKids = "HealthyKids",
  HealthyTeens = "HealthyTeens",
  HealthyAdults = "HealthyAdults",
  EpiKids = "EpiKids",
  EpiTeens = "EpiTeens",
  EpiAdults = "EpiAdults",
  NotChosen = "aaaaaargh",
}
export const defaultStudyPopulation = StudyPopulation.NotChosen;

(window as any).studyPopulation = defaultStudyPopulation;

export const StudyPopulationContext = React.createContext<StudyPopulation>(
  defaultStudyPopulation
);

interface SPChoiceProps {
  studyPopulation: StudyPopulation | StudyPopulation[];
}

export class SPChoice extends React.Component<SPChoiceProps> {
  static contextType = StudyPopulationContext;

  render() {
    let myTest = false;
    if (Array.isArray(this.props.studyPopulation)) {
      myTest = this.props.studyPopulation.indexOf(this.context) >= 0;
    } else {
      myTest = this.props.studyPopulation === this.context;
    }
    return myTest && this.props.children;
  }
}

export const SPProvider: React.SFC<{ value: StudyPopulation }> = (p) => {
  (window as any).studyPopulation = p.value;
  return (
    <StudyPopulationContext.Provider value={p.value} children={p.children} />
  );
};

interface SPObjects {
  [sp: string]: string;
}

export const sp = (d: SPObjects) => {
  const studyPopulation = (window as any).studyPopulation;
  return studyPopulation in d ? d[studyPopulation] : d[defaultStudyPopulation];
};
