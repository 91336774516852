import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "ImageCoordinates";

interface Props {
  onValid: (state?: AnswerObj) => void;
  src: string;
}

export interface State {
  x?: number;
  y?: number;
}

const MarkContainer = styled.span`
  position: absolute;
`;
const Mark = styled.span`
  background-color: #6434c9;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  left: -1.5em;
  top: -1.5em;
  position: absolute;
`;

export class Question extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLImageElement>;
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.myRef = React.createRef();
  }

  onClick = (e: any) => {
    const rect = (this.myRef.current as any).getBoundingClientRect();
    const x = (e.pageX - rect.left - window.scrollX) / rect.width;
    const y = (e.pageY - rect.top - window.scrollY) / rect.height;
    this.setState({ x, y }, () => {
      this.props.onValid({ data: this.state, questionProps: this.props, type });
    });
  };

  render() {
    const rect =
      this.myRef.current && (this.myRef.current as any).getBoundingClientRect();
    const left = this.state.x && this.state.x * rect.width;
    const top = this.state.y && this.state.y * rect.height;
    return (
      <div
        ref={this.myRef}
        onClick={this.onClick}
        style={{ position: "relative" }}
      >
        <Ui.Image src={this.props.src} />
        {left && (
          <MarkContainer style={{ left, top }}>
            <Mark />
          </MarkContainer>
        )}
      </div>
    );
  }
}

export default Question;
