import React from "react";
import PSQI from "../ScreeningQuestionnaire/PSQI";
import MCTQ_short from "../ScreeningQuestionnaire/MCTQ";
import Wake from "../ScreeningQuestionnaire/Wake";
import Sleep from "../ScreeningQuestionnaire/Sleep";
import { Language } from "../ScreeningQuestionnaire/misc";
import { LanguageProvider } from "ewoq/components/Language";
import * as Ui from "semantic-ui-react";

import SensitiveChecklist from "../ScreeningQuestionnaire/SensitiveChecklist";

import styled from "styled-components";
import DrinkingQ from "../LifestyleQuestionnaire/Part1/Drinking";
import Eating1 from "../LifestyleQuestionnaire/Part1/Eating1";
// import Handedness from "../LifestyleQuestionnaire/Part1/Handedness";
import Health from "../LifestyleQuestionnaire/Part1/Health";
import Lifestyle1 from "../LifestyleQuestionnaire/Part1/Lifestyle1";
import LifestyleSleep from "../LifestyleQuestionnaire/Part1/LifestyleSleep";
import Disruption from "../LifestyleQuestionnaire/Part1/Disruption";
import Demographics from "../LifestyleQuestionnaire/Part2/Demographics";
import Lifestyle2 from "../LifestyleQuestionnaire/Part2/Lifestyle2";
import PainSensitivity from "../LifestyleQuestionnaire/Part2/PainSensitivity";
import Dreams from "../SleepReport/Dreams";
import Feelings from "../SleepReport/Feelings";
// import Activities from "../SleepReport/Activities";
// import CurrentState from "../SleepReport/CurrentState";
import FoodDrinks from "../SleepReport/FoodDrinks";
import NeedRescheduling from "../SleepReport/NeedRescheduling";
import SleepAssessment from "../SleepReport/SleepAssessment";
import Overview from "../RestTestQuestionnaire/Overview";
import Thoughts from "../RestTestQuestionnaire/Thoughts";
import FeelingsRest from "../RestTestQuestionnaire/FeelingsRest";
import TirednessRest from "../RestTestQuestionnaire/TirednessRest";
import BasicExclusion from "../ScreeningQuestionnaire/BasicExclusion";
import Entrance from "../ScreeningQuestionnaire/Entrance";

import MCTQ_long from "../BasicInfo/MCTQ";
import Handedness from "../BasicInfo/Handedness";
import SleepQuality from "../BasicInfo/SleepQuality";
import HandDexterity from "../BasicInfo/HandDexterity";
import Attention from "../BasicInfo/Attention";
import DaytimeSleepiness from "../BasicInfo/DaytimeSleepiness";
import EpworthSleepinessScale from "../BasicInfo/EpworthSleepinessScale";
import StrengthDifficulties from "../BasicInfo/StrengthDifficulties";
import SocioEconomicStatus from "../BasicInfo/SocioEconomicStatus";
import MRSafety from "../BasicInfo/MRSafety";
import MenstrualCycle from "../BasicInfo/MenstrualCycle";

import Debriefing from "../DebriefingQuestionnaire";
import Eating2 from "../LifestyleQuestionnaire/Part2/Eating2";
import Tiredness from "../SleepReport/Tiredness";
import { ParticipantID } from "ewoq/components/miscStyles";
import { getUserId } from "ewoq/utils";

// This page is meant to see all the questionnaires together, so that I can both show them
// to people as needed, and to correct in case of a mistake

interface Questionnaire {
  questionniare: (onDone: () => void, viewAll?: boolean) => any;
  label: string;
  color: string;
}

enum QColors {
  screening = "violet",
  lifestyle1 = "green",
  lifestyle2 = "pink",
  sleepReport = "blue",
  restTest = "red",
  debriefing = "purple"
}

const Questionnaires: Questionnaire[] = [
  {
    label: "Sensitive Checklist",
    color: QColors.screening,
    questionniare: (v, viewAll) => (
      <SensitiveChecklist onDone={v} viewAll={viewAll} />
    )
  },
  {
    label: "BasicExclusion",
    color: QColors.screening,
    questionniare: (v, viewAll) => <BasicExclusion onDone={v} viewAll={viewAll} />
  },  {
    label: "Entrance",
    color: QColors.screening,
    questionniare: (v, viewAll) => <Entrance onDone={v} viewAll={viewAll} />
  },  
  {
    label: "PSQI",
    color: QColors.screening,
    questionniare: (v, viewAll) => <PSQI onDone={v} viewAll={viewAll} />
  },
  {
    label: "MCTQ (short)",
    color: QColors.screening,
    questionniare: (v, viewAll) => <MCTQ_short onDone={v} viewAll={viewAll} />
  },

  {
    label: "MCTQ (long)",
    color: QColors.screening,
    questionniare: (v, viewAll) => <MCTQ_long onDone={v} viewAll={viewAll} />
  },
  {
    label: "Wake",
    color: QColors.screening,
    questionniare: (v, viewAll) => <Wake onDone={v} viewAll={viewAll} />
  },
  {
    label: "Sleep",
    color: QColors.screening,
    questionniare: (v, viewAll) => <Sleep onDone={v} viewAll={viewAll} />
  },
  {
    label: "Sleep Lifestyle",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => (
      <LifestyleSleep onDone={v} viewAll={viewAll} />
    )
  },
  {
    label: "Handedness",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Handedness onDone={v} viewAll={viewAll} />
  },

  {
    label: "Sleep Quality",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <SleepQuality onDone={v} viewAll={viewAll} />
  },
  
  {
    label: "Attention",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Attention onDone={v} viewAll={viewAll} />
  },

  {
    label: "HandDexterity",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <HandDexterity onDone={v} viewAll={viewAll} />
  },

  {
    label: "Daytime Sleepiness",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <DaytimeSleepiness onDone={v} viewAll={viewAll} />
  },

  {
    label: "Epworth Sleepiness Scale",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <EpworthSleepinessScale onDone={v} viewAll={viewAll} />
  },

  {
    label: "Strengths and Difficulties",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <StrengthDifficulties onDone={v} viewAll={viewAll} />
  },

  {
    label: "Socio-economic Status",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <  SocioEconomicStatus onDone={v} viewAll={viewAll} />
  },

  {
    label: "MR Safety",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <  MRSafety onDone={v} viewAll={viewAll} />
  },

  {
    label: "Menstrual Cycle",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <  MenstrualCycle onDone={v} viewAll={viewAll} />
  },
  

  {
    label: "Health",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Health onDone={v} viewAll={viewAll} />
  },
  {
    label: "Drinking Habits",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <DrinkingQ onDone={v} viewAll={viewAll} />
  },
  {
    label: "Eating Habits 1",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Eating1 onDone={v} viewAll={viewAll} />
  },
  {
    label: "Lifestyle 1",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Lifestyle1 onDone={v} viewAll={viewAll} />
  },

  {
    label: "Anticipated Disruption",
    color: QColors.lifestyle1,
    questionniare: (v, viewAll) => <Disruption onDone={v} viewAll={viewAll} />
  },
  {
    label: "Demographics",
    color: QColors.lifestyle2,
    questionniare: (v, viewAll) => <Demographics onDone={v} viewAll={viewAll} />
  },
  {
    label: "Eating Habits 2",
    color: QColors.lifestyle2,
    questionniare: (v, viewAll) => <Eating2 onDone={v} viewAll={viewAll} />
  },
  {
    label: "Lifestyle 2",
    color: QColors.lifestyle2,
    questionniare: (v, viewAll) => <Lifestyle2 onDone={v} viewAll={viewAll} />
  },
  {
    label: "Pain Sensitivity",
    color: QColors.lifestyle2,
    questionniare: (v, viewAll) => (
      <PainSensitivity onDone={v} viewAll={viewAll} />
    )
  },
  {
    label: "Dreams",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Dreams name="redo" onDone={v} viewAll={viewAll} />
    )
  },

  {
    label: "Sleep Assessment",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <SleepAssessment onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Need to Reschedule",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <NeedRescheduling onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Feelings",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Feelings onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  // {
  //   label: "Current State",
  //   color: QColors.sleepReport,
  //   questionniare: (v, viewAll) => (
  //     <CurrentState onDone={v} name="redo" viewAll={viewAll} />
  //   )
  // },
  {
    label: "Food and Drinks",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <FoodDrinks onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  // {
  //   label: "Activities",
  //   color: QColors.sleepReport,
  //   questionniare: (v, viewAll) => (
  //     <Activities onDone={v} name="redo" viewAll={viewAll} />
  //   )
  // },
  {
    label: "Tiredness",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Tiredness onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Feelings",
    color: QColors.restTest,
    questionniare: (v, viewAll) => (
      <FeelingsRest onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Overview",
    color: QColors.restTest,
    questionniare: (v, viewAll) => (
      <Overview onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Thoughts",
    color: QColors.restTest,
    questionniare: (v, viewAll) => (
      <Thoughts onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Tiredness",
    color: QColors.restTest,
    questionniare: (v, viewAll) => (
      <TirednessRest onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Debriefing",
    color: QColors.debriefing,
    questionniare: (v, viewAll) => <Debriefing viewAll={viewAll} />
  }
];

interface Props {}

interface State {
  questionnaire?: number;
  language: Language;
  viewAll: boolean;
}

const ButtonArea = styled.div`
margin: auto;
margin-bottom: 2em;
margin-top: 2em;
text-align: justify;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 800px) {
  width: 70%;
`;

const SegmentBox = styled.div`
  margin: 1em;
`;

class Screening extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questionnaire: undefined,
      language: Language.en,
      viewAll: true
    };
  }

  onClick = (idx: number) => () => {
    this.setState({ questionnaire: idx });
  };

  onDone = () => () => {
    this.setState({ questionnaire: undefined });
  };

  onLanguageChoice = (language: Language) => this.setState({ language });

  render() {
    return (
      <LanguageProvider value={this.state.language}>
        {this.renderInner()}
      </LanguageProvider>
    );
  }

  renderInner() {
    return (
      <div>
        {this.state.questionnaire === undefined ? (
          <SegmentBox>
            <ParticipantID>{getUserId() || ""}</ParticipantID>
            <Ui.Segment>
              <Ui.ButtonGroup size="mini">
                <Ui.Button
                  onClick={() => this.onLanguageChoice(Language.en)}
                  color={
                    this.state.language === Language.en ? "teal" : undefined
                  }
                >
                  <Ui.Flag name="gb" />
                </Ui.Button>
                <Ui.ButtonOr />
                <Ui.Button
                  onClick={() => this.onLanguageChoice(Language.de)}
                  color={
                    this.state.language === Language.de ? "teal" : undefined
                  }
                >
                  <Ui.Flag name="de" />
                </Ui.Button>
              </Ui.ButtonGroup>

              <Ui.ButtonGroup floated="right" size="mini">
                <Ui.Button
                  onClick={() => this.setState({ viewAll: true })}
                  color={this.state.viewAll ? "teal" : undefined}
                >
                  See all
                </Ui.Button>
                <Ui.ButtonOr />
                <Ui.Button
                  onClick={() => this.setState({ viewAll: false })}
                  color={!this.state.viewAll ? "teal" : undefined}
                >
                  Simulate
                </Ui.Button>
              </Ui.ButtonGroup>

              <Ui.Divider horizontal>All Questionnaires</Ui.Divider>

              <Ui.Form widths="equal">
                {Questionnaires.map((q, idx) => (
                  <ButtonArea key={idx}>
                    <Ui.Button
                      fluid
                      size="large"
                      color={q.color as any}
                      onClick={this.onClick(idx)}
                      inverted
                    >
                      {q.label}
                    </Ui.Button>
                  </ButtonArea>
                ))}
              </Ui.Form>
            </Ui.Segment>
          </SegmentBox>
        ) : (
          Questionnaires[this.state.questionnaire].questionniare(
            this.onDone(),
            this.state.viewAll
          )
        )}
      </div>
    );
  }
}

export default Screening;
