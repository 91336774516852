// here the layout of the homepage is established, and calls the sections to be filled in

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import adultsections from "./sections";

import {
  SectionBody,
  HeaderContainer,
  SectionBodySegment,
  Squeeze,
} from "ewoq/components/miscStyles";

enum Version {
  Start,
  Adults,
  EpiKids,
  HealthyKids,
  HealthyAdults,
}

interface Props {}
interface State {
  version: Version;
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      version: Version.Start,
    };
  }

  advancePhase = (newVersion: Version) => () => {
    this.setState({ version: newVersion }, () => console.log(this.state));
  };

  renderSections = () => {
    switch (this.state.version) {
      case Version.HealthyKids: {
        return adultsections;
      }
      case Version.EpiKids: {
        return adultsections;
      }
      case Version.HealthyAdults: {
        return adultsections;
      }
    }
  }; //TODO: instead of different sections, make it as different languages

  render() {
    // const sections = this.renderSections();
    const sections = adultsections;
    return (
      <div>
        <HeaderContainer>
          Participate in a Sleep EEG Experiment!
        </HeaderContainer>
        <Ui.Container>
          {/* {<Ui.Segment>
            <Squeeze>
              <Ui.Form>
                <Ui.Form.Group inline widths="equal">
                  <Ui.Form.Button
                    onClick={() => this.setState({ version: Version.HealthyKids })}
                  >
                    Kinder mit Epilepsie (8-13J) und deren Eltern
                  </Ui.Form.Button>

                  <Ui.Form.Button
                    onClick={() => this.setState({ version: Version.HealthyAdults })}
                  >
                    Adults (18-30J) without epilepsy
                  </Ui.Form.Button>
                  
                  <Ui.Form.Button
                    onClick={() => this.setState({ version: Version.EpiKids })}
                  >
                    Kinder ohne Epilepsie (8-13J) und deren Eltern
                  </Ui.Form.Button>
                </Ui.Form.Group>
              </Ui.Form>
            </Squeeze>
          </Ui.Segment>} */}
          {sections &&
            sections.map((section, idx) => (
              <Ui.Segment key={idx}>
                <span id={`${idx}-home-section`}></span>{" "}
                <SectionBodySegment>
                  <Ui.Divider horizontal>{section.title}</Ui.Divider>
                  <SectionBody>{section.body}</SectionBody>
                </SectionBodySegment>
              </Ui.Segment>
            ))}
        </Ui.Container>
      </div>
    );
  }
}

export default Home;
