import React from "react";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import { En, De, l } from "ewoq/components/Language";
// import {Text} from 'react-native'

import styled from "styled-components";
const Smiley = styled.span`
  font-size: 2em;
`;

export const feelings = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      {
        // question: <Smiley>😀</Smiley>,
        question: "🥳",
        labels: [
          l({ en: "Not at all", de: "Überhaupt nicht" }),
          l({ en: "Interested", de: "Interessiert" }),
          l({ en: "Excited",    de: "Begeistert" }),
        ]
      },
      {
        // question: <Smiley>😀</Smiley>,
        question: "😀",
        labels: [
          l({ en: "Not at all", de: "Überhaupt nicht" }),
          l({ en: "Happy",      de: "Zufrieden" }),
          l({ en: "Cheerful",   de: "Fröhlich" }),
        ]
      },
      {
        // question: <Smiley>😢</Smiley>,
        question: "😢",
        labels: [
          l({ en: "Not at all", de: "Überhaupt nicht" }),
          l({ en: "Sad",        de: "Traurig" }),
          l({ en: "Miserable",  de: "Jämmerlich" }),
        ]
      },
      {
        // question: <Smiley>😠</Smiley>,
        question: "😠",
        labels: [
          l({ en: "Not at all",   de: "Überhaupt nicht" }),
          l({ en: "Irritated",    de: "Irritiert" }),
          l({ en: "Angry",        de: "Wütend" }),
        ]
      },
      {
        // question: <Smiley>😨</Smiley>,
        question: "😨",
        labels: [
          l({ en: "Not at all",         de: "Überhaupt nicht" }),
          l({ en: "Concerned/nervous",  de: "Besorgt/nervös" }),
          l({ en: "Afraid",             de: "Ängstlich" }),
        ]
      }
    ]}
  />
);

export const extraFeelings = (v: any) => (
  <MultipleChoice.Question
    onValid={v}
    options={[
      { text: l({ en: "Busy",        de: "Beschäftigt" }) },
      { text: l({ en: "Frustrated",  de: "Frustriert" }) },
      { text: l({ en: "Embarrassed", de: "Verlegen" }) },
      { text: l({ en: "Confused",    de: "Verwirrt" }) },
      { text: l({ en: "Disgusted",   de: "Angeekelt" }) },
      { text: l({ en: "Bored",       de: "Gelangweilt" }) },
      { text: l({ en: "Lonely",      de: "Einsam" }) },
      { text: l({ en: "Other",       de: "Andere" }) , extraInput: "string" }
    ]}
  />
);
